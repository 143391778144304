import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialViewComponent } from '@common/shared/components/material-view/material-view.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedPipesModule } from '@common/pipes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatIconModule, MatTooltipModule, MatButtonModule, SharedPipesModule],
  declarations: [MaterialViewComponent],
  exports: [MaterialViewComponent]
})
export class MaterialViewModule {}
