<form (ngSubmit)="onSubmit()">
  <header class="pb-4 text-center text-2xl border-b-1-gray-500 font-bold">
    Замечания к предоставленным материалам:
  </header>
  <div class="flex flex-col gap-y-1 min-w-[400px]">
    <com-form-checkbox
      [control]="formGroup.controls.informationErrors"
      [label]="E_MATERIAL_VIOLATION.INFORMATION_ERRORS"
    ></com-form-checkbox>
    <com-form-checkbox
      [control]="formGroup.controls.formatMismatch"
      [label]="E_MATERIAL_VIOLATION.FORMAT_MISMATCH"
    ></com-form-checkbox>
  </div>
  <footer class="flex w-full justify-end gap-2 mt-6">
    <button mat-flat-button type="button" color="warn" (click)="onClose()">
      <span>Отмена</span>
    </button>
    <button mat-flat-button color="primary" type="submit">
      <span>Сохранить</span>
    </button>
  </footer>
</form>
