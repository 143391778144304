import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@common/shared';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly _keycloak: KeycloakService,
    private readonly _snackBar: MatSnackBar
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case HttpStatusCode.BadRequest: {
              const { error } = err;
              if (error) {
                this._snackBar.openFromComponent(SnackbarComponent, {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 5000,
                  data: {
                    icon: 'heroicons_outline:exclamation',
                    iconClass: 'text-red-500',
                    title: error
                  },
                  panelClass: 'committees-app'
                });
              }
              break;
            }
            case HttpStatusCode.Unauthorized: {
              this._keycloak.login({
                redirectUri: window.location.href
              });
            }
          }
        }
        return throwError(err.message);
      })
    );
  }
}
