<div class="flex">
    <div
        class="inline-flex items-center justify-center w-[26px] h-[26px] overflow-hidden rounded-full z-90"
        [ngClass]="{'bg-green-400': !isBusy, 'bg-red-700': isBusy, 'opacity-30': !isSelected, 'opacity-90': isSelected}"
        (click)="onBadgeClick()"
    >
        <span class="text-xs text-white mt-[1px]">{{ title }}</span>
    </div>
</div>

