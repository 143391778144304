import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'com-form-toggle',
  templateUrl: './form-toggle.component.html'
})
export class FormToggleComponent {
  @Input() control: AbstractControl;
  @Input() beforeLabel: string;
  @Input() afterLabel: string;
  @Input() beforeLabelClass: string;
  @Input() formControl: string;
}
