import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IEmployeeWithInfo,
  IAppRole,
  IEmployeeCurrentUserInfo,
  IEmployeeFull,
  IOption,
  IUserData,
  IUserRequest
} from '@common/types';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AppRolesEnum } from '@common/enums';
import { IUpdateEmployee } from '@common/dialogs';
import { omit } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private readonly employeeCurrentUserInfo = new BehaviorSubject<IEmployeeCurrentUserInfo>(null);

  constructor(private readonly _http: HttpClient) {
    /**
     * Load Current Employee Info
     */
    this.retrieveEmployeeCurrentUserInfo().subscribe((user) => {
      this.employeeCurrentUserInfo.next(user);
    });
  }

  public updateCurrentUser(user: Partial<IEmployeeCurrentUserInfo>): void {
    this.employeeCurrentUserInfo.next({
      ...this.employeeCurrentUserInfo.value,
      ...user
    });
  }

  public getCurrentUserId(): string {
    return this.employeeCurrentUserInfo.value.id;
  }

  get employeeCurrentUserInfo$(): Observable<IEmployeeCurrentUserInfo> {
    return this.employeeCurrentUserInfo.asObservable();
  }

  public currentUserIsAdmin(): boolean {
    return this.employeeCurrentUserInfo.getValue()?.appRole.keyCloakName === AppRolesEnum.ADMIN;
  }

  public retrieveAppRoles(): Observable<IOption[]> {
    return this._http.get<IAppRole[]>('api/AppRole');
  }

  public retrievePositions(): Observable<IOption[]> {
    return this._http.get<IOption[]>('api/Position');
  }

  public positionSearch(name = ' '): Observable<IOption[]> {
    const params = new HttpParams().set('name', name).set('page', 0).set('pageSize', 25);

    return this._http.get<IOption[]>('api/Position/GetWithSeacrh', { params });
  }

  public retrieveEmployeesWithInfo(): Observable<IEmployeeWithInfo[]> {
    return this._http.get<IEmployeeWithInfo[]>('api/Employee/employeeWithInfo');
  }

  public retrieveEmployeeById(employeeId: string): Observable<IEmployeeFull> {
    return this._http.get<IEmployeeFull>(`api/Employee/${employeeId}`);
  }

  public retrieveEmployeeByAdmin(options?: IUserRequest): Observable<IUserData> {
    let params = new HttpParams().set('page', options?.page || 0).set('pageSize', options?.pageSize || 10);
    if (options?.positionId) {
      params = params.append('positionId', options.positionId);
    }
    if (options?.appRoleId) {
      params = params.append('appRoleId', options.appRoleId);
    }
    if (options?.divisionId) {
      params = params.append('divisionId', options.divisionId);
    }
    if (options?.name) {
      params = params.append('name', options.name);
    }

    return this._http.get<IUserData>('api/Employee/employeeByAdmin', { params });
  }

  public retrieveEmployeeSearchForSelect(
    search = '',
    page = 0,
    pageSize = 30
  ): Observable<IEmployeeWithInfo[]> {
    return this._http
      .post<IEmployeeWithInfo[]>('api/Employee/searchForSelect', {
        search,
        page,
        pageSize
      })
      .pipe(map((res) => res.map((item) => ({ ...item, fullName: item.fullName.trim() }))));
  }

  public retrieveEmployeeCurrentUserInfo(): Observable<IEmployeeCurrentUserInfo> {
    return this._http.get<IEmployeeCurrentUserInfo>('api/Employee/currentUserInfo').pipe(
      map((res) => ({
        ...res,
        fullName: `${res.lastName} ${res.name} ${res.patronymic.charAt(0)}`,
        shortName: `${res.lastName} ${res.name.charAt(0)} ${res.patronymic.charAt(0)}`
      }))
    );
  }

  public updateEmployee(body: IUpdateEmployee): Observable<void> {
    return this._http.put<void>(`api/Employee/${body.employeeId}/UpdateEmployee`, omit(body, 'employeeId'));
  }

  public activateEmployee(id: string): Observable<void> {
    return this._http.post<void>(`api/Employee/${id}/activate`, null);
  }

  public deactivateEmployee(id: string): Observable<void> {
    return this._http.post<void>(`api/Employee/${id}/deactivate`, null);
  }
}
