import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAttachment, IAttachmentDto } from '@common/types';
import { AttachmentTypeEnum } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class AgendaItemAttachmentService {
  constructor(private readonly _http: HttpClient) {}

  createAgendaItemAttachment(data: IAttachment): Observable<IAttachmentDto> {
    const formData = new FormData();
    formData.append('committeeEventId', data.committeeEventId);
    formData.append('agendaItemId', data.agendaItemId);
    formData.append('attachmentType', `${data.attachmentType}`);
    formData.append('required', `${data.required}`);

    if (data.eventResolutionId) {
      formData.append('eventResolutionId', data.eventResolutionId);
    }

    if (data.attachmentType === AttachmentTypeEnum.LINK) {
      formData.append('link', data.link);
    } else if (data.attachmentType === AttachmentTypeEnum.FILE) {
      formData.append('file', data.file, data.file.name);
    }

    return this._http.post<IAttachmentDto>(
      'api/AgendaItemAttachment',
      formData
    );
  }

  deleteAgendaItemAttachment(
    committeeEventId: string,
    attachmentId: string
  ): Observable<void> {
    return this._http.delete<void>('api/AgendaItemAttachment', {
      params: { committeeEventId, attachmentId }
    });
  }
}
