import { getFullRruleString, IEmployeeOption, IMemberDto, RRuleModel } from '@common/types';
import { difference } from 'lodash';
import { IMemberFormValue } from '@common/shared/components/form-groups/members/members.types';
import { RoleAccessesEnum } from '@common/enums';

const findChangeInMembers = (oldVal: IMemberDto[], val: IMemberFormValue[]): boolean => {
  const map = val.reduce(
    (acc, m) => {
      acc[String(m.employeeOption.id)] = true;
      return acc;
    },
    {} as Record<string, boolean>
  );

  for (const member of oldVal) {
    if (!map[member.employee.id]) {
      return true;
    }
  }

  return false;
};

const isMemberWasDelete = (oldMembers: IMemberDto[], members: IMemberFormValue[]): boolean => {
  let result = true;
  const oldMembersMap = oldMembers.reduce(
    (acc, m) => {
      acc[m.employee.id] = true;
      return acc;
    },
    {} as Record<string, boolean>
  );

  members.map((m) => {
    if (!oldMembersMap[m.employeeOption.id as string]) {
      result = false;
    }
  });

  return result;
};

export const memberIsChange = (oldMembers: IMemberDto[], members: IMemberFormValue[]): boolean => {
  const oldList = getRequired(oldMembers);
  const newList = getRequired(members);

  if (newList.length > oldList.length) return true;

  if (isMemberWasDelete(oldList, newList)) {
    return false;
  }
  return findChangeInMembers(oldList, newList);
};

const holidaysIsChange = (oldVal: RRuleModel, val: RRuleModel): boolean => {
  if (oldVal.excludeHolidays !== val.excludeHolidays) return true;
  if (oldVal.excludeSaturdays !== val.excludeSaturdays) return true;
  return oldVal.excludeSundays !== val.excludeSundays;
};

const durationIsChange = (oldVal: RRuleModel, val: RRuleModel): boolean => {
  return oldVal.duration !== val.duration;
};

const startDateIsChange = (oldVal: RRuleModel, val: RRuleModel): boolean => {
  return new Date(oldVal.dtStart).getTime() !== new Date(val.dtStart).getTime();
};

const excludeLunchTimeChange = (oldVal: RRuleModel, val: RRuleModel): boolean => {
  if (oldVal.excludeLunchTime !== val.excludeLunchTime) return true;
};

export const rruleIsChange = (oldRrule: RRuleModel, rrule: RRuleModel): boolean => {
  if (durationIsChange(oldRrule, rrule)) return true;
  if (holidaysIsChange(oldRrule, rrule)) return true;
  if (startDateIsChange(oldRrule, rrule)) return true;
  if (excludeLunchTimeChange(oldRrule, rrule)) return true;
  return getFullRruleString(oldRrule) !== getFullRruleString(rrule);
};

export const roomsIsChanged = (oldRooms: string[], rooms: string[]): boolean => {
  if (oldRooms.length > rooms.length && rooms.every((r) => oldRooms.includes(r))) {
    return false;
  }

  if (oldRooms.length !== rooms.length) return true;

  return difference(oldRooms, rooms).length !== 0;
};

export const getUniqOptions = (members: IMemberDto[]): IEmployeeOption[] => {
  const userIdMap: Record<string, boolean> = {};
  return members
    .filter(({ employeeOption }) => {
      if (!employeeOption) return false;

      const userId = employeeOption.id as string;
      if (!userIdMap[userId]) {
        userIdMap[userId] = true;
      } else {
        return false;
      }

      return true;
    })
    .map(({ employeeOption }) => employeeOption);
};

export const getRequired = <T extends IMemberFormValue | IMemberDto>(members: T[]): T[] => {
  return members.filter((m) => m.role.accesses.includes(RoleAccessesEnum.REQUIRED_TO_ATTEND_THE_COMMITTEE));
};
