import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'com-right-panel-block',
  templateUrl: './right-panel-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelBlockComponent {
  @Input() public label = '';
  @Input() public labelClass = 'text-hint';
}
