import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IUser } from '../types/user';
import { AuthUtils } from '../utils/auth';
import { environment } from '../../environments/environment';
import { UcpService } from './ucp.service';

@Injectable()
export class AuthService {
  private readonly keycloak = this.keycloakService.getKeycloakInstance();
  private readonly user = new BehaviorSubject<IUser>(null);

  constructor(private readonly keycloakService: KeycloakService, private readonly http: HttpClient) {
    this.loadUser();
  }

  get accessToken(): string {
    return this.keycloak.token || UcpService.token;
  }

  get user$(): Observable<IUser> {
    return this.user.asObservable();
  }

  public logout$(): Observable<void> {
    localStorage.removeItem('token');
    return from(this.keycloakService.logout());
  }

  private loadUser(): void {
    this.user.next(AuthUtils.getKeycloakUser(this.accessToken));
  }
}
