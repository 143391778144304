export enum ErrorsEnum {
  SelectOptionError = 'SelectOptionError',
  MultiselectOptionError = 'MultiselectOptionError',
  WeeklyError = 'WeeklyError',
  MonthlyError = 'MonthlyError',
  QuarterlyError = 'QuarterlyError',
  HalfAYearlyError = 'HalfAYearlyError',
  YearlyError = 'YearlyError',
  RangeError = 'RangeError',
  TimesError = 'TimesError',
  IntervalError = 'IntervalError',
  MemberTypesError = 'MemberTypesError'
}
