import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  CommitteeIntersection,
  DateOfDay,
  DayInfo,
  FreeDay,
  FreeSlot,
  Option,
  TransferInfo
} from '@common/dialogs/planning-dialog/types';
import { IntersectionTab } from '@common/dialogs/planning-dialog/modules/planning-intersection/const';
import { BusyTimeline } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/types';
import { IEmployeeShort } from '@common/types';
import { GetBusyDateRangePipe } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/pipes/get-busy-date-range.pipe';
import { toISO } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { getFreeDays, getPlanningDate, startOfDayString } from '@common/dialogs/planning-dialog/utils';
import { FreeSlotSelect } from '@common/dialogs/planning-dialog/modules/planning-intersection/types';
import { BusyTimelineService } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/services/busy-timeline.service';
@Component({
  selector: 'com-planning-intersection',
  templateUrl: 'planning-intersection.component.html',
  providers: [GetBusyDateRangePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningIntersectionComponent implements OnChanges {
  @Input() events: CommitteeIntersection[] = [];
  @Input() dayInfo: DayInfo | null;
  @Input() transferInfo: TransferInfo | undefined;
  @Input() dayLoading: boolean;
  @Input() eventsLoading: boolean;
  @Input() employees: IEmployeeShort[];
  @Input() tabs: Option<IntersectionTab>[] = [];
  @Input() currentTab: IntersectionTab;
  @Input() planningStart: string;
  @Input() planningEnd: string;
  @Input() draft: boolean;
  @Input() duration: number;
  @Input() freeSlots: Record<DateOfDay, FreeSlot[]>;

  @Output() tableSelect = new EventEmitter<CommitteeIntersection>();
  @Output() tabChange = new EventEmitter<IntersectionTab>();
  @Output() eventTransfer = new EventEmitter<TransferInfo>();
  @Output() save = new EventEmitter<void>();
  @Output() saveAsDraft = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  public timeline: BusyTimeline[] = [];
  public freeDays: FreeDay[] = [];
  public selectedFreeSlotDay: string | null = null;
  public selectedTransferDate: FreeSlotSelect | null = null;

  protected Boolean = Boolean;
  protected IntersectionTab = IntersectionTab;

  constructor(private busyTimelineService: BusyTimelineService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('dayInfo' in changes && this.dayInfo) {
      this.timeline = this.busyTimelineService.getTimeline(
        this.dayInfo,
        this.employees,
        this.planningStart,
        this.planningEnd
      );
    }
    if ('freeSlots' in changes && this.freeSlots) {
      this.freeDays = getFreeDays(this.freeSlots);
      this.initSelectedFreeSlotDay(this.dayInfo.dayStart);
    }
    if ('transferInfo' in changes && this.transferInfo) {
      this.selectedFreeSlotDay = this.transferInfo.transferDay;
    }
    if ('loading' in changes) {
      this.selectedTransferDate = null;
    }
  }

  private initSelectedFreeSlotDay(date: string): void {
    this.selectedFreeSlotDay = date;
    if (!this.freeSlots[date] && this.freeDays[0]) {
      this.selectedFreeSlotDay = this.freeDays[0]?.date;
    }
  }

  public transferEvent(): void {
    this.eventTransfer.emit({
      id: this.dayInfo.id,
      transferDateInterval: getPlanningDate(this.selectedTransferDate.date, this.duration),
      transferStart: toISO(this.selectedTransferDate.date),
      transferDay: startOfDayString(this.selectedTransferDate.date),
      select: this.selectedTransferDate
    });
  }
}
