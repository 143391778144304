import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Centrifuge from 'centrifuge';
import {
  ICommitteeEventAgendaItemSocket,
  ICommitteeEventContinueSocket,
  ICommitteeEventSocket,
  IEmployeeOnline,
  IEmployeeStatusSocket,
  IQuorumSocket,
  Socket
} from '@common/types';
import { _uniqueArray } from '@common/utils/util';
import { Centrifugo } from '@common/constants';
import { JitsuLoggerService } from '@common/services/jitsu-logger.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  quorum$ = new Subject<IQuorumSocket>();
  employeeOnline$ = new Subject<IEmployeeStatusSocket[]>();
  materialStatus$ = new Subject<IEmployeeStatusSocket[]>();
  startCommitteeEvent$ = new Subject<ICommitteeEventSocket>();
  toResolutionEvent$ = new Subject<ICommitteeEventSocket>();
  pauseCommitteeEvent$ = new Subject<ICommitteeEventSocket>();
  closeCommittee$ = new Subject<ICommitteeEventSocket>();
  resolutionVote$ = new Subject<any>();
  newResolution$ = new Subject<any>();
  updateResolution$ = new Subject<any>();
  deleteResolution$ = new Subject<any>();
  continueCommitteeEvent$ = new Subject<ICommitteeEventContinueSocket>();
  addMaterial$ = new Subject<any>();
  removeMaterial$ = new Subject<any>();
  addViolationMaterial$ = new Subject<any>();
  addDisciplineViolation$ = new Subject<any>();
  startDiscussionAgendaItem$ = new Subject<ICommitteeEventAgendaItemSocket>();
  viewMaterial$ = new Subject<any>();
  statusPreResolution$ = new Subject<any>();
  loadMembers$ = new Subject<ICommitteeEventSocket>();

  private _centrifuge: Centrifuge;
  private _centrifugeCall: Centrifuge;
  private _centrifugeEventPage: Centrifuge;
  private _jwtToken = '';

  constructor(private readonly _jitsuLoggerService: JitsuLoggerService) {}

  connect(parameters: any, jwtToken: string, userId: string): void {
    this._centrifuge = new Centrifuge(parameters);

    this._jwtToken = jwtToken;
    this._centrifuge.setToken(jwtToken);

    this._centrifuge.subscribe(`committee:${userId}`, (ctx: { data: Socket }) => {
      if (ctx.data) {
        switch (ctx.data.type) {
          case 'continueCommitteeEvent': {
            this.continueCommitteeEvent$.next(ctx.data.data);
            break;
          }
        }
      }
    });

    this._centrifuge.connect();
  }

  connectToCall(parameters: any, committeeEventId: string, url: string, path: string): void {
    this._centrifugeCall = new Centrifuge(parameters);

    this._centrifugeCall.setToken(this._jwtToken);

    this._centrifugeCall
      .subscribe(`committee:${committeeEventId}`, (ctx: { data: Socket }) => {
        this.handlerNewMessage('call', ctx.data);
        if (ctx.data) {
          switch (ctx.data.type) {
            case 'quorum': {
              this.quorum$.next(ctx.data.data);
              break;
            }
            case 'employeeOnline': {
              this.employeeOnline$.next(ctx.data.data);
              break;
            }
            case 'materialStatus': {
              this.materialStatus$.next(ctx.data.data);
              break;
            }
            case 'startCommitteeEvent': {
              this.startCommitteeEvent$.next(ctx.data.data);
              break;
            }
            case 'toResolutionEvent': {
              this.toResolutionEvent$.next(ctx.data.data);
              break;
            }
            case 'pauseCommitteeEvent': {
              this.pauseCommitteeEvent$.next(ctx.data.data);
              break;
            }
            case 'closeCommittee': {
              this.closeCommittee$.next(ctx.data.data);
              break;
            }
            case 'vote': {
              this.resolutionVote$.next(ctx.data.data);
              break;
            }
            case 'newResolution': {
              this.newResolution$.next(ctx.data.data);
              break;
            }
            case 'updateResolution': {
              this.updateResolution$.next(ctx.data.data);
              break;
            }
            case 'deleteResolution': {
              this.deleteResolution$.next(ctx.data.data);
              break;
            }
            case 'addMaterial': {
              this.addMaterial$.next(ctx.data.data);
              break;
            }
            case 'removeMaterial': {
              this.removeMaterial$.next(ctx.data.data);
              break;
            }
            case 'addViolationMaterial': {
              this.addViolationMaterial$.next(ctx.data.data);
              break;
            }
            case 'addDisciplineViolation': {
              this.addDisciplineViolation$.next(ctx.data.data);
              break;
            }
            case 'startDiscussionAgendaItem': {
              this.startDiscussionAgendaItem$.next(ctx.data.data);
              break;
            }
          }
        }
      })
      .on('unsubscribe', (data) => this.handleUnsubscribe('call', data, url, path))
      .on('subscribe', (data) => this.handleSubscribe('call', data))
      .on('error', (data) => this.handleSubscribeError('call', data));

    this._centrifugeCall.on('connect', (data) => this.handlerConnect('call', data));
    this._centrifugeCall.on('disconnect', () => this.handlerDisconnect('call', url, path));
    this._centrifugeCall.on('error', (data) => console.log(data));

    this._centrifugeCall.connect();
  }

  disconnectCall(): void {
    this._centrifugeCall.disconnect();
  }

  connectToEventPage(parameters: any, committeeEventId: string, url: string, path: string): void {
    this._centrifugeEventPage = new Centrifuge(parameters);

    this._centrifugeEventPage.setToken(this._jwtToken);

    this._centrifugeEventPage
      .subscribe(`committee:${committeeEventId}`, (ctx: { data: Socket }) => {
        this.handlerNewMessage('event', ctx.data);
        if (ctx.data) {
          switch (ctx.data.type) {
            case 'vote': {
              this.resolutionVote$.next(ctx.data.data);
              break;
            }
            case 'newResolution': {
              this.newResolution$.next(ctx.data.data);
              break;
            }
            case 'updateResolution': {
              this.updateResolution$.next(ctx.data.data);
              break;
            }
            case 'materialStatus': {
              this.materialStatus$.next(ctx.data.data);
              break;
            }
            case 'deleteResolution': {
              this.deleteResolution$.next(ctx.data.data);
              break;
            }
            case 'addMaterial': {
              this.addMaterial$.next(ctx.data.data);
              break;
            }
            case 'removeMaterial': {
              this.removeMaterial$.next(ctx.data.data);
              break;
            }
            case 'addViolationMaterial': {
              this.addViolationMaterial$.next(ctx.data.data);
              break;
            }
            case 'addDisciplineViolation': {
              this.addDisciplineViolation$.next(ctx.data.data);
              break;
            }
            case 'startDiscussionAgendaItem': {
              this.startDiscussionAgendaItem$.next(ctx.data.data);
              break;
            }
            case 'viewMaterial': {
              this.viewMaterial$.next(ctx.data.data);
              break;
            }
            case 'statusPreResolution': {
              this.statusPreResolution$.next(ctx.data.data);
              break;
            }
            case 'loadMembers': {
              this.loadMembers$.next(ctx.data.data);
              break;
            }
          }
        }
      })
      .on('unsubscribe', (data) => this.handleUnsubscribe('event', data, url, path))
      .on('subscribe', (data) => this.handleSubscribe('event', data))
      .on('error', (data) => this.handleSubscribeError('event', data));

    this._centrifugeEventPage.on('connect', (data) => this.handlerConnect('event', data));
    this._centrifugeEventPage.on('disconnect', () => this.handlerDisconnect('event', url, path));
    this._centrifugeEventPage.on('error', (data) => console.log(data));

    this._centrifugeEventPage.connect();
  }

  disconnectEventPage(): void {
    this._centrifugeEventPage.disconnect();
  }

  handleUnsubscribe(page: string, data: any, url: string, path: string): void {
    console.log(`Unsubscribing on channel ${data.channel}`);
    this._jitsuLoggerService.logEvent(
      Centrifugo.centrifugoUnsubscribe,
      {
        page,
        channel: data.channel
      },
      {
        page: {
          url,
          path
        }
      }
    );
  }

  handlerNewMessage(page: string, data: any): void {
    console.log('Centrifugo  new message: ', data);
    this._jitsuLoggerService.logEvent(Centrifugo.centrifugoNewMessage, {
      page,
      data: data
    });
  }

  handleSubscribe(page: string, data: any): void {
    console.log(`Subscribing on channel ${data.channel}`);
    this._jitsuLoggerService.logEvent(Centrifugo.centrifugoSubscribe, {
      page,
      channel: data.channel
    });
  }

  handleSubscribeError(page: string, data: any): void {
    console.log(`Error subscribing on channel ${data.channel}:${data.message}`);
    this._jitsuLoggerService.logEvent(Centrifugo.centrifugoErrorSubscribe, {
      page,
      channel: data.channel,
      message: data.message,
      code: data.code
    });
  }

  handlerConnect(page: string, data: any): void {
    console.log(`Connected with client ID ${data.client}`);
    this._jitsuLoggerService.logEvent(Centrifugo.centrifugoConnect, { page });
  }

  handlerDisconnect(page: string, url: string, path: string): void {
    console.log('Disconnected with client ID');
    this._jitsuLoggerService.logEvent(
      Centrifugo.centrifugoDisconnect,
      { page },
      {
        page: {
          url,
          path
        }
      }
    );
  }
}
