<ekp-button
  #button
  [size]="size"
  [variant]="variant"
  [ngClass]="{
    'cursor-none': disabled
  }"
>
  <ng-content></ng-content>
</ekp-button>


