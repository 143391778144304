export enum EventTypesEnum {
  COMMITTEE = 'COMMITTEE',
  CHAIRMAN = 'CHAIRMAN',
  COCHAIR = 'COCHAIR',
  CHAIRMAN_ASSISTANT = 'SECRETARY',
  MANDATORY = 'MANDATORY',
  INVITED = 'INVITED'
}

export enum EventTypeNumbersEnum {
  NULL = 0,
  COMMITTEE = 1,
  MEETING = 2
}
