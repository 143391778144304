<button *ngFor="let option of legend" class="flex gap-2 justify-center items-center">
  <div class="rounded-[2px] overflow-hidden">
    <div
      [style.min-height.px]="cellSize"
      [style.min-width.px]="cellSize"
      [ngClass]="option.class"
    ></div>
  </div>
  <span class="text-sm text-foreground"> {{ option.name }}</span>
</button>
