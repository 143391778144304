import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[entityStatus]'
})
export class EntityStatusDirective implements OnChanges {
    @Input() selected = true;
    @Input() isBusy: boolean;

    constructor(private el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if ('selected' in changes || 'isBusy' in changes) {
            this.selected
                ? this.el.nativeElement.classList.remove('opacity-70')
                : this.el.nativeElement.classList.add('opacity-70');

            this.isBusy
                ? this.el.nativeElement.classList.add('text-red-500')
                : this.el.nativeElement.classList.remove('text-red-500');
        }
    }
}
