<div
  *ngIf="employee; else noData"
  class="flex items-center"
  [ngClass]="{ 'py-2': showImage }"
  (mouseleave)="toggleRoles(false)"
>
  <ng-container *ngIf="showImage">
    <div
      class="flex relative shrink-0 justify-center items-center ring rounded-full text-xl bg-gray-200 ml-1 {{
        statusColorClass
      }}"
      [ngClass]="{
        'min-w-6 w-6 min-h-6 h-6': isSmall,
        'min-w-12 w-12 min-h-12 h-12': !isSmall
      }"
      [matTooltip]="showContent ? '' : employee.fullName"
    >
      <img
        *ngIf="employee.avatar"
        class="rounded-full"
        [src]="employee.avatar"
        (error)="onErrorImg()"
        [alt]="employee.fullName"
      />
      <span *ngIf="!employee.avatar">{{ employee.fullName | slice: 0 : 1 }}</span>
      <div
        *ngIf="employee | MemberCheckAccess: RoleAccessesEnum.TAKE_ACCOUNT_OF_COUNTING_QUORUM"
        class="absolute top-0 right-0"
      >
        <mat-icon
          class="icon-size-4 text-green-600"
          svgIcon="heroicons_solid:shield-check2"
          matTooltip="Участвует в расчете кворума"
        ></mat-icon>
      </div>
    </div>
    <div class="flex gap-x-0.5 self-end relative right-4 min-w-4">
      <ng-container *ngIf="showContent && employee.roles?.length">
        <span
          *ngIf="employee.roles.length === 1"
          [matTooltip]="employee.roles[0].name"
          class="flex items-center justify-center role-circle"
        >
          {{ employee.roles[0].name | slice: 0 : 1 }}
        </span>
        <ng-container *ngIf="employee.roles.length > 1">
          <span
            *ngIf="!rolesOpened"
            (mouseenter)="toggleRoles(true)"
            class="flex items-center justify-center role-circle"
          >
            +{{ employee.roles.length }}
          </span>
          <ng-container *ngIf="rolesOpened">
            <span
              *ngFor="let role of employee.roles; index as i"
              class="flex items-center justify-center role-circle"
              [matTooltip]="role.name"
            >
              {{ role.name | slice: 0 : 1 }}
            </span>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <div *ngIf="showContent" class="flex flex-col">
    <span *ngIf="showRole && employee.role" class="truncate text-md mb-1 font-semibold  {{ maxWidth }}">
      {{ employee.role.name }}
    </span>
    <span class="flex flex-col">
      <span
        *ngIf="employee?.id"
        class="truncate whitespace-nowrap text-base {{ maxWidth }}"
        [ngClass]="{
          'text-gray-500 line-through': delegateTo,
          'text-blue-500 underline cursor-pointer': showLink && !delegateTo,
          'opacity-70': employee.isActive !== undefined && !employee.isActive
        }"
        [matTooltip]="delegationReason ? 'Причина отсутствия: ' + delegationReason.name : null"
        (click)="showLink && !delegateTo ? openEmployeePanel($event, employee.id) : null"
      >
        {{ employee.fullName || employee.shortName }}
      </span>
      <span
        *ngIf="employee.isActive !== undefined && !employee.isActive"
        class="text-red-500 font-semibold text-[12px]"
      >
        Заблокирован
      </span>
      <span *ngIf="!employee?.id">(вакансия)</span>
      <span
        *ngIf="delegateTo"
        class="truncate cursor-pointer whitespace-nowrap text-blue-500 underline  {{ maxWidth }} "
        (click)="showLink ? openEmployeePanel($event, delegateTo.employee?.id) : null"
      >
        {{ delegateTo.employee?.fullName || delegateTo.employee?.shortName }}
      </span>
    </span>
    <span
      *ngIf="showPosition && employee.position && employee.isActive"
      class="text-gray-400 truncate"
      [matTooltip]="employee.position.name"
    >
      {{ employee.position.name }}
    </span>
  </div>
</div>
<ng-template #noData>-</ng-template>
