export enum DrawerPage {
  Delegation = 'delegation',
  MeetingDate = 'meetingDate',
  AtypicalSolution = 'atypicalSolution',
  CancelCommittee = 'CancelCommittee',
  AgendaItem = 'AgendaItem',
  Protocol = 'Protocol'
}

export enum QuorumColors {
  Gray = 'bg-gray-400',
  Green = 'bg-green-500',
  Red = 'bg-red-500'
}

export enum StatusColors {
  Gray = 'text-gray-400',
  Green = 'text-green-500',
  Red = 'text-red-500'
}

export enum StatusPointColors {
  Gray = 'bg-gray-400',
  Green = 'bg-green-500',
  LightGreen = 'bg-green-400',
  Red = 'bg-red-500',
  Yellow = 'bg-yellow-400'
}

export enum CalendarGroupsEnum {
  EVENT,
  ROLE
}

export enum EventActiveTypeEnum {
  CALL = 'call',
  CALENDAR = 'calendar'
}

export enum CalendarPeriodEnum {
  DAY,
  WEEK,
  MONTH
}
