<div class="flex gap-2 h-5 text-sm">
  <div class="flex gap-2">
    <div class="h-4 w-4 border rounded-md bg-[#e15757]/50"></div>
    <span> Время занято </span>
  </div>
  <div class="flex gap-2">
    <div class="h-4 w-4 border rounded-md bg-[#f1ee76]/50"></div>
    <span> Плановое время комитета </span>
  </div>
</div>
