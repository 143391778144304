<div class="relative flex flex-col gap-4 w-full h-full max-h-[90vh]" [formGroup]="formGroup">
  <div class="absolute top-0 right-0">
    <button mat-dialog-close="">
      <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </div>

  <div class="text-2xl font-semibold tracking-tight text-center">{{ props.title }}</div>

  <div class="flex flex-col gap-2 grow overflow-y-hidden">
    <div
      class="w-full flex justify-center items-center h-[120px] animate-pulse bg-gray-100 dark:bg-gray-200 rounded-md"
      [ngClass]="{ 'invisible h-0 w-0': (isFirstInit$ | async) === false }"
      role="status"
    >
      <div class="flex gap-2 justify-center items-center">
        <mat-spinner class="w-10" [diameter]="ButtonSpinnerDiameter"></mat-spinner>
        <span> Загружается график.. </span>
      </div>
    </div>

    <div class="flex flex-col" [ngClass]="{ 'invisible h-0 w-0': (isFirstInit$ | async) === true }">
      <com-form-block class="flex gap-2 pb-2">
        <h2>
          Внеплановое событие будет выставлено в график вне заданной периодичности проведения комитета.
          Выберите дату и время:
        </h2>
        <div class="flex flex-col relative mb-2">
          <div class="flex justify-between items-end">
            <mat-form-field class="w-full max-w-70">
              <input matInput type="datetime-local" formControlName="eventDate" [min]="currentDate" />
            </mat-form-field>
            <div class="flex flex-col text-sm mr-5">
              <span class="font-bold">Дата на графике:</span>
              <span>{{ forecastStart | dateRu }}</span>
            </div>
          </div>

          <mat-error
            *ngIf="formGroup.controls.eventDate.errors as errors"
            class="text-sm absolute top-[38px]"
          >
            <ng-container *ngIf="errors.required"> Поле обязательно для заполнения </ng-container>
            <ng-container *ngIf="errors.min">
              Вы не можете создать событие, которое произойдет до текущего времени
            </ng-container>
          </mat-error>
        </div>
      </com-form-block>

      <div
        *ngIf="isError$ | async; else intersectionTimeline"
        class="w-full flex justify-center items-center h-[120px] border border-red-500 border-dashed rounded-md"
      >
        <div class="flex gap-2 max-w-[520px] justify-center items-center">
          <mat-icon class="text-red-500 icon-size-8" svgIcon="heroicons_outline:calendar-days"></mat-icon>
          <span class="text-red-500">
            К сожалению, сервис календаря временно недоступен. Мы не можем проверить пересечения с другими событиями.
          </span>
        </div>
      </div>

      <ng-template #intersectionTimeline>
        <div
          *ngIf="timelineEntities$ | async | isTimelineEntityBusy; else noIntersectionsBlock"
          class="flex flex-col"
        >
          <div class="flex justify-between pr-[22px]">
            <com-timeline-legend class="pl-[10px] mt-[10px]"></com-timeline-legend>
          </div>
          <com-intersection-timeline
            [isLoading]="isLoading$ | async"
            [currentUser]="employeeService.employeeCurrentUserInfo$ | async"
            [timelineEntities]="timelineEntities$ | async"
            [forecastEventStart]="forecastStart"
            [forecastEventEnd]="forecastEnd"
            [disableAdaptive]="true"
          ></com-intersection-timeline>
        </div>
        <ng-template #noIntersectionsBlock>
          <div *ngIf="isLoading$ | async; else noIntersections" class="flex items-center gap-2">
            <mat-spinner [diameter]="18"></mat-spinner>
            Проверяем, доступо ли выбранное время..
          </div>
          <ng-template #noIntersections>
            <div class="text-green-500 text-sm">
              Пересечений не найдено, событие доступно для выставления в график
            </div>
          </ng-template>
        </ng-template>
      </ng-template>
    </div>
  </div>

  <div class="min-w-full min-h-12"></div>
  <footer class="absolute bottom-0 left-0 flex items-end h-12 justify-end gap-4 min-w-full bg-white z-99999">
    <button mat-stroked-button mat-dialog-close="">Отмена</button>
    <button
      mat-flat-button
      color="primary"
      (click)="onSave()"
      [disabled]="formGroup.controls.eventDate.invalid || (isFirstInit$ | async) || (isFormBlocked$ | async)"
    >
      Сохранить
    </button>
  </footer>
</div>
