import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import moment from 'moment';
import {
  ICalendarEvent,
  IEmployee,
  IMember,
  IMembersByRole,
  IRole
} from '@common/types';
import {
  CommitteeEventStatusEnum,
  ProtocolStatusEnum,
  RoleTypesEnum
} from '@common/enums';
import { EXTRA_TIME_FOR_ADDING_DELEGATE } from '@common/constants';

@Component({
  selector: 'com-right-panel-members',
  templateUrl: './right-panel-members.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelMembersComponent implements OnChanges {
  @Input() event: ICalendarEvent;
  @Input() members: IMember[] = [];
  @Input() roles: IRole[] = [];
  @Input() currentMember: IMember;
  @Input() responsibleUploadingMaterial: IEmployee;

  @Output() delegationButtonClick = new EventEmitter<IMember>();
  @Output() agendaDrawerOpen = new EventEmitter<IMember>();
  @Output() accessDrawerOpen = new EventEmitter<IMember>();

  public isTimeToDelegate = false;
  public isTimeToUpload = false;
  public uniqueMembers: IMember[] = [];
  public duplicateMembers: IMembersByRole = {};
  public availableMembers: IMember[] = [];

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    this._onEventChanges(changes);
    const newMembers = this.members
      .filter(
        (item) =>
          item.committeeMember.employee &&
          item.committeeMember.role.type !== RoleTypesEnum.DIVISIONAL
      )
      .map((member) => ({
        ...member,
        employee: {
          ...member.committeeMember.employee,
          role: member.committeeMember.role,
          roles: member.roles.filter(
            (el) => el.id !== member.committeeMember.role.id
          ),
          position: member.committeeMember.position
        },
        isDelegated: this.members.some(
          (item) => item.delegateTo?.id === member.committeeMember.employee?.id
        )
      }));
    this.uniqueMembers = newMembers.filter(
      (member) => !member.committeeMember.role.hasDublicate
    );
    this.duplicateMembers = {};
    newMembers
      .filter((member) => member.committeeMember.role.hasDublicate)
      .forEach((member) => {
        this.duplicateMembers[member.committeeMember.role.id] = [
          ...(this.duplicateMembers[member.committeeMember.role.id] || []),
          member
        ];
      });
    this.availableMembers = this.members.filter(
      (member) =>
        !member.delegateTo &&
        !this.members.some(
          (item) => item.delegateTo?.id === member.committeeMember.employee?.id
        ) &&
        member.roles.some((role) => role.hasDeputies)
    );
  }

  private _onEventChanges(changes: SimpleChanges): void {
    const event: ICalendarEvent = changes.event?.currentValue;
    if (event) {
      this.isTimeToDelegate =
        event.protocolStatus === ProtocolStatusEnum.FORMING &&
        moment().isBefore(
          moment(event.start).add(EXTRA_TIME_FOR_ADDING_DELEGATE, 'minutes')
        ) &&
        event.committeeEventStatus !== CommitteeEventStatusEnum.SUSPENDED &&
        event.committeeEventStatus !== CommitteeEventStatusEnum.FINISHED;
      this.isTimeToUpload =
        event.protocolStatus === ProtocolStatusEnum.FORMING &&
        moment().isBefore(
          moment(event.start).add(
            event.duration, //+ EXTRA_TIME_FOR_ENDING_CALL,
            'minutes'
          )
        ) &&
        event.committeeEventStatus !== CommitteeEventStatusEnum.SUSPENDED &&
        event.committeeEventStatus !== CommitteeEventStatusEnum.FINISHED;
    }
  }
}
