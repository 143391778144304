import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IOption } from '@common/types';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'com-select-with-search',
  templateUrl: 'select-with-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectWithSearchComponent implements OnInit, OnChanges {
  @Input() clearable = false;
  @Input() showTooltip = false;
  @Input() options: IOption[] = [];
  @Input() placeholder = 'Выберите значение';
  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() value: string | IOption;

  public filteredOptions$ = new BehaviorSubject<IOption[]>([]);

  ngOnInit(): void {
    this.filteredOptions$.next(this.options);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes && typeof this.value === 'string') {
      this.search(this.value);
    }
  }

  private search(query: string): void {
    if (query === '') {
      this.filteredOptions$.next(this.options);
    } else {
      this.filteredOptions$.next(
        this.options.filter((option) => option.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
      );
    }
  }

  public onClick(): void {
    !this.value && this.filteredOptions$.next(this.options);
  }

  public displayFn(value: IOption): string {
    return value?.name || '';
  }

  public onClose(): void {
    const control = this.form.get(this.controlName);
    if (typeof control.value === 'string') {
      control.setValue(null);
    }
  }

  public onClear(event: MouseEvent): void {
    event.stopPropagation();
    this.form.get(this.controlName).reset();
  }

  public onSelect(value: IOption): void {
    this.form.get(this.controlName).setValue(value, { emitEvent: false });
  }
}
