<div class="relative flex flex-col w-full h-full">
  <button class="absolute top-0 right-0" mat-dialog-close="">
    <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
  </button>

  <div class="text-2xl font-semibold tracking-tight text-center">Тест для допуска</div>

  <div class="flex flex-col gap-4 grow">
    <form class="flex flex-col gap-4" [formGroup]="formGroup">
      <div>
        <div>
          <com-form-label text="Название теста" [required]="true"></com-form-label>
          <com-form-input [control]="formGroup.controls.name"></com-form-input>
        </div>
        <div>
          <com-form-label text="Ссылка на тест" [required]="true"></com-form-label>
          <com-form-input
            postIcon="heroicons_outline:link"
            [control]="formGroup.controls.url"
            type="url"
          ></com-form-input>
        </div>
        <div>
          <com-form-label
            type="group"
            text="Минимальный процент прохождения"
            [required]="true"
          ></com-form-label>
          <com-form-input
            [control]="formGroup.controls.minimumThreshold"
            [min]="0"
            [max]="100"
            type="number"
          ></com-form-input>
        </div>
        <div class="flex flex-col gap-y-2">
          <com-days-off
            [formValue]="updateDaysOffForm$ | async"
            (valueChange)="onDaysOffValueChange($event)"
          ></com-days-off>
          <div>
            <com-form-label
              type="group"
              text="Количество дней для получения допуска до комитета"
              [required]="true"
            ></com-form-label>
            <com-form-input
              [control]="formGroup.controls.daysBeforePassing"
              [min]="1"
              type="number"
            ></com-form-input>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center sm:justify-end gap-4">
        <button mat-stroked-button mat-dialog-close="">Отмена</button>
        <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="confirm()">
          Подтвердить
        </button>
      </div>
    </form>
  </div>
</div>
