<div class="flex">
  <div class="min-w-[250px] max-w-[250px]"></div>
  <section class="flex w-full">
    <ng-container *ngFor="let scaleCell of scale$ | async">
      <div class="flex items-end justify-start w-4 h-8 box-border" scaleCell [cell]="scaleCell">
        <span class="ml-0.5 text-sm"> {{ scaleCell.valueName }} </span>
      </div>
    </ng-container>
  </section>
</div>

<div class="flex relative touch-manipulation overflow-y-scroll com-perfect-scrollbar max-h-[calc(100vh-536px)]" [ngClass]="{ 'opacity-50': isLoading }">
  <section
    *ngIf="(windowWidth$ | async) >= 785; else mobileTemplate"
    class="flex flex-col min-w-[220px] max-w-[220px]"
  >
    <ng-container *ngFor="let entity of timelineEntities">
      <div class="flex items-center min-h-12 min-w-8 ml-2">
        <mat-icon
          *ngIf="entity.isRoom; else user"
          class="icon-size-6 cursor-pointer mr-2.5 ml-1"
          matTooltip="Переговорная комната"
          svgIcon="heroicons_outline:building-office-2"
        ></mat-icon>
        <ng-template #user>
          <com-avatar
            class="mr-2 cursor-default"
            fontSize="sm"
            [sizePx]="32"
            [isLink]="true"
            [name]="entity.fullName"
          ></com-avatar>
        </ng-template>

        <div class="flex flex-col justify-center">
          <span
            class="text-sm font-medium truncate max-w-[150px]"
            [matTooltip]="entity.fullName"
            entityStatus
            [isBusy]="entity.isBusy"
          >
            {{ entity.fullName }}
          </span>
          <!--  <span class="text-xs truncate text-[#5A74A8FF]max-w-[150px]">
                       Должность
                   </span> -->
        </div>
      </div>
    </ng-container>
  </section>
  <ng-template #mobileTemplate>
    <div class="flex flex-col mt-8">
      <div
        *ngFor="let entity of timelineEntities"
        class="flex justify-center items-center min-h-12 max-h-12 relative"
      >
        <mat-checkbox
          [(ngModel)]="entity.selected"
          (change)="changeMemberActive()"
          [disabled]="isLoading"
          color="primary"
        ></mat-checkbox>
        <com-employee-badge
          [fullName]="entity.fullName"
          [isBusy]="entity.isBusy"
          [isSelected]="entity.selected"
          [timezone]="entity.timeZone"
        ></com-employee-badge>
      </div>
    </div>
  </ng-template>

  <div class="flex h-full pl-[10px]">
    <button
      class="flex items-center cursor-pointer min-w-5 max-w-5 hover:bg-gradient-to-t from-white from-10% via-[#f8fafc] via-20% to-white to-90%"
      (click)="changeIntervalSubtract()"
      [disabled]="isLoading || timelineStart <= 0"
    >
      <mat-icon class="icon-size-5 opacity-80" svgIcon="heroicons_outline:chevron-double-left"></mat-icon>
    </button>

    <div class="relative">
      <ng-container *ngFor="let row of timelineGrid$ | async">
        <div class="flex flex-col">
          <section
            class="flex relative h-12"
            [style.width]="timelineSize * 32 + 'px'"
          >
            <ng-container *ngFor="let timelineCell of row.timeline; let i = index">
              <div
                class="flex items-center justify-center h-12 box-border border text-white absolute"
                timelineCell
                [cell]="timelineCell"
                [index]="i"
                [timeline]="row.timeline"
                [timelineStart]="timelineStart"
              ></div>
            </ng-container>
          </section>
        </div>
      </ng-container>

      <div class="absolute top-0 left-0">
        <ng-container *ngIf="eventTimeline$ | async as eventTimeline">
          <section
            *ngFor="let entity of eventTimeline; let indexOfRow = index"
            class="flex w-full relative h-12"
          >
            <ng-container *ngFor="let timelineCell of entity.timeline; let i = index">
              <div
                class="flex items-center justify-center h-12 box-border text-white absolute"
                timelineCell
                [cell]="timelineCell"
                [index]="i"
                [timeline]="entity.timeline"
                [timelineStart]="timelineStart"
                [indexOfRow]="indexOfRow"
                [lastRowIndex]="eventTimeline.length"
              >
                <div
                  class="w-full h-full"
                  *ngIf="timelineCell?.valueName"
                  [matTooltip]="timelineSize > 11 ? (timelineCell | timelineEventTooltip) : ''"
                  (click)="timelineSize <= 11 && onEventClick(timelineCell)"
                ></div>
              </div>
            </ng-container>
          </section>
        </ng-container>
      </div>
    </div>

    <button
      class="flex items-center cursor-pointer min-w-5 max-w-5 hover:bg-gradient-to-t from-white from-10% via-[#f8fafc] via-20% to-white to-90%"
      (click)="changeIntervalAdd()"
      [disabled]="isLoading || timelineEnd >= 24"
    >
      <mat-icon class="icon-size-5 opacity-80" svgIcon="heroicons_outline:chevron-double-right"></mat-icon>
    </button>
  </div>

  <div *ngIf="isLoading" class="flex gap-2 items-center absolute top-1/2 left-1/2">
    <span class="font-semibold text-lg"> Ищем события </span>
    <mat-spinner [diameter]="ButtonSpinnerDiameter"></mat-spinner>
  </div>
</div>
