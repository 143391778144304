import { LegendOption } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';

enum LegendName {
  currentDay = 'текущий день',
  weekday = 'будний день',
  weekend = 'выходной день',
  event = 'дни с событиями',
  intersection = 'пересечение',
  transferEvent = 'перенесенное событие',
  freeSlot = 'дни со свободными слотами'
}

enum Legend {
  currentDay = 'currentDay',
  weekday = 'weekday',
  weekend = 'weekend',
  event = 'event',
  intersection = 'intersection',
  transferEvent = 'transferEvent',
  freeSlot = 'freeSlot'
}

export const LEGEND: LegendOption[] = [
  {
    name: LegendName.currentDay,
    value: Legend.currentDay,
    class: 'border-[2px] border-diagram-today'
  },
  {
    name: LegendName.freeSlot,
    value: Legend.freeSlot,
    class: 'bg-green-300'
  },
  {
    name: LegendName.weekday,
    value: Legend.weekday,
    class: 'bg-diagram-weekday'
  },
  {
    name: LegendName.weekend,
    value: Legend.weekend,
    class: 'bg-diagram-weekend'
  },
  {
    name: LegendName.event,
    value: Legend.event,
    class: 'bg-diagram-planning'
  }
];
