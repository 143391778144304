<div class="relative">
  <div
    class="container overflow-hidden p-0"
    [ngClass]="{
      'rounded-full': isCircle,
      'rounded-xl': !isCircle,
      'cursor-pointer': isLink,
      'border-4 border-solid border-white': isBorder
    }"
    [ngStyle]="{
      'width.px': sizePx,
      'height.px': sizePx,
      'min-width.px': sizePx
    }"
    *ngIf="avatarUrl"
  >
    <img class="h-full object-cover" [src]="avatarUrl" />
  </div>
  <div
    class="
    container
    flex
    items-center
    justify-center
    overflow-hidden
    uppercase
    text-{{ fontSize }}
    font-bold
    leading-none
  "
    [ngClass]="{
      'rounded-full': isCircle,
      'rounded-xl': !isCircle,
      'cursor-pointer hover:bg-gray-200': isLink,
      'border-4 border-solid border-white': isBorder
    }"
    [ngStyle]="{
      'width.px': sizePx,
      'height.px': sizePx,
      'min-width.px': sizePx
    }"
    *ngIf="!avatarUrl"
  >
    <ng-container *ngIf="name">
      {{ name?.[0] }}
    </ng-container>
    <ng-container *ngIf="!name">1</ng-container>
  </div>
  <div
    *ngIf="showStatus"
    class="status absolute bottom-0 right-0 w-2 h-2 rounded-full"
  ></div>
</div>
