import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[comEllipsisTooltip][matTooltip]',
  standalone: true
})
export class EllipsisTooltipDirective {
  constructor(
    private readonly _matTooltip: MatTooltip,
    private readonly _elementRef: ElementRef<HTMLElement>
  ) {}

  @HostListener('mouseenter', ['$event'])
  setTooltipState(): void {
    const element = this._elementRef.nativeElement;
    this._matTooltip.disabled = element.scrollWidth <= element.clientWidth;
  }
}
