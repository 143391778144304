import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { navigation } from 'app/mock-api/navigation/data';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class NavigationMockApi {
  private readonly navigation: FuseNavigationItem[] = navigation;

  constructor(private readonly _fuseMockApiService: FuseMockApiService) {
    this.registerHandlers();
  }

  registerHandlers(): void {
    this._fuseMockApiService.onGet('api/common/navigation').reply(() => [
      200,
      {
        default: cloneDeep(this.navigation)
      }
    ]);
  }
}
