import { FreeSlotSelect } from '@common/dialogs/planning-dialog/modules/planning-intersection/types';

export type Option<T> = {
  value: T;
  name: string;
};

export type CommitteeIntersectionDto = {
  id: string;
  hasIntersection: boolean;
  timeStart: string;
  timeEnd: string;
  eventId: string | null;
};

export type CommitteeIntersection = CommitteeIntersectionDto & {
  transferedStart: string | null;
  transferedEnd: string | null;
  intersectionOrder: number | null;
};

export type EventInterval = TimeInterval & {
  eventId: string;
  eventName: string;
  roleId: string | null;
  roleName: string | null;
  employeeId?: string;
};

export type TimeInterval = {
  timeStart: string;
  timeEnd: string;
};

export type FreeSlot = TimeInterval & {
  view: string;
};

export type FreeDay = {
  date: string;
  view: string;
};

export type UserId = string;
export type RoomId = string;
export type DateOfDay = string;

export type DayInfoDto = {
  freeSlots: TimeInterval[];
  busyMembers: Record<UserId, EventInterval[]>;
  busyRooms: Record<RoomId, EventInterval[]>;
};

export type ClosestDay = Record<DateOfDay, DayInfoDto>;

export type DayInfo = DayInfoDto & {
  id: string;
  planningDate: string;
  dayStart: string;
};

export type TransferMap = Record<string, TransferInfo>;

export type TransferInfo = {
  transferDateInterval: string;
  transferStart: string;
  transferDay: string;
  id: string;
  select: FreeSlotSelect;
};

export type DateInfo = {
  date: string;
  type: DayType;
};

export enum DayType {
  Busy,
  Free,
  Our
}
