<div class="flex justify-center items-center bg-tab-gray h-[32px] max-h-[32px] rounded-lg px-1">
  <button
    *ngFor="let option of options"
    (click)="selectTab.emit(option.value)"
    class="px-3 py-1 h-[28px] max-h-[28px] flex justify-center items-center"
    [ngClass]="{
      'bg-white border border-planning-border shadow-tab rounded-[6px]': currentTab === option.value
    }"
  >
    <span class="text-foreground"> {{ option.name }}</span>
  </button>
</div>
