export enum CommitteeBelongingEnum {
  ALL,
  MY
}

export enum CommitteeStatusEnum {
  HELD,
  NOT_HELD,
  DRAFT
}

export enum CommitteeStatusLabelEnum {
  HELD = 'Активные',
  NOT_HELD = 'Неактивные',
  DRAFT = 'Черновики'
}

export enum StatusColor {
  success = '#3DD35E',
  warning = '#FF7629'
}

export enum CommitteeMeetingRoomType {
  COMMITTEE_TEMPLATE,
  EVENT
}

export enum E_AGENDA_VIEW {
  LIST = 'list',
  SLIDER = 'slider'
}
