<form [formGroup]="formGroup" class="flex flex-col justify-between p-6">
  <com-right-panel-header
    [title]="'Добавление доверенности для члена комитета'"
    (closeDrawer)="closeDrawer.emit()"
    class="mb-5"
  ></com-right-panel-header>
  <section class="flex flex-col gap-4">
    <com-employee [employee]="whoDelegates?.committeeMember?.employee" [showImage]="true"></com-employee>

    <div class="flex flex-col">
      <com-form-label text="Доверенное лицо" type="group" [required]="true"></com-form-label>
      <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript w-full h-10">
        <mat-select placeholder="Выберите участника" [formControl]="formGroup.get('delegateTo')">
          <mat-select-trigger>
            {{ delegateToUserName }}
          </mat-select-trigger>
          <mat-option
            *ngIf="whoDelegates.committeeMember?.legitimateDeputy as legitimateDeputy"
            class="h-fit"
            [value]="legitimateDeputy.id"
          >
            <div class="flex flex-col leading-normal py-3">
              <span class="truncate">{{ legitimateDeputy.fullName }}</span>
              <span class="text-sm text-gray-500"> Легитимный заместитель </span>
            </div>
          </mat-option>
          <mat-option *ngFor="let member of availableMembers" [value]="member.committeeMember?.employee?.id">
            {{ member.committeeMember?.employee?.fullName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <com-form-basic-select
        class="h-12"
        [options]="reasons"
        [control]="formGroup.get('reason')"
      ></com-form-basic-select> -->
    </div>
    <div class="flex flex-col">
      <com-form-label text="Причина отсутствия" type="group" [required]="true"></com-form-label>
      <com-form-basic-select
        class="h-10"
        [options]="reasons"
        [control]="formGroup.get('reason')"
      ></com-form-basic-select>
      <com-form-input
        *ngIf="showAltField"
        placeholder="Введите причину"
        [control]="formGroup.get('reasonAlt')"
        class="mt-4"
      ></com-form-input>
    </div>
    <div class="flex justify-end">
      <button mat-flat-button class="w-fit" color="primary" [disabled]="formGroup.invalid" (click)="onSave()">
        Сохранить
      </button>
    </div>
  </section>
</form>
