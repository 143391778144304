import { Component, Input, OnChanges, OnInit, Self, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { switchMap, takeUntil, tap } from 'rxjs';
import moment from 'moment';
import { DATE_FORMAT_DOT, BLOT_FORMAT, URL_FORMAT } from '@common/constants';
import { IFixerElement, IOption } from '@common/types';
import { EmployeeService, UnsubscribeService } from '@common/services';
import { EditorBlotsEnum, FormTemplateTypesEnum } from '@common/enums';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'com-form-fixer',
  templateUrl: './form-fixer.component.html',
  providers: [UnsubscribeService]
})
export class FormFixerComponent implements OnInit, OnChanges {
  @Input() control: FormControl<string>;
  @Input() employees: IOption[] = [];
  @Input() readonly = false;
  @Input() value: string;

  public FormTemplateTypesEnum = FormTemplateTypesEnum;
  public formGroup: FormGroup;
  public formTemplate: IFixerElement[] = [];
  // public currentDate: string = moment().format(DATE_FORMAT);

  private _selectedEmployee: IOption;

  constructor(
    private readonly _employeeService: EmployeeService,
    private readonly _domSanitizer: DomSanitizer,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    if (this.value) {
      this.formTemplate = this._genArrayFromHTMLString(this.value);
      this.formGroup = this._initForm(this.formTemplate);
    }

    this.formGroup?.valueChanges.subscribe((res) => {
      if (this.formGroup.valid) {
        Object.keys(res).forEach((key) => {
          const elem = this.formTemplate.find((item) => item.label === key);
          if (elem) {
            if (Array.isArray(res[key])) {
              elem.value = res[key].map((id) => this.employees.find((el) => el.id === id)?.name).join(', ');
            } else if (key.startsWith('date')) {
              elem.value = res[key] && moment(res[key]).format(DATE_FORMAT_DOT);
            } else {
              elem.value = res[key];
            }
          }
        });
        let index = 0;
        const value = this.value.replace(
          BLOT_FORMAT,
          (item, tag1, type, propName, userName, tag2, _, tag3) => {
            const name = this.formTemplate
              .find((templateItem) => templateItem.label === Object.keys(res)[index])
              .options.find((option) => option.id === Object.values(res)[index])?.name;
            return `${tag1}${type}${name ? `" name="${name}` : ''}${tag2}${
              Object.values(res)[index++]
            }${tag3}`;
          }
        );
        this.control.setValue(value);
      }
    });

    this.control?.valueChanges.subscribe(() => {
      this.formGroup.markAllAsTouched();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const employees = changes.employees?.currentValue;
    if (employees) {
      this._employeeService
        .retrieveEmployeeSearchForSelect()
        .pipe(takeUntil(this._unsubscribeService))
        .subscribe((employeesForSelect) => {
          this.formTemplate = this.formTemplate.map((elem) => {
            const options = [
              ...employeesForSelect.map((employee) => ({
                id: employee.id,
                name: employee.fullName,
                position: employee.position,
                division: employee.division
              }))
            ];
            if (
              elem.type === FormTemplateTypesEnum.SELECT &&
              !employeesForSelect.map((emp) => emp.id).includes(elem.value)
            ) {
              this._employeeService
                .retrieveEmployeeById(elem.value)
                .pipe(takeUntil(this._unsubscribeService))
                .subscribe((employee) => {
                  options.push({
                    id: employee.id,
                    name: employee.fullName,
                    position: employee.position,
                    division: employee.division
                  });
                });
            }

            return { ...elem, options };
          });
        });
    }
  }

  private _initForm(arr: IFixerElement[]): FormGroup {
    const group = {};
    arr.forEach((el) => {
      switch (el.type) {
        case FormTemplateTypesEnum.INPUT:
          group[el.label] = new FormControl<string>((this.control?.value && el.value) || null, [
            Validators.required
          ]);
          break;
        case FormTemplateTypesEnum.DATE:
          group[el.label] = new FormControl<string>((this.control?.value && el.value) || null, [
            Validators.required
            // dateValidator({ min: this.currentDate })
          ]);
          break;
        case FormTemplateTypesEnum.NUMBER:
          group[el.label] = new FormControl((this.control?.value && el.value) || null, [Validators.required]);
          break;
        case FormTemplateTypesEnum.SELECT:
          const control = new FormControl((this.control?.value && el.value) || null, [Validators.required]);
          control.valueChanges
            .pipe(
              tap((value) => {
                const employee = this.formTemplate
                  .find((item) => item.label === el.label)
                  .options.find((option) => option.id === value);
                if (employee) {
                  this._selectedEmployee = employee;
                } else {
                  this._selectedEmployee = null;
                }
              }),
              switchMap((value) =>
                this._employeeService.retrieveEmployeeSearchForSelect(
                  this.formTemplate
                    .find((item) => item.label === el.label)
                    .options.map((option) => option.id)
                    .includes(value)
                    ? ''
                    : value
                )
              ),
              takeUntil(this._unsubscribeService)
            )
            .subscribe((employees) => {
              const formItem = this.formTemplate.find((item) => item.label === el.label);
              formItem.options = employees.map((employee) => ({
                id: employee.id,
                name: employee.fullName,
                position: employee.position,
                division: employee.division
              }));
              if (
                this._selectedEmployee &&
                !formItem.options.map((option) => option.id).includes(this._selectedEmployee.id)
              ) {
                formItem.options = [...formItem.options, this._selectedEmployee];
              }
            });
          group[el.label] = control;
          break;
        case FormTemplateTypesEnum.LINK:
          group[el.label] = new FormControl((this.control?.value && el.value) || null, [
            Validators.required,
            Validators.pattern(URL_FORMAT)
          ]);
          break;
      }
    });
    return new FormGroup(group);
  }

  private _genArrayFromHTMLString(htmlString: string): IFixerElement[] {
    const result = [];
    const div = document.createElement('div');
    div.insertAdjacentHTML('beforeend', htmlString);
    const paragraphs = div.querySelectorAll('p');
    const blocks = paragraphs.length ? paragraphs : [div];
    blocks.forEach((innerContent, index) => {
      if (index) {
        result.push({ type: FormTemplateTypesEnum.BREAK });
      }
      if (innerContent) {
        const content = innerContent.innerHTML;
        const textArr = content.split(/<span class="ql-editable-blot".*?<\/span>/gi);
        const spans = Array.from(innerContent.querySelectorAll('span'));
        textArr.forEach((item, idx) => {
          if (item) {
            result.push({
              type: FormTemplateTypesEnum.TEXT,
              value: this._domSanitizer.bypassSecurityTrustHtml(item.trim())
            });
          }
          const span = spans[idx] as HTMLSpanElement;
          if (span) {
            const type = span.getAttribute('type');
            const value = span.textContent;
            switch (type) {
              case EditorBlotsEnum.TEXT:
                result.push({
                  type: FormTemplateTypesEnum.INPUT,
                  value
                });
                break;
              case EditorBlotsEnum.NUMBER:
                result.push({ type: FormTemplateTypesEnum.NUMBER, value });
                break;
              case EditorBlotsEnum.DATE:
                result.push({ type: FormTemplateTypesEnum.DATE, value });
                break;
              case EditorBlotsEnum.USER:
                result.push({
                  type: FormTemplateTypesEnum.SELECT,
                  value: Object.values(EditorBlotsEnum).includes(value as EditorBlotsEnum) ? '' : value
                });
                break;
              case EditorBlotsEnum.LINK:
                result.push({ type: FormTemplateTypesEnum.LINK, value });
                break;
            }
          }
        });
      } else {
        result.push({
          type: FormTemplateTypesEnum.TEXT,
          value: this._domSanitizer.bypassSecurityTrustHtml(div.textContent.trim())
        });
      }
    });
    return result.map((el, idx) => {
      el.label = `${el.type}${idx}`;
      el.options = [];
      return el;
    });
  }
}
