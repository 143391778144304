import { NgModule } from '@angular/core';
import { EventRedirectComponent } from '@common/modules/event-redirect/event-redirect.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [EventRedirectComponent],
  imports: [CommonModule, MatIconModule]
})
export class EventRedirectModule {}
