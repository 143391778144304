<mat-label
  class="flex shrink-0 max-w-full leading-8"
  [ngClass]="{ 'w-64': type === 'block', 'w-fit': type === 'group' }"
  [matTooltip]="text + (required ? '*' : '')"
  [matTooltipDisabled]="tooltipDisabled"
>
  <div
    #textElement
    class="truncate"
  >{{ text }} </div>
  <div class="w-2">
    <span *ngIf="required">*</span>
  </div>
</mat-label>
