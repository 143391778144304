import { Component, Inject, OnInit, Self } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { ITaskEditData } from '@common/types';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@common/constants';
import moment from 'moment';
import { minDateTimeValidator } from '@common/utils/validators';
import { takeUntil, tap } from 'rxjs';
import { UnsubscribeService } from '@common/services';

@Component({
  selector: 'com-task-edit',
  styleUrls: ['task-edit.component.scss'],
  templateUrl: './task-edit.component.html',
  providers: [UnsubscribeService]
})
export class TaskEditComponent implements OnInit {
  public minDeadline = moment().format(DATE_FORMAT);
  public formGroup = new FormGroup({
    assigneeId: new FormControl(this.data.assigneeId),
    deadline: new FormControl(this.data.deadline),
    projectStatusId: new FormControl(this.data.projectStatusId)
  });

  constructor(
    private _matDialogRef: MatDialogRef<TaskEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITaskEditData,
    @Self() private unsubscribeService: UnsubscribeService
  ) {}

  ngOnInit(): void {
    const { deadline, projectStatusId } = this.formGroup.controls;
    deadline.markAsTouched({ onlySelf: true });
    projectStatusId.valueChanges
      .pipe(
        tap((value) => {
          const isFinal = this.data.projectStatuses.find(
            (projectStatus) => projectStatus.id === value
          )?.is_final;
          deadline.setValidators(isFinal ? [] : [minDateTimeValidator(this.minDeadline)]);
          this.formGroup.controls.deadline.updateValueAndValidity();
          this.minDeadline = isFinal ? null : moment().format(DATE_FORMAT);
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  public confirm(): void {
    const { deadline } = this.formGroup.value;
    this._matDialogRef.close({
      ...this.data,
      ...this.formGroup.value,
      deadline: (deadline || null) && moment(deadline).format(DATE_TIME_FORMAT)
    });
  }
}
