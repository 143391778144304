import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Self} from '@angular/core';
import {distinctUntilChanged, takeUntil} from 'rxjs';
import {CalendarEventService, DelegationReasonService, JitsuLoggerService, UnsubscribeService} from '@common/services';
import {IMember, IReasonForDelegation} from '@common/types';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CommitteeMemberActions} from "@common/constants/jitsu-logger.actions";

@Component({
  selector: 'com-delegation-form',
  templateUrl: './delegation-form.component.html',
  providers: [UnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DelegationFormComponent implements OnInit {
  @Output() closeDrawer = new EventEmitter<void>();
  @Output() saveDelegation = new EventEmitter<IMember>();

  @Input() currentMember: IMember;
  @Input() eventId: string;
  @Input() members: IMember[] = [];
  @Input() canDelegateAllRole = false;
  @Input() canIndicateAdditionalReason = false;
  @Input() set selectedMember(member: IMember) {
    this._selectedMember = member;
    this._initWhoDelegates();
    this._initAvailableMembersToDelegate();
    this._getDelegationReason();
  }
  get selectedMember(): IMember {
    return this._selectedMember;
  }
  private _selectedMember: IMember;

  public showAltField = false;
  public reasons: IReasonForDelegation[];
  public otherReason: string | null;
  public delegateToUserName: string;
  public whoDelegates: IMember;
  public availableMembers: IMember[];
  public formGroup: FormGroup = new FormGroup({
    delegateTo: new FormControl<string>(null, [Validators.required]),
    reason: new FormControl<string>(null, [Validators.required]),
    reasonAlt: new FormControl<string>(null)
  });

  constructor(
    private readonly _calendarEventService: CalendarEventService,
    private readonly _delegationReasonService: DelegationReasonService,
    private readonly _jitsuLoggerService: JitsuLoggerService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    this._initDelegationReasons();
    this._valueChanges();
  }

  public onSave(): void {
    this._jitsuLoggerService.logEvent(CommitteeMemberActions.delegateMember, {delegateFrom: this.canDelegateAllRole
        ? this.selectedMember.employee.id
        : this.currentMember.committeeMember.employee.id, delegateTo: this.formGroup.get('delegateTo').value});
    this._calendarEventService
      .delegateTo(this.eventId, {
        delegateToEmployeeId: this.formGroup.get('delegateTo').value,
        delegationReasonId: this.formGroup.get('reason').value,
        otherReason: this.formGroup.get('reasonAlt').value,
        delegateFromMemberId: this.canDelegateAllRole
          ? this.selectedMember.employee.id
          : this.currentMember.committeeMember.employee.id
      })
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((res) => {
        this.saveDelegation.emit({
          ...this.currentMember,
          delegateTo: res.delegateTo,
          delegationReason: res.delegationReason,
          otherReason: res.otherReason
        });
        this.closeDrawer.emit();
      });
  }

  private _initAvailableMembersToDelegate(): void {
    this.availableMembers = this.members.filter(
      (member, _, array) =>
        member.committeeMember.employee &&
        member.committeeMember.employee.id !==
          this.selectedMember.committeeMember.employee.id &&
        member.delegateTo === null &&
        array.every(
          (item) => item.delegateTo?.id !== member.committeeMember.employee.id
        ) &&
        member.roles.some((role) => role.hasDeputies) &&
        member.committeeMember.employee.id !==
          this.whoDelegates.committeeMember.legitimateDeputy?.id
    );
  }

  private _initWhoDelegates(): void {
    if (this.canDelegateAllRole) {
      this.whoDelegates = this.selectedMember;
      this.formGroup.get('delegateTo').reset();
    } else {
      this.whoDelegates = this.currentMember;
      this.formGroup
        .get('delegateTo')
        .setValue(this.selectedMember?.committeeMember?.employee?.id);
    }
    this.whoDelegates.committeeMember.employee.role =
      this.whoDelegates.committeeMember.role;
  }

  private _initDelegationReasons(): void {
    this._delegationReasonService
      .getDelegationReasons()
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((reasons) => {
        this.reasons = reasons.filter(
          (item) =>
            this.canIndicateAdditionalReason || !item.withAdditionalField
        );
      });
  }

  private _valueChanges(): void {
    this.formGroup
      .get('reason')
      .valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        const selectedReason = this.reasons.find((item) => item.id === value);
        if (selectedReason?.withAdditionalField) {
          this.showAltField = true;
          this.formGroup.get('reasonAlt').addValidators([Validators.required]);
          this.formGroup.get('reason').clearValidators();
          this.formGroup.get('reason').updateValueAndValidity();
        } else {
          this.showAltField = false;
          this.formGroup.get('reasonAlt').reset();
          this.formGroup.get('reasonAlt').clearValidators();
          this.formGroup.get('reasonAlt').updateValueAndValidity();
          this.formGroup.get('reason').addValidators([Validators.required]);
        }
      });

    this.formGroup
      .get('delegateTo')
      .valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        const legitimateDeputy =
          this.whoDelegates.committeeMember?.legitimateDeputy;
        this.delegateToUserName =
          legitimateDeputy?.id === value
            ? legitimateDeputy.fullName
            : this.availableMembers.find(
                (member) => member.committeeMember.employee.id === value
              )?.committeeMember.employee.fullName;
      });
  }

  private _getDelegationReason(): void {
    if (this.canDelegateAllRole) {
      this.formGroup
        .get('reason')
        .setValue(this.selectedMember?.delegationReason?.id);
    } else {
      this.formGroup
        .get('reason')
        .setValue(this.currentMember?.delegationReason?.id);
    }
  }
}
