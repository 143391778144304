import { Injectable } from '@angular/core';
import {
  FuseNavigationItem,
  FuseNavigationService
} from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { navigation } from 'app/mock-api/navigation/data';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class SearchMockApi {
  private readonly _defaultNavigation: FuseNavigationItem[] = navigation;

  /**
   * Constructor
   */
  constructor(
    private readonly _fuseMockApiService: FuseMockApiService,
    private readonly _fuseNavigationService: FuseNavigationService
  ) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // Get the flat navigation and store it
    const flatNavigation = this._fuseNavigationService.getFlatNavigation(
      this._defaultNavigation
    );
  }
}
