import { setTimeZone } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import {
  addMinutes,
  format,
  getHours,
  getMinutes,
  isAfter,
  isSameDay,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { WeekDay } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';
import {
  CommitteeIntersection,
  CommitteeIntersectionDto,
  DateOfDay,
  FreeDay,
  TimeInterval
} from '@common/dialogs/planning-dialog/types';
import { IEventDate } from '@common/types';

export const addTimeToDate = (date: string, time: string): Date => {
  const start = setTimeZone(date);
  const eventTime = setTimeZone(time);
  const minutes = getMinutes(eventTime);
  const hours = getHours(eventTime);
  return setHours(setMinutes(start, minutes), hours);
};

export const getPlanningDate = (date: string | Date, duration: number): string => {
  const start = new Date(date);
  const end = addMinutes(start, duration);
  const dayMonthYear = format(start, 'd MMMM yyyy, ', { locale: ru });
  const weekDay =
    start.getDay().toString() !== WeekDay.Saturday ? format(start, 'EEE', { locale: ru }).slice(0, -1) : 'сб';
  const hours = format(start, ' HH:mm', { locale: ru });
  const formattedStart = dayMonthYear + weekDay + hours;

  const formattedEnd = format(end, '-HH:mm');
  return formattedStart + formattedEnd;
};

export const startOfDayString = (date: Date | string): string => {
  if (typeof date === 'string') date = new Date(date);

  return `${format(date, 'yyyy-MM-dd')}T00:00:00`;
};

export const getFreeDays = (freeSlots: Record<DateOfDay, TimeInterval[]>): FreeDay[] => {
  const result: FreeDay[] = [];
  for (const day in freeSlots) {
    result.push({
      date: startOfDayString(day),
      view: format(setTimeZone(day), 'dd.MM.yyyy, EEEE', { locale: ru })
    });
  }
  return result;
};

export const isDayAfter = (currentDate: Date, targetDate: Date): boolean => {
  const midnightCurrent = setHours(setMinutes(setSeconds(setMilliseconds(currentDate, 0), 0), 0), 0);
  const midnightTarget = setHours(setMinutes(setSeconds(setMilliseconds(targetDate, 0), 0), 0), 0);
  return isSameDay(currentDate, targetDate) || isAfter(midnightTarget, midnightCurrent);
};

export const dateEventsMapper = (
  events: CommitteeIntersection[] | CommitteeIntersectionDto[]
): IEventDate[] => {
  return events.map((e) => {
    return {
      eventTime: e.timeStart,
      transferTime: e.transferedStart,
      eventId: e.eventId
    };
  });
};
