import { IOption } from './common';
import { IEmployee } from './employee';
import { ICommitteeItem } from './form';

export interface IViolation {
  violationDate: string;
  type: string;
  violationResponsible: IEmployee;
  prescriptionId: string;
  appointmentResponsible: IEmployee;
  planDate: string;
  prescriptions?: IOption[];
  employees?: ICommitteeItem[];
}
