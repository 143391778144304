export enum DialogActionsEnum {
  CONFIRM = 'Подтвердить',
  CANCEL = 'Отмена'
}

export enum DialogTitlesEnum {
  VIDEO_CALL_END = 'Подтвердите завершение видеоконференции' //'Требуется подтверждение'
}

export enum DialogMessagesEnum {
  VIDEO_CALL_END = 'Вы уверены, что хотите завершить совещание?'
}

export enum FuseDialogActionsEnum {
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled'
}
