<header class="flex border-b gap-1 p-1 mr-2.5">
  <div class="min-w-[70%] max-w-[70%] font-semibold flex justify-center">Название комитета</div>
  <div class="min-w-[30%] max-w-[30%] font-semibold flex justify-center">Время проведения</div>
</header>
<div
  class="flex flex-col gap-1 max-w-[720px] h-[160px] overflow-y-scroll com-perfect-scrollbar px-1 mt-1"
  scrollToSelected
  [selectedIndex]="indexSelectFromNavigation"
  [id]="events[selectedEventIndex]?.id + selectedEventIndex"
>
  <ng-container *ngFor="let event of events; let i = index">
    <button
      class="flex my-0.5 px-2 py-2.5 gap-1 rounded-md"
      [ngClass]="{ 'bg-[#0f766e]/10 font-semibold': selectedEventIndex === i, 'bg-gray-50': selectedEventIndex !== i }"
      [id]="event.id + i"
      (click)="selectIndex.emit(i)"
    >
      <div class="min-w-[70%] max-w-[70%] pl-2 text-sm flex justify-between gap-2">
        <span>{{ event.title }}</span>
        <com-event-type-tag [eventTypeId]="event.eventTypeId"></com-event-type-tag>
      </div>
      <div class="min-w-[30%] max-w-[30%] text-sm">{{ event.dateRange }}</div>
    </button>
  </ng-container>
</div>
