<mat-form-field *ngIf="control" class="w-full">
  <mat-chip-list #multipleChipList class="grow mr-0" [formControl]="control">
    <div class="ml-2 pt-2" *ngIf="selectedOptions.length">
      <mat-chip
        class="text-sm h-5 min-h-5"
        *ngFor="let option of selectedOptions"
        (removed)="onSelectedOptionRemove(option)"
      >
        <span [className]="'whitespace-nowrap overflow-hidden overflow-ellipsis ' + widthChip">{{
          option.name
        }}</span>
        <button matChipRemove>
          <mat-icon class="icon-size-4 mt-0.5 ml-0.5" svgIcon="heroicons_outline:x"></mat-icon>
        </button>
      </mat-chip>
    </div>
    <input
      #multipleInput
      type="text"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [matChipInputFor]="multipleChipList"
      [formControl]="inputControl"
      id="mat-chip-list-input"
    />
  </mat-chip-list>
  <div class="flex items-center h-full cursor-pointer" matSuffix (click)="multipleInput.focus()">
    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:chevron-down"></mat-icon>
  </div>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="onOptionSelect($event, multipleInput)"
  >
    <mat-option *ngFor="let option of filteredOptions" [value]="option.id">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
