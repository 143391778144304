import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, catchError, EMPTY, Observable } from 'rxjs';

export abstract class AbstractRightPanel<T> {
  protected abstract paramKey: string;
  protected abstract dataQuery: (id: string) => Observable<T>;

  get isOpen(): boolean {
    return !!this.currentData$.value;
  }

  protected get currentData(): Observable<T | null> {
    return this.currentData$.asObservable();
  }

  private currentData$ = new BehaviorSubject<T | null>(null);

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const dataId = params[this.paramKey];

      if (!dataId) {
        this.currentData$.next(null);
        return;
      }

      this.dataQuery(dataId)
        .pipe(
          catchError(() => {
            this.router.navigate([], {});
            return EMPTY;
          })
        )
        .subscribe((data) => {
          this.update(data);
        });
    });

    this.currentData.subscribe((committee) => {
      document.body.style.overflowY = committee ? 'hidden' : 'auto';
    });
  }

  open(id: string): void {
    this.router.navigate([], { queryParams: { [this.paramKey]: id } });
  }

  close(): void {
    this.router.navigate([], { queryParams: {} });
  }

  update(data: T): void {
    this.currentData$.next(data);
  }
}
