import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommitteeIntersection } from '@common/dialogs/planning-dialog/types';
@Component({
  selector: 'com-intersection-table',
  templateUrl: 'intersection-table.component.html',
  styleUrls: ['intersection-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntersectionTableComponent {
  @Input() events: CommitteeIntersection[] = [];
  @Input() loading = true;
  @Output() selectDate = new EventEmitter<CommitteeIntersection>();
}
