<form class="flex flex-col gap-4 p-8" (ngSubmit)="onSubmit()">
  <com-right-panel-header
    title="Отмена события"
    [parentTitle]="event.title"
    (closeDrawer)="closeDrawer.emit()"
  ></com-right-panel-header>
  <com-form-block>
    <com-form-label text="Причина отмены" type="group" [required]="true"></com-form-label>

    <com-form-basic-select class="h-12" [options]="reasons" [control]="reason"></com-form-basic-select>
  </com-form-block>
  <div class="flex justify-end">
    <button
      [disabled]="isLoading || !reason.value"
      class="w-fit"
      type="submit"
      mat-flat-button
      color="primary"
    >
      Сохранить
    </button>
  </div>
</form>
