import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'com-form-block',
  templateUrl: './form-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormBlockComponent {
  @Input() legend: string;
  @Input() showDivider = true;
}
