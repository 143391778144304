import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { UnsubscribeService } from '@common/services';
import { FormControl } from '@angular/forms';
import { IEmployeeOption, IOption } from '@common/types';
import { takeUntil, tap } from 'rxjs';

@Component({
  selector: 'com-form-auto-multiselect',
  templateUrl: 'form-auto-multiselect.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class AutoMultiselectComponent implements OnInit {
  @Input() control: FormControl<IOption[]>;
  @Input() options: IOption[] | IEmployeeOption[] = [];
  @Input() placeholder = 'Выберите значения';

  public selectedOptions: IOption[] = [];
  public inputControl = new FormControl<string>(null);

  @Output() searchValueChange = new EventEmitter<string>();
  @Output() inputClick = new EventEmitter<string>();
  @Output() optionSelect = new EventEmitter<string>();

  constructor(@Self() private readonly unsubscribeService: UnsubscribeService) {}

  ngOnInit(): void {
    this.inputControl.valueChanges
      .pipe(
        tap((value) => this.searchValueChange.emit(value)),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  public displayFn(option: IOption | IEmployeeOption): string {
    return option?.name || '';
  }

  public onSelect(option: IOption | IEmployeeOption, input: HTMLInputElement): void {
    this.control.setValue([...(this.control.value || []), option]);
    this.inputControl.setValue(null);
    this.selectedOptions.push(option);
    input.value = null;
  }

  public onSelectedOptionRemove(option: IOption): void {
    this.control.setValue(this.control.value.filter((valueOption) => valueOption.id !== option.id));
    this.selectedOptions = [...this.selectedOptions.filter((x) => x.id !== option.id)];
  }
}
