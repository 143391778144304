<div class="relative flex flex-col w-full h-full">
  <button class="absolute top-0 right-0" mat-dialog-close="">
    <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
  </button>

  <div class="text-2xl font-semibold tracking-tight text-center">Изменить исполнителя и сроки</div>

  <div class="flex flex-col gap-2 grow">
    <form class="py-4" [formGroup]="formGroup">
      <div class="flex flex-col gap-2 grow">
        <com-form-label text="Исполнитель" type="block"></com-form-label>
        <com-form-basic-select
          [control]="formGroup.controls.assigneeId"
          [options]="data.memberOptions"
        ></com-form-basic-select>
      </div>
      <div class="flex flex-col gap-2 grow">
        <com-form-label text="Плановый срок" type="block"></com-form-label>
        <com-form-input
          [control]="formGroup.controls.deadline"
          type="date"
          [min]="minDeadline"
        ></com-form-input>
      </div>
      <div class="flex flex-col gap-2 grow">
        <com-form-label text="Статус выполнения" type="block"></com-form-label>
        <com-form-basic-select
          placeholder="Выберите статус"
          [control]="formGroup.controls.projectStatusId"
          [options]="data.statusOptions"
        ></com-form-basic-select>
      </div>
    </form>
  </div>

  <div class="flex items-center justify-center sm:justify-end gap-4">
    <button mat-stroked-button mat-dialog-close>Отмена</button>
    <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="confirm()">
      Подтвердить
    </button>
  </div>
</div>
