<div
  [ngClass]="{
    'min-h-8': type === 'quorum',
    'min-h-7': type !== 'quorum'
  }"
  class="w-fit px-3 py-1.5 text-sm text-white whitespace-nowrap font-semibold rounded-3xl {{
    styles
  }} {{ bgColor }}"
>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'quorum'">
      <span>{{ label }} {{ value || 0 }}%</span>
      <span *ngIf="total" class="pl-2">( {{ count }} / {{ total }} )</span>
    </ng-container>
    <div *ngSwitchDefault class="flex flex-col items-center">
      <span>
        {{ value }}
      </span>
      <div class="flex flex-col items-center text-xs leading-3">
        <span *ngIf="error" class="text-red-300">
          {{ error }}
        </span>
        <span *ngIf="hint" class="text-gray-200">
          {{ hint }}
        </span>
      </div>
    </div>
  </ng-container>
</div>
