import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { ITimelineEntity } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';
import {
  getEndOfDay,
  getStartOfDay,
  isOverlap,
  setTimeZone
} from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { COMMITTEE_EVENT_TEMPLATE_TYPES, IntersectionService } from '@common/services/intersection.service';
import {
  EventTypeIds,
  IBusyIntervalCollection,
  IBusyIntervalsResponse,
  ICalendarEmployee,
  IEventProps,
  IEventsByDayCollection,
  IEventShortInfo,
  IUserBusyInterval
} from '@common/types/calendar-api';
import { endOfDay, startOfDay } from 'date-fns';
@Injectable({
  providedIn: 'root'
})
export class TimelineEventsService {
  private employeesInfoCash: ICalendarEmployee[] = [];
  private roomEventsCash: IEventShortInfo[] = [];
  private busyIntervalResponseCash: IBusyIntervalsResponse | null = null;
  private eventTimelineFilter = [EventTypeIds.committee2_0, EventTypeIds.committee];

  public forecastStart: string | null = null;
  public forecastEnd: string | null = null;
  constructor(private intersectionService: IntersectionService) {}

  public loadTimelineEvents(
    start: string,
    end: string,
    employeeIds: string[],
    roomIds: string[],
    committeeId: string
  ): Observable<ITimelineEntity[]> {
    this.forecastStart = start;
    this.forecastEnd = end;
    return forkJoin([
      this.loadUserBusyEvents(start, end, employeeIds, committeeId),
      this.loadRoomEvents(start, end, roomIds, committeeId)
    ]).pipe(map(([users, rooms]) => [...users, ...rooms]));
  }

  public setFilter(filter: EventTypeIds[]): void {
    this.eventTimelineFilter = filter;
  }

  public recalculateEvents(
    roomIds: string[],
    committeeId: string,
    start?: string,
    end?: string
  ): ITimelineEntity[] {
    if (start) {
      this.forecastStart = start;
      this.forecastEnd = end;
    }
    return [
      ...this.initEmployeeStatus(this.employeesInfoCash, this.busyIntervalResponseCash, committeeId),
      ...this.initRoomStatus(this.roomEventsCash, roomIds, committeeId)
    ];
  }

  private loadUserBusyEvents(
    start: string,
    end: string,
    employeeIds: string[],
    committeeId: string
  ): Observable<ITimelineEntity[]> {
    return forkJoin([
      this.intersectionService.getInfoAboutEmployees(employeeIds),
      this.intersectionService.getUserBusyEvents(
        getStartOfDay(start),
        getEndOfDay(end),
        employeeIds,
        COMMITTEE_EVENT_TEMPLATE_TYPES
      )
    ]).pipe(
      map(([{ body: employees }, response]) => this.initEmployeeStatus(employees, response, committeeId))
    );
  }

  private initEmployeeStatus(
    employees: ICalendarEmployee[],
    response: IBusyIntervalsResponse,
    committeeId: string
  ): ITimelineEntity[] {
    this.busyIntervalResponseCash = response;
    this.employeesInfoCash = employees;

    return employees.map(({ id, firstName, lastName }) => {
      const busyIntervals = this.getEventsFromSearchResponse(response, id).map((event) => {
        event.committeeId = event.props ? this.parseEventProps(event.props)?.templateId : null;
        return event;
      });

      return this.initTimelineEntity(
        id,
        `${lastName} ${firstName}`,
        this.filterTimelineEvents(busyIntervals, committeeId, this.forecastStart)
      );
    });
  }

  private getEventsFromSearchResponse(
    { body: collection }: IBusyIntervalsResponse,
    employeeId: string
  ): IUserBusyInterval[] {
    const events = [];
    if (collection[employeeId]) {
      Object.keys(collection[employeeId]).forEach((day) => {
        collection[employeeId][day].map((event) => events.unshift(event));
      });
    }
    return events;
  }

  private loadRoomEvents(
    start: string,
    end: string,
    roomIds: string[],
    committeeId: string
  ): Observable<ITimelineEntity[]> {
    return this.intersectionService
      .getRoomEvents(getStartOfDay(start), getEndOfDay(end), roomIds, COMMITTEE_EVENT_TEMPLATE_TYPES)
      .pipe(
        switchMap(({ body: eventCollection }) => {
          const events: IEventShortInfo[] = this.getOnlyEvents(eventCollection);
          this.roomEventsCash = events;
          return of(this.initRoomStatus(events, roomIds, committeeId));
        })
      );
  }

  private initRoomStatus(
    events: IEventShortInfo[],
    roomIds: string[],
    committeeId: string
  ): ITimelineEntity[] {
    return this.intersectionService
      .getRoomList()
      .filter((room) => roomIds.includes(room.id))
      .map(({ id, title }) => {
        const busyIntervals = events
          .filter(({ eventRooms }) => eventRooms.some((r) => r.roomId === id))
          .map((e) => this.eventRoomToIntervalAdaptor(e));

        return this.initTimelineEntity(
          id,
          title,
          this.filterTimelineEvents(busyIntervals, committeeId, this.forecastStart),
          true
        );
      });
  }

  private filterTimelineEvents<T extends IUserBusyInterval>(
    events: T[],
    committeeId: string,
    eventForecastDate: string
  ): T[] {
    return events.filter(
      (event) =>
        event.committeeId !== committeeId &&
        this.eventTimelineFilter.includes(event.eventTemplateTypeId) &&
        isOverlap(
          setTimeZone(event.startTime),
          setTimeZone(event.endTime),
          startOfDay(setTimeZone(eventForecastDate)),
          endOfDay(setTimeZone(eventForecastDate))
        )
    );
  }

  private initTimelineEntity(
    id: string,
    fullName: string,
    busyIntervals: IUserBusyInterval[],
    isRoom = false
  ): ITimelineEntity {
    return {
      id,
      fullName,
      isBusy: busyIntervals.some((event) =>
        isOverlap(
          setTimeZone(event.startTime),
          setTimeZone(event.endTime),
          setTimeZone(this.forecastStart),
          setTimeZone(this.forecastEnd)
        )
      ),
      isRoom,
      timeZone: null,
      events: busyIntervals
    };
  }

  private getOnlyEvents<
    T extends IEventsByDayCollection | IBusyIntervalCollection,
    R extends IEventShortInfo | IUserBusyInterval
  >(collectionOfEvent: T): R[] {
    const result: R[] = [];
    let lastNode: R[] = [];
    for (const key in collectionOfEvent) {
      if (Object.prototype.hasOwnProperty.call(collectionOfEvent, key)) {
        (collectionOfEvent[key] as R[]).map((event) => {
          const isRepeatedEvent = lastNode.some(
            (e) => event.eventTemplateId === e.eventTemplateId && e.isAllDay
          );
          if (
            isRepeatedEvent &&
            event.eventTemplateTypeId !== EventTypeIds.integrated &&
            event.eventTemplateTypeId !== EventTypeIds.imported &&
            event.isAllDay
          ) {
            return;
          } else result.push(event);
        });
        lastNode = collectionOfEvent[key] as R[];
      }
    }
    return result;
  }
  private parseEventProps(props: string): Partial<IEventProps> {
    return JSON.parse(props);
  }

  private eventRoomToIntervalAdaptor({
    eventTemplateId,
    eventTemplateTypeId,
    startTime,
    endTime,
    startDate,
    endDate,
    isAllDay,
    factType,
    confirmed,
    props
  }: IEventShortInfo): IUserBusyInterval {
    return {
      eventTemplateId,
      eventTemplateTypeId,
      startTime,
      endTime,
      startDate,
      endDate,
      isAllDay,
      factType,
      confirmed,
      reason: null,
      committeeId: props ? this.parseEventProps(props)?.templateId : null
    };
  }
}
