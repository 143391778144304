import { ResolutionGeneralTypeEnum } from '@common/enums/protocols.enum';
import {
  ICommitteeItem,
  IEmployee,
  ICommitteeMemberDto,
  IRole,
  IAttachmentDto,
  IData,
  IResponseWrapper,
  IPage,
  ISpeaker,
  IOption,
  IBadge
} from '@common/types';
import { BehaviorSubject } from 'rxjs';
import {
  ResolutionAudioRecordStatusEnum,
  ResolutionKindEnum,
  ResolutionTypeEnum,
  VoteEnum
} from '@common/enums';

export interface IProtocol {
  id: string;
  name: string;
  start: string;
  end: string;
  meetingRoomId: string;
  committeeId: string;
  participants: IParticipant[];
  duration?: number;
  meetingRoom?: ICommitteeItem;
  membersByRole?: ICommitteeMemberDto[];
}

interface IParticipant {
  id?: string;
  order: number;
  employeeId?: string;
  employee?: IEmployee;
  roleId?: string;
  role?: IRole;
}

export interface IEventAgenda {
  id?: string;
  title?: string;
  responsible?: IEmployee;
  isSystem?: boolean;
  responsibleId?: string;
  order?: number;
  speakers?: ISpeaker[] | string[];
  speakerId?: string;
  resolution?: IEventResolution[];
  materials?: IAttachmentDto[];
  canUploadMaterial?: boolean;
  responsibleUploadingMaterial?: IEmployee;
  responsiblePreResolution?: IEmployee;
  responsibleEmployees?: IEmployee[];
  materialLoading$?: BehaviorSubject<boolean>;
  eventResolutions?: IEventResolution[];
  templateResolutions?: IEventResolution[];
  resolutionType?: ResolutionGeneralTypeEnum;
}

export interface IEventResolution {
  id: string;
  agendaItemId: string;
  value: string;
  createdAt?: string;
  createdBy?: IEmployee;
  type: ResolutionGeneralTypeEnum;
  resolutionType: ResolutionTypeEnum;
  resolutionKind: ResolutionKindEnum;
  order: number;
  votes: number;
  responsible?: IEmployee;
  responsibleId: string;
  deadline: string;
  abstainedMembersCount: number;
  abstainedMembers: IEmployee[];
  isUserAbstained?: boolean;
  againstMembersCount: number;
  againstMembers: IEmployee[];
  isUserAgainst?: boolean;
  behindMembersCount: number;
  behindMembers: IEmployee[];
  isUserBehind?: boolean;
  committeeEventId?: string;
  employeeRatings?: IEmployeeRating[];
  employeeVotes: IEmployeeVote[];
  relatedTaskId?: string;
  statusId?: string;
  recordFile?: Blob;
  recordFileId?: string;
  audioRecordStatus: ResolutionAudioRecordStatusEnum;
}

export interface IEmployeeVote {
  employee: IEmployee;
  voteType: VoteEnum;
}

export interface IEmployeeRating {
  createdAt?: string;
  employee: IEmployee;
  rating: number;
  resolutionId?: string;
}

export interface IProtocolDataTable {
  name: string;
  id: string;
  eventId: string;
  quorum: number;
  roomNames: IBadge[];
  uploaded: string;
  responsible: IEmployee;
  eventTime: string;
  quorumBoundary: number;
  actions: {
    download: {
      show: boolean;
    };
  };
}

export type IProtocolData = {
  id: string;
  index: number;
  responsible: IEmployee;
  uploaded: string;
  meetingRooms: IOption[];
  meetingRoomsIds: string[];
  quorumBoundary?: number;
  committeeEventDto: {
    duration: number;
    eventTime: string;
    id: string;
    name: string;
    eventId: string;
    quorum: number;
  };
};

export interface IProtocolRequest extends Partial<IPage> {
  committeeId?: string;
  startDate: string;
  endDate: string;
}

export interface IProtocolWrapper extends IResponseWrapper {
  data: IProtocolData[];
}

export interface IProtocolDocData extends IData {
  data: IProtocolData[];
}

export interface IBlackListBody {
  attachmentId: string;
  blackListLoad: string[];
  blackListRead: string[];
}
