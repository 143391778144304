import { ChangeDetectionStrategy, Component, Inject, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment, { Moment, tz } from 'moment';
import {
  CalendarService,
  CommitteeEventActionService,
  EmployeeService,
  JitsuLoggerService,
  UnsubscribeService
} from '@common/services';
import { minDateTimeValidator } from '@common/utils/validators';
import { BUTTON_SPINNER_DIAMETER, CommitteeEventActions, DATE_TIME_FORMAT } from '@common/constants';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  filter,
  interval,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { ITimelineEntity } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';
import { TimelineEventsService } from '@common/services/timeline-events.service';
import { isDayOfDatesEqual, toISO } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { addMinutes } from 'date-fns';
import { ICalendarCommitteeEvent } from '@common/types';

export interface IUnplannedEventDialogData {
  title: string;
  committeeId: string;
  isCanceled: boolean | null;
  duration: number;
  employeeIds: string[];
  roomIds: string[];
  eventId?: string;
  eventTime?: Moment | string;
}

type UnplannedEventForm = FormGroup<{
  eventDate: FormControl<string>;
}>;

@Component({
  selector: 'com-unplanned-event',
  templateUrl: './unplanned-event.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class UnplannedEventComponent {
  public currentDate = moment().format(DATE_TIME_FORMAT);
  public isLoading = false;
  public formGroup: UnplannedEventForm;
  public forecastStart: string;
  public forecastEnd: string;
  public ButtonSpinnerDiameter = BUTTON_SPINNER_DIAMETER;

  public timelineEntities$ = new BehaviorSubject<ITimelineEntity[]>([]);
  public isError$ = new BehaviorSubject(false);
  public isLoading$ = new BehaviorSubject(true);
  public isFirstInit$ = new BehaviorSubject(true);
  public isFormBlocked$ = new BehaviorSubject(false);

  public loadEvent$ = new Subject<void>();
  private cancelPrevRequest$ = new Subject<void>();

  constructor(
    public timelineEventsService: TimelineEventsService,
    public employeeService: EmployeeService,
    private _dialogRef: MatDialogRef<UnplannedEventComponent>,
    private _jitsuLoggerService: JitsuLoggerService,
    private _committeeEventActionService: CommitteeEventActionService,
    private readonly _calendarService: CalendarService,
    @Inject(MAT_DIALOG_DATA) public props: IUnplannedEventDialogData,
    @Self() readonly _unsubscribeService: UnsubscribeService
  ) {
    this._createForm();
    this._loadTimelineEventsSub();
    this._formChangeSub();
    this._updateCurrentDateSub();
    this._loadEvents(this.currentDate);
  }

  private _createForm(): void {
    this.formGroup = new FormGroup({
      eventDate: new FormControl<string>(this.currentDate, [
        Validators.required,
        minDateTimeValidator(this.currentDate)
      ])
    });
  }

  private _formChangeSub(): void {
    this.formGroup.controls.eventDate.valueChanges
      .pipe(
        filter(() => this.formGroup.controls.eventDate.valid),
        tap((date) => this._loadEvents(date)),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _loadEvents(date: string): void {
    const lastForecastStart = this.forecastStart;
    this.forecastStart = toISO(date);
    this.forecastEnd = toISO(addMinutes(new Date(date), this.props.duration));
    if (!lastForecastStart || !isDayOfDatesEqual(lastForecastStart, this.forecastStart)) {
      this.isLoading$.next(true);
      this.loadEvent$.next();
    } else {
      this._refreshTimeline();
    }
  }

  private _refreshTimeline(): void {
    const { committeeId, roomIds } = this.props;
    const timelineEntities = this.timelineEventsService.recalculateEvents(
      roomIds,
      committeeId,
      this.forecastStart,
      this.forecastEnd
    );
    this.timelineEntities$.next(timelineEntities);
  }

  private _loadTimelineEventsSub(): void {
    this.loadEvent$
      .pipe(
        debounceTime(100),
        tap(() => this.cancelPrevRequest$.next()),
        switchMap(() => {
          const { employeeIds, roomIds, committeeId } = this.props;
          return this.timelineEventsService
            .loadTimelineEvents(this.forecastStart, this.forecastEnd, employeeIds, roomIds, committeeId)
            .pipe(takeUntil(this.cancelPrevRequest$));
        }),
        tap((timelineEntities) => {
          this.timelineEntities$.next(timelineEntities);
          this._offLoaders();
        }),
        catchError(() => {
          this.isError$.next(true);
          this._offLoaders();
          return of(null);
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _offLoaders(): void {
    this.isLoading$.next(false);
    this.isFirstInit$.next(false);
  }

  public onSave(): void {
    if (this.formGroup.controls.eventDate.valid) {
      this.isFormBlocked$.next(true);
      this._sendDataToServer()
        .pipe(
          tap((data) => this._dialogRef.close(data)),
          catchError(() => {
            this.isFormBlocked$.next(false);
            return of(null);
          }),
          takeUntil(this._unsubscribeService)
        )
        .subscribe();
    }
  }

  private _sendDataToServer(): Observable<ICalendarCommitteeEvent> {
    const { eventId, isCanceled, committeeId } = this.props;
    const transferDate = moment(this.formGroup.controls.eventDate.value).tz(tz.guess()).format();

    if (eventId && !isCanceled) {
      this._jitsuLoggerService.logEvent(CommitteeEventActions.suspendedAndCreateUnplanned, {
        eventId
      });
      return this._committeeEventActionService.suspendAndCreateUplannedEvent(eventId, transferDate);
    } else {
      this._jitsuLoggerService.logEvent(CommitteeEventActions.openUnplannedDialogButton, {
        committeeId
      });
      return this._calendarService.saveUnplannedEvent({
        committeeId,
        eventDate: moment(this.formGroup.controls.eventDate.value).tz(tz.guess()).format()
      });
    }
  }

  private _updateCurrentDateSub(): void {
    const oneMinute = 60000;
    interval(oneMinute)
      .pipe(
        tap(() => {
          this.currentDate = moment().format(DATE_TIME_FORMAT);
          const control = this.formGroup.controls.eventDate;
          control.setValidators([Validators.required, minDateTimeValidator(this.currentDate)]);
          control.updateValueAndValidity();
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }
}
