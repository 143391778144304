import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { debounce } from 'lodash';

@Component({
  selector: 'com-button',
  templateUrl: 'button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnDestroy, AfterViewInit, OnChanges {
  @Input() disabled = false;
  @Input() variant = 'primary';
  @Input() size: 'sm' | 'lg' = 'lg';
  @Output() buttonClick = new EventEmitter<void>();
  @ViewChild('button') button: ElementRef<HTMLEkpButtonElement>;
  bouncedEmit: () => void;

  constructor() {
    this.bouncedEmit = debounce(this.emitClick.bind(this), 10);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('disabled' in changes && this.button) {
      this.setDisabled();
    }
  }

  ngAfterViewInit(): void {
    this.button?.nativeElement.addEventListener('click', this.bouncedEmit);
    this.setDisabled();
  }

  ngOnDestroy(): void {
    this.button?.nativeElement.removeEventListener('click', this.bouncedEmit);
  }

  private setDisabled(): void {
    if (this.disabled) {
      this.button.nativeElement.setAttribute('disabled', String(true));
    } else {
      this.button.nativeElement.removeAttribute('disabled');
    }
  }

  private emitClick(): void {
    this.buttonClick.emit();
  }
}
