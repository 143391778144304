import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'com-right-panel-header',
  templateUrl: './right-panel-header.component.html'
})
export class RightPanelHeaderComponent {
  @Input() title: string;
  @Input() parentTitle: string;

  @Output() closeDrawer = new EventEmitter<void>();
}
