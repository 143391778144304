import { Pipe, PipeTransform } from '@angular/core';
import { EventInterval } from '@common/dialogs/planning-dialog/types';
import { isOverlap, setTimeZone } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { addTimeToDate } from '@common/dialogs/planning-dialog/utils';

@Pipe({
  name: 'getIntersectionEvents'
})
export class GetIntersectionEventsPipe implements PipeTransform {
  transform(events: EventInterval[], forecastStart: string, forecastEnd: string): EventInterval[] {
    return events.filter((event) => {
      const start = addTimeToDate(forecastStart, event.timeStart);
      const end = addTimeToDate(forecastEnd, event.timeEnd);
      const planningStart = setTimeZone(forecastStart);
      const planningEnd = setTimeZone(forecastEnd);

      return isOverlap(start, end, planningStart, planningEnd, true);
    });
  }
}
