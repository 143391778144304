import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LegendOption } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';

@Component({
  selector: 'com-planning-diagram-legend',
  templateUrl: 'planning-diagram-legend.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningDiagramLegendComponent {
  @Input() cellSize: number;
  @Input() legend: LegendOption[];
}
