import { IEmployee } from '@common/types/employee';
import { IPage } from '@common/types/page.model';

export interface IMemberSlim {
  id: string;
  committeeId: string;
  committeeName: string;
  formationProtocol: boolean;
  responsibleForUploadingDocument: boolean;
  responsibleOfTestDevelopment: boolean;
  roleName: string;
  uploadMaterial: string;
  uploadPreResolution: string;
  employee: IEmployee;
}

export interface IMemberSlimResponse extends IPage {
  data: IMemberSlim[];
}
