import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'environments/environment';
import {
  KeycloakAngularModule,
  KeycloakEventType,
  KeycloakService
} from 'keycloak-angular';
import { filter, tap } from 'rxjs';
import { keycloakOptions } from '../../core/config/keycloak-options';

const initializeKeycloak =
  (keycloak: KeycloakService) => (): Promise<boolean> =>
    keycloak.init({
      config: environment.keycloak,
      initOptions: keycloakOptions
    });

@NgModule({
  imports: [HttpClientModule, KeycloakAngularModule],
  providers: [
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [KeycloakService],
      multi: true
    }
  ]
})
export class AuthModule {}
