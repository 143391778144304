import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuorumColors } from '@common/enums';

@Component({
  selector: 'com-badge',
  templateUrl: './badge.component.html'
})
export class BadgeComponent implements OnChanges {
  @Input() type: 'quorum' | never;
  @Input() value: number | string;
  @Input() count: number = 0;
  @Input() total: number;
  @Input() label: string;
  @Input() error: string;
  @Input() hint: string;
  @Input() quorumBoundary: number;
  @Input() styles: string = '';

  public bgColor: QuorumColors = QuorumColors.Gray;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.type === 'quorum' && !this.label) {
      this.label = 'Кворум (факт)';
    }
    if ('value' in changes || 'quorumBoundary' in changes) {
      switch (this.type) {
        case 'quorum':
          if (+this.value >= this.quorumBoundary) {
            this.bgColor = QuorumColors.Green;
          } else {
            this.bgColor = QuorumColors.Red;
          }
          break;
        default:
          this.bgColor = QuorumColors.Gray;
          break;
      }
    }
  }
}
