import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
@Component({
  selector: 'com-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input() text: string;
}
