<div class="flex flex-col gap-8" [ngClass]="{ 'opacity-50': isLoading }">
  <div class="flex">
    <div [style.min-width.px]="itemListWidth" [style.max-width.px]="itemListWidth"></div>
    <section class="flex w-full relative">
      <div
        *ngFor="let scaleCell of scale$ | async; let i = index"
        class="flex justify-center items-center max-h-[44px] text-[20px]"
        [ngClass]="{
          'bg-timeline-cell-one': i % 2 === 0,
          'bg-timeline-cell-two': i % 2 !== 0
        }"
        [ngStyle]="{
          width: cellSize + 'px'
        }"
      >
        {{ scaleCell.valueName }}
      </div>
      <div
        *ngIf="forecastEventStart && forecastEventEnd"
        class="absolute z-20 border-[2px] border-diagram-planning rounded-[4px] border-dashed"
        [style.min-width.px]="windowWidth$ | async"
        [style.min-height.px]="windowHeight$ | async"
        [style.left.px]="windowLeft$ | async"
      ></div>
      <div
        class="absolute -right-[24px] -top-[5px] w-[60px] bg-white z-30"
        [style.min-height.px]="(windowHeight$ | async) + 10"
      ></div>
      <div
        class="absolute bg-white z-30"
        [style.min-width.px]="itemListWidth + 23"
        [style.left.px]="(itemListWidth + 23) * -1"
        [style.min-height.px]="(windowHeight$ | async) + 10"
      >
        <ng-content></ng-content>
      </div>
    </section>
  </div>

  <div class="flex relative overflow-y-scroll com-perfect-scrollbar">
    <section
      class="flex flex-col gap-4 z-40"
      [style.min-width.px]="itemListWidth - 20"
      [style.max-width.px]="itemListWidth - 20"
    >
      <ng-container *ngFor="let entity of timelineEntities">
        <div class="flex justify-between items-center">
          <div class="flex items-center min-h-11 min-w-8 relative">
            <ng-container *ngIf="entity.isRoom; else user">
              <mat-icon
                class="icon-size-6 cursor-pointer mr-2.5 ml-1"
                svgIcon="heroicons_outline:building-office-2"
              ></mat-icon>
              <div
                class="absolute min-h-[32px] min-w-[32px] border border-planning-border rounded-full"
              ></div>
            </ng-container>
            <ng-template #user>
              <com-avatar
                class="mr-2 cursor-default"
                fontSize="sm"
                [sizePx]="32"
                [isLink]="true"
                [name]="entity.fullName"
              ></com-avatar>
            </ng-template>

            <div class="flex flex-col justify-center">
              <span
                class="text-[14px] font-medium truncate text-foreground max-w-[330px]"
                [ngClass]="{ 'opacity-70': !entity.isActive && !entity.isRoom }"
                [matTooltip]="entity.fullName"
              >
                {{ entity.fullName }}
              </span>
              <span
                class="text-sm truncate max-w-[330px]"
                [matTooltip]="entity.position"
                [ngClass]="
                  !entity.isActive && !entity.isRoom ? 'text-red-500 font-semibold' : 'text-foreground'
                "
              >
                {{
                  entity.isRoom
                    ? 'Переговорная комната'
                    : entity.isActive
                      ? entity.position || 'Участник комитета'
                      : 'Заблокирован'
                }}
              </span>
            </div>
          </div>
          <div
            *ngIf="
              forecastEventStart && forecastEventEnd && entity.events
                | getBusyDateRange: forecastEventStart : forecastEventEnd as busyInterval
            "
            class="flex items-center gap-2 max-h-[44px] mr-2 min-w-[193px] rounded-lg border-planning-red border px-2 min-h-[44px] cursor-pointer"
            [matMenuTriggerFor]="detailIntersection"
          >
            <mat-icon
              class="icon-size-4 text-timeline-gray"
              svgIcon="heroicons_outline:information-circle"
            ></mat-icon>
            <mat-menu
              #detailIntersection="matMenu"
              yPosition="above"
              xPosition="before"
              class="max-w-none w-full"
            >
              <com-intersection-detail
                [events]="entity.events | getIntersectionEvents: forecastEventStart : forecastEventEnd"
              ></com-intersection-detail>
            </mat-menu>

            <span class="text-foreground text-[14px] font-medium">Занят в:</span>
            <div class="flex justify-center items-center">
              {{ busyInterval }}
            </div>
          </div>
        </div>
      </ng-container>
    </section>

    <div class="flex h-full">
      <button
        class="flex z-40 items-center cursor-pointer min-w-5 max-w-5 hover:bg-gradient-to-t from-white from-10% via-[#f8fafc] via-20% to-white to-90%"
        (click)="changeIntervalSubtract()"
        [disabled]="isLoading || timelineStart <= 0"
      >
        <mat-icon class="icon-size-5 opacity-80" svgIcon="heroicons_outline:chevron-double-left"></mat-icon>
      </button>

      <div class="relative flex flex-col gap-4">
        <ng-container *ngFor="let row of timelineGrid$ | async">
          <div class="flex flex-col">
            <section class="flex relative h-11" [style.width]="timelineSize * cellSize + 'px'">
              <ng-container *ngFor="let timelineCell of row.timeline; let i = index">
                <div
                  class="flex items-center justify-center h-11 box-border text-white absolute"
                  [ngClass]="{
                    'bg-[#F8F8F8]': i % 2 === 0,
                    'bg-[#F3F3F3]': i % 2 !== 0
                  }"
                  cell
                  [cell]="timelineCell"
                  [index]="i"
                  [timeline]="row.timeline"
                  [timelineStart]="timelineStart"
                  [cellSize]="cellSize"
                ></div>
              </ng-container>
            </section>
          </div>
        </ng-container>

        <div class="absolute top-0 left-0 flex flex-col gap-4 z-40">
          <ng-container *ngIf="eventTimeline$ | async as eventTimeline">
            <section
              *ngFor="let entity of eventTimeline; let indexOfRow = index"
              class="flex w-full relative h-11"
            >
              <ng-container *ngFor="let timelineCell of entity.timeline; let i = index">
                <div
                  [matMenuTriggerFor]="
                    timelineCell?.events && timelineCell.events.length !== 0 ? detailEvent : null
                  "
                  class="flex items-center justify-center h-11 box-border text-white absolute"
                  [ngClass]="{ 'cursor-pointer': timelineCell?.events && timelineCell.events.length !== 0 }"
                  cell
                  [cell]="timelineCell"
                  [index]="i"
                  [timeline]="entity.timeline"
                  [timelineStart]="timelineStart"
                  [indexOfRow]="indexOfRow"
                  [lastRowIndex]="eventTimeline.length"
                  [cellSize]="cellSize"
                ></div>
                <mat-menu
                  #detailEvent="matMenu"
                  yPosition="above"
                  xPosition="before"
                  class="max-w-none w-full"
                >
                  <com-intersection-detail
                    *ngIf="timelineCell?.events"
                    [events]="timelineCell.events"
                  ></com-intersection-detail>
                </mat-menu>
              </ng-container>
            </section>
          </ng-container>
        </div>
      </div>

      <button
        class="flex z-30 items-center cursor-pointer min-w-5 max-w-5 hover:bg-gradient-to-t from-white from-10% via-[#f8fafc] via-20% to-white to-90%"
        (click)="changeIntervalAdd()"
        [disabled]="isLoading || timelineEnd >= 24"
      >
        <mat-icon class="icon-size-5 opacity-80" svgIcon="heroicons_outline:chevron-double-right"></mat-icon>
      </button>
    </div>
  </div>
</div>
