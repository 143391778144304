import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaysOffComponent } from '@common/shared/components/form-groups/days-off/days-off.component';
import { SharedFormElementsModule } from '@common/shared';

@NgModule({
  declarations: [DaysOffComponent],
  exports: [DaysOffComponent],
  imports: [CommonModule, SharedFormElementsModule]
})
export class DaysOffModule {}
