import { Pipe, PipeTransform } from '@angular/core';
import { CommitteeIntersection } from '@common/dialogs/planning-dialog/types';

@Pipe({
  name: 'allIntersectionResolve'
})
export class AllIntersectionResolvePipe implements PipeTransform {
  transform(events: CommitteeIntersection[]): boolean {
    return !events.some((e) => e.hasIntersection && !e.transferedStart);
  }
}
