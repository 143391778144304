import { IEventAgenda } from './protocols.types';
import { IUploadConditionsDto, IDocument, IAttachmentDto } from './form';
import { IEmployee } from './employee';

export interface IAgendaItem {
  id: string;
  title: string;
  resolution: IResolution[];
  speakers: IEmployee[];
  duration?: number;
  timezone?: string;
  attachments?: IDocument[];
  order: number;
  materialCondition?: IUploadConditionsDto;
}

export interface IResolution {
  id: string;
  order: number;
  value: string;
}

export interface IAgendaItemAttachmentPayload {
  agendaItem: IEventAgenda;
  materialIndex: number;
}

export interface ISpeaker {
  employee: IEmployee;
  needLoadedMaterial: boolean;
  needLoadedPreResolution: boolean;
}

export interface INoSpeakerMaterial {
  user: IEmployee;
  materials: IAttachmentDto[];
}
