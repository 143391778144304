import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEmployee, IOption } from '@common/types';

interface IMaterialRestrictDialogData {
  downloadAccess: boolean;
  viewAccess: boolean;
  members: IEmployee[];
  downloadBlacklist: string[];
  viewBlacklist: string[];
}

@Component({
  selector: 'com-material-restrict-access',
  templateUrl: './material-restrict-access.component.html'
})
export class MaterialRestrictAccessComponent implements OnInit {
  public members: IOption[] = [];
  public formGroup: FormGroup = new FormGroup({
    downloadBlacklist: new FormControl<string[]>(null),
    viewBlacklist: new FormControl<string[]>(null)
  });

  constructor(
    private readonly _dialogRef: MatDialogRef<MaterialRestrictAccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMaterialRestrictDialogData
  ) {}

  public ngOnInit(): void {
    this.formGroup.get('downloadBlacklist').setValue(this.data.downloadBlacklist);
    this.formGroup.get('viewBlacklist').setValue(this.data.viewBlacklist);
    this.members = this.data.members.map(({ id, fullName }) => ({
      id,
      name: fullName
    }));
  }

  public onSubmit(): void {
    this._dialogRef.close(this.formGroup.value);
  }

  public onClose(): void {
    this._dialogRef.close();
  }
}
