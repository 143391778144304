<div class="flex flex-col gap-4">
  <div class="flex items-center gap-2">
    <mat-icon class="icon-size-4 mb-0.5" svgIcon="heroicons_outline:clock"></mat-icon>
    Плановый срок загрузки материалов:
    <span>{{ committee.materialUploadDeadline | date: 'dd.MM.yyyy HH:mm' }}</span>
  </div>

  <div class="flex flex-col">
    <mat-tab-group>
      <mat-tab label="Обязательные">
        <div class="flex flex-col gap-3">
          <ng-container
            *ngTemplateOutlet="
              materialUploadButton;
              context: {
                isLoading: isRequiredLoading && (isMaterialLoading$ | async),
                isShowBlock: isRequiredShowBlock && addLinkBlockShown,
                isRequired: true
              }
            "
          ></ng-container>
        </div>
      </mat-tab>
      <mat-tab label="Дополнительные">
        <div class="flex flex-col gap-3">
          <ng-container
            *ngTemplateOutlet="
              materialUploadButton;
              context: {
                materials:
                  (agenda.materials
                  | filterByField: 'required' : false
                  | filterByField: 'eventResolutionId' : null),
                isLoading: !isRequiredLoading && (isMaterialLoading$ | async),
                isShowBlock: !isRequiredShowBlock && addLinkBlockShown,
                isRequired: false
              }
            "
          ></ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-template
    #materialUploadButton
    let-isRequired="isRequired"
    let-isLoading="isLoading"
    let-isShowBlock="isShowBlock"
  >
    <div class="flex flex-col">
      <section *ngIf="agenda.order === 0" class="flex flex-col gap-2">
        <span class="font-bold">Загружено системой автоматически</span>
        <com-material-view
          [materials]="agenda.materials | getSystemMaterial"
          [canSetViolation]="canMakeCommentsOnTheProvidedMaterials && !protocolSent"
          [canRestrictMaterialDownload]="canRestrictMaterialDownload"
          [canRestrictMaterialView]="canRestrictMaterialView"
          [canUploadMaterial]="canUploadMaterial"
          [currentMember]="currentMember"
          [responsibleUploadingId]="committee.responsibleUploadingMaterial?.id"
          [materialUploadDeadline]="materialUploadDeadline"
          (deleteAttachment)="onDeleteAttachmentClick($event)"
          (setViolation)="onSetViolation($event)"
          (configAttachment)="configAttachmentClick.next($event)"
          (download)="downloadAttachmentClick.emit($event)"
        ></com-material-view>
      </section>

      <section *ngIf="agenda.speakers.length !== 0" class="flex flex-col gap-1 mt-4">
        <span class="font-bold">Материалы докладчиков</span>
        <div *ngFor="let speaker of agenda.speakers" class="flex flex-col gap-1">
          <div class="flex items-center gap-2">
            <com-employee
              class="w-9"
              [isSmall]="true"
              [showImage]="true"
              [showContent]="false"
              [employee]="speaker.employee"
            ></com-employee>
            <com-employee [employee]="speaker.employee"></com-employee>
          </div>

          <com-material-view
            [materials]="
              speaker
                | getSpeakerMaterials: agenda.materials
                | filterByField: 'required' : isRequired
                | filterByField: 'eventResolutionId' : null
            "
            [canSetViolation]="canMakeCommentsOnTheProvidedMaterials && !protocolSent"
            [canRestrictMaterialDownload]="canRestrictMaterialDownload"
            [canRestrictMaterialView]="canRestrictMaterialView"
            [canUploadMaterial]="canUploadMaterial"
            [currentMember]="currentMember"
            [responsibleUploadingId]="committee.responsibleUploadingMaterial?.id"
            [materialUploadDeadline]="materialUploadDeadline"
            [speaker]="speaker"
            [required]="isRequired"
            (deleteAttachment)="onDeleteAttachmentClick($event)"
            (setViolation)="onSetViolation($event)"
            (configAttachment)="configAttachmentClick.next($event)"
            (download)="downloadAttachmentClick.emit($event)"
          ></com-material-view>
        </div>
      </section>

      <ng-container
        *ngIf="agenda.speakers | getUserMaterialWithoutSpeaker: agenda.materials : isRequired as materials"
      >
        <section *ngIf="materials.length !== 0" class="flex flex-col gap-2 mt-4">
          <span class="font-bold">Загружено не докладчиками</span>
          <div *ngFor="let noSpeaker of materials">
            <div class="flex items-center gap-2">
              <com-employee
                class="w-9"
                [isSmall]="true"
                [showImage]="true"
                [showContent]="false"
                [employee]="noSpeaker.user"
              ></com-employee>
              <com-employee [employee]="noSpeaker.user"></com-employee>
            </div>
            <com-material-view
              [materials]="noSpeaker.materials"
              [canSetViolation]="canMakeCommentsOnTheProvidedMaterials && !protocolSent"
              [canRestrictMaterialDownload]="canRestrictMaterialDownload"
              [canRestrictMaterialView]="canRestrictMaterialView"
              [canUploadMaterial]="canUploadMaterial"
              [currentMember]="currentMember"
              [responsibleUploadingId]="committee.responsibleUploadingMaterial?.id"
              [materialUploadDeadline]="materialUploadDeadline"
              [required]="isRequired"
              (deleteAttachment)="onDeleteAttachmentClick($event)"
              (setViolation)="onSetViolation($event)"
              (configAttachment)="configAttachmentClick.next($event)"
              (download)="downloadAttachmentClick.emit($event)"
            ></com-material-view>
          </div>
        </section>
      </ng-container>
    </div>
    <button
      *ngIf="canUploadMaterial"
      class="w-fit h-8 min-h-8"
      mat-stroked-button
      color="primary"
      [matMenuTriggerFor]="menu"
      [disabled]="isLoading"
    >
      <mat-spinner *ngIf="isLoading" class="absolute" [diameter]="BUTTON_SPINNER_DIAMETER"></mat-spinner>
      <div class="flex items-center gap-1">
        <span>Прикрепить</span>
        <mat-icon class="icon-size-4" svgIcon="heroicons_outline:chevron-down"></mat-icon>
      </div>
    </button>

    <mat-menu #menu="matMenu">
      <input #fileInput [hidden]="true" type="file" (change)="onMaterialUpload($event, isRequired)" />
      <button mat-menu-item (click)="fileInput.click()">
        <mat-icon class="icon-size-4" svgIcon="heroicons_outline:document-add"></mat-icon>
        <span>Материал</span>
      </button>
      <button mat-menu-item (click)="onLinkUploadBlockAdd(isRequired)">
        <mat-icon class="icon-size-4" svgIcon="heroicons_outline:link"></mat-icon>
        <span>Ссылку</span>
      </button>
    </mat-menu>
    <div *ngIf="isShowBlock" class="flex items-center gap-2">
      <com-form-block [showDivider]="false">
        <com-form-input
          #linkInput
          class="form-group"
          [control]="linkControl"
          type="url"
          placeholder="Введите ссылку"
        >
          <ng-container actions>
            <button
              class="w-fit min-w-0 rounded-l-none"
              mat-flat-button
              color="primary"
              [disabled]="linkControl.invalid"
              (click)="onLinkAdd(isRequired)"
            >
              <mat-icon class="icon-size-4" svgIcon="heroicons_outline:check"></mat-icon>
            </button>
          </ng-container>
        </com-form-input>
      </com-form-block>
      <button class="w-8 min-w-8 h-8" mat-button (click)="onLinkUploadBlockDelete()">
        <mat-icon class="icon-size-4" svgIcon="heroicons_outline:trash"></mat-icon>
      </button>
    </div>
  </ng-template>
</div>
