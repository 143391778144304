import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class HttpHostInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      url: request.url.startsWith('api')
        ? `${environment.api}/${request.url}`
        : request.url.startsWith('v1') || request.url.startsWith('v2')
        ? `${environment.calendarApi}/${request.url}`
        : request.url.startsWith('taskManagerApi')
        ? `${environment.taskManagerApi}/${request.url.replace(
            'taskManagerApi',
            'api'
          )}`
        : request.url
    });

    return next.handle(request);
  }
}
