import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileType' })
export class FileTypePipe implements PipeTransform {
  transform(fileName: string, extensions: string[]): boolean {
    if (!fileName) return false;
    const ext = fileName.split('.').pop();
    return extensions.includes(ext);
  }
}
