import { Pipe, PipeTransform } from '@angular/core';
import { MemberPresenceStatusEnum } from '@common/enums';

enum IStatusClass {
  SUCCESS = 'ring-color-green',
  ACCENT = 'ring-color-orange',
  WARN = 'ring-color-yellow',
  ERROR = 'ring-color-red',
  DEFAULT = 'ring-color-gray'
}

@Pipe({ name: 'status' })
export class EmployeeStatusPipe implements PipeTransform {
  transform(employeeStatus: MemberPresenceStatusEnum): IStatusClass {
    switch (employeeStatus) {
      case MemberPresenceStatusEnum.PARTICIPATE:
        return IStatusClass.SUCCESS;
      case MemberPresenceStatusEnum.MISSED:
        return IStatusClass.ERROR;
      case MemberPresenceStatusEnum.LATE:
        return IStatusClass.ACCENT;
      case MemberPresenceStatusEnum.EARLIER_LOGOUT:
        return IStatusClass.WARN;
      case MemberPresenceStatusEnum.NOT_REQUIRED:
        return IStatusClass.DEFAULT;
      default:
        return IStatusClass.DEFAULT;
    }
  }
}
