import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarComponent } from './snackbar.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [SnackbarComponent],
  entryComponents: [SnackbarComponent],
  declarations: [SnackbarComponent]
})
export class ComSnackbarModule {}
