import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
  selector: 'com-timeline-legend',
  templateUrl: 'timeline-legend.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineLegendComponent {

}
