import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from './custom-paginator-intl';

@Injectable()
export class ProtocolsPaginatorIntl extends CustomPaginatorIntl {
  override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    if (length === 0 || pageSize === 0) {
      return `0 из ${length}`;
    }
    length = Math.max(length, 0);
    const index = page * pageSize;
    return `${index + 1} из ${length}`;
  };
}
