import { KeycloakConfig } from 'keycloak-js';

export interface IEnvironment {
  production: boolean;
  keycloak: KeycloakConfig;
  api: string;
  calendarApi: string;
  constructorApi: string;
  taskManagerApi: string;
  wsHost: string;
  jitsuLogging: IJitsuLogging;
  jitsiUrl: string;
}

export interface IJitsuLogging {
  hostUrl: string;
  apiKey: string;
}
