import { IMemberDto, IEmployee, IOption, IRole, IRrule, IQuorumModel } from '@common/types';
import {
  CalendarGroupsEnum,
  CommitteeEventStatusEnum,
  E_EVENT_IN_PROGRESS_STATE,
  EventTypesEnum,
  MemberAllowanceStatusEnum,
  MemberMaterialStatusEnum,
  MemberMaterialViewStatusEnum,
  MemberPresenceStatusEnum,
  MemberResolutionStatusEnum,
  ProtocolStatusEnum
} from '@common/enums';

export type CalendarViewMode = 'grid' | 'list';
export type CalendarView = 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay' | 'listDay';

export interface ICalendar {
  id: EventTypesEnum;
  title: string;
  color: string;
  group: CalendarGroupsEnum;
  visible: boolean;
}

export interface ICalendarSettings {
  dateFormat: 'DD/MM/YYYY' | 'MM/DD/YYYY' | 'YYYY-MM-DD' | 'll';
  timeFormat: '12' | '24';
  startWeekOn: 6 | 0 | 1;
}

export interface ICalendarWeekday {
  abbr: string;
  label: string;
  value: string;
}

export interface ICalendarEvent {
  id: string;
  allDay?: boolean;
  calendarIds: string[];
  cancel: boolean | null;
  calendarEventTemplateId?: string;
  committeeId?: string;
  duration: number;
  eventInProgressState?: E_EVENT_IN_PROGRESS_STATE;
  eventStartedTime?: string;
  eventId?: string;
  baseTime?: string;
  title: string;
  name?: string;
  start: string | null;
  end: string | null;
  eventTime?: string;
  transferDate?: string;
  protocolStatus?: ProtocolStatusEnum;
  protocolData?: IEventProtocolData;
  committeeEventStatus?: CommitteeEventStatusEnum;
  quorumBoundary?: number;
  committeeMeetingRooms?: IOption[];
  cancelReason?: string;
  transferReason?: string;
  suspendedReason?: string;
  quorum: IQuorumModel;
  quorumOnlyThosePresent: IQuorumModel;
  rrule?: IRrule;
  prevEventId: string;
  prevEventTime: string;
  rruleString?: string;
  timeString?: string;
}

export interface QuorumResponse {
  quorum: IQuorumModel;
  quorumOnlyThosePresent: IQuorumModel;
}

export interface ICalendarCommitteeEvent {
  id: string;
  committeeId: string;
  eventTime: string;
  baseTime: string;
  transferReason: string;
  suspendedReason: string;
  duration: number;
  name: string;
  role: IRole;
  cancel: boolean;
  cancelReason: string;
  protocolStatus: ProtocolStatusEnum;
  committeeEventStatus: CommitteeEventStatusEnum;
  formationPeriod: any;
  transferDate: string;
  prevEventId: string;
  prevEventTime: string;
  // isMeeting: boolean | null;
  // currentUserIsCreator: boolean;
  // currentUserIsMember: boolean;
  eventId?: string;
  position?: string;
}

export interface IEventProtocolData {
  status: ProtocolStatusEnum;
  formatedAt: string;
  formatedBy: IEmployee;
  formationDeadline: string;
  formationResponsible: IEmployee;
}

export interface IMember {
  committeeMember: IMemberDto;
  memberAccesses: number[];
  // Права без добавления делегируемых прав
  originalAccesses: number[];
  id: string;
  isParticipate: boolean;
  delegateTo: IMemberDto;
  delegationReason: IReasonForDelegation | null;
  allowanceStatus: MemberAllowanceStatusEnum;
  hasResolution: boolean;
  materialStatus: MemberMaterialStatusEnum;
  materialViewStatus: MemberMaterialViewStatusEnum;
  hasMaterialViolation?: boolean;
  hasDisciplinaryViolation?: boolean;
  materialViolations: string[];
  disciplinaryViolations: string[];
  resolutionStatus: MemberResolutionStatusEnum;
  presence: MemberPresenceStatusEnum;
  systemPresence: MemberPresenceStatusEnum;
  violation: string | number;
  employee?: IEmployee;
  type?: number;
  isDelegated?: boolean;
  role?: IRole;
  roles?: IRole[];
  status?: boolean;
  otherReason?: string;
  markManually?: boolean;
  preResolutionStatus?: MemberMaterialStatusEnum;
}

// export interface IDelegation {
//   committeeId: string;
//   employee: IEmployee;
//   id: string;
//   legitimateDeputy: string | null;
//   position: string | null;
// }

export interface IMemberPresentStat {
  total: number;
  present: number;
  totalSystem: number;
  presentSystem: number;
  quorum: number;
  quorumSystem: number;
}

export interface IDelegateOpt {
  delegateToEmployeeId: string;
  delegationReasonId?: string;
  otherReason?: string;
  delegateFromMemberId: string;
}

export interface IReasonForDelegation {
  id: string;
  name: string;
  withAdditionalField: boolean;
}

export interface IChangeStatusParams {
  employeeId: string;
  status: number;
}

export interface IUnplannedEvent {
  committeeId: string;
  eventDate: string;
}

export interface IChangeEventStatusParams {
  committeeEventStatus: CommitteeEventStatusEnum;
  newTime: string | null;
  event: ICalendarCommitteeEvent | null;
  suspendedReason: string | null;
}

export interface ITransferEventParams {
  eventTime: string;
  reason: string | null;
}
