import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventInterval } from '@common/dialogs/planning-dialog/types';

@Component({
  selector: 'com-intersection-detail',
  templateUrl: 'intersection-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntersectionDetailComponent {
  @Input() events: EventInterval[] = [];
}
