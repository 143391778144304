import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optionField'
})
export class OptionFieldPipe implements PipeTransform {
  transform(options: any[], value: number | string | boolean): any {
    const option = options.find((item) => item.value === value);

    return option;
  }
}
