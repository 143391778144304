import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'com-ekp-checkbox',
  templateUrl: 'ekp-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EkpCheckboxComponent implements OnDestroy, AfterViewInit {
  @Input() label: string;
  @Input() checked = true;
  @Output() valueChanged = new EventEmitter<boolean>();

  @ViewChild('checkbox') checkbox: ElementRef<HTMLEkpCheckboxElement>;

  ngAfterViewInit(): void {
    this.checkbox?.nativeElement.addEventListener('valueChange', this.valueChange.bind(this));
  }

  ngOnDestroy(): void {
    this.checkbox?.nativeElement.removeEventListener('valueChange', this.valueChange.bind(this));
  }

  private valueChange(e: CustomEvent<boolean>): void {
    this.valueChanged.emit(e.detail);
  }
}
