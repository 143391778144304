import { Option } from '@common/dialogs/planning-dialog/types';

export enum IntersectionTab {
  all,
  one
}

export const INTERSECTION_TABS: Option<IntersectionTab>[] = [
  {
    name: 'Все',
    value: IntersectionTab.all
  },
  {
    name: 'Пересечение',
    value: IntersectionTab.one
  }
];
