import { Option } from '@common/dialogs/planning-dialog/types';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

export const DAYS_OF_WEEK: string[] = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export const WEEK_IN_YEAR = 53;
export const WEEK_IN_YEAR_ARRAY: number[] = Array.from({ length: WEEK_IN_YEAR }, (_, index) => index + 1);

export enum WeekDay {
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
  Sunday = '0'
}

export const DAY_IN_WEEK: WeekDay[] = [
  WeekDay.Monday,
  WeekDay.Tuesday,
  WeekDay.Wednesday,
  WeekDay.Thursday,
  WeekDay.Friday,
  WeekDay.Saturday,
  WeekDay.Sunday
];

export enum LegendName {
  currentDay = 'текущий день',
  weekday = 'будний день',
  weekend = 'выходной день',
  plannedEvent = 'планируемое событие',
  intersection = 'пересечение',
  transferEvent = 'перенесенное событие'
}

export enum Legend {
  currentDay = 'currentDay',
  weekday = 'weekday',
  weekend = 'weekend',
  plannedEvent = 'plannedEvent',
  intersection = 'intersection',
  transferEvent = 'transferEvent',
  freeSlot = 'freeSlot'
}

export type LegendOption = { name: string; value: string; class: string };

export const LEGEND: LegendOption[] = [
  {
    name: LegendName.currentDay,
    value: Legend.currentDay,
    class: 'border-[2px] border-diagram-today'
  },
  {
    name: LegendName.weekday,
    value: Legend.weekday,
    class: 'bg-diagram-weekday'
  },
  {
    name: LegendName.weekend,
    value: Legend.weekend,
    class: 'bg-diagram-weekend'
  },
  {
    name: LegendName.plannedEvent,
    value: Legend.plannedEvent,
    class: 'bg-diagram-planning'
  },
  {
    name: LegendName.intersection,
    value: Legend.intersection,
    class: 'com-busy-gradient'
  },
  {
    name: LegendName.transferEvent,
    value: Legend.transferEvent,
    class: 'bg-diagram-transfer'
  }
];

export enum DiagramTab {
  year,
  month
}

export const DIAGRAM_TABS: Option<DiagramTab>[] = [
  {
    name: 'год',
    value: DiagramTab.year
  }
];

export enum CellType {
  WeekDay,
  Weekend,
  Planning,
  Transfer,
  Intersection,
  FreeDay
}

export const MAT_TOOLTIP_OPTIONS: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: 'auto',
  position: 'left',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true
};
