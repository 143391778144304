import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DATE_TIME_FORMAT } from '@common/constants';
import { catchError, map, Observable } from 'rxjs';
import moment from 'moment/moment';
import {
  IEventAgenda,
  IEventResolution,
  PageModel,
  IProtocolDocData,
  IProtocolWrapper,
  IProtocolRequest,
  IBlackListBody,
  ITask,
  IAgendaItemTask
} from '@common/types';
import { compareByField } from '@common/utils/util';
import { FileService } from '@common/services/file.service';
import { VoteEnum } from '@common/enums';

@Injectable({
  providedIn: 'root'
})
export class ProtocolService {
  constructor(
    private readonly http: HttpClient,
    private readonly _fileService: FileService
  ) {}

  // ***** Protocol Event Agenda Item *****
  /**
   * Get all agendas for event protocol
   *
   * @param eventId
   * @returns
   */
  public retrieveProtocolEventAgendas(eventId: string): Observable<IEventAgenda[]> {
    return this.http.get(`api/ProtocolEventAgendaItem/${eventId}/agenda`).pipe(
      map((agendaItems: IEventAgenda[]) => {
        return agendaItems.sort(compareByField('order')).map((agendaItem) => ({
          ...agendaItem,
          materials: agendaItem.materials.map((material) => ({
            ...material,
            created: moment(material.created).format()
          }))
        }));
      })
    );
  }

  //TODO проверить на беке нет такого ендпоинта
  /**
   * Create protocol event agendas
   *
   * @param eventId
   * @param body
   * @returns
   */
  public createProtocolEventAgenda(eventId: string, body: IEventAgenda): Observable<IEventAgenda> {
    return this.http.post<IEventAgenda>(`api/ProtocolEventAgendaItem/${eventId}/agenda`, body);
  }

  //TODO проверить на беке нет такого ендпоинта
  /**
   * Update agendas for event protocol
   *
   * @param eventId
   * @param body
   * @returns
   */
  public updateProtocolEventAgenda(eventId: string, body: IEventAgenda): Observable<IEventAgenda> {
    return this.http.put<IEventAgenda>(`api/ProtocolEventAgendaItem/${eventId}/agenda`, body).pipe(
      map((res: IEventAgenda) => ({
        ...res,
        materials: res.materials.map((material) => ({
          ...material,
          created: moment(material.created).format()
        }))
      }))
    );
  }

  public reloadAudioTranslating(agendaId: string, committeeEventId: string): Observable<void> {
    return this.http.get<void>(
      `api/ProtocolEventAgendaItem/ReloadAudioTranslating/${committeeEventId}/anenda/${agendaId}`
    );
  }

  //TODO перенести в agenda-item-attachment.service.ts
  // ***** Agenda Item Attachment *****
  public updateBlackList(body: IBlackListBody): Observable<void> {
    return this.http.put<void>('api/AgendaItemAttachment/UpdateBlackList', body);
  }

  // ***** Protocol Event Resolution *****
  /**
   * Create One or More protocol resolution
   *
   * @param body
   * @returns
   */
  public createSomeProtocolEventResolutions(
    body: Partial<IEventResolution>[]
  ): Observable<Partial<IEventResolution>[]> {
    return this.http.post<Partial<IEventResolution>[]>('api/ProtocolEventResolution/batch', body);
  }
  /**
   * Create One protocol resolution
   *
   * @param body
   * @returns
   */
  public createProtocolEventResolution(body: Partial<IEventResolution>): Observable<IEventResolution> {
    const formData = new FormData();
    const recordFile = 'recordFile';

    for (const key in body) {
      if (Object.prototype.hasOwnProperty.call(body, key)) {
        const value = body[key];

        if (value || value === 0) {
          if (key === recordFile) {
            const file = new File([value], `resolution_${new Date().getTime().toString()}.wav`, {
              type: 'audio/wav'
            });
            formData.append(recordFile, file);
          } else {
            formData.append(key, value);
          }
        }
      }
    }

    return this.http.post<IEventResolution>('api/ProtocolEventResolution', formData);
  }
  /**
   * Update Protocol resolution
   *
   * @param body
   * @returns
   */
  public updateProtocolEventResolution(body: Partial<IEventResolution>): Observable<IEventResolution> {
    const formData = new FormData();

    for (const key in body) {
      if (Object.prototype.hasOwnProperty.call(body, key)) {
        const value = body[key];

        if (value || value === 0) {
          formData.append(key, value);
        }
      }
    }

    return this.http.put<IEventResolution>('api/ProtocolEventResolution', formData);
  }
  /**
   * Delete Protocol resolution
   *
   * @param protocolResolutionEventId
   * @param committeeEventId
   * @returns
   */
  public deleteProtocolEventResolution(
    protocolResolutionEventId: string,
    committeeEventId: string
  ): Observable<void> {
    return this.http.patch<void>(`api/ProtocolEventResolution/${protocolResolutionEventId}`, {
      committeeEventId
    });
  }
  /**
   * Отправка события кнопки "Обсуждение повестки"
   *
   * @param eventId
   * @param agendaItemId
   * @returns
   */
  public startProtocolEventAgendaDiscussion(eventId: string, agendaItemId: string): Observable<void> {
    // return this._http.get<IEventAgenda>(`api/ProtocolEventAgendaItem/${eventId}/agenda/${agendaItemId}`);
    return this.http.post<void>('api/ProtocolEventResolution/StartDiscussion', {
      committeeEventId: eventId,
      agendaItemId: agendaItemId
    });
  }

  public setVote(
    committeeEventId: string,
    resolutionId: string,
    employeeId: string,
    vote: VoteEnum
  ): Observable<void> {
    return this.http.put<void>('api/ProtocolEventResolution/SetVote', {
      committeeEventId,
      resolutionId,
      employeeId,
      vote
    });
  }

  public sendProtocolEventRating(
    committeeEventId: string,
    resolutionId: string,
    rating: number
  ): Observable<any> {
    return this.http.patch(`api/ProtocolEventResolution/${resolutionId}/${rating}`, null);
  }

  public getPreviousTasks(committeeEventId: string): Observable<IAgendaItemTask[]> {
    const params = new HttpParams({ fromObject: { committeeEventId } });
    return this.http
      .get<IAgendaItemTask[]>('api/ProtocolEventResolution/GetPreviousTasks', { params })
      .pipe
      /*map((tasks) =>
          tasks.sort((a, b) =>
            a.createdAt === null && b.createdAt !== null
              ? 1
              : a.createdAt !== null && b.createdAt === null
                ? -1
                : moment(b.createdAt).diff(moment(a.createdAt))
          )
        )*/
      ();
  }

  // ***** Protocol *****
  public getProtocolsEventsByCommittee(options: IProtocolRequest): Observable<IProtocolDocData> {
    let params = new HttpParams().set('page', options.page || 0).set('pageSize', options.pageSize || 10);

    if (options.startDate) {
      params = params.append('startDate', options.startDate);
    }

    if (options.endDate) {
      params = params.append('endDate', options.endDate);
    }

    return this.http.get(`api/ProtocolEvent/committee/${options.committeeId}`, { params }).pipe(
      map((res: IProtocolWrapper) => ({
        data: res.data.map((protocol) => ({
          ...protocol,
          committeeEventDto: {
            ...protocol.committeeEventDto,
            eventTime: moment.utc(protocol.committeeEventDto.eventTime).local().format(DATE_TIME_FORMAT)
          }
        })),
        pagination: new PageModel(res)
      }))
    );
  }

  public getProtocolPdf(eventId: string, filename = 'file'): void {
    this.http
      .get(`api/ProtocolEvent/pdf/${eventId}`, {
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        catchError((err) => {
          throw new Error(`Can't download. Details: ${err}`);
        })
      )
      .subscribe((res) => {
        this._fileService.saveFile(res.body, filename, 'application/pdf');
      });
  }

  public getProtocolZIP(eventId: string): Observable<HttpResponse<Blob>> {
    return this.http
      .get(`api/protocolEvent/downloadZip/${eventId}`, {
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        map((res) => {
          if (res.status === 204) {
            throw new Error('File is empty');
          }
          return res;
        })
      );
  }

  public getProtocolSinglePdf(eventId: string): Observable<HttpResponse<Blob>> {
    return this.http
      .get(`api/protocolEvent/getMergedAttachmentPDFFiles/${eventId}`, {
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        map((res) => {
          if (res.status === 204) {
            throw new Error('File is empty');
          }
          return res;
        })
      );
  }
}
