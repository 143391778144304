import { FuseNavigationItem } from '@fuse/components/navigation';

export enum NavigationId {
  Calendar = 'calendar',
  Committees = 'committees',
  Users = 'users',
  Roles = 'roles',
  Documents = 'documents',
  HealthStat = 'healthStat'
}

export const navigation: FuseNavigationItem[] = [
  {
    id: NavigationId.Calendar,
    title: 'Календарь',
    type: 'basic',
    link: '/calendar'
  },
  {
    id: NavigationId.Committees,
    title: 'Комитеты',
    type: 'basic',
    link: '/committees'
  },
  {
    id: NavigationId.Users,
    title: 'Пользователи',
    type: 'basic',
    link: '/users',
    admin: true
  },
  {
    id: NavigationId.Roles,
    title: 'Справочник ролей',
    type: 'basic',
    link: '/roles',
    admin: true
  },
  {
    id: NavigationId.Documents,
    title: 'Справочник документов',
    type: 'basic',
    link: '/documents',
    admin: true
  },
  {
    id: NavigationId.HealthStat,
    title: 'Работоспособность',
    type: 'basic',
    link: '/health'
  }
];
