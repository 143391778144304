export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

export const DATE_TIME_FORMAT = 'yyyy-MM-DDTHH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'yyyy-MM-DD';
export const DATE_FORMAT_DOT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT_TABLE = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_DOWNLOAD_FILE = 'DD.MM.YYYY_HH-mm';
