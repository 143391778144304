<div class="flex flex-col gap-4 p-6">
  <div class="flex items-start justify-between gap-2">
    <h2 class="font-bold text-2xl">
      {{ agendaItem ? agendaItem.title || '-' : 'Дополнительный вопрос повестки' }}
    </h2>
    <button mat-icon-button matTooltip="Закрыть" (click)="closeDrawer.emit()">
      <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </div>

  <div *ngIf="!agendaItem" class="flex flex-col gap-1 grow">
    <h2 class="font-semibold">Текст вопроса</h2>
    <com-form-input [control]="formGroup.controls.title"></com-form-input>
  </div>
  <div class="flex flex-col gap-1 grow">
    <h2 class="font-semibold">Докладчики</h2>
    <com-form-multiselect [control]="formGroup.controls.speakers" [options]="speakers"></com-form-multiselect>
  </div>
  <com-resolution
    [editable]="isResolutionEditable"
    [permission]="permission"
    [committeeEventId]="committeeEventId"
    [agenda]="agendaItem"
    (valueChange)="onResolutionValueChange($event)"
  ></com-resolution>

  <div class="flex justify-end">
    <button mat-flat-button color="primary" (click)="onSubmit()">Сохранить</button>
  </div>
</div>
