<div class="flex flex-col gap-4">
  <div *ngIf="parentTitle" class="flex items-center gap-2">
    <button
      class="w-8 h-8 min-h-8"
      mat-icon-button
      (click)="closeDrawer.emit()"
    >
      <mat-icon
        class="icon-size-4"
        svgIcon="heroicons_outline:arrow-left"
      ></mat-icon>
    </button>
    <span class="text-md">{{ parentTitle }}</span>
  </div>
  <div *ngIf="!parentTitle" class="flex items-center justify-between gap-2">
    <h2 class="font-bold text-2xl">{{ title }}</h2>
    <button mat-icon-button matTooltip="Закрыть" (click)="closeDrawer.emit()">
      <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </div>
</div>
