import { IUserBusyInterval } from '@common/types/calendar-api';
import { ITimelineEntity } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';

export interface Timeline {
  value: [number, number];
  status: StatusOfEvent;
  color: string;
  startTime: Date;
  endTime: Date;
  isAllDay: boolean;
  valueName?: string;
  event?: IUserBusyInterval;
}

export type TotalTimeline = ITimelineEntity & {
  timeline: Timeline[];
};

export interface ITimelineEvent extends IUserBusyInterval {
  eventStart: number;
  eventEnd: number;
}

export enum StatusOfEvent {
  eventTime = 'eventTime',
  userEvent = 'userEvent',
  empty = 'empty'
}
