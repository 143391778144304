import { ChangeDetectionStrategy, Component, Inject, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICommitteeDocumentDialogData, IOption, UploadedFileDto } from '@common/types';
import { BehaviorSubject, debounceTime, filter, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { DirectoryService } from '@common/services/directory.service';
import { UnsubscribeService } from '@common/services';
import { typeOptionsMapper } from '@common/modules/committees/committee-view/components/committee-documents/helpers/committee-documents.helpers';
import { IDirectory } from '@common/types/directory';
import { JoinStringPipe } from '@common/pipes';
import { FileExtension } from '@common/enums/directory.enum';
import { FileValidator } from '@common/modules/committees/committee-view/components/committee-documents/helpers/fileValidation';

interface ICommitteeDocumentForm {
  type: FormControl<IOption | string>;
  fileData: FormControl<UploadedFileDto | null>;
}

@Component({
  selector: 'com-committee-document',
  templateUrl: './committee-document.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class CommitteeDocumentComponent {
  public formGroup: FormGroup<ICommitteeDocumentForm>;
  public typeOptions$ = new BehaviorSubject<IOption[]>([]);
  public loadTypeOption$ = new Subject<string>();
  public directories: IDirectory[] = [];
  public acceptFile$ = new BehaviorSubject<string[]>([]);
  constructor(
    public matDialogRef: MatDialogRef<CommitteeDocumentComponent>,
    private directoryService: DirectoryService,
    @Inject(MAT_DIALOG_DATA) public data: ICommitteeDocumentDialogData,
    @Self() private unsubscribeService: UnsubscribeService
  ) {
    if (this.data) {
      this.acceptFile$.next(FileExtension[this.data.fileFormat].extension);
    }
    this.createForm();
    this.directorySearchSub();
    this.loadTypeOptionsSub();
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      type: new FormControl({ value: null, disabled: !!this.data }, [Validators.required]),
      fileData: new FormControl({ value: null, disabled: !this.data }, [
        Validators.required,
        FileValidator.fileExtensions(this.acceptFile$.value)
      ])
    });
  }

  private directorySearchSub(): void {
    this.formGroup.controls.type.valueChanges
      .pipe(
        debounceTime(150),
        filter((query) => typeof query === 'string'),
        tap((query) => this.loadTypeOption$.next(query as string)),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  public confirm(): void {
    const { value, valid } = this.formGroup;
    if (valid) {
      this.matDialogRef.close({
        ...this.data,
        ...value
      });
    }
  }

  private initTypeOptions(directories: IDirectory[]): void {
    this.directories = directories;
    this.typeOptions$.next(typeOptionsMapper(directories.filter((d) => !d.deleted)));
  }
  public typeSelect(type: IOption) {
    if (type) {
      const directory = this.directories.find((x) => x.id === type.id);

      if (!directory) return;

      this.acceptFile$.next(FileExtension[directory.fileFormat].extension);
      this.formGroup.controls.fileData.setValidators([
        Validators.required,
        FileValidator.fileExtensions(this.acceptFile$.value)
      ]);
      this.formGroup.controls.fileData.setValue(null);
      this.formGroup.controls.fileData.enable();
    } else {
      this.formGroup.controls.fileData.disable();
    }
  }

  private loadTypeOptionsSub(): void {
    this.loadTypeOption$
      .pipe(
        debounceTime(150),
        switchMap((query) =>
          this.directoryService
            .retrieveDirectories(query, 10, 0)
            .pipe(tap(({ data }) => this.initTypeOptions(data)))
        ),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }
}
