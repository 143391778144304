import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { FreeDay, FreeSlot } from '@common/dialogs/planning-dialog/types';
import { addTimeToDate } from '@common/dialogs/planning-dialog/utils';
import { FreeSlotSelect } from '@common/dialogs/planning-dialog/modules/planning-intersection/types';
import { TRANSFER_REASONS } from '@common/constants';

@Component({
  selector: 'com-free-slots',
  templateUrl: 'free-slots.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeSlotsComponent implements AfterViewInit, OnDestroy {
  @Input() times: FreeSlot[];
  @Input() days: FreeDay[];
  @Input() selectedDate = '';
  @Input() selectedTime = '';
  @Input() showReason = false;
  @Input() timeLoading = false;

  @Output() dateChange = new EventEmitter<FreeSlotSelect>();
  @Output() dayChange = new EventEmitter<string>();
  @Output() reasonChange = new EventEmitter<string>();

  @ViewChild('dateSelect') dateSelect: ElementRef<HTMLEkpSelectElement>;
  @ViewChild('timeSelect') timeSelect: ElementRef<HTMLEkpSelectElement>;
  @ViewChild('reasonSelect') reasonSelect: ElementRef<HTMLEkpSelectElement>;

  protected transferReasons = TRANSFER_REASONS;

  ngAfterViewInit(): void {
    this.dateSelect?.nativeElement.addEventListener('valueChange', this.setDate.bind(this));
    this.timeSelect?.nativeElement.addEventListener('valueChange', this.setTime.bind(this));
    this.reasonSelect?.nativeElement.addEventListener('valueChange', this.emitReason.bind(this));
  }

  ngOnDestroy(): void {
    this.dateSelect?.nativeElement.removeEventListener('valueChange', this.setDate.bind(this));
    this.timeSelect?.nativeElement.removeEventListener('valueChange', this.setTime.bind(this));
    this.reasonSelect?.nativeElement.removeEventListener('valueChange', this.emitReason.bind(this));
  }

  private setDate({ detail }: CustomEvent<string>): void {
    if (detail) {
      this.selectedDate = detail;
      this.dayChange.emit(detail);
      this.selectedTime = '';
      this.dateChange.emit(null);
    }
  }

  private emitReason({ detail }: CustomEvent<string>): void {
    this.reasonChange.emit(detail);
  }

  private setTime({ detail }: CustomEvent<string>): void {
    this.selectedTime = detail;
    this.emitDate();
  }

  private emitDate(): void {
    if (this.selectedDate && this.selectedTime) {
      this.dateChange.emit({
        date: addTimeToDate(this.selectedDate, this.selectedTime),
        selectedDate: this.selectedDate,
        selectedTime: this.selectedTime
      });
    }
  }
}
