import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'com-form-list',
  templateUrl: './form-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormListComponent {
  @Input() array = new FormArray<FormControl<string>>([]);
  @Input() labelText: string;
  @Input() customLabelClass = '';
  @Input() update: number;

  public addFormArrayItem(): void {
    this.array.push(new FormControl(null));
  }

  public removeFormArrayItem(index: number): void {
    this.array.removeAt(index);
  }
}
