<div class="flex flex-col gap-4">
  <com-form-item>
    <com-form-label [text]="labelText" type="group"></com-form-label>
  </com-form-item>
  <com-form-item *ngFor="let control of array.controls; index as i">
    <com-form-label
      *ngIf="array.controls.length > 1"
      class="{{customLabelClass}}"
      text="{{ i + 1 }}."
      type="block"
    ></com-form-label>
    <com-form-group>
      <com-form-input [control]="control" (keydown.enter)="$event.preventDefault()"></com-form-input>
      <button
        *ngIf="array.controls.length > 1"
        (click)="removeFormArrayItem(i)"
        mat-icon-button
      >
        <mat-icon class="mb-1" svgIcon="heroicons_outline:trash"></mat-icon>
      </button>
    </com-form-group>
  </com-form-item>
  <div class="flex">
    <button mat-button class="items-center" (click)="addFormArrayItem()">
      <mat-icon class="icon-size-4" svgIcon="heroicons_outline:plus"></mat-icon>
      <span class="text-sm ml-1">Добавить</span>
    </button>
  </div>
</div>
