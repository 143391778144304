import { IEmployeeOption, IOption, IRole } from '@common/types';
import { FormControl, FormGroup } from '@angular/forms';

export interface IMemberModel {
  id: string;
  controlId: string;
  role: IRole | null;
  employeeOption: IEmployeeOption | null;
  positionOption: IOption | null;
  roleMembersCount?: number;
}

export enum SelectorSearchType {
  employee,
  position
}

export type MemberFormUnion = FormGroup<Record<string, MemberForm>>;

export type MemberForm = FormGroup<{
  employee: FormControl<IEmployeeOption>;
  position: FormControl<IOption>;
}>;

export type MemberControlValue = Partial<{
  employeeId: string;
  positionId: string;
}>;

export interface IMemberFormValue extends IMemberModel {
  employeeOption: IEmployeeOption | null;
  positionOption: IOption | null;
}
