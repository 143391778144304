import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEmployeeFull } from '@common/types';

@Component({
  selector: 'com-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {
  @Output() closeDrawer = new EventEmitter<void>();
  @Input() userInfo: IEmployeeFull

  constructor() {}
}