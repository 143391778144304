import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { JitsuLoggerService, PlatformService, RoleService, UnsubscribeService } from '@common/services';
import {
  ICalendarEvent,
  IChangeEventStatusParams,
  ICommitteeMeetingRoom,
  IEmployee,
  IMember,
  IRole,
  ITransferHistory
} from '@common/types';
import { CallActions, CommitteeEventActions } from '@common/constants';
import { AppRolesEnum, CommitteeEventStatusEnum, ProtocolStatusEnum } from '@common/enums';
import { canJoinCommittee, isEventEnded } from '@common/utils/util';

@Component({
  selector: 'com-event-sidebar',
  templateUrl: './event-sidebar.component.html',
  providers: [UnsubscribeService]
})
export class EventSidebarComponent implements OnInit {
  @Input() event: ICalendarEvent;
  @Input() meetingRooms: ICommitteeMeetingRoom[] = [];
  @Input() transferHistory: ITransferHistory[] = [];
  @Input() isUnplannedEvent = false;
  @Input() members: IMember[] = [];
  @Input() currentMember: IMember;
  @Input() responsibleUploadingMaterial: IEmployee;
  @Input() appRole = AppRolesEnum.USER;

  @Output() delegationButtonClick = new EventEmitter<IMember>();
  @Output() agendaDrawerOpen = new EventEmitter<IMember>();
  @Output() cancelEventDrawerOpen = new EventEmitter<void>();
  @Output() transferEvent = new EventEmitter<void>();
  @Output() accessDrawerOpen = new EventEmitter<IMember>();
  @Output() transferDrawerOpen = new EventEmitter<boolean>(false);
  @Output() unplannedEvent = new EventEmitter<ICalendarEvent>();
  @Output() changeStatus = new EventEmitter<IChangeEventStatusParams>();
  @Output() closeDrawer = new EventEmitter<void>();

  EventStatusEnum = CommitteeEventStatusEnum;
  CallActions = CallActions;
  CommitteeEventActions = CommitteeEventActions;
  AppRolesEnum = AppRolesEnum;
  isMobile = this.platformService.isMobile;
  roles: IRole[] = [];
  isProtocolForming = false;
  canJoinCommittee = false;
  eventEnded = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private roleService: RoleService,
    private platformService: PlatformService,
    public jitsuLoggerService: JitsuLoggerService,
    @Self() private unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    this._setSubscriptions();
    this.isProtocolForming = this.event.protocolStatus === ProtocolStatusEnum.FORMING;
    this.canJoinCommittee = canJoinCommittee(this.event.start);
    this.eventEnded = isEventEnded(this.event);
  }

  private _setSubscriptions(): void {
    this.roleService.roles$.pipe(takeUntil(this.unsubscribeService)).subscribe((roles) => {
      this.roles = roles;
      this.cdr.markForCheck();
    });
  }
}
