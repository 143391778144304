import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Self,
  SimpleChanges
} from '@angular/core';
import {
  DEFAULT_DAYS,
  DEFAULT_MINUTES,
  UPLOAD_BEFORE_TYPE_OPTIONS
} from '@common/constants';
import { UploadBeforeTypeEnum } from '@common/enums';
import { FormControl, FormGroup } from '@angular/forms';
import {
  IUploadConditionsDto,
  IUploadConditionsFormGroup
} from '@common/types';
import { takeUntil } from 'rxjs';
import { UnsubscribeService } from '@common/services';

@Component({
  selector: 'com-upload',
  templateUrl: './upload.component.html',
  providers: [UnsubscribeService]
})
export class UploadComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup<IUploadConditionsFormGroup>;
  @Input() value: IUploadConditionsDto;

  public isFixedTerm = new FormControl(false);
  public uploadBeforeType = new FormControl<UploadBeforeTypeEnum>(
    UploadBeforeTypeEnum.DAYS
  );

  protected readonly UPLOAD_BEFORE_TYPE_OPTIONS = UPLOAD_BEFORE_TYPE_OPTIONS;
  protected readonly UploadBeforeTypeEnum = UploadBeforeTypeEnum;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  ngOnInit(): void {
    this._valueChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const value: IUploadConditionsDto = changes.value?.currentValue;
    if (value) {
      this.isFixedTerm.setValue(!!value.beforeDaysTime, { emitEvent: false });
      this.uploadBeforeType.setValue(
        value.beforeDays
          ? UploadBeforeTypeEnum.DAYS
          : UploadBeforeTypeEnum.MINUTES,
        { emitEvent: false }
      );
      if (value.beforeDaysTime) {
        this.formGroup.controls.beforeDaysTime.enable();
        this.formGroup.controls.beforeMinute.disable();
      }
      if (value.beforeDays) {
        this.formGroup.controls.beforeDays.enable();
        this.formGroup.controls.beforeMinute.disable();
      }
      if (value.beforeMinute) {
        this.formGroup.controls.beforeDays.disable();
        this.formGroup.controls.beforeMinute.enable();
      }
      this._cdr.detectChanges();
    }
  }

  private _valueChanges(): void {
    this.isFixedTerm.valueChanges
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((value) => {
        if (!value) {
          this.formGroup.controls.beforeDaysTime.reset();
          this.formGroup.controls.beforeDaysTime.disable();
          this.formGroup.controls.beforeMinute.disable();
        } else {
          this.uploadBeforeType.setValue(UploadBeforeTypeEnum.DAYS);
          this.formGroup.controls.beforeMinute.disable();
          this.formGroup.controls.beforeDaysTime.enable();
        }
      });
    this.uploadBeforeType.valueChanges
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((value) => {
        if (value === UploadBeforeTypeEnum.DAYS) {
          this.formGroup.controls.beforeMinute.reset();
          this.formGroup.controls.beforeMinute.disable();
          this.formGroup.controls.beforeDays.enable();
          this.formGroup.controls.beforeDays.setValue(DEFAULT_DAYS);
        } else {
          this.formGroup.controls.beforeDays.reset();
          this.formGroup.controls.beforeDays.disable();
          this.formGroup.controls.beforeMinute.enable();
          this.formGroup.controls.beforeMinute.setValue(DEFAULT_MINUTES);
        }
      });
  }
}
