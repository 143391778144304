import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { E_MATERIAL_VIOLATION } from '@common/enums/violations.enum';

@Component({
  selector: 'com-material-violations',
  templateUrl: './material-violations.component.html'
})
export class MaterialViolationsComponent implements OnInit {
  public formGroup = new FormGroup({
    informationErrors: new FormControl(false),
    formatMismatch: new FormControl(false)
  });

  protected readonly E_MATERIAL_VIOLATION = E_MATERIAL_VIOLATION;

  constructor(
    private readonly _dialogRef: MatDialogRef<MaterialViolationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[]
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.formGroup.setValue({
        informationErrors: this.data.includes(E_MATERIAL_VIOLATION.INFORMATION_ERRORS),
        formatMismatch: this.data.includes(E_MATERIAL_VIOLATION.FORMAT_MISMATCH)
      });
    }
  }

  public onSubmit(): void {
    const { value } = this.formGroup;
    this._dialogRef.close([
      ...(value.informationErrors ? [E_MATERIAL_VIOLATION.INFORMATION_ERRORS] : []),
      ...(value.formatMismatch ? [E_MATERIAL_VIOLATION.FORMAT_MISMATCH] : [])
    ]);
  }

  public onClose(): void {
    this._dialogRef.close();
  }
}
