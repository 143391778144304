import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { IMember } from '@common/types/calendar.types';
import { UnsubscribeService } from '@common/services';

@Component({
  selector: 'com-member-list-item',
  templateUrl: './member-list-item.component.html',
  providers: [UnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberListItemComponent {
  @Input() member: IMember;
  @Input() isDelegationAvailable: boolean;
  @Input() isTrustedUserVisible: boolean;
  @Input() trustedUser: string;
  @Output() delegateTo = new EventEmitter<void>();
  @Output() delegateEdit = new EventEmitter<void>();
  @Output() deleteDelegation = new EventEmitter<void>();
}
