import { FormArray } from '@angular/forms';
import moment from 'moment/moment';
import {
  CommitteeEventStatusEnum,
  EventActiveTypeEnum,
  RoleAccessesEnum,
  UnitDeclinationEnum
} from '@common/enums';
import { JOIN_COMMITTEE_BEFORE, UNIT_DECLINATIONS } from '@common/constants';
import {
  IAttachmentDto,
  ICalendarEvent,
  ICommitteeMemberDto, IMemberDto
} from '@common/types';

export const _uniqueArray = (array: any[], field: string) => {
  const output: any[] = [];
  for (let i = 0; i < array.length; i++) {
    if (!output.map((item) => item[field]).includes(array[i][field])) {
      output.push(array[i]);
    }
  }
  return output;
};

export const onlyUniqueFilter = (value, index, array) => {
  return array.indexOf(value) === index;
};

export const timeToLocal = (time: string): string => {
  const hour = +time?.split(':')[0] + moment().utcOffset() / 60;
  const hourNormal = hour >= 24 ? hour - 24 : hour;
  return `${hourNormal < 10 ? `0${hourNormal}` : hourNormal}:${
    time?.split(':')[1]
  }`;
};

export const timeToUtc = (time: string): string => {
  const hour = +time?.split(':')[0] - moment().utcOffset() / 60;
  return `${hour >= 0 ? hour : 24 + hour}:${time?.split(':')[1]}`;
};

export const getTimezoneOffset = (offset: number) => {
  const zFun = (n) => (n < 10 ? '0' : '') + n;
  const sign = offset < 0 ? '-' : '+';
  offset = Math.abs(offset);
  return `${sign}${zFun(offset / 60)}:${zFun(offset % 60)}`;
};

export const _unionArrayBy = (
  array: any[],
  compareField: string,
  joinField: string
) => {
  const output: any[] = [];
  for (let i = 0; i < array.length; i++) {
    const index = output.findIndex(
      (item) => item[compareField] === array[i][compareField]
    );
    if (index < 0) {
      output.push({ ...array[i], [joinField]: [array[i][joinField]] });
    } else {
      output[index][joinField].push(array[i][joinField]);
    }
  }

  return output;
};

export const clearFormArray = (formArray: FormArray) => {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
};

export const getObjectPropertyValue = (obj: Object, field: string): any => {
  let result = { ...obj };
  field.split('.').forEach((key) => {
    if (result?.hasOwnProperty(key)) {
      result = result[key];
    }
  });
  return result;
};

export const sortedUniq = (arr: number[]) => {
  return arr
    .filter((val, idx, array) => array.indexOf(val) === idx)
    .sort((a, b) => a - b);
};

export const compareByDate = (field: string) => {
  return (a, b) => {
    const valA = getObjectPropertyValue(a, field);
    const valB = getObjectPropertyValue(b, field);
    const nameA = new Date(valA);
    const nameB = new Date(valB);

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };
};

export const compareByField = (field: string) => {
  return (a, b): number => {
    const valA = getObjectPropertyValue(a, field);
    const valB = getObjectPropertyValue(b, field);
    const nameA =
      typeof valA === 'string'
        ? valA.toUpperCase()
        : typeof valA === 'boolean'
        ? +valA
        : valA;
    const nameB =
      typeof valB === 'string'
        ? valB.toUpperCase()
        : typeof valB === 'boolean'
        ? +valB
        : valB;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };
};

export const isEmptyObject = (obj): boolean => {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] != '') {
      return false;
    }
  }
  return true;
};

export const randomColor = (): string => {
  const color = Math.floor(0x1000000 * Math.random()).toString(16);
  return `#${('000000' + color).slice(-6)}`;
};

export const asFormArray = (formArray: any) => formArray as FormArray;

export const unitDeclination = (
  value: number,
  unit: UnitDeclinationEnum
): string => {
  let result: string = '';

  const preLastDigit = (value % 100) / 10;

  if (preLastDigit === 1) {
    result = UNIT_DECLINATIONS[unit][5];
  }

  switch (value % 10) {
    case 1:
      result = UNIT_DECLINATIONS[unit][1];
      break;
    case 2:
    case 3:
    case 4:
      result = UNIT_DECLINATIONS[unit][2];
      break;
    default:
      result = UNIT_DECLINATIONS[unit][5];
      break;
  }

  return result;
};

export const canJoinCommittee = (eventTime: string): boolean => {
  const eventStart = moment(eventTime).subtract(
    JOIN_COMMITTEE_BEFORE,
    'minutes'
  );
  const currentDate = moment();
  return eventStart.isBefore(currentDate);
};

export const isEventEnded = (calendarEvent: ICalendarEvent): boolean =>
  calendarEvent.committeeEventStatus === CommitteeEventStatusEnum.FINISHED ||
  moment().isAfter(
    moment(calendarEvent.eventTime || calendarEvent.start).add(
      calendarEvent.duration,
      'minutes'
    )
  );

export const isEventActiveByTime = (
  eventTime: string,
  duration: number,
  type: EventActiveTypeEnum
): boolean => {
  const eventStart = moment(eventTime).subtract(
    JOIN_COMMITTEE_BEFORE,
    'minutes'
  );
  const eventEnded = moment(eventTime).add(duration + 1, 'minutes');
  const currentDate = moment();

  switch (type) {
    case EventActiveTypeEnum.CALL:
      return (
        eventStart.isBefore(currentDate) && eventEnded.isAfter(currentDate)
      );
    case EventActiveTypeEnum.CALENDAR:
      return eventEnded.isAfter(currentDate);
    default:
      return false;
  }
};

export const hasAccess = (
  members: IMemberDto[],
  userId: string,
  access: RoleAccessesEnum
): boolean => {
  return members
    ?.filter((member) => member.employee?.id === userId)
    .map((member) => member.memberAccesses)
    .some((accesses) => accesses?.includes(access));
};

export const deleteExtraSpaces = (value: string): string => {
  return value
    ?.split(' ')
    .filter((e) => e)
    .join(' ');
};

export const addMaterialAccess = (
  material: IAttachmentDto,
  employeeId: string
): IAttachmentDto => ({
  ...material,
  canDownloadMaterial: !material.blackListLoadMaterial?.includes(employeeId),
  canViewMaterial: !material.blackListReadMaterial?.includes(employeeId)
});
