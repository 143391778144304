import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { differenceInMinutes, startOfDay, parse, addMinutes, format } from 'date-fns';
import { intervalsOverlapping } from '@common/utils/time.helpers';
import { FormControl } from '@angular/forms';
import { Minutes } from '@common/types/form';

@Component({
  selector: 'com-form-input-event-time',
  templateUrl: 'form-input-event-time.component.html'
})
export class FormInputEventTimeComponent implements OnChanges {
  @Input() hint: string | null = null;
  @Input() duration: Minutes;
  @Input() lunchStart: Minutes;
  @Input() lunchEnd: Minutes;
  @Input() workDayStart: Minutes = 480;
  @Input() workDayEnd: Minutes = 1140;
  @Input() excludeLunchTime = false;
  @Input() update: unknown;
  @Input() control: FormControl<string>;

  public showHint = false;

  ngOnChanges(changes: SimpleChanges): void {
    if ('duration' in changes || 'excludeLunchTime' in changes) {
      this.checkData();
    }
  }

  public onShowHint(): void {
    this.showHint = true;
  }

  private getEventInterval(date: Date): [start: Minutes, end: Minutes] {
    const timeString = this.control.value.toString();
    const parsedTime = parse(timeString, 'HH:mm', new Date());

    const eventStart = differenceInMinutes(parsedTime, date);
    const eventEnd = eventStart + this.duration;

    return [eventStart, eventEnd];
  }

  public checkData(): void {
    let breaker = 0;
    const maxWhileCount = 100;

    if (this.control.value && this.duration) {
      this.showHint = false;
      const startOfDayTime = startOfDay(new Date());
      let [eventStart, eventEnd] = this.getEventInterval(startOfDayTime);
      if (this.excludeLunchTime) {
        while (
          intervalsOverlapping(
            { start: this.lunchStart, end: this.lunchEnd },
            { start: eventStart, end: eventEnd }
          ) &&
          breaker < maxWhileCount
        ) {
          eventStart += 10;
          eventEnd = eventStart + this.duration;
          breaker++;
        }
      }

      if (eventStart < this.workDayStart) {
        eventStart = this.workDayStart;
      }

      while (eventEnd > this.workDayEnd && breaker < maxWhileCount) {
        eventStart -= 10;
        eventEnd = eventStart + this.duration;
        breaker++;
      }

      if (breaker === maxWhileCount) throw Error('Произошло зацикливание');

      this.roundedTime(startOfDayTime, eventStart);
    }
  }

  private roundedTime(date: Date, eventStart: Minutes): void {
    const adjustedTime = addMinutes(date, eventStart);
    const minutes = adjustedTime.getMinutes();
    const roundedMinutes = Math.floor(minutes / 30) * 30;
    const timeString = format(new Date(adjustedTime.setMinutes(roundedMinutes, 0, 0)), 'HH:mm');

    if (this.control.value === timeString) return;

    this.control.setValue(timeString);
  }
}
