import { ChangeDetectionStrategy, Component, EventEmitter, Output, Self } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EventTypeIds } from '@common/types/calendar-api';
import { UnsubscribeService } from '@common/services';
import { takeUntil, tap } from 'rxjs';

type TimelineFilterForm = FormGroup<{
  [EventTypeIds.committee]: FormControl<boolean>;
  [EventTypeIds.committee2_0]: FormControl<boolean>;
}>;

@Component({
  selector: 'com-timeline-filter',
  templateUrl: 'timeline-filter.component.html',
  styleUrls: ['timeline-filter.component.scss'],
  providers: [UnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineFilterComponent {
  @Output() filterChange = new EventEmitter<EventTypeIds[]>();

  public form: TimelineFilterForm;
  public EventTypeIds = EventTypeIds;

  constructor(private _fb: FormBuilder, @Self() private _unsubscribeService: UnsubscribeService) {
    this._createForm();
    this._formChangeSub();
  }

  private _formChangeSub(): void {
    this.form.valueChanges
      .pipe(
        tap((formValue) => {
          const result: EventTypeIds[] = [];
          Object.keys(formValue).map((eventTypeId) => {
            if (formValue[eventTypeId]) result.push(eventTypeId as EventTypeIds);
          });
          this.filterChange.emit(result);
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _createForm(): void {
    this.form = this._fb.group({
      [EventTypeIds.committee]: true as boolean,
      [EventTypeIds.committee2_0]: true as boolean
    });
  }
}
