<input
  #input
  hidden="hidden"
  type="file"
  [multiple]="multiple"
  [formControl]="inputControl"
  [accept]="acceptFile"
/>
<button
  *ngIf="(fileName$ | async) === null && (isDocumentLoading$ | async) === false"
  class="w-fit h-8 min-h-8"
  mat-stroked-button
  color="primary"
  type="button"
  (click)="input.click()"
  [disabled]="disabled"
>
  Загрузить
</button>

<button
  *ngIf="(isDocumentLoading$ | async) === true"
  class="w-fit h-8 min-h-8"
  mat-stroked-button
  color="primary"
  type="button"
  [disabled]="true"
>
  Загружаем файл..
  <mat-icon>
    <mat-spinner color="accent" diameter="16"> </mat-spinner>
  </mat-icon>
</button>

<div *ngIf="fileName$ | async as filename" class="flex gap-2">
  <span
    class="max-w-[540px] truncate min-h-[32px] pt-0.5"
    [matTooltip]="filename"
    matTooltipClass="com-tooltip-full-text"
    [ngClass]="{
      'text-blue-500': !control.errors,
      'text-red-500': control.errors
    }"
  >
    {{ fileName$ | async }}
  </span>

  <button *ngIf="control.value" (click)="onFileDownload()" matTooltip="Загрузить">
    <mat-icon class="icon-size-4" svgIcon="heroicons_solid:download"></mat-icon>
  </button>
  <button *ngIf="control.value" (click)="onDeleteFile()" matTooltip="Удалить">
    <mat-icon class="icon-size-4 text-red-500" svgIcon="heroicons_outline:trash"></mat-icon>
  </button>
</div>
