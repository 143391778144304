export interface IPage {
  page: number;
  pageSize?: number;
  totalRecords?: number;
}

export interface IResponseWrapper<T = any> extends IPage {
  data: T[];
}

export interface IData<T = any> {
  data: T[];
  pagination: PageModel;
}

export class PageModel {
  pageIndex = 0;
  pageSize = 0;
  length: number;

  constructor(option?: any) {
    this.pageIndex = option.page;
    this.pageSize = option.pageSize;
    this.length = option.totalRecords;
  }
}
