<div class="relative flex flex-col w-full h-full max-h-[90vh]">
  <div class="absolute top-0 right-0">
    <button mat-dialog-close="">
      <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </div>

  <div class="text-xl font-semibold tracking-tight text-center px-10">
    Обращения по работе в системе или сбоям направляются через заявку в ИСУИ
  </div>

  <div class="flex flex-col gap-2 grow overflow-y-scroll com-perfect-scrollbar mt-4">
    <ul class="p-5 md:p-0 max-w-700 mx-auto pb-0 flex flex-col gap-4">
      <li class="px-4 font-medium">
        1. Для регистрации заявки необходимо авторизоваться в ИСУИ по ссылке
        <span class="underline">https://helpdesk.efko.ru </span> под корпоративной учетной записью и нажать
        «Вход».
      </li>
      <div class="min-h-[290px]">
        <img class="px-10" alt="step-1" src="../../../assets/images/manual/1-step.png" />
      </div>

      <li class="px-4 font-medium">2. Нажать на кнопку «Запросить помощь» и выбрать «ПО и оборудование».</li>
      <div class="min-h-[244px]">
        <img class="px-10" alt="step-2" src="../../../assets/images/manual/2-step.png" />
      </div>

      <li class="px-4 font-medium">
       3. Выбрать вид заявки «Сбой в работе (не работает ПК, Интернет, почта, ПО, не работает ИС)» и
        информационную систему «ПТК ДКО».
      </li>
      <div class="min-h-[250px]">
        <img class="px-10" alt="step-3" src="../../../assets/images/manual/3-step.png" />
      </div>

      <li class="px-4 font-medium">4. Описать возникшую ошибку и зарегистрировать заявку.</li>
    </ul>
  </div>
</div>
