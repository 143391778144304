import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[scrollToSelected]'
})
export class ScrollToSelectedDirective implements OnChanges {
  @Input('selectedIndex') selectedIndex: number;
  @Input('id') id: string;
  ngOnChanges(changes: SimpleChanges) {
    if ('selectedIndex' in changes && !changes.selectedIndex.firstChange) {
      const selectedEvent = document.getElementById(this.id);
      if (selectedEvent) {
        selectedEvent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
