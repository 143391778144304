import { Pipe, PipeTransform } from '@angular/core';
import { IAttachmentDto, INoSpeakerMaterial, ISpeaker } from '@common/types';
import { SYSTEM_USER_ID } from '@common/constants';

@Pipe({ name: 'getUserMaterialWithoutSpeaker' })
export class GetUserMaterialWithoutSpeakerPipe implements PipeTransform {
  transform(speakers: ISpeaker[], materials: IAttachmentDto[], required: boolean): INoSpeakerMaterial[] {
    const result: INoSpeakerMaterial[] = [];
    const map: Record<string, IAttachmentDto[]> = {};

    materials
      .filter((m) => m.required === required)
      .map((m) => {
        if (!speakers.some((s) => m.createdBy.id === s.employee.id) && m.createdBy.id !== SYSTEM_USER_ID) {
          map[m.createdBy.id] = map[m.createdBy.id] ? [...map[m.createdBy.id], m] : [m];
        }
      });

    for (const userId in map) {
      result.push({
        user: map[userId][0].createdBy,
        materials: map[userId]
      });
    }

    return result;
  }
}
