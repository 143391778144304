import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormAbstractionComponent {
  @Output() isValid = new EventEmitter<() => boolean>();
  formGroup: UntypedFormGroup;
  childFormsValidMethods: Array<() => boolean> = [];

  public emitFormMethods(): void {
    this.isValid.emit(this.isFormValid.bind(this));
  }

  public setValidMethodCheck(...functions: Array<() => boolean>): void {
    this.childFormsValidMethods = functions;
  }

  public isFormValid(): boolean {
    const isChildFormsValid = this.childFormsValidMethods.map((func) => func());
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
    console.log(this.formGroup)
    return this.formGroup.valid && !isChildFormsValid.includes(false);
  }
}
