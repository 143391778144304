export enum MemberTypeTitlesEnum {
  MANDATORY_SINGULAR = 'Постоянный участник',
  INVITED_SINGULAR = 'Приглашенный участник'
}

export enum MemberPresenceStatusEnum {
  PARTICIPATE = 0, // Присутствовал
  MISSED = 1, // Отсутствовал
  LATE = 2, // Опоздал
  NOT_REQUIRED = 3, // Не обязателен
  EARLIER_LOGOUT = 4, // Ранее отключение
  DELEGATION = 5, // Есть доверенность
  CONNECTION_TIME_HAS_NOT_ARRIVED = 6 // Подключение не наступило
}
export enum MemberMaterialStatusEnum {
  NOT_UPLOAD_TIME = 0, // Время загрузки не наступило
  NOT_UPLOADED = 1, // Не загружен(ы)
  UPLOADED = 2, // Загружен(ы)
  NOT_REQUIRED = 3, // Загрузка не обязательна
  INNCORRECT_FILE = 4, // Имеются замечания
  LATE_UPLOADED = 5 // Загружен(ы) с опозданием
}

export enum MemberMaterialViewStatusEnum {
  VIEWED,
  NOT_VIEWED,
  NOT_NECESSARY_VIEWED,
  NO_MATERIALS
}

export enum MemberViewMaterialLabelEnum {
  NOT_NECESSARY_VIEWED = 'Ознакомление не обязательно',
  NOT_VIEWED = 'Не ознакомлен',
  VIEWED = 'Ознакомлен',
  NO_MATERIALS = 'Материалы отсутствуют',
}

export enum MemberAllowanceStatusEnum {
  AllowanceIsNotRequired,
  HasAllowance,
  NotEnoughPointForAllowance,
  NotAllowance
}

export enum MemberAllowanceLabelEnum {
  AllowanceIsNotRequired = 'Допуск не требуется',
  HasAllowance = 'Есть допуск',
  NotEnoughPointForAllowance = 'Недостаточно баллов для допуска',
  NotAllowance = 'Нет допуска'
}

export enum MemberPresenceLabelEnum {
  PARTICIPATE = 'Присутствует',
  MISSED = 'Отсутствует',
  LATE = 'Опоздал',
  EARLIER_LOGOUT = 'Раньше отключился',
  NOT_REQUIRED = 'Не обязателен',
  DELEGATION = 'Доверенность',
  CONNECTION_TIME_HAS_NOT_ARRIVED = 'Время подключения не наступило'
}
export enum MemberAllowanceLabelEnum {
  ADMITTED = 'Допущен',
  NOT_ADMITTED = 'Не допущен'
}

export enum MemberMaterialLabelEnum {
  UPLOADED = 'Загружен(ы)',
  NOT_UPLOADED = 'Не загружен(ы)',
  NOT_UPLOAD_TIME = 'Время загрузки не наступило',
  NOT_REQUIRED = 'Загрузка не обязательна',
  INNCORRECT_FILE = 'Имеются замечания',
  LATE_UPLOADED = 'Загружен(ы) с опозданием'
}

export enum MemberNeedinessLabelEnum {
  REQUIRED = 'Требуется',
  NOT_REQUIRED = 'Не требуется'
}

export enum MemberRoleEnum {
  SPEAKER = 'Докладчик',
  SPECIALIST = 'Специалист'
}

export enum MemberDisciplinaryLabelEnum {
  HasViolation = 'Есть нарушение',
  NotViolaion = 'Нарушения отсутствуют'
}
