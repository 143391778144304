import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '@common/pipes/shared-pipe.module';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { EmployeeComponent } from './employee.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    SharedPipesModule
  ],
  exports: [EmployeeComponent],
  declarations: [
    UserProfileComponent,
    EmployeeComponent
  ]
})
export class EmployeeModule {}
