<div class="flex">
  <mat-form-field class="w-full">
    <mat-icon *ngIf="preIcon" matPrefix [svgIcon]="preIcon" class="icon-size-4 text-black"></mat-icon>
    <textarea
      *ngIf="isMultiline"
      #textarea
      matInput
      cdkTextareaAutosize
      [type]="type"
      [placeholder]="placeholder"
      [formControl]="control"
      [readonly]="readonly"
      (change)="$event.stopPropagation()"
    ></textarea>
    <input
      *ngIf="!isMultiline"
      matInput
      [type]="type"
      [placeholder]="placeholder"
      [formControl]="control"
      [readonly]="readonly"
      [min]="min"
      [ngClass]="{ withoutTimePicker: type === 'time' && step }"
      [max]="max"
      (focus)="inputFocus.emit()"
      (focusout)="inputFocusOut.emit()"
      (change)="$event.stopPropagation()"
    />
    <mat-icon *ngIf="postIcon" matPostfix [svgIcon]="postIcon" class="icon-size-4 text-black"></mat-icon>
    <div *ngIf="clearable" class="min-w-4 invisible"></div>
    <mat-hint [@expandCollapse]="alwaysShowHint || showHint ? 'expanded' : 'collapsed'">{{ hint }}</mat-hint>
    <mat-icon
      *ngIf="clearable && control?.value"
      (click)="clear($event)"
      matSuffix
      matTooltip="Удалить"
      class="absolute -left-4 icon-size-5 cursor-pointer ml-1 opacity-60"
      svgIcon="heroicons_outline:x"
    ></mat-icon>
    <ng-content select="[suffix]"></ng-content>
  </mat-form-field>
  <ng-content select="[actions]"></ng-content>
</div>
