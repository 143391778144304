import { Pipe, PipeTransform } from '@angular/core';
import { IOption } from '../types';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(items: IOption[] | string[], divider: '/' | ',' = ','): string {
    return items.map((item) => item.name || item).join(divider + ' ');
  }
}
