import {
  MemberMaterialLabelEnum,
  MemberMaterialStatusEnum,
  MemberPresenceLabelEnum,
  MemberPresenceStatusEnum
} from '@common/enums/member-types';
import { ITableColumn, TableFieldTypeEnum } from '@common/shared/components/table';

export const PRIMARY_PROTOCOL_VIEW_MEMBERS_TABLE_COLUMNS: ITableColumn[] = [
  {
    title: 'Участник',
    field: 'employee',
    fieldType: TableFieldTypeEnum.USER,
    columnClass: 'flex-initial w-6/12',
    showImage: true,
    showStatus: true,
    showRole: true
  },
  {
    title: 'Присутствие',
    field: 'presence',
    fieldType: TableFieldTypeEnum.EDIT_SELECT,
    columnClass: 'flex-initial justify-center w-24',
    list: [
      { value: MemberPresenceStatusEnum.PARTICIPATE, title: MemberPresenceLabelEnum.PARTICIPATE, icon: 'heroicons_outline:check-circle', class: 'color-limeGreen' },
      { value: MemberPresenceStatusEnum.MISSED, title: MemberPresenceLabelEnum.MISSED, icon: 'heroicons_outline:exclamation', class: 'text-red-500' },
      { value: MemberPresenceStatusEnum.LATE, title: MemberPresenceLabelEnum.LATE, icon: 'heroicons_outline:exclamation', class: 'text-orange-500' },
      { value: MemberPresenceStatusEnum.EARLIER_LOGOUT, title: MemberPresenceLabelEnum.EARLIER_LOGOUT, icon: 'heroicons_outline:exclamation', class: 'text-yellow-500' },
      { value: MemberPresenceStatusEnum.NOT_REQUIRED, title: MemberPresenceLabelEnum.NOT_REQUIRED, icon: 'heroicons_outline:check-circle' }
    ]
  },
  {
    title: 'Материалы',
    field: 'materialStatus',
    fieldType: TableFieldTypeEnum.EDIT_SELECT,
    columnClass: 'flex-initial justify-center w-24',
    list: [
      { value: MemberMaterialStatusEnum.UPLOADED, title: MemberMaterialLabelEnum.UPLOADED, icon: 'heroicons_outline:check-circle', class: 'color-limeGreen' },
      { value: MemberMaterialStatusEnum.NOT_UPLOADED, title: MemberMaterialLabelEnum.NOT_UPLOADED, icon: 'heroicons_outline:exclamation', class: 'text-red-500' },
      { value: MemberMaterialStatusEnum.NOT_UPLOAD_TIME, title: MemberMaterialLabelEnum.NOT_UPLOAD_TIME, icon: 'heroicons_outline:check-circle' },
      { value: MemberMaterialStatusEnum.NOT_REQUIRED, title: MemberMaterialLabelEnum.NOT_REQUIRED, icon: 'heroicons_outline:check-circle' },
      { value: MemberMaterialStatusEnum.LATE_UPLOADED, title: MemberMaterialLabelEnum.LATE_UPLOADED, icon: 'heroicons_outline:exclamation', class: 'text-yellow-500' }
    ]
  }
];

export const SECONDARY_PROTOCOL_VIEW_MEMBERS_TABLE_COLUMNS =
  PRIMARY_PROTOCOL_VIEW_MEMBERS_TABLE_COLUMNS.map((col) => ({
    ...col,
    title: null,
    showRole: false
  }));
