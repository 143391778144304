import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { catchError, map, Observable } from 'rxjs';
import { UploadedFileDto } from '@common/types';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private http: HttpClient) {}

  createFile(file: File): Observable<UploadedFileDto> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<UploadedFileDto>('api/Files', formData);
  }

  downloadFile(fileId: string, filename = 'file'): void {
    const params = new HttpParams().set('fileId', fileId);
    this.http
      .get('api/Files', { params, observe: 'response', responseType: 'blob' })
      .pipe(
        catchError((err) => {
          throw new Error(`Can't download. Details: ${err}`);
        })
      )
      .subscribe((res) => {
        this.saveFile(res.body, filename);
      });
  }

  getFile(fileId: string): Observable<Blob> {
    return this.http
      .get('api/Files', { params: { fileId }, observe: 'response', responseType: 'blob' })
      .pipe(map((httpResponse) => httpResponse.body));
  }

  saveFile(data: any, filename: string, type?: string): void {
    const blob = new Blob([data], { type });

    saveAs(blob, `${type ? `${filename}.${type.split('/')[1]}` : filename}`);
  }
}
