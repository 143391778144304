import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from '@common/modules/auth/auth.module';
import { IconsModule } from './icons/icons.module';
import { TranslocoCoreModule } from './transloco/transloco.module';

@NgModule({
  imports: [AuthModule, IconsModule, TranslocoCoreModule]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Import this module in the AppModule only.'
      );
    }
  }
}
