export enum CommitteeEventStatusEnum {
  PLANNED = 0, // запланирован
  IN_PROGRESS = 1, // в прогрессе
  PAUSE = 2, // поставлен на паузу
  WAIT_RESOLUTION = 3, // ждет решения
  SUSPENDED = 4, // приостановлен
  FINISHED = 5, // закончен
  CANCEL = 6 // отменен
}

export enum AttachmentTypeEnum {
  FILE,
  LINK
}

export enum E_EVENT_IN_PROGRESS_STATE {
  NOT_STARTED,
  STARTED,
  AGENDA
}
