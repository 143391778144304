<div class="flex flex-col gap-1">
  <div
    *ngFor="let name of DaysOfWeek"
    class="text-diagram-gray opacity-70 text-sm"
    [style.min-width.px]="cellSize"
    [style.max-width.px]="cellSize"
    [style.min-height.px]="cellSize"
    [style.max-height.px]="cellSize"
  >
    {{ name }}
  </div>
</div>
