import { IOption, UploadedFileDto } from '@common/types/common';
import { FileFormat } from '@common/enums/directory.enum';

export interface ICommitteeDocumentDialogData {
  documentId: string;
  type: IOption;
  fileFormat: FileFormat;
}

export interface ICommitteeDocumentDialogResult {
  type: IOption;
  fileData: UploadedFileDto;
  documentId: string;
}
