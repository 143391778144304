import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EkpCheckboxComponent } from '@common/shared/components/ekp-checkbox/ekp-checkbox.component';

@NgModule({
  declarations: [EkpCheckboxComponent],
  exports: [EkpCheckboxComponent],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EkpCheckboxModule {}
