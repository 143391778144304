import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { DAYS_OF_WEEK } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';

@Component({
  selector: 'com-planning-diagram-axios-y',
  templateUrl: 'planning-diagram-axios-y.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningDiagramAxiosYComponent {
  @Input() cellSize: number;
  protected DaysOfWeek = DAYS_OF_WEEK;
}
