import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UnsubscribeService } from '@common/services';
import { IEmployeeOption, IOption } from '@common/types';
import { debounceTime, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'com-auto-suggestion',
  templateUrl: 'auto-suggestion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class AutoSuggestionComponent implements OnInit {
  @Input() clearable = false;
  @Input() showTooltip = false;
  @Input() options: IOption[] | IEmployeeOption[] = [];
  @Input() placeholder = 'Выберите значение';
  @Input() controlName: string;
  @Input() form: UntypedFormGroup;
  @Input() isLoading: boolean;
  @Input() update: boolean;

  @Output() searchValueChange = new EventEmitter<string>();
  @Output() isClose = new EventEmitter<void>();
  @Output() inputClick = new EventEmitter<string>();
  @Output() optionSelect = new EventEmitter<IOption>();
  @Output() optionRemove = new EventEmitter<string>();

  private lastValueId: string | null = null;

  constructor(@Self() private unsubscribeService: UnsubscribeService) {}

  ngOnInit(): void {
    this.initValueChangeSub();
  }

  public onInputClick(): void {
    const control = this.form.get(this.controlName);
    const { value } = control;
    if (typeof value !== 'string' && value) {
      this.lastValueId = value?.id;
      this.inputClick.emit('');
    } else {
      this.inputClick.emit(value);
    }
  }

  public onClear(event: MouseEvent): void {
    event.stopPropagation();
    this.optionRemove.emit((this.form.get(this.controlName).value as IOption)?.id as string);
    this.form.get(this.controlName).setValue(null);
  }

  public onClose(): void {
    const control = this.form.get(this.controlName);
    if (typeof control.value === 'string') {
      control.setValue(null);
      this.optionRemove.emit(this.lastValueId);
    }
    this.isClose.emit();
  }

  private initValueChangeSub(): void {
    this.form
      .get(this.controlName)
      .valueChanges.pipe(
        debounceTime(50),
        tap((value) => {
          const control = this.form.get(this.controlName);
          typeof control.value === 'string' && this.optionRemove.emit(this.lastValueId);
          this.searchValueChange.emit(value);
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  public displayFn(option: IOption | IEmployeeOption): string {
    return option?.name || '';
  }

  private setPosition(option: IEmployeeOption): void {
    if (option.position) {
      this.form.controls.position.setValue({
        id: option.position.id,
        name: option.position.name
      });
    }
  }

  public onSelect(option: IOption | IEmployeeOption): void {
    if (this.controlName === 'employee' && this.form.controls.position) {
      this.setPosition(option as IEmployeeOption);
    }
    this.form.get(this.controlName).setValue(option, { emitEvent: false });
    const { id } = option;
    this.optionSelect.emit(option);
    this.lastValueId = id as string;
  }
}
