import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { ICalendarEvent, IEmployee, IMember } from '@common/types';
import { CommitteeEventStatusEnum, RoleAccessesEnum } from '@common/enums';
import { hasAccess } from '@common/utils/util';
import { EmployeeService, UnsubscribeService } from '@common/services';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'com-right-panel-members-block',
  templateUrl: './right-panel-members-block.component.html',
  providers: [UnsubscribeService]
})
export class RightPanelMembersBlockComponent implements OnChanges {
  @Input() event: ICalendarEvent;
  @Input() isTimeToDelegate = false;
  @Input() isTimeToUpload = false;
  @Input() members: IMember[] = [];
  @Input() availableMembers: IMember[] = [];
  @Input() currentMember: IMember;
  @Input() showRole = true;
  @Input() responsibleUploadingMaterial: IEmployee;

  @Output() delegationButtonClick = new EventEmitter<IMember>();
  @Output() agendaDrawerOpen = new EventEmitter<IMember>();
  @Output() accessDrawerOpen = new EventEmitter<IMember>();

  public isMember = false;
  public canDelegateAllRole = false;
  public canUploadMaterial = false;
  public canAccess = false;
  public isSuspended = false;

  protected readonly CommitteeEventStatusEnum = CommitteeEventStatusEnum;

  constructor(
    private readonly _employeeService: EmployeeService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const currentEvent: ICalendarEvent = changes?.event?.currentValue;
    if (currentEvent) {
      this.isSuspended =
        currentEvent.committeeEventStatus ===
        CommitteeEventStatusEnum.SUSPENDED;
    }
    const currentMember: IMember = changes.currentMember?.currentValue;
    if (currentMember) {
      this._employeeService.employeeCurrentUserInfo$
        .pipe(takeUntil(this._unsubscribeService))
        .subscribe((employeeCurrentUserInfo) => {
          this.isMember =
            currentMember.committeeMember.employee.id ===
            employeeCurrentUserInfo.id;
          this.canDelegateAllRole = hasAccess(
            [
              {
                ...currentMember.committeeMember,
                memberAccesses: currentMember.memberAccesses
              }
            ],
            employeeCurrentUserInfo.id,
            RoleAccessesEnum.CAN_DELEGATE_ALL_ROLE
          );
          this.canUploadMaterial = hasAccess(
            [
              {
                ...currentMember.committeeMember,
                memberAccesses: currentMember.memberAccesses
              }
            ],
            employeeCurrentUserInfo.id,
            RoleAccessesEnum.CAN_UPLOAD_MATERIAL
          );
          this.canAccess = hasAccess(
            [
              {
                ...currentMember.committeeMember,
                memberAccesses: currentMember.memberAccesses
              }
            ],
            employeeCurrentUserInfo.id,
            RoleAccessesEnum.IT_IS_MANDATORY_TO_OBTAIN_ADMISSION_IF_NECESSARY
          );
        });
    }
  }
}
