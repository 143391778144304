import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaterialViolationService {
  constructor(private readonly _http: HttpClient) {}

  public postMaterialViolation(
    committeeEventId: string,
    employeeId: string,
    materialId: string,
    violations: string[]
  ): Observable<void> {
    return this._http.post<void>('api/MaterialViolation', {
      committeeEventId,
      employeeId,
      materialId,
      violations
    });
  }

  public addDisciplineViolation(
    committeeEventId: string,
    employeeId: string,
    violations: string[]
  ): Observable<void> {
    return this._http.post<void>(
      `api/DisciplinaryViolation/${committeeEventId}/set`,
      {
        employeeId,
        violations
      }
    );
  }

  public retrieveMaterialViolations(
    committeeEventId: string,
    employeeId: string,
    materialId: string
  ): Observable<string[]> {
    return this._http.get<string[]>(
      `api/MaterialViolation/GetWith/${committeeEventId}/${employeeId}/${materialId}`
    );
  }
}
