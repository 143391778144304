import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { compareByField } from '@common/utils/util';
import { IOption } from '@common/types';
import 'moment-timezone';
import moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  public readonly zones = new BehaviorSubject<IOption[]>(null);

  constructor() {
    this.loadTimezones();
  }

  private loadTimezones(): void {
    const zones = [];
    moment.tz.countries().forEach((code) => {
      moment.tz.zonesForCountry(code, true).forEach((val) => {
        if (!zones.map((el) => el.name).includes(val.name)) {
          zones.push({ ...val, countryCode: code });
        }
      });
    });
    zones.sort(compareByField('offset')).reverse();

    this.zones.next(
      zones.map((val) => ({
        id: val.name,
        name: getTimezoneOffset(val.offset, val.name)
      }))
    );
  }
}

const getTimezoneOffset = (offset: number, name: any): string => {
  const z = (n: number): string => (n < 10 ? '0' : '') + n;
  const sign = offset < 0 ? '+' : '-';
  offset = Math.abs(offset);
  return `(GMT${sign}${Math.floor(offset / 60)}:${z(offset % 60)}) ${name}`;
};
