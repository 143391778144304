export enum TableActionsEnum {
  ADD = 'add',
  TOGGLE_DELEGATION = 'toggle-delegation',
  TOGGLE_ACCESS = 'toggle-access',
  UPDATE = 'update',
  READ = 'read',
  DELETE = 'delete',
  EDIT = 'edit',
  DOWNLOAD = 'download',
  LINK_BY_ID = 'link-by-id',
  LINK_DOCUMENTS = 'link-documents',
  LINK_TO_FIELD_ID = 'link-to-field-id',
  OPEN_MATERIALS_SIDEBAR = 'open-materials-sidebar',
  OPEN_ACCESS_SIDEBAR = 'open-access-sidebar',
  OPEN_DISCIPLINARY_POPUP = 'open-disciplinary-popup',
  TOGGLE_BLOCK = 'block-unblock',
  TOGGLE_COMPLATE = 'resume-complete',
  TOGGLE_FILE = 'upload-refresh',
  TOGGLE_CRUD = 'create-delete',
  PAGE = 'page',
  UPDATE_VALUE = 'update-value',
  NEXT_PREV_COLUMNS = 'next-prev-columns'
}

export enum TableFieldTypeEnum {
  ACTION = 'action',
  ANCHOR = 'anchor',
  DATE = 'date',
  FILE = 'file',
  ICON = 'icon',
  ICON_WITH_TEXT = 'icon-with-text',
  USER = 'user',
  STRING = 'string',
  WITH_ICON = 'withIcon',
  WITH_BUTTON = 'withButton',
  BADGE = 'badge',
  BADGES = 'badges',
  SELECT = 'select',
  EDIT_SELECT = 'edit-select',
  DATEPICKER = 'datepicker',
  ARRAY = 'array',
  MENU = 'menu',
  BOOLEAN = 'boolean',
  LINK = 'link'
}

export enum TableActionStatusEnum {
  ALT,
  DEFAULT
}

export enum TableActionButtonTypeEnum {
  ICON = 'icon',
  LABEL = 'label',
  TOGGLE_ICON = 'toggle-icon'
}
