import { Pipe, PipeTransform } from '@angular/core';
import { IMember } from '@common/types';
import { MemberPresenceStatusEnum, RoleTypesEnum } from '@common/enums';

@Pipe({
  name: 'membersByRole',
  pure: false
})
export class MembersByRolePipe implements PipeTransform {
  transform(members: IMember[] = []): IMember[] {
    return members.filter((item) => {
      return (
        item.committeeMember.role.type !== RoleTypesEnum.DIVISIONAL ||
        item.presence === MemberPresenceStatusEnum.PARTICIPATE
      );
    });
  }
}
