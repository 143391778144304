<div
  class="relative pl-4 flex justify-center overflow-hidden z-10 max-h-[90vh] min-h-[90vh] min-w-[1146px] max-w-[1146px]"
>
  <div class="flex flex-col">
    <div class="absolute top-4 right-6">
      <button mat-dialog-close="">
        <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
      </button>
    </div>
    <div class="flex flex-col gap-4 w-full font-inter">
      <div class="text-3xl font-semibold tracking-tight text-center">{{ props.title }}</div>
      <div
        class="flex flex-col justify-between gap-2 overflow-y-scroll overflow-x-hidden com-perfect-scrollbar max-h-[calc(90vh-52px)] min-h-[calc(90vh-52px)]"
      >
        <div class="flex flex-col relative">
          <com-planning-diagram
            class="py-6 mr-1 pl-2 pr-6"
            [weekDayMap]="diagramService.weekDayMap$ | async"
            [cellSize]="cellSize"
            [legend]="Legends"
            [disabled]="dayLoading$ | async"
            [ngClass]="{
              'opacity-80': dayLoading$ | async
            }"
            (cellClick)="onCellClick($event)"
          ></com-planning-diagram>

          <com-ekp-checkbox
            class="absolute right-[36px] top-[282px]"
            (valueChanged)="onSettingsChange($event)"
            label="Учёт обеденного времени, с 12 до 13"
          ></com-ekp-checkbox>

          <ng-container *ngIf="loading$ | async; else content">
            <div class="w-full flex justify-center min-h-10 gap-2">
              <mat-spinner [diameter]="24"></mat-spinner>
              <span class="text-planning-gray">Загрузка..</span>
            </div>
            <footer></footer>
          </ng-container>

          <ng-template #content>
            <ng-container *ngIf="freeDaysNotFound || error; else freeSlots">
              <div class="text-[16px] text-[#EE5B5B] w-full flex justify-center">
                <ng-container *ngIf="error; else noFound">
                  <div class="flex gap-2">
                    <mat-icon svgIcon="heroicons_solid:exclamation" class="text-[#EE5B5B]"></mat-icon>
                    Ошибка при запросе на сервер
                  </div>
                </ng-container>
                <ng-template #noFound>
                  Мы не смогли найти свободные дни.
                  {{
                    props.type === EventPlanningType.transfer
                      ? 'Перенос события невозможен'
                      : 'Создание события невозможно'
                  }}.
                </ng-template>
              </div>
              <footer></footer>
            </ng-container>

            <ng-template #freeSlots>
              <div class="flex flex-col gap-6">
                <com-busy-timeline
                  *ngIf="timeline.length"
                  [timelineEntities]="timeline"
                  [itemListWidth]="480"
                  [isLoading]="dayLoading$ | async"
                >
                  <span class="ml-6 text-2xl font-semibold"> Выбранный день: {{ timelineDate }}</span>
                </com-busy-timeline>

                <div
                  class="flex-col flex gap-2"
                  [ngClass]="{ 'invisible max-h-px': (showFreeSlots$ | async) === false }"
                >
                  <div class="text-2xl font-semibold">Свободные даты и время</div>

                  <com-free-slots
                    [times]="freeTime"
                    [days]="freeDays"
                    [selectedDate]="selectedDay"
                    [showReason]="props.type === EventPlanningType.transfer"
                    [timeLoading]="dayLoading$ | async"
                    (dayChange)="dayChange$.next($event)"
                    (dateChange)="transferDate = $event"
                    (reasonChange)="reason = $event"
                  >
                  </com-free-slots>
                </div>
              </div>
            </ng-template>
          </ng-template>
        </div>

        <footer *ngIf="!freeDaysNotFound && !error" class="flex gap-2 w-full justify-end mt-4 pr-2 pb-2">
          <com-button (buttonClick)="dialogRef.close()" variant="outline"
            ><span class="text-[14px]">Отмена</span></com-button
          >
          <com-button (buttonClick)="onSave()" [disabled]="!transferDate || (loading$ | async)"
            ><span class="text-[14px]">Сохранить</span></com-button
          >
        </footer>
      </div>
    </div>
  </div>
</div>
