import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedPipesModule } from '@common/pipes/shared-pipe.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { EmployeeModule } from '../employee/employee.module';
import { ComBadgeModule } from '../badge/badge.module';
import { ComTableComponent } from './table.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedPipesModule,
    ComBadgeModule,
    EmployeeModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatMenuModule
  ],
  exports: [ComTableComponent],
  declarations: [ComTableComponent]
})
export class ComTableModule {}
