import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMemberSlimResponse } from '@common/types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponsibleType } from '@common/modules/search-members/search-members.const';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  constructor(private readonly _http: HttpClient) {}

  public get(
    employeeId: string | null,
    roleIds: string[],
    typeResponsible: ResponsibleType | null,
    pageSize: number,
    page: number
  ): Observable<IMemberSlimResponse> {
    let params = new HttpParams().set('page', page).set('pageSize', pageSize);

    if (employeeId) {
      params = params.append('employeeId', employeeId);
    }

    if (roleIds.length > 0) {
      for (const roleId of roleIds) params = params.append('roleIds', roleId);
    }

    if (typeResponsible != null) {
      params = params.append('typeResponsible', typeResponsible);
    }

    return this._http.get<IMemberSlimResponse>('api/committeeMember', { params });
  }
}
