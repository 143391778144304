import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { StatusColor } from '@common/enums';

@Directive({
  selector: '[statusVoteColor]'
})
export class StatusVoteColorDirective implements OnChanges {
  @Input() value: number;

  constructor(private readonly el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes) {
      if (this.value <= 10) {
        this.el.nativeElement.style.color = StatusColor.warning;
      } else {
        this.el.nativeElement.style.color = StatusColor.success;
      }
    }
  }
}
