<div *ngIf="materials.length !== 0; else noSpeakerMaterials" class="flex flex-col gap-2">
  <div *ngFor="let material of materials" class="flex flex-col gap-1">
    <section class="flex items-center gap-2 max-h-5">
      <mat-icon
        *ngIf="material.violations?.length; else documentIcon"
        class="text-yellow-500 icon-size-4"
        svgIcon="heroicons_outline:exclamation"
        [matTooltip]="material?.violations | join: ','"
      ></mat-icon>
      <ng-template #documentIcon>
        <mat-icon class="icon-size-4" svgIcon="heroicons_outline:document-text"></mat-icon>
      </ng-template>
      <span class="text-sm">Материал:</span>
      <div *ngIf="material; else noMaterial" class="flex flex-col gap-1 w-full">
        <div class="flex justify-between items-center gap-2">
          <a
            *ngIf="material.link"
            class="text-blue-500 truncate max-w-[280px] com-tooltip text-sm"
            [href]="material.link"
            [matTooltip]="material.link"
            >{{ material.link }}</a
          >
          <span
            *ngIf="material.fileName"
            (click)="material.canDownloadMaterial && download.emit(material)"
            class="text-sm truncate max-w-[280px] com-tooltip"
            [matTooltip]="material.fileName"
            matTooltipClass="com-tooltip"
            [ngClass]="{
              'text-blue-500 underline cursor-pointer': material.canDownloadMaterial
            }"
          >
            {{ material.fileName }}
          </span>
          <div class="flex gap-x-0.5">
            <button
              *ngIf="canSetViolation"
              class="w-8 h-8 min-h-8"
              mat-icon-button
              (click)="setViolation.emit(material)"
            >
              <mat-icon class="icon-size-4" matTooltip="Ввод замечаний к материалу"> sd_card_alert </mat-icon>
            </button>
            <button
              *ngIf="material.fileId && (canRestrictMaterialDownload || canRestrictMaterialView)"
              class="w-8 h-8 min-h-8"
              mat-icon-button
              (click)="configAttachment.emit(material)"
            >
              <mat-icon
                class="icon-size-4"
                svgIcon="heroicons_outline:ban"
                matTooltip="Выставление ограничений на скачивание и просмотр материалов"
              ></mat-icon>
            </button>
            <button
              *ngIf="
                canUploadMaterial ||
                (currentMember && currentMember.committeeMember.employee.id === responsibleUploadingId)
              "
              class="w-8 h-8 min-h-8"
              mat-icon-button
              (click)="deleteAttachment.emit({ material, required })"
            >
              <mat-icon
                class="icon-size-4"
                svgIcon="heroicons_outline:trash"
                matTooltip="Удалить материал"
              ></mat-icon>
            </button>
            <button
              class="w-8 h-8 min-h-8"
              *ngIf="canShowDocument && material.fileName | fileType: MATERIAL_TYPES"
              mat-icon-button
              [disabled]="!material.canViewMaterial"
              (click)="showDocument.emit(material)"
            >
              <mat-icon
                [matTooltip]="material.canViewMaterial ? 'Посмотреть документ' : 'Запрет просмотра документа'"
                class="icon-size-4"
                svgIcon="heroicons_solid:eye"
              ></mat-icon>
            </button>
          </div>
        </div>
      </div>
      <ng-template #noMaterial>
        <span *ngIf="speaker.needLoadedMaterial" class="text-red-500 text-sm font-medium"
          >Материалы не были загружены</span
        >
        <span *ngIf="!speaker.needLoadedMaterial" class="text-sm font-medium">Загрузка не обязательна</span>
      </ng-template>
    </section>

    <div *ngIf="material; else noMaterialLoaded" class="flex items-center gap-2">
      <mat-icon class="icon-size-4" svgIcon="heroicons_outline:clock"></mat-icon>
      <span class="text-sm">Загружено:</span>
      <div
        class="flex flex-col gap-1 text-sm"
        [ngClass]="{
          'text-red-500':
            speaker &&
            !(material.created | compareTime: materialUploadDeadline) &&
            speaker.needLoadedMaterial,
          'text-green-500':
            speaker && (material.created | compareTime: materialUploadDeadline) && speaker.needLoadedMaterial
        }"
      >
        {{ material.created | date: 'dd.MM.yyyy HH:mm' }}
      </div>
    </div>
    <ng-template #noMaterialLoaded>
      <span class="text-red-500 text-sm font-medium">-</span>
    </ng-template>
  </div>
</div>

<ng-template #noSpeakerMaterials>
  <div class="flex flex-col gap-1 w-full">
    <div class="flex items-center gap-2">
      <mat-icon class="icon-size-4" svgIcon="heroicons_outline:document-text"></mat-icon>
      <span class="text-sm">Материал:</span>
      <span *ngIf="speaker && speaker.needLoadedMaterial" class="text-red-500 text-sm font-medium"
        >Материалы не были загружены</span
      >
      <span *ngIf="speaker && !speaker.needLoadedMaterial" class="text-sm font-medium"
        >Загрузка не обязательна</span
      >
      <span *ngIf="!speaker" class="text-sm font-medium">Материалы не были загружены</span>
    </div>
    <div class="flex items-center gap-2">
      <mat-icon class="icon-size-4" svgIcon="heroicons_outline:clock"></mat-icon>
      <span class="text-sm">Загружено:</span>
      <span class="text-sm font-medium" [ngClass]="{ 'text-red-500': speaker && speaker.needLoadedMaterial }"
        >-</span
      >
    </div>
  </div>
</ng-template>
