import { Pipe, PipeTransform } from '@angular/core';
import { IMember } from '@common/types/calendar.types';
import { CommitteeRolesEnum } from '@common/enums/committee-roles.enum';

@Pipe({ name: 'memberFullNamesPipe' })
export class MemberFullNamesPipe implements PipeTransform {
  transform(members: IMember[] = []): string[] {
    const filtered = members.filter((member) =>
      [CommitteeRolesEnum.MANDATORY, CommitteeRolesEnum.INVITED].includes(
        member.committeeMember.role?.name as CommitteeRolesEnum
      )
    );
    return filtered.length
      ? filtered.map((m) => m.committeeMember.employee?.fullName).filter(Boolean)
      : ['-'];
  }
}
