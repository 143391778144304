import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UnsubscribeService } from '@common/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ITestQuestion, TestQuestionForm } from '@common/types/test-questions.types';
import { environment } from '../../../environments/environment';
import { asyncScheduler, Subject } from 'rxjs';
import { DaysOffFormValue } from '@common/types';

@Component({
  selector: 'com-test-question',
  templateUrl: './test-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class TestQuestionComponent {
  public formGroup: TestQuestionForm;
  public updateDaysOffForm$: Subject<DaysOffFormValue> = new Subject<DaysOffFormValue>();
  public daysOffForm: DaysOffFormValue;

  constructor(
    public matDialogRef: MatDialogRef<TestQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITestQuestion
  ) {
    this.createForm();
    this.updateDaysOffForm();
  }

  private updateDaysOffForm(): void {
    asyncScheduler.schedule(() => {
      const { excludeSaturdays, excludeSundays, excludeHolidays } = this.data;
      this.updateDaysOffForm$.next({
        excludeSaturdays,
        excludeSundays,
        excludeHolidays
      });
    });
  }

  public onDaysOffValueChange(daysOffForm: DaysOffFormValue) {
    this.daysOffForm = daysOffForm;
  }

  public confirm() {
    if (this.formGroup.valid) {
      this.matDialogRef.close({
        ...this.data,
        ...this.formGroup.value,
        ...this.daysOffForm
      });
    }
  }

  private createForm() {
    this.formGroup = new FormGroup({
      id: new FormControl(this.data?.id ?? null),
      name: new FormControl(this.data?.name ?? null, Validators.required),
      url: new FormControl(this.data?.url ?? null, [
        Validators.required,
        Validators.pattern(`${environment.constructorApi}/.+`)
      ]),
      minimumThreshold: new FormControl(this.data?.minimumThreshold ?? 0, [
        Validators.required,
        Validators.min(0),
        Validators.max(100)
      ]),
      daysBeforePassing: new FormControl(this.data?.daysBeforePassing ?? 1, [
        Validators.required,
        Validators.min(1)
      ]),
      excludeSaturdays: new FormControl(this.data?.excludeSaturdays ?? false),
      excludeSundays: new FormControl(this.data?.excludeSundays ?? false),
      excludeHolidays: new FormControl(this.data?.excludeHolidays ?? false)
    });
  }
}
