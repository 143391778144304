export enum ResolutionGeneralTypeEnum {
  TEMPLATE,
  TYPICAL,
  ATYPICAL
}

export enum ProtocolStatusEnum {
  FORMING = 0, // формируется
  FORMED = 1, // сформирован
  AGREEDING = 2, // согласовывается
  SENT = 3 // отправлен
}
