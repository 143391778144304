import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RRuleModel } from '@common/types';
import {
  ClosestDay,
  CommitteeIntersectionDto,
  DateInfo,
  DayInfoDto
} from '@common/dialogs/planning-dialog/types';

@Injectable({
  providedIn: 'root'
})
export class IntersectionCommitteeService {
  constructor(private http: HttpClient) {}

  public get(
    rrUle: RRuleModel | null,
    committeeId: string,
    employeeIds: string[],
    roomIds: string[]
  ): Observable<CommitteeIntersectionDto[]> {
    return this.http.post<CommitteeIntersectionDto[]>('api/Intersections/committee', {
      rrUle,
      employeeIds,
      committeeId,
      roomIds
    });
  }

  public unplannedEvent(
    rrUle: RRuleModel | null,
    committeeId: string,
    employeeIds: string[],
    roomIds: string[]
  ): Observable<DateInfo[]> {
    return this.http.post<DateInfo[]>('api/Intersections/dayInfoForUnplanned', {
      rrUle,
      employeeIds,
      committeeId,
      roomIds
    });
  }

  public dayInfo(
    date: string,
    rrUle: RRuleModel | null,
    committeeId: string,
    employeeIds: string[],
    roomIds: string[],
    committeeEventId?: string
  ): Observable<DayInfoDto> {
    return this.http.post<DayInfoDto>('api/Intersections/dayInfo', {
      rrUle,
      employeeIds,
      committeeId,
      roomIds,
      date,
      committeeEventId
    });
  }

  public transferEvent(
    rrUle: RRuleModel | null,
    committeeEventId: string,
    employeeIds: string[],
    roomIds: string[]
  ): Observable<DateInfo[]> {
    return this.http.post<DateInfo[]>('api/Intersections/dayInfoForTransfer', {
      rrUle,
      employeeIds,
      committeeEventId,
      roomIds
    });
  }

  public getClosestDayInfo(
    date: string,
    prevDate: string | null,
    nextDate: string | null,
    committeeId: string,
    employeeIds: string[],
    roomIds: string[],
    id: string,
    rrUle: RRuleModel
  ): Observable<ClosestDay> {
    return this.http.post<ClosestDay>('api/Intersections/dayInfoWithClosestDays', {
      date,
      prevDate,
      nextDate,
      roomIds,
      committeeId,
      employeeIds,
      id,
      rrUle
    });
  }
}
