import moment from 'moment';
import { MinutesInterval } from '@common/types';

export const format = (date: string, type: string): string => moment(date).format(type);

export const intervalsOverlapping = (a: MinutesInterval, b: MinutesInterval) => {
  if (b.start < a.start) {
    return b.end > a.start;
  } else {
    return b.start < a.end;
  }
};
