import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DiagramTab } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';
import { Option } from '@common/dialogs/planning-dialog/types';
@Component({
  selector: 'com-planning-diagram-actions',
  templateUrl: 'planning-diagram-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningDiagramActionsComponent {
  @Input() tabs: Option<DiagramTab>[];
  @Input() currentTab: DiagramTab;
  @Output() tabChange = new EventEmitter<DiagramTab>();
}
