import { Component, Inject, OnInit, Self } from '@angular/core';
import { ICalendarEvent, IOption, IRoomIntersectionData } from '@common/types';
import { FormControl, Validators } from '@angular/forms';
import {
  CalendarEventService, COMMITTEE_EVENT_TEMPLATE_TYPES,
  IntersectionService,
  JitsuLoggerService,
  RoomService,
  UnsubscribeService
} from '@common/services';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, filter, finalize, forkJoin } from 'rxjs';
import { ITableColumn } from '@common/shared';
import { ROOM_INTERSECTION_TABLE_COLUMNS } from '@common/dialogs/event-meeting-rooms/intersection-dialog.config';
import moment from 'moment';
import { DATE_FORMAT_DOT, TIME_FORMAT } from '@common/constants';
import { CommitteeEventActions } from '@common/constants/jitsu-logger.actions';

@Component({
  selector: 'com-event-meeting-rooms',
  templateUrl: './event-meeting-rooms.component.html',
  providers: [UnsubscribeService]
})
export class EventMeetingRoomsComponent implements OnInit {
  public formControl = new FormControl<string[]>(
    this.data.committeeMeetingRooms.map((committeeMeetingRoom) =>
      String(committeeMeetingRoom.id)
    ),
    [Validators.required]
  );
  public meetingRooms: IOption[] = [];
  public roomsDisplayColumns: ITableColumn[] = ROOM_INTERSECTION_TABLE_COLUMNS;
  public dataSource: Partial<IRoomIntersectionData>[] = [];
  public isChecking = false;

  constructor(
    private readonly _roomService: RoomService,
    private readonly _calendarEventService: CalendarEventService,
    private readonly _intersectionService: IntersectionService,
    private readonly _dialogRef: MatDialogRef<EventMeetingRoomsComponent>,
    private readonly _jitsuLoggerService: JitsuLoggerService,
    @Self() private readonly _unsubscribeService: UnsubscribeService,
    @Inject(MAT_DIALOG_DATA) public data: ICalendarEvent
  ) {}

  ngOnInit(): void {
    this._setSubscriptions();
    this._getIntersections();
    this.formControl.valueChanges
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._getIntersections();
      });
  }

  public onSubmit(): void {
    this._jitsuLoggerService.logEvent(
      CommitteeEventActions.changeMeetingRoomCommitteeEvent,
      { eventId: this.data.id }
    );
    this._calendarEventService
      .setMeetingRooms(this.data.id, this.formControl.value)
      .pipe(
        filter((res) => res),
        takeUntil(this._unsubscribeService)
      )
      .subscribe(() => {
        this._dialogRef.close(
          this.meetingRooms.filter((meetingRoom) =>
            this.formControl.value.includes(String(meetingRoom.id))
          )
        );
      });
  }

  private _setSubscriptions(): void {
    this._roomService.meetingRooms$
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((meetingRooms) => {
        this.meetingRooms = meetingRooms;
      });
  }

  private _getIntersections(): void {
    this.isChecking = true;
    const start = this.data.start || this.data.eventTime;
    this._intersectionService
      .checkRoomsAreBusy(
        moment(start).toISOString(),
        moment(start).add(this.data.duration, 'minutes').toISOString(),
        this.formControl.value,
        COMMITTEE_EVENT_TEMPLATE_TYPES
      )
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((roomIntersections) => {
        this.dataSource = roomIntersections
          .filter(
            (roomIntersection) =>
              roomIntersection.eventTemplateId !==
              this.data.calendarEventTemplateId
          )
          .flatMap((roomIntersection) => {
            const { startDate, endDate, title } = roomIntersection;
            return roomIntersection.eventRooms
              .filter(
                (eventRoom) =>
                  this.formControl.value.includes(eventRoom.id) &&
                  roomIntersection.eventId !== this.data.id
              )
              .map((eventRoom) => ({
                roomName: eventRoom.title,
                rangeDate: `${moment(startDate).format(
                  DATE_FORMAT_DOT
                )}, ${moment(startDate).format(TIME_FORMAT)}-${moment(
                  endDate
                ).format(TIME_FORMAT)}`,
                title
              }));
          });
        this.isChecking = false;
      });
  }
}
