import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {IAgendaItemCreateDto} from "@common/types";

@Injectable({
  providedIn: 'root'
})
export class CommitteeEventAgendaService {
  constructor(private readonly http: HttpClient) {}

  public add(agenda: IAgendaItemCreateDto, eventId: string): Observable<void> {
    return this.http.post<void>(
      `api/CommitteeEventAgendaItem/${eventId}`,
      agenda
    );
  }

  public edit(agenda: IAgendaItemCreateDto, eventId: string): Observable<void> {
    return this.http.put<void>(
      `api/CommitteeEventAgendaItem/${eventId}`,
      agenda
    );
  }

  public delete(eventId: string, agendaItemId: string): Observable<void> {
    const params = new HttpParams().set('agendaItemId', agendaItemId);
    return this.http.delete<void>(
      `api/CommitteeEventAgendaItem/${eventId}`,  { params }
    );
  }
}
