import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { CalendarEventService, EmployeeService } from '@common/services';
import { AppRolesEnum } from '@common/enums';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@common/shared';

@Injectable({
  providedIn: 'root'
})
export class EventGuard implements CanActivate {
  constructor(
    protected router: Router,
    private employeeService: EmployeeService,
    private calendarEventService: CalendarEventService,
    private snackBar: MatSnackBar
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([
      this.employeeService.retrieveEmployeeCurrentUserInfo(),
      this.calendarEventService.retrieveEventMembers(route.params.eventId)
    ]).pipe(
      map(
        ([employeeCurrentUserInfo, eventMembers]) =>
          ![AppRolesEnum.BUSINESS_ADMIN, AppRolesEnum.USER].includes(
            employeeCurrentUserInfo.appRole.keyCloakName
          ) ||
          eventMembers
            .map((eventMember) => eventMember.committeeMember.employee.id)
            .includes(employeeCurrentUserInfo.id)
      ),
      tap(async (res) => {
        if (!res) {
          await this.router.navigate(['/']);
          this.snackBar.openFromComponent(SnackbarComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 5000,
            data: {
              icon: 'heroicons_outline:exclamation',
              iconClass: 'text-red-500',
              title: 'Доступ отсутствует',
              message: 'Вы не можете просматривать данное событие, так как не являетесь его участником'
            },
            panelClass: 'committees-app'
          });
        }
      })
    );
  }
}
