import { Component, Input, OnChanges, OnInit, Self, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { filter, takeUntil } from 'rxjs';
import { UnsubscribeService } from '@common/services';
import { IOption } from '@common/types';
import { multiselectOptionValidator } from '@common/utils/validators';

@Component({
  selector: 'com-form-multiselect',
  templateUrl: './form-multiselect.component.html',
  providers: [UnsubscribeService]
})
export class FormMultiselectComponent implements OnInit, OnChanges {
  @Input() control: FormControl<(string | number)[]>;
  @Input() options: IOption[] = [];
  @Input() placeholder = 'Выберите значения';
  @Input() widthChip = 'max-w-full';

  public filteredOptions: IOption[] = [];
  public selectedOptions: IOption[] = [];
  public inputControl = new FormControl<string>(null);

  constructor(@Self() private readonly unsubscribeService: UnsubscribeService) {}

  ngOnInit(): void {
    if (this.control.disabled) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
    this.control.statusChanges.pipe(takeUntil(this.unsubscribeService)).subscribe((status) => {
      if (status === 'DISABLED') {
        this.inputControl.reset();
        this.selectedOptions = [];
      }
    });
    this.inputControl.valueChanges
      .pipe(
        filter((value) => typeof value === 'string'),
        takeUntil(this.unsubscribeService)
      )
      .subscribe((value) => {
        this.filteredOptions = this.options.filter(
          (option) =>
            option.name.toLowerCase().includes(value.toLowerCase()) &&
            !this.selectedOptions.some((selectedOption) => selectedOption.id === option.id)
        );
      });
    this.control.valueChanges.pipe(takeUntil(this.unsubscribeService)).subscribe(() => {
      this._updateOptions();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._onControlChanges(changes);
    if ('options' in changes) {
      this._updateOptions();
    }
  }

  displayFn(value: string | boolean): string {
    return this.options.find((option) => option.id === value)?.name;
  }

  onOptionSelect(event: MatAutocompleteSelectedEvent, input: HTMLInputElement): void {
    this.control.setValue([...(this.control.value || []), event.option.value]);
    this.inputControl.setValue(null);
    input.value = null;
  }

  onSelectedOptionRemove(option: IOption): void {
    this.control.setValue(this.control.value.filter((valueOption) => valueOption !== option.id));
  }

  private _onControlChanges(changes: SimpleChanges): void {
    const control = changes.control?.currentValue;
    if (control) {
      this.inputControl.setValidators([multiselectOptionValidator(this.control)]);
    }
  }

  private _updateOptions(): void {
    this.filteredOptions = this.options.filter(
      (option) => !(this.control.value || []).includes(option.id as number | string)
    );
    this.selectedOptions = this.options.filter((option) =>
      (this.control.value || []).includes(option.id as number | string)
    );
  }

  protected readonly document = document;
}
