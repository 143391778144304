import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IAttachmentDto, IMember, ISpeaker } from '@common/types';
import { VIEWING_MATERIAL_TYPES } from '@common/constants';
@Component({
  selector: 'com-material-view',
  templateUrl: 'material-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialViewComponent {
  @Input() materials: IAttachmentDto[];
  @Input() canSetViolation: boolean;
  @Input() canRestrictMaterialDownload: boolean;
  @Input() canRestrictMaterialView: boolean;
  @Input() canUploadMaterial: boolean;
  @Input() currentMember: IMember;
  @Input() responsibleUploadingId: string;
  @Input() required: boolean;
  @Input() speaker: ISpeaker | string | undefined;
  @Input() materialUploadDeadline: string;
  @Input() canShowDocument: boolean;

  @Output() download = new EventEmitter<IAttachmentDto>();
  @Output() setViolation = new EventEmitter<IAttachmentDto>();
  @Output() deleteAttachment = new EventEmitter<{ material: IAttachmentDto; required: boolean }>();
  @Output() configAttachment = new EventEmitter<IAttachmentDto>();
  @Output() showDocument = new EventEmitter<IAttachmentDto>();

  protected MATERIAL_TYPES = VIEWING_MATERIAL_TYPES;
}
