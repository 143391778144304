import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs';
import { GeneralFormValue, IGeneralFormGroup, IOption } from '@common/types';
import { UnsubscribeService } from '@common/services';
import { ModelsEnum } from '@common/enums';
import { FormAbstractionComponent } from '@common/shared/components/form-abstraction/form-abstraction.component';

@Component({
  selector: 'com-general',
  templateUrl: './general.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class GeneralComponent extends FormAbstractionComponent implements OnInit, OnChanges {
  @Output() valueChange = new EventEmitter<GeneralFormValue>();

  @Input() committeeTypes: IOption[] = [];
  @Input() committeeSubTypes: IOption[] = [];
  @Input() committeeKinds: IOption[] = [];
  @Input() isEdit: boolean;
  @Input() modelType = ModelsEnum.COMPROMISE;
  @Input() formValue: GeneralFormValue;

  public formGroup: FormGroup<IGeneralFormGroup>;

  constructor(
    private formBuilder: FormBuilder,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {
    super();
    this._createForm();
  }

  ngOnInit(): void {
    this._valueChanges();
    this.isEdit && this.formGroup.controls.name.disable();
    this.emitFormMethods();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('modelType' in changes && changes.modelType.currentValue === ModelsEnum.IDEAL) {
      this.formGroup.controls.committeeSubTypeId.clearValidators();
      this.formGroup.controls.committeeKindId.clearValidators();
    }
    if ('committeeKinds' in changes && this.committeeKinds.length !== 0) {
      this.formGroup.controls.committeeKindId.setValue(this.committeeKinds[0].id as string);
    }
    if ('committeeSubTypes' in changes && this.committeeSubTypes.length !== 0) {
      this.formGroup.controls.committeeSubTypeId.setValue(this.committeeSubTypes[0].id as string);
    }
    if ('formValue' in changes && this.formValue) {
      this.formGroup.patchValue(this.formValue);
    }
  }

  private _createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      committeeKindId: ['', [Validators.required]],
      committeeSubTypeId: ['', [Validators.required]]
    });
  }

  private _valueChanges(): void {
    this.formGroup.valueChanges
      .pipe(
        tap(() => this.valueChange.emit(this.formGroup.getRawValue())),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }
}
