import { Pipe, PipeTransform } from '@angular/core';
import { IEmployeeRating } from '@common/types';

@Pipe({
  name: 'averageRating'
})
export class AverageRatingPipe implements PipeTransform {
  transform(employeeRatings: IEmployeeRating[]): string {
    const { length } = employeeRatings;
    return length ? (employeeRatings.reduce((acc, cur) => acc + +cur.rating, 0) / length).toFixed(1) : '-';
  }
}
