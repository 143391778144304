import { MemberMaterialStatusEnum, MemberPresenceStatusEnum } from '@common/enums';

export interface IEmployeeStatusSocket {
  committeeEventId: string;
  employeeId: string;
  status: MemberPresenceStatusEnum | MemberMaterialStatusEnum;
  systemStatus: MemberPresenceStatusEnum | MemberMaterialStatusEnum;
  markManually: boolean;
}

export interface IEmployeeOnline {
  employeeOnlineWithStatusList: IEmployeeStatusSocket[];
}

export interface IQuorumSocket {
  committeeEventId: string;
  quorumModelFixed: IQuorumModel;
  quorumOnlyThosePresent: IQuorumModel;
}

export interface IQuorumModel {
  presentCount: number;
  quorum: number;
  totalCount: number;
}

export interface IDisciplineViolationSocket {
  committeeEventId: string;
  employeeId: string;
  value: boolean;
  violations: string[];
}

export interface ICommitteeEventSocket {
  committeeEventId: string;
}

export interface ICommitteeEventAgendaItemSocket extends ICommitteeEventSocket {
  agendaItemId: string;
}

export interface ICommitteeEventContinueSocket {
  committeeEventId: string;
  name: string;
}

export type Socket =
  | {
      data: IQuorumSocket;
      type: 'quorum';
    }
  | {
      data: IEmployeeStatusSocket[];
      type: 'employeeOnline';
    }
  | {
      data: IEmployeeStatusSocket[];
      type: 'materialStatus';
    }
  | {
      // TODO: добавить интерфейс
      data: any;
      type:
        | 'vote'
        | 'newResolution'
        | 'updateResolution'
        | 'deleteResolution'
        | 'addMaterial'
        | 'removeMaterial'
        | 'addViolationMaterial'
        | 'addDisciplineViolation'
        | 'viewMaterial'
        | 'statusPreResolution';
    }
  | {
      data: ICommitteeEventSocket;
      type:
        | 'startCommitteeEvent'
        | 'toResolutionEvent'
        | 'pauseCommitteeEvent'
        | 'closeCommittee'
        | 'eventFinishing'
        | 'loadMembers';
    }
  | {
      data: ICommitteeEventAgendaItemSocket;
      type: 'startDiscussionAgendaItem';
    }
  | {
      data: ICommitteeEventContinueSocket;
      type: 'continueCommitteeEvent';
    };
