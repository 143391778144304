import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { E_EVENT_IN_PROGRESS_STATE, ResolutionKindEnum } from '@common/enums';
import { ITableChange, TableActionsEnum } from '@common/shared';
import { debounceTime, filter, pairwise, takeUntil, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TaskEditComponent } from '@common/dialogs/task-edit/task-edit.component';
import { ProtocolService, UnsubscribeService } from '@common/services';
import {
  IAgendaItemTask,
  IMember,
  IOption,
  ITask,
  ITaskChange,
  ITaskDataSource,
  ITaskEditData,
  ITaskProjectStatus
} from '@common/types';
import { FormControl, FormGroup } from '@angular/forms';
import { TaskManagerService } from '@common/services/task-manager.service';
import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_DOT } from '@common/constants';
import { TASKS_TABLE_COLUMNS } from '@common/shared/components/tasks/tasks.config';

@Component({
  selector: 'com-tasks',
  templateUrl: './tasks.component.html',
  providers: [UnsubscribeService]
})
export class TasksComponent implements OnInit, OnChanges {
  @Input() committeeId: string;
  @Input() committeeEventId: string;
  @Input() eventInProgressState: E_EVENT_IN_PROGRESS_STATE;
  @Input() set members(members: IMember[]) {
    this.memberOptions = members.map(({ committeeMember: { employee } }) => ({
      id: employee.id,
      name: employee.fullName
    }));
  }
  @Input() editable = false;
  @Input() canAddDecisionsForTasks = false;

  @Output() taskChange = new EventEmitter<ITaskChange>();

  dataSources: ITaskDataSource[] = [];
  columns = TASKS_TABLE_COLUMNS;
  filterFormGroup = new FormGroup({
    isTask: new FormControl(null),
    title: new FormControl(''),
    assigneeId: new FormControl(''),
    projectStatusId: new FormControl(''),
    dateDeadlineStart: new FormControl(''),
    dateDeadlineEnd: new FormControl(''),
    completedAtStart: new FormControl(''),
    completedAtEnd: new FormControl('')
  });
  memberOptions: IOption[] = [];
  resolutionKinds: IOption[] = [
    { id: null, name: 'Все типы' },
    {
      id: false,
      name: '<div class="flex items-center"><iconify-icon icon="heroicons-outline:document-text" class="mat-icon mr-3" width="24px"></iconify-icon><span>Решение</span></div>'
    },
    {
      id: true,
      name: '<div class="flex items-center"><iconify-icon icon="heroicons-outline:document-check" class="mat-icon mr-3" width="24px"></iconify-icon><span>Задача</span></div>'
    }
  ];
  projectStatuses: ITaskProjectStatus[] = [];
  statusOptions: IOption[] = [];
  Object = Object;

  private tasks: IAgendaItemTask[] = [];

  constructor(
    private readonly matDialog: MatDialog,
    private readonly taskManagerService: TaskManagerService,
    private readonly protocolService: ProtocolService,
    @Self() private readonly unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    this.taskManagerService
      .retrieveProjectStatuses(this.committeeId)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(({ data: projectStatuses }) => {
        this.projectStatuses = projectStatuses;
        this.statusOptions = [
          { id: null, name: 'Все статусы' },
          ...projectStatuses.map((projectStatus) => ({
            id: projectStatus.id,
            name: projectStatus.status
          }))
        ];
      });
    this.filterFormGroup.valueChanges
      .pipe(
        pairwise(),
        filter(([cur, prev]) => JSON.stringify(cur) !== JSON.stringify(prev)),
        debounceTime(250),
        tap(() => {
          this.setDataSources();
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
    this.getTasks();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    console.log(this.dataSources);
    if ('eventInProgressState' in changes || 'canAddDecisionsForTasks' in changes) {
      this.dataSources = this.dataSources.map((dataSource) => ({
        ...dataSource,
        tasks: dataSource.tasks.map((task) =>
          task.isTask
            ? {
                ...task,
                actions: {
                  [TableActionsEnum.EDIT]: {
                    show: this.editable && this.canAddDecisionsForTasks,
                    disabled: this.eventInProgressState !== E_EVENT_IN_PROGRESS_STATE.AGENDA
                  }
                }
              }
            : task
        )
      }));
    }
  }

  public onTableChange(evt: ITableChange): void {
    switch (evt.action) {
      case TableActionsEnum.EDIT: {
        const deadline = evt.payload.deadline?.deadline;
        const data: ITaskEditData = {
          assigneeId: evt.payload.assignee.id,
          deadline: deadline && moment(deadline).format(DATE_FORMAT),
          projectStatusId: evt.payload.taskStatus?.projectStatus.id,
          memberOptions: this.memberOptions,
          statusOptions: this.statusOptions.slice(1),
          projectStatuses: this.projectStatuses
        };
        const dialogRef = this.matDialog.open(TaskEditComponent, {
          width: '600px',
          disableClose: true,
          data,
          panelClass: 'committees-app'
        });
        dialogRef
          .afterClosed()
          .pipe(filter(Boolean), takeUntil(this.unsubscribeService))
          .subscribe((res) => {
            this.taskChange.emit({ task: evt.payload, change: res });
          });
        break;
      }
    }
  }

  public clearForm(): void {
    this.filterFormGroup.reset();
  }

  private getTasks(): void {
    this.protocolService
      .getPreviousTasks(this.committeeEventId)
      .pipe(
        tap((tasks) => {
          this.tasks = tasks;
          this.setDataSources();
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  private setDataSources(): void {
    const {
      isTask,
      title,
      assigneeId,
      projectStatusId,
      dateDeadlineStart,
      dateDeadlineEnd,
      completedAtStart,
      completedAtEnd
    } = this.filterFormGroup.value;
    this.dataSources = Object.values<ITaskDataSource>(
      this.tasks.map((task) => ({
        agendaItemOrder: task.order,
        agendaItemTitle: task.title,
        tasks: task.items
          .filter(
            (item) =>
              (isTask === null || item.isTask === isTask) &&
              (!title || item.title.toLowerCase().includes(title.toLowerCase())) &&
              (!assigneeId || item.assignee.id === assigneeId) &&
              (!projectStatusId || item.taskStatus?.projectStatus.id === projectStatusId) &&
              (!dateDeadlineStart ||
                moment(item.deadline?.deadline).isAfter(moment(dateDeadlineStart).startOf('day'))) &&
              (!dateDeadlineEnd ||
                moment(item.deadline?.deadline).isBefore(moment(dateDeadlineEnd).endOf('day'))) &&
              (!completedAtStart ||
                moment(item.completeDate).isAfter(moment(completedAtStart).startOf('day'))) &&
              (!completedAtEnd || moment(item.completeDate).isBefore(moment(completedAtEnd).endOf('day')))
          )
          .map(
            (item) =>
              ({
                ...item,
                order: item?.order,
                assigneeArray: [...(item.assigneeHistory || [])].reverse().map((item) => item.assignee?.name),
                deadlineArray:
                  item.deadlines?.map((deadline) => moment(deadline.deadline).format(DATE_FORMAT_DOT)) || [],
                projectStatusString: item.historyStatus?.[0].projectStatus.status,
                resolutionKind: ResolutionKindEnum.TASK,
                actions: {
                  [TableActionsEnum.EDIT]: {
                    show: this.editable && this.canAddDecisionsForTasks,
                    disabled: this.eventInProgressState !== E_EVENT_IN_PROGRESS_STATE.AGENDA
                  }
                }
              }) as ITask
          )
      }))
    ).sort((a, b) =>
      a.agendaItemOrder === null && b.agendaItemOrder !== null
        ? 1
        : a.agendaItemOrder !== null && b.agendaItemOrder === null
          ? -1
          : a.agendaItemOrder - b.agendaItemOrder
    );
  }
}
