<mat-form-field *ngIf="control" class="w-full">
  <input
    matInput
    type="text"
    [placeholder]="placeholder"
    [matAutocomplete]="auto"
    [formControl]="control"
    [matTooltip]="showTooltip ? (options | optionName: control?.value) : ''"
    class="text-ellipsis"
  />
  <mat-icon
    *ngIf="clearable && control?.value && !control.disabled"
    matSuffix
    matTooltip="Удалить"
    (click)="clear($event)"
    class="icon-size-6 cursor-pointer ml-1"
    svgIcon="heroicons_outline:x-circle"
  ></mat-icon>
  <mat-icon matSuffix class="icon-size-4 ml-1" [svgIcon]="arrowIconSubject.getValue()"></mat-icon>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (closed)="closePanel()"
    (opened)="openPanel()"
  >
    <mat-option *ngIf="!options.length"> --Нет данных-- </mat-option>
    <ng-container *ngIf="options.length">
      <mat-option *ngFor="let option of options" class="h-fit min-h-12" [value]="option.id">
        <div class="flex flex-col leading-normal py-3">
          <span
            #name
            class="truncate"
            [matTooltip]="option.name"
            matTooltipPosition="after"
            [matTooltipDisabled]="name.scrollWidth <= name.clientWidth"
          >
            {{ option.name }}
          </span>
          <span
            #position
            *ngIf="option.position"
            class="truncate text-sm text-gray-500"
            [matTooltip]="option.position.name"
            matTooltipPosition="after"
            [matTooltipDisabled]="position.scrollWidth <= position.clientWidth"
          >
            {{ option.position.name }}
          </span>
          <span
            #division
            *ngIf="option.division"
            class="truncate text-sm text-gray-500"
            [matTooltip]="option.division.name"
            matTooltipPosition="after"
            [matTooltipDisabled]="division.scrollWidth <= division.clientWidth"
          >
            {{ option.division.name }}
          </span>
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
