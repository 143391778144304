import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'filterByField' })
export class FilterByFieldPipe implements PipeTransform {
  transform(options: any[], fieldName: string, value: string | boolean | number): any[] {
    if (options?.length && fieldName) {
      return options.filter((el: any) => el[fieldName] === value);
    }

    return options || [];
  }
}
