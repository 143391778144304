import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IEmployee } from '@common/types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IEmployeeListProps {
  employees: IEmployee[];
  title: string;
}

@Component({
  templateUrl: 'employee-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeListComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public props: IEmployeeListProps) {}
}
