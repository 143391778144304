import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IntersectionEvent } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';

@Component({
  selector: 'com-intersection-list',
  templateUrl: 'intersection-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntersectionListComponent {
  @Input() events: IntersectionEvent[];
  @Input() selectedEventIndex: number;
  @Input() indexSelectFromNavigation: number;
  @Output() selectIndex = new EventEmitter<number>();
}
