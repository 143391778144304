import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EventTypeIds } from '@common/types/calendar-api';

@Component({
  selector: 'com-event-type-tag',
  templateUrl: 'event-type-tag.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventTypeTagComponent implements OnInit {
  @Input() eventTypeId: EventTypeIds;

  public title: string | null = null;
  public color: string | null = null;

  ngOnInit(): void {
    switch (this.eventTypeId) {
      case EventTypeIds.committee:
        this.title = 'Комитет 1.0';
        this.color = '#786FA6';
        break
      case EventTypeIds.committee2_0:
        this.title = 'Комитет 2.0';
        this.color = '#4666B6';
    }
  }
}
