export enum DocumentsEnum {
  COMMITTEE_CONCEPT,
  ATYPICAL_JOB_INSTRUCTION,
  ATYPICAL_JOB_INSTRUCTION_PARTICIPANT
}

export enum ATYPICAL_INSTRUCTION {
  COMMITTEE_CONCEPT = 'Положение о комитете',
  CHAIRMAN = 'Нетиповая ДИ Председателя',
  MEMBER = 'Нетиповая ДИ Члена Комитета'
}

export enum TYPICAL_INSTRUCTION {
  CHAIRMAN = 'ДИ Председателя',
  MEMBER = 'ДИ Члена Комитета'
}

export enum TYPICAL_INSTRUCTION_FILE_NAME {
  MemberJobInstruction = 'ДИ_Члена Комитета.docx',
  ChairmanJobInstruction = 'ДИ_Председателя Комитета.docx'
}

export enum UploadBeforeTypeEnum {
  DAYS,
  MINUTES
}

export enum NoSystemAgendaDeadlineTypeEnum {
  MINUTES,
  DAYS
}

export enum UploadMaterialsTypeEnum {
  ONE_MATERIAL_FOR_ALL,
  ALL_NEED_UPLOAD_MATERIAL,
  ONE_MATERIAL_ONE_RESPONSIBLE
}

export enum MaterialTypesEnum {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLSX = 'xlsx'
}

export enum ResponsibleTypeEnum {
  RESPONSIBLE_IS_SPEAKER = 0,
  ONE_RESPONSIBLE = 1,
  RESPONSIBLE_NOT_SPEAKER = 2
}
