import { ITableColumn, TableFieldTypeEnum } from '@common/shared';

const COMMON_TABLE_COLUMNS: ITableColumn[] = [
  {
    title: 'Название комитета',
    field: 'title',
    columnClass: 'w-1/4'
  },
  {
    title: 'Время проведения',
    field: 'rangeDate',
    columnClass: 'w-1/4'
  }
];

export const ROOM_INTERSECTION_TABLE_COLUMNS: ITableColumn[] = [
  {
    title: 'Переговорная',
    field: 'roomName',
    fieldType: TableFieldTypeEnum.BADGE,
    columnClass: 'w-1/2'
  },
  ...COMMON_TABLE_COLUMNS
];
