import { Component, HostBinding, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, map } from 'rxjs';
import { WebsocketService } from '@common/services/websocket.service';
import { AuthUtils } from '@common/utils/auth';
import { AuthService, HolidayService, JitsuLoggerService } from '@common/services';
import moment from 'moment';
import 'moment/locale/ru';
import { environment } from '../environments/environment';
import { SnackbarComponent } from './modules/shared/components/snackbar/snackbar.component';

@Component({
  selector: 'com-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class')
  public class = 'committees-app';

  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly _authService: AuthService,
    private readonly _wsService: WebsocketService,
    private readonly _holidayService: HolidayService,
    private readonly _jitsuLoggerService: JitsuLoggerService
  ) {}

  ngOnInit(): void {
    this._authService.user$
      .pipe(
        map(() => AuthUtils.getKeycloakUser(this._authService.accessToken)),
        filter(Boolean)
      )
      .subscribe((user) => {
        this._holidayService.loadHolidays();
        this._wsService.connect(
          `${environment.wsHost}/connection/websocket`,
          this._authService.accessToken,
          user.sub.toString()
        );
        this._jitsuLoggerService.connect(user.sub.toString());
      });
    this._wsService.continueCommitteeEvent$.subscribe((data) => {
      this._snackBar.openFromComponent(SnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 5000,
        data: {
          icon: 'heroicons_outline:exclamation',
          iconClass: 'text-red-500',
          title: 'Комитет продолжается',
          message: `${data.name} продолжается перейдите по <a href="calendar/${data.committeeEventId}/call">ссылке</a>`
        },
        panelClass: 'committees-app'
      });
    });
    moment.locale('ru');
  }
}
