export enum E_MATERIAL_VIOLATION {
  INFORMATION_ERRORS = 'Ошибки в информации',
  FORMAT_MISMATCH = 'Несоответствие материалов типовому формату'
}

export enum E_DISCIPLINE_VIOLATION {
  USING_GADGET_DURING_MEETING = 'Общение по телефону / другим средствам связи во время заседания',
  LEAVING_ROOM_DURING_MEETING = 'Выход из переговорной во время заседания',
  EATING_IN_THE_MEETING = 'Употребление продуктов питания, напитков на заседании',
  CONNECTION_FROM_NON_WORKPLACE = 'Подключение на заседание с нерабочего места',
  REMOTE_CONNECTION_WITHOUT_VIDEO = 'Удаленное подключение без видеосвязи',
  ABSENCE_IN_THE_MEETING = 'Отсутствие в переговорной комнате'
}
