import { ChangeDetectionStrategy, Component, Inject, Self } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommitteeEventAgendaService, UnsubscribeService } from '@common/services';
import { IAgendaItem, IAgendaOptions, IOption } from '@common/types';
import { IAgendaFormValue } from '@common/shared/components/form-groups/agenda/agenda.component';
import { BehaviorSubject, finalize, Observable, takeUntil, tap } from 'rxjs';

export type NoSystemAgendaProps = {
  eventId: string;
  editedAgendaId: string | null;
  title: string;
  agendaItems: IAgendaItem[];
  agendaOptions: IAgendaOptions;
  members: IOption[];
  speakers: IOption[];
  compromiseStart: string;
};

@Component({
  selector: 'com-no-system-agenda',
  templateUrl: 'no-system-agenda.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class NoSystemAgendaComponent {
  public checkAgendaIsValid: () => boolean;
  public agendaFormValue: IAgendaFormValue;

  public loading$ = new BehaviorSubject(false);
  public invalid$ = new BehaviorSubject(false);

  constructor(
    public dialogRef: MatDialogRef<NoSystemAgendaComponent>,
    private eventAgendaService: CommitteeEventAgendaService,
    @Inject(MAT_DIALOG_DATA) public props: NoSystemAgendaProps,
    @Self() readonly unsubscribe: UnsubscribeService
  ) {}

  save(): void {
    if (this.checkValid()) {
      this.loading$.next(true);
      (this.props.editedAgendaId ? this.edit() : this.create())
        .pipe(
          tap(() => this.dialogRef.close(true)),
          finalize(() => this.loading$.next(false)),
          takeUntil(this.unsubscribe)
        )
        .subscribe();
    }
  }

  create(): Observable<void> {
    const { agendaItems } = this.agendaFormValue;
    return this.eventAgendaService.add(
      agendaItems.find((a) => !a.id),
      this.props.eventId
    );
  }

  edit(): Observable<void> {
    const { agendaItems } = this.agendaFormValue;
    return this.eventAgendaService.edit(
      agendaItems.find((a) => a.id === this.props.editedAgendaId),
      this.props.eventId
    );
  }

  getAgendaValue(value: IAgendaFormValue): void {
    this.checkValid();
    this.agendaFormValue = value;
  }

  checkValid(): boolean {
    const valid = this.checkAgendaIsValid();
    this.invalid$.next(!valid);
    return valid;
  }
}
