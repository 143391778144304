<div
  class="flex flex-col gap-4 grow py-4 max-w-full text-base"
  [ngClass]="{ 'border-b-2': showDivider }"
>
  <div
    *ngIf="legend"
    class="flex justify-between items-center border-b-2 text-md leading-7"
  >
    <h2 class="text-lg font-semibold">{{ legend }}</h2>
    <ng-content select="[actions]"></ng-content>
  </div>
  <ng-content></ng-content>
</div>
