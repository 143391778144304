import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'compareTime'
})
export class CompareTimePipe implements PipeTransform {
  transform(firstTime: string, secondTime: string): boolean {
    return moment(firstTime).isBefore(moment(secondTime));
  }
}
