import { NgModule } from '@angular/core';
import { ButtonModule } from '@common/dialogs/planning-dialog/ui/button/button.module';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PlanningDiagramModule } from '@common/dialogs/planning-dialog/modules/planning-diagram/planning-diagram.module';
import { PlanningIntersectionModule } from '@common/dialogs/planning-dialog/modules/planning-intersection/planning-intersection.module';
import { FreeSlotsModule } from '@common/dialogs/planning-dialog/modules/planning-intersection/components/free-slots/free-slots.module';
import { EventPlanningComponent } from '@common/dialogs/event-planning/event-planning.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BusyTimelineModule } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/busy-timeline.module';
import { EkpCheckboxModule } from '@common/shared/components/ekp-checkbox/ekp-checkbox.module';

@NgModule({
  declarations: [EventPlanningComponent],
  exports: [EventPlanningComponent],
  imports: [
    ButtonModule,
    CommonModule,
    MatDialogModule,
    MatIconModule,
    PlanningDiagramModule,
    PlanningIntersectionModule,
    FreeSlotsModule,
    MatProgressSpinnerModule,
    BusyTimelineModule,
    EkpCheckboxModule
  ],
  schemas: []
})
export class EventPlanningModule {}
