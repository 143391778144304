import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { FormLabelType } from '@common/types';

@Component({
  selector: 'com-form-label',
  templateUrl: './form-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormLabelComponent implements AfterViewInit, OnDestroy {
  @Input() text = '';
  @Input() type: FormLabelType = 'block';
  @Input() required = false;

  @ViewChild('textElement') textElement: ElementRef;

  @HostBinding('class') get class(): string {
    return 'overflow-x-hidden';
  }

  public tooltipDisabled = true;

  private readonly _resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      this.tooltipDisabled = entry.target.scrollWidth === entry.target.clientWidth;
    });
  });

  ngAfterViewInit(): void {
    this._resizeObserver.observe(this.textElement.nativeElement);
  }

  ngOnDestroy(): void {
    this._resizeObserver.disconnect();
  }
}
