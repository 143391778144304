import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ITestResult } from '@common/types/allowance';

@Injectable({
  providedIn: 'root'
})
export class AllowanceService {
  constructor(private readonly _http: HttpClient) {}

  public retrieveTestResults(
    committeeEventId: string,
    employeeId: string
  ): Observable<ITestResult[]> {
    const params = new HttpParams({
      fromObject: { employeeId }
    });
    return this._http.get<ITestResult[]>(
      `api/Allowance/GetTestResults/${committeeEventId}`,
      {
        params
      }
    );
  }

  public hasAllowanceToConnectCall(committeeEventId: string,
                                   employeeId: string): Observable<boolean> {
    const params = new HttpParams({
      fromObject: { employeeId }
    });
    return this._http.get<boolean>(
      `api/Allowance/HasAllowanceToConnectCall/${committeeEventId}`,
      {
        params
      }
    );
  }
}
