<com-form-block [formGroup]="formGroup">
  <h2 class="text-xl leading-8">Общее</h2>
  <com-form-item>
    <com-form-label text="Название" type="block" [required]="true"></com-form-label>
    <com-form-input  class="w-full" [control]="formGroup.controls.name"></com-form-input>
  </com-form-item>
  <com-form-item>
    <com-form-label text="Уровень управления" type="block" [required]="true"></com-form-label>
    <mat-form-field class="w-full">
      <mat-select
              formControlName="committeeKindId"
      >
        <mat-option
                *ngFor="let kind of committeeKinds"
                [value]="kind.id"
        >
          {{ kind.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </com-form-item>
  <com-form-item>
    <com-form-label text="Тип комитета" type="block" [required]="true"></com-form-label>
    <mat-form-field class="w-full">
      <mat-select
              formControlName="committeeSubTypeId"
      >
        <mat-option
                *ngFor="let type of committeeSubTypes"
                [value]="type.id"
        >
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </com-form-item>
</com-form-block>
