import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { addDays, endOfYear, isAfter, isSameDay, isSameYear, startOfYear } from 'date-fns';
import { setTimeZone, toISO } from '@common/dialogs/intersection-dialog/helpers/date.helpers';

type ResponseHoliday = {
  body: Holiday[];
  errorCode: string;
  isSuccess: boolean;
};

export type Holiday = {
  id: string;
  holidayDate: string;
  title: string;
};

@Injectable({
  providedIn: 'root'
})
export class HolidayService {
  public holidays$ = new BehaviorSubject<Holiday[]>([]);
  private storageDays = 7;

  constructor(private readonly http: HttpClient) {}

  public isHoliday(date: Date): boolean {
    const { value } = this.holidays$;
    return value.some((holiday) => isSameDay(setTimeZone(holiday.holidayDate), date));
  }

  public loadHolidays(): void {
    const holidaysSaveDate = localStorage.getItem('holidaysSaveDate');
    if (holidaysSaveDate) {
      const savedDate = new Date(Number(holidaysSaveDate));
      if (this.isHashExpired(savedDate)) {
        this.loadHolidaysIntervalYear();
      } else {
        this.getHolidaysFromLocalStorage();
      }
    } else {
      this.loadHolidaysIntervalYear();
    }
  }

  private isHashExpired(savedDate: Date): boolean {
    return isAfter(new Date(), addDays(savedDate, this.storageDays)) || !isSameYear(new Date(), savedDate);
  }

  private getHolidaysFromLocalStorage(): void {
    const savedHolidays = localStorage.getItem('holidays');
    this.holidays$.next(JSON.parse(savedHolidays));
  }

  private loadHolidaysIntervalYear(): void {
    const startYear = toISO(startOfYear(new Date()));
    const endYear = toISO(endOfYear(new Date()));
    this.getHolidays(startYear, endYear, 'Россия')
      .pipe(
        tap(({ body }) => {
          this.holidays$.next(body);
          this.cashHolidays(body);
        })
      )
      .subscribe();
  }

  private cashHolidays(holidays: Holiday[]): void {
    localStorage.setItem('holidays', JSON.stringify(holidays));
    localStorage.setItem('holidaysSaveDate', String(new Date().getTime()));
  }

  private getHolidays(startDate: string, endDate: string, country: string): Observable<ResponseHoliday> {
    const params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('country', country);
    return this.http.get<ResponseHoliday>('v1/holiday/list', {
      params
    });
  }
}
