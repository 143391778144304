import { IOption } from '@common/types';

export enum DirectoryTypesEnum {
  DOCUMENTS
}

export enum FileFormat {
  Any,
  Pdf,
  Zip,
  Doc,
  Xls
}

export const FileFormatOption: IOption[] = [
  { id: FileFormat.Any, name: 'Любой формат' },
  { id: FileFormat.Pdf, name: 'PDF' },
  { id: FileFormat.Zip, name: 'ZIP' },
  { id: FileFormat.Doc, name: 'doc / docx' },
  { id: FileFormat.Xls, name: 'xlsx' }
];

export const FileExtension = [
  { id: FileFormat.Any, extension: [] },
  { id: FileFormat.Pdf, extension: ['.pdf'] },
  { id: FileFormat.Zip, extension: ['.zip'] },
  { id: FileFormat.Doc, extension: ['.doc', '.docx'] },
  { id: FileFormat.Xls, extension: ['.xsl', '.xlsx'] }
];
