<com-status-point
  [green]="isGreen"
  [lightGreen]="isLightGreen"
  [red]="isRed"
  [gray]="!isGreen && !isRed && !isYellow"
  [yellow]="isYellow"
  [tooltip]="committeeEventStatus | committeeEventStatus : reason : customTooltip"
  [padding]="padding"
>
</com-status-point>
