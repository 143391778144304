<div class="flex gap-2">
  <mat-icon
    class="icon-size-6"
    [svgIcon]="icon"
    [ngClass]="iconClass"
  ></mat-icon>

  <div class="flex flex-col">
    <h2 *ngIf="title" class="font-semibold">{{title}}</h2>
    <p class="text-md" [innerHTML]="message"></p>
    </div>
</div>