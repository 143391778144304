<div
  class="rounded-[2px] cursor-pointer flex justify-center items-center text-white"
  [ngClass]="{
    invisible: !isSameYear,
    'border-[2px] border-diagram-today': isToday,
    'bg-green-300': type === CellType.FreeDay,
    'com-busy-gradient': type === CellType.Intersection,
    'bg-diagram-weekday': type === CellType.WeekDay,
    'bg-diagram-planning': type === CellType.Planning,
    'bg-diagram-transfer': type === CellType.Transfer,
    'bg-diagram-weekend': type === CellType.Weekend
  }"
  [style.min-height.px]="cellSize"
  [style.min-width.px]="cellSize"
  [style.max-width.px]="cellSize"
  [style.max-height.px]="cellSize"
  [matTooltip]="date | date: 'dd.MM.YYYY'"
>
  <span
    *ngIf="intersectionOrder"
    [ngClass]="{
      'text-[15px]': intersectionOrder <= 99,
      'text-[12px]': intersectionOrder > 99
    }"
  >
    <ng-container *ngIf="showOrder">
      {{ intersectionOrder }}
    </ng-container>
  </span>
</div>
