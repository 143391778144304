<div class="flex flex-col border border-planning-border px-2 rounded-lg">
  <header class="flex max-w-full min-w-full h-10 border-b border-planning-border">
    <div class="min-w-[20%] max-w-[20%] flex justify-start px-3 py-2 text-foreground font-medium">Дата</div>
    <div class="min-w-[20%] max-w-[20%] flex justify-start px-3 py-2 text-foreground font-medium">Время</div>
    <div class="min-w-[30%] max-w-[30%] flex justify-start px-3 py-2 text-foreground font-medium">
      Перенесенная дата
    </div>
    <div class="min-w-[30%] max-w-[30%] flex justify-start px-3 py-2 text-foreground font-medium">
      Перенесенное время
    </div>
  </header>
  <div
    class="flex flex-col px-1 mt-1 max-w-full min-w-full com-perfect-scrollbar max-h-[576px] min-h-[200px] overflow-y-scroll"
  >
    <div *ngIf="loading; else content" class="flex justify-center items-center w-full min-h-[190px] text-planning-gray gap-2">
      <mat-spinner [diameter]="24"></mat-spinner>
      Загружаем данные..
    </div>
    <ng-template #content>
      <ng-container *ngIf="events.length !== 0; else noIntersection">
        <div
          class="flex max-h-12 min-h-12 items-center justify-start px-3"
          *ngFor="let event of events; let i = index"
          (click)="selectDate.emit(event)"
          [ngClass]="{
            'bg-[#F8F8F8]': i % 2 === 0 && (!event.hasIntersection || event.transferedStart),
            'bg-[#FFE4E4]': event.hasIntersection && !event.transferedStart,
            'cursor-pointer': event.id
          }"
        >
          <div
            class="min-w-[20%] max-w-[20%] com-perfect-scrollbar text-foreground px-3 py-2"
            [ngClass]="{
              'line-through': event.transferedStart
            }"
          >
            {{ event.timeStart | date: 'dd MMMM yyyy, EEEE' }}
          </div>
          <div
            class="min-w-[20%] max-w-[20%] text-foreground px-3 py-2"
            [ngClass]="{
              'line-through': event.transferedStart
            }"
          >
            {{ event.timeStart | date: 'HH:mm' }} — {{ event.timeEnd | date: 'HH:mm' }}
          </div>
          <div class="min-w-[30%] max-w-[30%] text-foreground px-3 py-2">
            <ng-container *ngIf="event.transferedStart">
              {{ event.transferedStart | date: 'dd MMMM yyyy, EEEE' }}
            </ng-container>
          </div>
          <div class="min-w-[30%] max-w-[30%] text-foreground px-3 py-2">
            <ng-container *ngIf="event.transferedEnd">
              {{ event.transferedStart | date: 'HH:mm' }} — {{ event.transferedEnd | date: 'HH:mm' }}
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #noIntersection>
      <div class="flex justify-center items-center w-full min-h-[190px]">
        <div class="text-planning-gray">Пересечений не найдено</div>
      </div>
    </ng-template>
  </div>
</div>
