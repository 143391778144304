import { RoleTypesEnum } from '@common/enums';
import { IMember } from './calendar.types';
import { IEmployee } from './employee';
import { ICommitteeItem } from './form';
import { IPage } from '@common/types/page.model';

export interface IRole extends ICommitteeItem {
  accesses: number[];
  accessLabels?: string[];
  hasDeputies: boolean;
  order: number;
  required: boolean;
  requiredToIdealModel: boolean;
  hasVacancy: boolean;
  hasDublicate: boolean;
  blocked: boolean;
  type: RoleTypesEnum;
  typeLabel?: string;
  paramLabels?: string[];
}

export interface IRoleAccess {
  id: number;
  value: string;
  name: string;
  group: number;
  groupName: string;
  disabled: boolean;
}

export interface IMembersByRole {
  [roleId: string]: IMember[];
}

export interface IEmployeesByRole {
  [roleId: string]: IEmployee[];
}

export interface IRoleData extends IPage {
  data: IRole[];
}
