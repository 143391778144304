import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Timeline } from '@common/dialogs/intersection-dialog/types/timeline.types';

@Directive({
  selector: '[scaleCell]'
})
export class ScaleCellDirective implements OnChanges {
  @Input() cell: Timeline;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('cell' in changes) {
      if (this.cell) {
        const { color, value } = this.cell;
        if (!Number.isInteger(value)) {
          this.el.nativeElement.classList.add('border-r');
        }
        if (color) {
          this.el.nativeElement.classList.add(color);
          this.el.nativeElement.classList.add('text-white');
        }
      }
    }
  }
}
