import { ChangeDetectionStrategy, Component, Inject, OnInit, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { distinctUntilChanged, filter, finalize, take, takeUntil, tap } from 'rxjs';
import moment from 'moment';
import {
  CalendarEventService,
  CommitteeEventActionService,
  JitsuLoggerService,
  UnsubscribeService
} from '@common/services';
import { minDateTimeValidator } from '@common/utils/validators';
import {
  CommitteeEventActions,
  DATE_TIME_FORMAT,
  REASON_OTHER_OPTION,
  SUSPENDED_REASONS
} from '@common/constants';
import { ICalendarEvent, IChangeEventStatusParams } from '@common/types';
import { SnackbarComponent } from '@common/modules/shared';
import { CommitteeEventStatusEnum } from '@common/enums';
import { EventPlanningService } from '@common/dialogs/event-planning/event-planning.service';
import { EventPlanningClose } from '@common/dialogs/event-planning/types';

enum TypeView {
  INIT,
  CANCEL,
  FINISHED
}

@Component({
  selector: 'com-agreement-event',
  templateUrl: './agreement-event.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class AgreementEventComponent implements OnInit {
  public typeView: TypeView = TypeView.INIT;
  public TypeView = TypeView;
  public currentDate = moment().format(DATE_TIME_FORMAT);
  public calendarEvent: ICalendarEvent;
  public isProgress = false;
  public isLoading = false;
  public suspendedReason = SUSPENDED_REASONS;
  public showAltField = false;
  public formGroup: FormGroup = new FormGroup({
    eventDate: new FormControl<string>(this.currentDate, [
      Validators.required,
      minDateTimeValidator(this.currentDate)
    ])
  });
  public formGroupSuspended: FormGroup = new FormGroup({
    reason: new FormControl<string>(null, Validators.required),
    reasonAlt: new FormControl<string>(null)
  });

  constructor(
    private committeeEventActionService: CommitteeEventActionService,
    private calendarEventService: CalendarEventService,
    private dialogRef: MatDialogRef<AgreementEventComponent>,
    private snackBar: MatSnackBar,
    private jitsuLoggerService: JitsuLoggerService,
    private eventPlanningService: EventPlanningService,
    @Self() private unsubscribeService: UnsubscribeService,
    @Inject(MAT_DIALOG_DATA) public committeeEvent: ICalendarEvent
  ) {}

  public ngOnInit(): void {
    this.calendarEvent = {
      id: this.committeeEvent.id,
      eventId: this.committeeEvent.id,
      committeeId: this.committeeEvent.committeeId,
      name: this.committeeEvent.title ?? this.committeeEvent.name,
      cancel: this.committeeEvent.cancel,
      duration: this.committeeEvent.duration,
      title: this.committeeEvent.title,
      start: this.committeeEvent.start,
      end: this.committeeEvent.end,
      calendarIds: [],
      eventTime: this.committeeEvent.eventTime ?? this.committeeEvent.start,
      committeeEventStatus: this.committeeEvent.committeeEventStatus,
      quorum: { quorum: 0, presentCount: 0, totalCount: 0 },
      quorumOnlyThosePresent: { quorum: 0, presentCount: 0, totalCount: 0 },
      prevEventId: this.committeeEvent.prevEventId,
      prevEventTime: this.committeeEvent.prevEventTime
    };
    this.isProgress = this.calendarEvent.committeeEventStatus === CommitteeEventStatusEnum.IN_PROGRESS;
    this.formGroupSuspended
      .get('reason')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (value === REASON_OTHER_OPTION.id) {
          this.showAltField = true;
          this.formGroupSuspended.get('reasonAlt').addValidators([Validators.required]);
          this.formGroupSuspended.get('reason').clearValidators();
          this.formGroupSuspended.get('reason').updateValueAndValidity();
        } else {
          this.showAltField = false;
          this.formGroupSuspended.get('reasonAlt').reset();
          this.formGroupSuspended.get('reasonAlt').clearValidators();
          this.formGroupSuspended.get('reasonAlt').updateValueAndValidity();
          this.formGroupSuspended.get('reason').addValidators([Validators.required]);
        }
      });
  }

  public suspendEvent(): void {
    this.isLoading = true;
    if (!this.formGroupSuspended.valid) {
      this.isLoading = false;
      return;
    }
    const reason =
      this.formGroupSuspended.get('reasonAlt').value || this.formGroupSuspended.get('reason').value;
    this.jitsuLoggerService.logEvent(CommitteeEventActions.suspendedEvent, {
      eventId: this.committeeEvent.id
    });
    this.committeeEventActionService
      .suspendEvent(this.committeeEvent.id, reason, false)
      .pipe(
        take(1),
        takeUntil(this.unsubscribeService),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(() => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000,
          data: {
            icon: 'heroicons_outline:exclamation',
            iconClass: 'text-red-500',
            title: 'Комитет признан не состоявшимся'
          },
          panelClass: 'committees-app'
        });
        this.drawerClose({
          committeeEventStatus: CommitteeEventStatusEnum.SUSPENDED,
          newTime: null,
          event: null,
          suspendedReason: reason
        });
      });
  }

  public changeView(typeView: TypeView): void {
    this.typeView = typeView;
  }

  public onTransfer(): void {
    this.eventPlanningService
      .openTransfer(this.committeeEvent.id)
      .pipe(
        filter(Boolean),
        tap((data) => this.transferComplete(data)),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  public transferComplete(data: EventPlanningClose): void {
    this.drawerClose({
      committeeEventStatus: CommitteeEventStatusEnum.PLANNED,
      newTime: data.eventTime,
      event: null,
      suspendedReason: data.reason
    });
  }

  public finished(): void {
    this.isLoading = true;
    this.jitsuLoggerService.logEvent(CommitteeEventActions.finishedEvent, {
      eventId: this.committeeEvent.id
    });
    this.calendarEventService
      .finishEventCall(this.calendarEvent.id, false)
      .pipe()
      .subscribe(() => {
        this.isLoading = false;
        this.drawerClose({
          committeeEventStatus: CommitteeEventStatusEnum.FINISHED,
          newTime: null,
          event: null,
          suspendedReason: this.calendarEvent.suspendedReason
        });
      });
  }

  public drawerClose(data: IChangeEventStatusParams | null): void {
    this.dialogRef.close(data);
  }
}
