import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertDuration' })
export class ConvertDurationPipe implements PipeTransform {
  transform(valueInMinutes: number): string {
    if (valueInMinutes < 60) return `${valueInMinutes} мин`;

    const milliseconds = valueInMinutes * 60000;

    const seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    minutes = minutes % 60;
    hours = hours % 24;
    if (!minutes) return `${hours} ч.`;

    return `${hours} ч. и ${minutes} мин`;
  }
}
