import { Pipe, PipeTransform } from '@angular/core';
import { IMember } from '@common/types/calendar.types';
import { CommitteeRolesEnum } from '@common/enums';

@Pipe({ name: 'fullNameByRole' })
export class FullNameByRolePipe implements PipeTransform {
  transform(members: IMember[], role: CommitteeRolesEnum): string {
    return (
      members.find((member) => member.committeeMember.role?.name === role)?.committeeMember.employee
        ?.fullName || '-'
    );
  }
}
