import {
  CalendarGroupsEnum,
  CommitteeRolesEnum,
  EventTypesEnum,
  MemberTypeTitlesEnum
} from '@common/enums';
import { ICalendar } from '@common/types';
import { EVENT_COLORS } from '@common/constants';

export const calendars: ICalendar[] = [
  {
    id: EventTypesEnum.COMMITTEE,
    title: 'Комитеты',
    color: EVENT_COLORS[0],
    group: CalendarGroupsEnum.EVENT,
    visible: true
  },
  {
    id: EventTypesEnum.CHAIRMAN,
    title: CommitteeRolesEnum.CHAIRMAN,
    color: EVENT_COLORS[2],
    group: CalendarGroupsEnum.ROLE,
    visible: false
  },
  {
    id: EventTypesEnum.COCHAIR,
    title: CommitteeRolesEnum.COCHAIR,
    color: EVENT_COLORS[3],
    group: CalendarGroupsEnum.ROLE,
    visible: false
  },
  {
    id: EventTypesEnum.CHAIRMAN_ASSISTANT,
    title: CommitteeRolesEnum.CHAIRMAN_ASSISTANT,
    color: EVENT_COLORS[4],
    group: CalendarGroupsEnum.ROLE,
    visible: false
  },
  {
    id: EventTypesEnum.MANDATORY,
    title: MemberTypeTitlesEnum.MANDATORY_SINGULAR,
    color: EVENT_COLORS[5],
    group: CalendarGroupsEnum.ROLE,
    visible: false
  }
];
export const events = [];
export const exceptions = [];
export const settings = {
  dateFormat: 'll', // Aug 20, 2019
  timeFormat: '24', // 24-hour format
  startWeekOn: 1 // Monday
};
export const weekdays = [
  {
    abbr: 'M',
    label: 'Понедельник',
    value: 'MO'
  },
  {
    abbr: 'T',
    label: 'Вторник',
    value: 'TU'
  },
  {
    abbr: 'W',
    label: 'Среды',
    value: 'WE'
  },
  {
    abbr: 'T',
    label: 'Четверг',
    value: 'TH'
  },
  {
    abbr: 'F',
    label: 'Пятница',
    value: 'FR'
  },
  {
    abbr: 'S',
    label: 'Суббота',
    value: 'SA'
  },
  {
    abbr: 'S',
    label: 'Воскресенье',
    value: 'SU'
  }
];
