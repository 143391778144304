import { Component, Input, OnChanges } from '@angular/core';
import { StatusPointColors } from '@common/enums';

@Component({
  selector: 'com-status-point',
  templateUrl: './status-point.component.html'
})
export class StatusPointComponent implements OnChanges {
  @Input() green: boolean;
  @Input() lightGreen: boolean;
  @Input() red: boolean;
  @Input() yellow: boolean;
  @Input() gray: boolean;
  @Input() tooltip: string;
  @Input() padding: string;

  public bgColor: StatusPointColors = StatusPointColors.Gray;

  ngOnChanges(): void {
    if (this.green) {
      this.bgColor = StatusPointColors.Green;
    } else if (this.lightGreen) {
      this.bgColor = StatusPointColors.LightGreen;
    } else if (this.red) {
      this.bgColor = StatusPointColors.Red;
    } else if (this.yellow) {
      this.bgColor = StatusPointColors.Yellow;
    } else {
      this.bgColor = StatusPointColors.Gray;
    }
  }
}
