import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IData, ITaskProjectStatus } from '@common/types';

@Injectable({
  providedIn: 'root'
})
export class TaskManagerService {
  constructor(private readonly http: HttpClient) {}

  public retrieveProjectStatuses(committeeId: string): Observable<IData<ITaskProjectStatus>> {
    const params = new HttpParams({
      fromObject: {
        project_id: [committeeId]
      }
    });
    return this.http.get<IData<ITaskProjectStatus>>('taskManagerApi/project-status', { params });
  }
}
