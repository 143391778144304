import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '@common/pipes';
import { MaterialModule } from '@common/shared';
import { ToggleComponent } from '@common/shared/components/new-form-elements/toggle/toggle.component';
import { AutoSuggestionComponent } from '@common/shared/components/new-form-elements/auto-suggestion/auto-suggestion.component';
import { SelectWithSearchComponent } from '@common/shared/components/new-form-elements/select-with-search/select-with-search.component';
import { AutoMultiselectComponent } from '@common/shared/components/new-form-elements/form-auto-multiselect/form-auto-multiselect';

const COMPONENTS = [
  AutoSuggestionComponent,
  ToggleComponent,
  SelectWithSearchComponent,
  AutoMultiselectComponent
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedPipesModule, MaterialModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class NewFormElementsModule {}
