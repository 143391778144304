<div class="flex justify-between w-120 h-min-10">
  <span>{{ member?.committeeMember?.employee?.fullName }}</span>
  <div class="ml-8">
    <button
      *ngIf="isDelegationAvailable"
      class="cursor-pointer flex items-center"
      (click)="delegateTo.emit()"
    >
      <mat-icon
        class="icon-size-4 mr-1"
        svgIcon="heroicons_solid:plus-circle"
      ></mat-icon>
      <span>Доверенность</span>
    </button>
    <div *ngIf="isTrustedUserVisible" class="flex items-center">
      <mat-icon
        class="icon-size-4 mr-1"
        svgIcon="heroicons_solid:arrow-right"
      ></mat-icon>
      <span class="text-blue-600">{{ trustedUser }}</span>
      <mat-icon
        class="icon-size-4 ml-4"
        svgIcon="common_icons:delegation"
      ></mat-icon>
      <mat-icon
        class="icon-size-4 ml-2 cursor-pointer opacity-70"
        svgIcon="heroicons_solid:pencil"
        (click)="delegateEdit.emit()"
      ></mat-icon>
      <mat-icon
        class="icon-size-5 ml-1 cursor-pointer opacity-70"
        svgIcon="heroicons_solid:x"
        (click)="deleteDelegation.emit()"
      ></mat-icon>
    </div>
  </div>
</div>
