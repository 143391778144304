import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { IOption } from '@common/types';

const PRESCRIPTIONS_MOCK: IOption[] = [
  { id: '1', name: 'Начислить санкцию' },
  { id: '2', name: 'Предписание 2' },
  { id: '3', name: 'Предписание 3' }
];

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {
  private readonly prescriptions = new ReplaySubject<IOption[]>(1);

  constructor() {
    this.loadPrescriptions();
  }

  get prescriptions$(): Observable<IOption[]> {
    return this.prescriptions.asObservable();
  }

  public loadPrescriptions(): void {
    this._retrievePrescriptions().subscribe((prescriptions) => {
      this.prescriptions.next(prescriptions);
    });
  }

  private _retrievePrescriptions(): Observable<IOption[]> {
    return of(PRESCRIPTIONS_MOCK);
  }
}
