import { Pipe, PipeTransform } from '@angular/core';
import { ICalendarEvent } from '@common/types/calendar.types';

@Pipe({ name: 'determineStatus' })
export class DetermineStatusPipe implements PipeTransform {
  transform(event: ICalendarEvent): string {
    const different = new Date(event.eventTime).getTime() - Date.now();
    if (different > 0) return 'Не проводился';
    const milliseconds = event.duration * 60000;
    const untilDate = milliseconds + new Date(event.eventTime).getTime();
    if (untilDate - Date.now() > 0) return 'Проводится';
    return 'Проведен';
  }
}
