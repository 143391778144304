import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { defer, iif, map, Observable, of, switchMap } from 'rxjs';
import { FuseConfirmationService } from '@common/fuse/services/confirmation';
import { CalendarEventService } from '@common/services';
import { FuseDialogActionsEnum } from '@common/enums';

@Injectable({
  providedIn: 'root'
})
export class CheckOutGuard implements CanDeactivate<any> {
  constructor(
    protected readonly router: Router,
    private readonly _matDialog: FuseConfirmationService,
    private readonly _calendarEventService: CalendarEventService
  ) {}

  canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const eventId = route.params.eventId;
    return iif(
      () => this.router.getCurrentNavigation().extras.state?.force,
      of(false),
      defer(() =>
        this._matDialog
          .open({
            title: 'Отключение от видеоконференции',
            message: 'Вы действительно собираетесь покинуть видеоконференцию?',
            actions: {
              confirm: {
                show: true,
                label: 'Да',
                color: 'warn'
              },
              cancel: {
                show: true,
                label: 'Нет'
              }
            }
          })
          .afterClosed()
      )
    ).pipe(
      switchMap((res) => {
        if (res === FuseDialogActionsEnum.CONFIRMED) {
          return this._calendarEventService
            .checkOutEvent(eventId)
            .pipe(map(() => true));
        } else if (res === FuseDialogActionsEnum.CANCELLED) {
          return of(false);
        }
        return of(true);
      })
    );
  }
}
