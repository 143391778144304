import { Injectable } from '@angular/core';
import { delay, Observable, of } from 'rxjs';
import moment from 'moment';
import { IData } from '@common/types';

@Injectable({
  providedIn: 'root'
})
export class ViolationService {
  constructor() {}

  // TODO: replace the mock with actual data
  retrieveViolations(): Observable<IData> {
    return of({
      data: Array.from({ length: 9 }, () => ({
        type: 'Неявка на комитет',
        violationDate: moment('2022-10-29T15:09:00').format('DD.MM.YYYY HH:mm'),
        violationResponsible: { id: '', fullName: 'Иванов Иван И.' },
        prescriptionId: '1',
        appointmentResponsible: {
          id: 'e760ef24-a50f-4b0f-8f87-791606fb67de',
          fullName: 'Рыжков Руслан Г.'
        },
        planDate: moment()
      })),
      pagination: {
        length: 9,
        pageIndex: 0,
        pageSize: 10
      }
    }).pipe(delay(1000));
  }
}
