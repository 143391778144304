<ng-container *ngIf="user">
  <div class="flex justify-center items-center w-10 h-10">
    <com-avatar
      fontSize="sm"
      [sizePx]="28"
      [isLink]="true"
      [name]="user.name"
      [showStatus]="true"
      [matMenuTriggerFor]="userActions"
    ></com-avatar>
  </div>
  <mat-menu xPosition="before" #userActions="matMenu">
    <button mat-menu-item [routerLink]="['/profile']">
      <span class="flex flex-col leading-none">
        <span class="font-medium">
          {{ user.name }}
        </span>
        <span class="mt-1.5 text-md text-secondary">Открыть профиль</span>
      </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="logout()">
      <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
      <span>Выйти</span>
    </button>
  </mat-menu>
</ng-container>
