import { IEnvironment } from '@common/types/environment';

export const environment: IEnvironment = {
  production: true,
  keycloak: {
    url: 'https://sso.efko.ru/auth/',
    realm: 'ucp',
    clientId: 'base'
  },
  api: 'https://api.committees.efko.ru',
  calendarApi: 'https://api.calendar.efko.ru',
  constructorApi: 'https://forms.efko.ru',
  taskManagerApi: 'https://api.track.efko.ru',
  wsHost: 'wss://centrifugo.efko.ru',
  jitsuLogging: {
    hostUrl: 'https://jitsu.efko.ru',
    apiKey: 'zBuOZDPIbSnw02MnJUKoF2Myt3QT9myr:CaRdqVOU9b4YkWtP7ciE154shIpw4Rq1'
  },
  jitsiUrl: 'meet.efko.ru'
};
