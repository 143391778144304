import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from '@common/dialogs/planning-dialog/types';

@Component({
  selector: 'com-tab-buttons',
  templateUrl: 'tab-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabButtonsComponent<T> {
  @Input() options: Option<T>[];
  @Input() currentTab: T;
  @Output() selectTab = new EventEmitter<T>();
}
