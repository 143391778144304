export const ARRAY_LAST = -1;
export const MIN_INTERVAL = 0;
export const QUORUM_BOUNDARY_COUNT = 70;
export const VOTE_UPDATE_INTERVAL = 10000;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 31;
export const MONTHS_IN_HALF_A_YEAR = 6;
export const MIN_MONTH_DAY = 1;
export const MAX_MONTH_DAY = 31;
export const MIN_COMMITTEE_INTERVAL = 1;
export const MIN_COMMITTEE_DURATION = 1;
export const MAX_COMMITTEE_DURATION = 180;
export const JOIN_COMMITTEE_BEFORE = 15;
export const SCROLL_STEP = 16;
export const DRAG_PLACEHOLDER_OFFSET = 0; // 15;
export const EXTRA_TIME_FOR_ENDING_CALL = 60;
export const EXTRA_TIME_FOR_ADDING_DELEGATE = 10;
export const MIN_DAYS = 1;
export const MIN_MINUTES = 1;
export const DEFAULT_DAYS = 1;
export const DEFAULT_MINUTES = 60;
export const BUTTON_SPINNER_DIAMETER = 24;
export const MIN_REPEAT_EVERY = 2;
export const MAX_ROW_HEIGHT = 96;
