import Quill from 'quill';
import { EDITABLE_BLOT_NAME } from '@common/constants';

export class EditableBlot extends Quill.import('parchment').Embed {
  static blotName = EDITABLE_BLOT_NAME;
  static tagName = 'span';
  static className = 'ql-editable-blot';

  static create(type: string) {
    const node = super.create(type);
    node.innerText = type;
    node.setAttribute('type', type);
    node.contentEditable = false;
    return node;
  }

  static value(node: Node) {
    return node.childNodes[0].textContent;
  }
}
