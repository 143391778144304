export const EVENT_COLORS = [
  '#0693E3',
  '#00D084',
  '#EB144C',
  '#FF6900',
  '#FCB900',
  '#F78DA7',
  '#9900EF'
];

export const DISABLED_EVENT_COLOR = '#ABB8C3';
