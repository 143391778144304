import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from '@common/shared/components/avatar/avatar.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BusyTimelineComponent } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/busy-timeline.component';
import { CellDirective } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/directives';
import {
  GetBusyDateRangePipe,
  GetIntersectionEventsPipe
} from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/pipes';
import { MatMenuModule } from '@angular/material/menu';
import { IntersectionDetailComponent } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/components/intersection-detail/intersection-detail.component';
import { IntersectionItemComponent } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/components/intersection-item/intersection-item.component';

@NgModule({
  declarations: [
    BusyTimelineComponent,
    CellDirective,
    GetBusyDateRangePipe,
    GetIntersectionEventsPipe,
    IntersectionItemComponent,
    IntersectionDetailComponent
  ],
  exports: [BusyTimelineComponent],
  imports: [
    CommonModule,
    MatIconModule,
    AvatarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule
  ]
})
export class BusyTimelineModule {}
