import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WEEK_IN_YEAR_ARRAY } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';

@Component({
  selector: 'com-planning-diagram-axios-x',
  templateUrl: 'planning-diagram-axios-x.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningDiagramAxiosXComponent {
  @Input() cellSize: number;
  protected WeekInYearArray = WEEK_IN_YEAR_ARRAY;
}
