import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IReasonForDelegation } from '@common/types/calendar.types';

@Injectable({
  providedIn: 'root'
})
export class DelegationReasonService {
  constructor(private readonly _http: HttpClient) {}

  getDelegationReasons(): Observable<IReasonForDelegation[]> {
    return this._http.get<IReasonForDelegation[]>('api/DelegationReason');
  }
}
