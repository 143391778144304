import { Pipe, PipeTransform } from '@angular/core';
import { IOption } from '@common/types';

@Pipe({
  name: 'optionName'
})
export class OptionNamePipe implements PipeTransform {
  transform(options: IOption[], id: string | string[]): string {
    if (Array.isArray(id)) {
      const res = options.filter((el: any) => id.includes(el.id));
      return res?.map(({ name }) => name).join(', ') || '-';
    }
    const option = options.find((item) => item.id === id);
    return option?.name || '';
  }
}
