import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDirectory } from '@common/types/directory';
import { FileFormat, FileFormatOption } from '@common/enums/directory.enum';
import { IOption } from '@common/types';

type DocumentDialogForm = FormGroup<{
  value: FormControl<string>;
  description: FormControl<string>;
  fileFormat: FormControl<number>;
  multipleValues: FormControl<boolean>;
}>;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './document-dialog.component.html'
})
export class DocumentDialogComponent {
  public formGroup: DocumentDialogForm;
  protected readonly FileFormat: IOption[] = FileFormatOption;

  constructor(
    public matDialogRef: MatDialogRef<DocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public directory: IDirectory
  ) {
    this.createForm();
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      value: new FormControl(this.directory?.value, [Validators.required]),
      description: new FormControl(this.directory?.description, [Validators.required]),
      fileFormat: new FormControl<number>(this.directory?.fileFormat ?? 0, [Validators.required]),
      multipleValues: new FormControl<boolean>(this.directory?.multipleValues ?? false, [Validators.required])
    });
  }

  public confirm(): void {
    const { value, valid } = this.formGroup;
    if (valid) {
      this.matDialogRef.close({
        ...this.directory,
        ...value
      });
    }
  }
}
