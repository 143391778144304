import moment, { Moment } from 'moment';
import {
  areIntervalsOverlapping,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  Interval,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setYear
} from 'date-fns';

export const setTimeZone = (dateString: string | Date): Date =>
  moment.utc(dateString).tz(moment.tz.guess()).toDate();

export const isDayOfDatesEqual = (date: Date | string, compareDate: Date | string): boolean =>
  moment(date).format('dd-MM-yyyy') === moment(compareDate).format('dd-MM-yyyy');

export const getStartOfDay = (date: Date | string): string => toISO(moment(date).startOf('day'));

export const getEndOfDay = (date: Date | string): string => toISO(moment(date).endOf('day'));

export const toISO = (date: Date | string | Moment, keepOffset = false): string =>
  moment(date).toISOString(keepOffset);

export const isOverlap = (
  compareStartDate: string | Date,
  compareEndDate: string | Date,
  startDate: string | Date,
  endDate: string | Date,
  inclusive = false
): boolean => {
  compareStartDate = new Date(compareStartDate);
  compareEndDate = new Date(compareEndDate);
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  const interval1: Interval = {
    start: compareStartDate,
    end: compareEndDate
  };
  const interval2: Interval = {
    start: startDate,
    end: endDate
  };

  return areIntervalsOverlapping(interval1, interval2, { inclusive });
};

export const addTimeToDate = (date: Date, time: string | Date): Date => {
  time = new Date(time);
  date = setHours(date, getHours(time as Date));
  date = setMinutes(date, getMinutes(time as Date));
  return date;
};

export const changeDateButSaveTime = (date: Date | string, changedDate: Date | string): Date => {
  changedDate = new Date(changedDate);
  date = new Date(date);
  return setYear(setMonth(setDate(changedDate, getDate(date)), getMonth(date)), getYear(date));
};
