<div class="relative flex flex-col w-full h-full max-h-128">
  <button class="absolute top-0 right-0" mat-dialog-close="">
    <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
  </button>

  <div class="text-2xl font-semibold tracking-tight text-center">
    {{ directory ? 'Редактировать' : 'Добавить' }} документ
  </div>

  <div class="flex flex-col gap-4 grow">
    <form class="flex flex-col gap-4" [formGroup]="formGroup">
      <div>
        <div>
          <com-form-label text="Тип" type="block" [required]="true"></com-form-label>
          <com-form-input [control]="formGroup.controls.value"></com-form-input>
        </div>
        <div>
          <com-form-label text="Описание" type="block" [required]="true"></com-form-label>
          <com-form-input [control]="formGroup.controls.description" [isMultiline]="true"></com-form-input>
        </div>
        <div>
          <com-form-label></com-form-label>
          <com-form-basic-select
            [control]="formGroup.controls.fileFormat"
            [options]="FileFormat"
          ></com-form-basic-select>
        </div>
      </div>
      <div>
        <com-form-label></com-form-label>
        <com-form-checkbox
          [control]="formGroup.controls.multipleValues"
          label="Загрузка нескольких файлов с сохранением версий"
        ></com-form-checkbox>
      </div>
      <div class="flex items-center justify-center sm:justify-end gap-4">
        <button mat-stroked-button mat-dialog-close="">Отмена</button>
        <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="confirm()">
          Подтвердить
        </button>
      </div>
    </form>
  </div>
</div>
