import { UnitDeclinationEnum } from '@common/enums';

export const UNIT_DECLINATIONS = {
  [UnitDeclinationEnum.MINUTE]: { 1: 'минута', 2: 'минуты', 5: 'минут' },
  [UnitDeclinationEnum.DAY]: { 1: 'день', 2: 'дня', 5: 'дней' },
  [UnitDeclinationEnum.WEEK]: { 1: 'неделю', 2: 'недели', 5: 'недель' },
  [UnitDeclinationEnum.MONTH]: { 1: 'месяц', 2: 'месяца', 5: 'месяцев' },
  [UnitDeclinationEnum.YEAR]: { 1: 'год', 2: 'года', 5: 'лет' },
  [UnitDeclinationEnum.COMMITTEE]: {
    1: 'комитет',
    2: 'комитета',
    5: 'комитетов'
  }
};
