import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommitteeService } from '@common/services';

@Injectable({
  providedIn: 'root'
})
export class CanCreateGuard implements CanActivate {
  constructor(
    private readonly committeeService: CommitteeService,
    protected readonly router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.committeeService.canCreateCommittee();
  }
}
