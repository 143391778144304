import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PlanningDiagramComponent } from '@common/dialogs/planning-dialog/modules/planning-diagram/planning-diagram.component';
import { PlanningDiagramCellComponent } from '@common/dialogs/planning-dialog/modules/planning-diagram/components/planning-diagram-cell/planning-diagram-cell.component';
import { PlanningDiagramHeaderComponent } from '@common/dialogs/planning-dialog/modules/planning-diagram/components/planning-diagram-header/planning-diagram-header.component';
import { PlanningDiagramLegendComponent } from '@common/dialogs/planning-dialog/modules/planning-diagram/components/planning-diagram-legend/planning-diagram-legend.component';
import { PlanningDiagramAxiosXComponent } from '@common/dialogs/planning-dialog/modules/planning-diagram/components/planning-diagram-axios-x/planning-diagram-axios-x.component';
import { PlanningDiagramAxiosYComponent } from '@common/dialogs/planning-dialog/modules/planning-diagram/components/planning-diagram-axios-y/planning-diagram-axios-y.component';
import { PlanningDiagramBlockComponent } from '@common/dialogs/planning-dialog/modules/planning-diagram/components/planning-diagram-block/planning-diagram-block.component';
import { PlanningDiagramActionsComponent } from '@common/dialogs/planning-dialog/modules/planning-diagram/components/planning-diagram-actions/planning-diagram-actions.component';
import { TabButtonsModule } from '@common/dialogs/planning-dialog/ui/tab-buttons/tab-buttons.module';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { MAT_TOOLTIP_OPTIONS } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';

@NgModule({
  declarations: [
    PlanningDiagramComponent,
    PlanningDiagramCellComponent,
    PlanningDiagramAxiosXComponent,
    PlanningDiagramHeaderComponent,
    PlanningDiagramLegendComponent,
    PlanningDiagramAxiosYComponent,
    PlanningDiagramBlockComponent,
    PlanningDiagramActionsComponent
  ],
  exports: [PlanningDiagramComponent],
  imports: [CommonModule, TabButtonsModule, MatTooltipModule],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: MAT_TOOLTIP_OPTIONS }]
})
export class PlanningDiagramModule {}
