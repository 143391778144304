import { TableActionsEnum, TableFieldTypeEnum, TableActionButtonTypeEnum } from '@common/shared';

export interface ITableColumn {
  title?: string;
  titleDescription?: string;
  field: string;
  fieldType?: TableFieldTypeEnum;
  isSticky?: boolean;
  format?: string;
  editable?: boolean;
  showImage?: boolean;
  showStatus?: boolean;
  showRole?: boolean;
  showPosition?: boolean;
  columnClass?: string;
  matBadge?: string;
  matBadgeColor?: string;
  icons?: {
    field?: string;
    activeClass?: string;
    icon: string;
    iconAlt?: string;
    iconClass?: string;
    iconAltClass?: string;
    title?: string;
    titleAlt?: string;
  };
  buttonTooltip?: string;
  buttonIcon?: string;
  buttonVisibility?: boolean;
  buttonAction?: string;
  buttons?: ITableIconButton[];
  fieldShowSubTitle?: string;
  list?: ITableIcons[];
  optionsField?: string;
  altText?: string;
  hiddenEdit?: boolean;
  isHistoryArray?: boolean;
  linkField?: string;
}

export interface ITableIcons {
  value?: string | number | boolean;
  icon: string;
  title: string;
  class?: string;
  hasDelegation?: boolean;
  subTitle?: string;
}

export interface ITableIconButton {
  action: TableActionsEnum;
  icon?: string;
  matIcon?: string;
  iconAlt?: string;
  title: string;
  titleAlt?: string;
  class?: string;
  classAlt?: string;
  type?: TableActionButtonTypeEnum;
}

export interface ITableChange {
  action: TableActionsEnum;
  payload: any;
}
