export const TOOLBAR_BUTTONS: string[] = [
  'camera',
  'chat',
  'closedcaptions',
  'desktop',
  'download',
  'filmstrip',
  'fullscreen',
  'highlight',
  'linktosalesforce',
  'livestreaming',
  'microphone',
  'noisesuppression',
  'participants-pane',
  'raisehand',
  'recording',
  'select-background',
  'settings',
  'shareaudio',
  'sharedvideo',
  'stats',
  'tileview',
  'toggle-camera'
];
