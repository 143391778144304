import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAdditionalDocument, IAttachmentDto } from '@common/types';
import { AttachmentTypeEnum } from '@common/enums';

@Injectable({
  providedIn: 'root'
})
export class CommitteeDocumentService {
  constructor(private readonly http: HttpClient) {}

  public createDocument(
    committeeId: string,
    typeId: string,
    file: File
  ): Observable<void> {
    const formData = new FormData();
    formData.append('committeeId', committeeId);
    formData.append('typeId', typeId);
    formData.append('file', file);
    return this.http.post<void>('api/CommitteeDocument', formData);
  }

  public retrieveDocuments(
    committeeId: string,
    type?: string,
    name?: string,
    startDate?: string,
    endDate?: string
  ): Observable<IAdditionalDocument[]> {
    const params = new HttpParams({
      fromObject: {
        committeeId,
        ...(type ? { types: [type] } : {}),
        ...(name ? { name } : {}),
        ...(startDate ? { startDate } : {}),
        ...(endDate ? { endDate } : {})
      }
    });
    return this.http.get<IAdditionalDocument[]>('api/CommitteeDocument', {
      params
    });
  }

  public updateDocument(
    id: string,
    committeeId: string,
    typeId: string,
    file: File
  ): Observable<void> {
    const formData = new FormData();
    formData.append('committeeId', committeeId);
    formData.append('typeId', typeId);
    formData.append('file', file);
    return this.http.put<void>(`api/CommitteeDocument/${id}`, formData);
  }
}
