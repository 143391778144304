import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  asyncScheduler,
  BehaviorSubject,
  debounceTime,
  filter,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import moment from 'moment/moment';
import { EmployeeService, UnsubscribeService } from '@common/services';
import {
  GeneralFormValue,
  IEmployee,
  IEmployeeOption,
  IIdealFormGroup,
  IIdealModel,
  IMemberDto,
  IOption,
  IRole,
  IRrule,
  ProtocolFormValue,
  RRuleModel,
  DaysOffFormValue
} from '@common/types';
import { ModelsEnum } from '@common/enums';
import { DATE_FORMAT } from '@common/constants';
import { IMemberFormValue } from '@common/shared/components/form-groups/members/members.types';
import { employeeOptionMapper } from '@common/modules/committees/committees-form/utils/adaptors';
import { FormAbstractionComponent } from '@common/shared/components/form-abstraction/form-abstraction.component';
import { dateValidator, minDateTimeValidator } from '@common/utils/validators';

export interface IdealFormValue {
  id: string;
  deadlineForUploadingDocument: string;
  responsibleForUploadingDocument: IEmployeeOption;
  rrule: RRuleModel;
  members: IMemberFormValue[];
  general: GeneralFormValue;
  protocol: ProtocolFormValue;
}

@Component({
  selector: 'com-ideal',
  templateUrl: './ideal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class IdealComponent extends FormAbstractionComponent implements OnInit, AfterViewInit {
  @Output() addShownRole = new EventEmitter<IRole>();
  @Output() removeShownRole = new EventEmitter<IRole>();
  @Output() protocolOptionsChange = new EventEmitter<IOption[]>();
  @Output() getFormValue = new EventEmitter<() => IdealFormValue>();

  @Input() committeeTypes: IOption[] = [];
  @Input() committeeSubTypes: IOption[] = [];
  @Input() committeeKinds: IOption[] = [];
  @Input() members: IMemberDto[] = [];
  @Input() model: IIdealModel;
  @Input() protocolOptions: IOption[] = [];
  @Input() isEdit = false;
  @Input() shownRoles: IRole[];
  @Input() hiddenRoles: IRole[];

  public ModelsEnum = ModelsEnum;
  public currentDate: string = moment().format(DATE_FORMAT);
  public formGroup: FormGroup<IIdealFormGroup>;
  public responsibleForUploadingDocument: IEmployeeOption;
  public isGeneralFormValid: () => boolean;
  public isMembersFormValid: () => boolean;
  public isProtocolFormValid: () => boolean;
  public isRruleFormValid: () => boolean;

  public getRruleFormValue: () => IRrule;
  public membersForm: IMemberFormValue[] = [];
  public generalForm: GeneralFormValue;
  public protocolForm: ProtocolFormValue;

  public selectorSearch$ = new Subject<string>();
  public employeeOptions$ = new BehaviorSubject<IEmployeeOption[]>([]);
  public updateGeneralForm$: Subject<GeneralFormValue> = new Subject<GeneralFormValue>();
  public updateProtocolForm$: Subject<ProtocolFormValue> = new Subject<ProtocolFormValue>();
  public updateRruleForm$: Subject<IRrule> = new Subject<IRrule>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _employeeService: EmployeeService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {
    super();
    this._createForm();
    this._searchSelectorChangeSub();
  }

  ngOnInit(): void {
    if (this.model) {
      this._initPreOption(this.model.responsibleForUploadingDocument);
      this._updateForms(this.model);
      this._getCurrentDate();
    }
    this.emitFormMethods();
    this.getFormValue.emit(this._idealFormMapper.bind(this));
  }

  ngAfterViewInit(): void {
    this.setValidMethodCheck(
      this.isGeneralFormValid,
      this.isMembersFormValid,
      this.isProtocolFormValid,
      this.isRruleFormValid
    );
  }

  private _updateForms(model: IIdealModel): void {
    const { duration, rrule, protocolAccess, formationPeriod } = model;
    this._updateMainForm(model);
    asyncScheduler.schedule(() => {
      this._updateGeneralForm(model);
      this._updateProtocolForm(protocolAccess, formationPeriod);
      this.updateRruleForm$.next({
        duration,
        ...rrule
      });
    });
  }

  private _updateProtocolForm(protocolAccess: IEmployee, formationPeriod: number): void {
    this.updateProtocolForm$.next({
      formationPeriod,
      protocolAccessEmployee: {
        id: protocolAccess.id,
        name: protocolAccess.fullName
      }
    });
  }

  private _updateGeneralForm({ name, committeeKind, committeeSubType }: IIdealModel): void {
    this.updateGeneralForm$.next({
      name,
      committeeKindId: committeeKind?.id,
      committeeSubTypeId: committeeSubType?.id
    });
  }

  private _initPreOption(value: IEmployee): void {
    this.responsibleForUploadingDocument = employeeOptionMapper(value);
  }

  private _createForm(): void {
    this.formGroup = this.formBuilder.group({
      id: [''],
      responsibleForUploadingDocument: [null as IEmployeeOption],
      deadlineForUploadingDocument: ['', [minDateTimeValidator(moment().format(DATE_FORMAT))]]
    });
  }

  private _updateMainForm(value: IIdealModel): void {
    this.formGroup.patchValue({
      id: value.id,
      responsibleForUploadingDocument: this.responsibleForUploadingDocument,
      deadlineForUploadingDocument:
        value.deadlineForUploadingDocument && moment(value.deadlineForUploadingDocument).format(DATE_FORMAT)
    });
  }

  private _searchSelectorChangeSub(): void {
    this.selectorSearch$
      .pipe(
        debounceTime(50),
        filter((query) => typeof query === 'string'),
        switchMap((query) =>
          this._employeeService
            .retrieveEmployeeSearchForSelect(query || '')
            .pipe(tap((employees) => this.employeeOptions$.next(employees.map(employeeOptionMapper))))
        ),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _idealFormMapper(): IdealFormValue {
    const { id, responsibleForUploadingDocument, deadlineForUploadingDocument } =
      this.formGroup.getRawValue();
    const rrule = this.getRruleFormValue();
    return {
      id,
      responsibleForUploadingDocument,
      deadlineForUploadingDocument,
      rrule: rrule.dtstarttime ? RRuleModel.omit(rrule) : rrule,
      members: this.membersForm,
      general: this.generalForm,
      protocol: this.protocolForm
    };
  }

  private _getCurrentDate(): void {
    if (
      this.model.deadlineForUploadingDocument &&
      moment(this.model.deadlineForUploadingDocument).isBefore(this.currentDate)
    ) {
      this.currentDate = moment(this.model.deadlineForUploadingDocument).format(DATE_FORMAT);
      this.formGroup
        .get('deadlineForUploadingDocument')
        .setValidators([Validators.required, dateValidator({ min: this.currentDate })]);

      this.formGroup
        .get('deadlineForUploadingDocument')
        .valueChanges.pipe(takeUntil(this._unsubscribeService))
        .subscribe(() => {
          this._checkDeadlineForUploadingDocument();
        });
    }
  }

  private _checkDeadlineForUploadingDocument(): void {
    const currentDate = moment().format(DATE_FORMAT);
    const { deadlineForUploadingDocument } = this.formGroup.controls;
    const date = deadlineForUploadingDocument.value;

    if (date && moment(date).isBefore(currentDate)) {
      this.currentDate = currentDate;
      deadlineForUploadingDocument.setValidators([Validators.required, dateValidator({ min: currentDate })]);
      deadlineForUploadingDocument.updateValueAndValidity({ emitEvent: false });
      deadlineForUploadingDocument.markAsTouched();
    }
  }
}
