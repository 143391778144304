import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  isMobile = false;

  constructor(private readonly platform: Platform) {
    this.isMobile =
      this.platform.ANDROID || this.platform.IOS || !!window.EKPObject;
  }
}
