import { Pipe, PipeTransform } from '@angular/core';
import { EventInterval } from '@common/dialogs/planning-dialog/types';
import { setTimeZone } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { format } from 'date-fns';
import {
  findMaxAndMinTime,
  restrictByPlanningWindow
} from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/utils';

@Pipe({
  name: 'getBusyDateRange'
})
export class GetBusyDateRangePipe implements PipeTransform {
  transform(events: EventInterval[], forecastStart: string, forecastEnd: string): string | null {
    if (!events) return null;

    const [max, min] = findMaxAndMinTime(restrictByPlanningWindow(events, forecastStart, forecastEnd));

    if (!max || !min) return null;

    return `${format(setTimeZone(min), 'HH:mm')} - ${format(setTimeZone(max), 'HH:mm')}`;
  }
}
