// TODO: заменить на id?
export enum AppRolesEnum {
  USER = 'user',
  BUSINESS_ADMIN = 'businessAdmin',
  ADMIN = 'admin',
  MEETING_ROOM = 'meetingRoom'
}

export enum RoleTypesEnum {
  MANDATORY,
  OPTIONAL,
  DIVISIONAL
}

export enum RoleAccessesEnum {
  EDIT_COMMITTEE = 1,
  VIEW_COMMITTEE = 2,
  CANCEL_OR_TRANSFER_EVENT = 3,
  CHANGE_MEETING_ROOM_EVENT = 4,
  CAN_DELEGATE_ALL_ROLE = 5,
  CAN_UPLOAD_MATERIAL = 6,
  ADD_RESOLUTION_EVENT = 7,
  VOTE_RESOLUTION = 8,
  FINISHED_AND_START_EVENT = 9,
  CHANGE_MATERIALS_READING_STATUS = 10,
  VIEW_PROTOCOL = 11,
  CHANGE_PROTOCOL_RESOLUTION = 12,
  EXPORT_PROTOCOL = 13,
  SEND_PROTOCOL = 14,
  VIEW_DOCUMENT_COMMITTEE = 15,
  CAN_BE_SPEAKERS = 17,
  CAN_CHANGE_ABSENCE_STATUSES = 18,
  CAN_RESTRICT_DOWNLOAD_ACCESS_MATERIALS = 20,
  CAN_RESTRICT_VIEW_ACCESS_MATERIALS = 21,
  CAN_INDICATE_ADDITIONAL_REASON_ATTORNEY = 22,
  CAN_CREATE_UNPLANNED_EVENT = 23,
  CAN_PAUSE_AND_CONTINUE_EVENT = 24,
  CAN_TRANSFER_AND_SUSPEND_EVENT = 25,
  CAN_FIX_QUORUM_INDICATOR_AT_START_COMMITTEE = 26,
  CAN_MANAGE_STATUS_OF_FAILED_EVENT = 27,
  CAN_CREATE_PRE_RESOLUTION = 28,
  CAN_RATE_PRE_RESOLUTION = 29,
  CAN_TRANSFER_COMMITTEE_REPORTING_PERIOD_WITHOUT_ACCRUAL_SANCTIONS = 30,
  CAN_VOTE_ALL_MEMBERS = 31,
  CAN_VOTE_FOR_DELEGATE_PARTICIPANT = 32,
  CAN_MAKE_COMMENTS_ON_THE_PROVIDED_MATERIALS = 33,
  REQUIRED_TO_ATTEND_THE_COMMITTEE = 34,
  IT_IS_MANDATORY_TO_OBTAIN_ADMISSION_IF_NECESSARY = 35,
  CHANGE_ADMISSION_STATUS_ACCESS = 36,
  ADD_DISCIPLINE_NOTES = 37,
  TAKE_ACCOUNT_OF_COUNTING_QUORUM = 38,
  CAN_VIEW_PRELIMINARY_SOLUTIONS_PROPOSED_BY_OTHER_PARTICIPANTS = 40,
  CAN_START_DISCUSSION_AGENDA_ITEM = 42,
  CAN_ADD_DECISIONS_FOR_TASKS = 43,
  CAN_CHANGE_MATERIAL_STATUS = 44,
  CAN_CHANGE_PRE_RESOLUTION = 45,
  UPLOADED_DOCUMENT_COMMITTEE = 46,
  HideThePreliminaryDecisionsIntroducedByTheRoleFromOtherParticipants = 47,
  CAN_ADD_AGENDA_ITEM_TO_COMMITTEE_EVENT = 48
}

export enum RoleParamsEnum {
  HAS_DEPUTIES = 'Управление личной доверенностью (делегировать / отменить / отклонить)',
  //HAS_VACANCY = 'Можно открыть вакансию',
  HAS_DUBLICATE = 'Можно назначить несколько сотрудников',
  REQUIRED_TO_IDEAL_MODEL = 'Обязательна в идеальной модели',
  REQUIRED = 'Обязательна в компромиссной модели'
}
