import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type
} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomService {
  constructor(
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _applicationRef: ApplicationRef,
    private readonly _injector: Injector
  ) {}

  appendComponentToBody<T>(component: Type<T>): object {
    const componentRef = this._componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this._injector);

    this._applicationRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<T>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    return () => {
      this._applicationRef.detachView(componentRef.hostView);
      componentRef.destroy();
    };
  }
}
