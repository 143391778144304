import { ChangeDetectionStrategy, Component, Inject, Self } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { IEmployeeIntersectionData, IRoomIntersectionData, RRuleModel } from '@common/types';
import { FuseDialogActionsEnum } from '@common/enums';
import {
  COMMITTEE_EVENT_TEMPLATE_TYPES,
  EmployeeService,
  IntersectionService,
  UnsubscribeService
} from '@common/services';
import { BUTTON_SPINNER_DIAMETER, DATE_TIME_FORMAT_TABLE, TIME_FORMAT } from '@common/constants';
import moment from 'moment';
import {
  addTimeToDate,
  changeDateButSaveTime,
  setTimeZone,
  toISO
} from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { IEventProps } from '@common/types/calendar-api';
import {
  IntersectionDialogProps,
  IntersectionEvent,
  ITimelineEntity
} from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';
import { TimelineEventsService } from '@common/services/timeline-events.service';
import { startOfDay } from 'date-fns';

enum ComponentStatus {
  PENDING,
  ERROR,
  SUCCESS
}

@Component({
  selector: 'com-intersection-dialog',
  templateUrl: './intersection-dialog.component.html',
  providers: [UnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntersectionDialogComponent {
  public ButtonSpinnerDiameter = BUTTON_SPINNER_DIAMETER;
  public ComponentStatus = ComponentStatus;

  public forecastStart: string | null = null;
  public forecastEnd: string | null = null;
  public selectedEventTitle = '';
  public selectedEventIndex = 0;
  public indexSelectFromNavigation = 0;

  public loadEvent$ = new Subject<void>();
  public timelineEntities$ = new BehaviorSubject<ITimelineEntity[]>([]);
  public events$ = new BehaviorSubject<IntersectionEvent[]>([]);
  public isLoading$ = new BehaviorSubject<boolean>(true);
  public componentStatus$ = new BehaviorSubject<ComponentStatus>(ComponentStatus.PENDING);
  private cancelPrevRequest$ = new Subject<void>();

  constructor(
    private matDialogRef: MatDialogRef<IntersectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogProps: IntersectionDialogProps,
    private intersectionService: IntersectionService,
    public employeeService: EmployeeService,
    public timelineEventsService: TimelineEventsService,
    @Self() private unsubscribeService: UnsubscribeService
  ) {
    this.loadTimelineEventsSub();
    this.initEventInterval(this.dialogProps.rRule);
    this.initIntersectionEvents(this.dialogProps);
  }

  public onSave(asDraft = false): void {
    this.matDialogRef.close({
      action: FuseDialogActionsEnum.CONFIRMED,
      payload: {
        isDraft: asDraft
      }
    });
  }

  public selectEvent({ start, end, title }: IntersectionEvent): void {
    this.isLoading$.next(true);
    this.forecastStart = toISO(addTimeToDate(setTimeZone(start), this.forecastStart));
    this.forecastEnd = toISO(addTimeToDate(setTimeZone(end), this.forecastEnd));
    this.selectedEventTitle = title;
    this.loadEvent$.next();
  }

  public onSelectIndex(index: number): void {
    this.selectedEventIndex = index;
    this.selectEvent(this.events$.value[index]);
  }

  private loadTimelineEventsSub(): void {
    this.loadEvent$
      .pipe(
        debounceTime(300),
        tap(() => this.cancelPrevRequest$.next()),
        switchMap(() => {
          const { employeeIds, roomIds, committeeId } = this.dialogProps;
          return this.timelineEventsService
            .loadTimelineEvents(this.forecastStart, this.forecastEnd, employeeIds, roomIds, committeeId)
            .pipe(takeUntil(this.cancelPrevRequest$));
        }),
        tap((timelineEntities) => {
          this.timelineEntities$.next(timelineEntities);
          this.componentStatus$.next(ComponentStatus.SUCCESS);
          this.isLoading$.next(false);
        }),
        catchError(() => this.setComponentError()),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  public onChangeSelectedIndex(value: number): void {
    let index = this.selectedEventIndex + value;
    const events = this.events$.getValue();
    const lastEventIndex = events.length - 1;
    index = index > lastEventIndex ? 0 : index < 0 ? lastEventIndex : index;

    this.selectedEventIndex = index;
    this.indexSelectFromNavigation = index;
    this.selectEvent(events[index]);
  }

  private checkStartDateMoreThanPresent(eventStart: string): string {
    const currentDate = startOfDay(new Date());

    return new Date(eventStart).getTime() > currentDate.getTime()
      ? eventStart
      : toISO(changeDateButSaveTime(currentDate, eventStart));
  }

  private initIntersectionEvents({ employeeIds, rRule, roomIds }: IntersectionDialogProps): void {
    const startDate = this.checkStartDateMoreThanPresent(rRule.dtStart);

    forkJoin([
      this.intersectionService.checkIntersections(
        rRule,
        COMMITTEE_EVENT_TEMPLATE_TYPES,
        employeeIds,
        startDate
      ),
      this.intersectionService.checkRoomIntersections(
        rRule,
        roomIds,
        COMMITTEE_EVENT_TEMPLATE_TYPES,
        startDate
      )
    ])
      .pipe(
        map(([employeeIntersectionEvents, roomIntersectionEvents]) =>
          this.filterUniqEvents(
            this.adaptorIntersectionEvent([...employeeIntersectionEvents, ...roomIntersectionEvents])
          ).filter((event) => event.templateId !== this.dialogProps.committeeId)
        ),
        tap((events) => {
          if (events.length === 0) {
            this.componentStatus$.next(ComponentStatus.SUCCESS);
            this.isLoading$.next(false);
          } else {
            this.events$.next(events);
            this.selectEvent(events[0]);
            this.selectedEventIndex = 0;
          }
        }),
        catchError(() => this.setComponentError()),
        take(1)
      )
      .subscribe();
  }

  private setComponentError(): Observable<null> {
    this.isLoading$.next(false);
    this.componentStatus$.next(ComponentStatus.ERROR);
    return of(null);
  }

  private filterUniqEvents(events: IntersectionEvent[]): IntersectionEvent[] {
    const map = new Map<string, boolean>();
    const result: IntersectionEvent[] = [];
    events.map((event) => {
      if (!map.has(event.id)) {
        map.set(event.id, true);
        result.push(event);
      }
    });
    return result;
  }

  private adaptorIntersectionEvent(
    events: (IEmployeeIntersectionData | IRoomIntersectionData)[]
  ): IntersectionEvent[] {
    return events.map(
      ({ startTime, endTime, startDate, endDate, title, eventTemplateId, eventTemplateTypeId, props }) => {
        const start = startTime ?? startDate;
        const end = endTime ?? endDate;
        return {
          start,
          end,
          title,
          dateRange: this.addDateRange(start, end),
          id: eventTemplateId,
          eventTypeId: eventTemplateTypeId,
          templateId: props ? this.parseEventProps(props)?.templateId : null
        } as IntersectionEvent;
      }
    );
  }
  private parseEventProps(props: string): Partial<IEventProps> {
    return JSON.parse(props);
  }

  private addDateRange(start: string, end: string): string {
    return `${moment.utc(start).local().format(DATE_TIME_FORMAT_TABLE)} —  ${moment
      .utc(end)
      .local()
      .format(TIME_FORMAT)}`;
  }

  private initEventInterval(rrule: RRuleModel): void {
    this.forecastStart = rrule.dtStart;
    this.forecastEnd = moment
      .utc(new Date(rrule.dtStart).getTime() + rrule.duration * 60 * 1000)
      .tz(rrule.timezone)
      .toISOString(true);
  }
}
