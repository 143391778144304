import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PlanningDiagramModule } from '@common/dialogs/planning-dialog/modules/planning-diagram/planning-diagram.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { PlanningDialogComponent } from '@common/dialogs/planning-dialog/planning-dialog.component';
import { TabButtonsModule } from '@common/dialogs/planning-dialog/ui/tab-buttons/tab-buttons.module';
import { PlanningIntersectionModule } from '@common/dialogs/planning-dialog/modules/planning-intersection/planning-intersection.module';
import { HasIntersectionPipe } from '@common/dialogs/planning-dialog/pipes';
import { ButtonModule } from '@common/dialogs/planning-dialog/ui/button/button.module';

@NgModule({
  declarations: [PlanningDialogComponent, HasIntersectionPipe],
  exports: [PlanningDialogComponent, PlanningDiagramModule],
  imports: [
    CommonModule,
    PlanningDiagramModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    TabButtonsModule,
    PlanningIntersectionModule,
    ButtonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PlanningDialogModule {}
