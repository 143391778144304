<div class="flex flex-col gap-6">
  <div class="flex flex-col space-y-2">
    <p class="text-2xl font-semibold">Участники</p>
    <mat-divider></mat-divider>
  </div>
  <com-right-panel-members-block
    [event]="event"
    [isTimeToDelegate]="isTimeToDelegate"
    [isTimeToUpload]="isTimeToUpload"
    [members]="uniqueMembers"
    [currentMember]="currentMember"
    [responsibleUploadingMaterial]="responsibleUploadingMaterial"
    [availableMembers]="availableMembers"
    (delegationButtonClick)="delegationButtonClick.emit($event)"
    (agendaDrawerOpen)="agendaDrawerOpen.emit($event)"
    (accessDrawerOpen)="accessDrawerOpen.emit($event)"
  ></com-right-panel-members-block>
  <ng-container *ngFor="let role of roles">
    <div *ngIf="duplicateMembers[role.id]?.length" class="flex flex-col gap-4">
      <span class="text-md font-semibold">
        {{ role.name }}
      </span>
      <com-right-panel-members-block
        [event]="event"
        [isTimeToDelegate]="isTimeToDelegate"
        [isTimeToUpload]="isTimeToUpload"
        [members]="duplicateMembers[role.id]"
        [currentMember]="currentMember"
        [showRole]="false"
        [responsibleUploadingMaterial]="responsibleUploadingMaterial"
        [availableMembers]="availableMembers"
        (delegationButtonClick)="delegationButtonClick.emit($event)"
        (agendaDrawerOpen)="agendaDrawerOpen.emit($event)"
        (accessDrawerOpen)="accessDrawerOpen.emit($event)"
      ></com-right-panel-members-block>
    </div>
  </ng-container>
</div>

<ng-template #noMember>
  <span>В данном событии нет участников</span>
</ng-template>
