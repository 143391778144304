<div class="flex items-start gap-2">
  <div class="flex flex-col justify-start text-foreground text-sm relative top-1.5 min-w-[74px]">
    <span class="border-t min-w-6 max-w-6 border-2 rounded-md border-planning-red"></span>
    {{ event.timeStart | date: 'HH:mm' }}-{{ event.timeEnd | date: 'HH:mm' }}
  </div>
  <div class="flex flex-col text-sm justify-start">
    <span class="text-foreground font-medium"> {{ event.eventName }}</span>
    <span class="text-hint relative bottom-0.5"> {{ event.roleName || 'Переговорная комната' }} </span>
  </div>
</div>
