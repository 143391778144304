import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { filter, Observable, of, switchMap } from 'rxjs';
import { EmployeeService } from '@common/services';

@Injectable({
  providedIn: 'root'
})
export class StatGuard implements CanActivate {
  constructor(
    private employeeService: EmployeeService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.employeeService.employeeCurrentUserInfo$.pipe(
      filter(Boolean),
      switchMap((info) => {
        if (info.hasAccessToSystemStat) {
          return of(true);
        } else {
          this.router.navigate(['/']);
          return of(false);
        }
      })
    );
  }
}
