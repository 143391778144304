import { ChangeDetectionStrategy, Component, Input, OnChanges, Self, SimpleChanges } from '@angular/core';
import { DrawerPanelSetupService, EmployeeService, UnsubscribeService } from '@common/services';
import { IEmployee, IMemberDto, IReasonForDelegation } from '@common/types';
import { switchMap, takeUntil } from 'rxjs';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { MemberPresenceStatusEnum, RoleAccessesEnum } from '@common/enums';

@Component({
  selector: 'com-employee',
  templateUrl: 'employee.component.html',
  styleUrls: ['./employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class EmployeeComponent implements OnChanges {
  @Input() employee: IEmployee = null;
  @Input() showImage = false;
  @Input() showRole = true;
  @Input() showPosition = true;
  @Input() delegateTo: IMemberDto = null;
  @Input() delegationReason: IReasonForDelegation = null;
  @Input() showContent = true;
  @Input() showLink = true;
  @Input() isSmall = false;
  @Input() maxWidth = 'max-w-40';

  private defaultStatusColorClass = 'ring-[#cbd5e1]';
  private statusColorClasses = new Map<MemberPresenceStatusEnum, string>([
    [MemberPresenceStatusEnum.PARTICIPATE, 'ring-[#22c55e]'],
    [MemberPresenceStatusEnum.MISSED, 'ring-[#ef4444]'],
    [MemberPresenceStatusEnum.LATE, 'ring-[#f97316]'],
    [MemberPresenceStatusEnum.EARLIER_LOGOUT, 'ring-[#ffcb29]'],
    [MemberPresenceStatusEnum.NOT_REQUIRED, this.defaultStatusColorClass]
  ]);

  RoleAccessesEnum = RoleAccessesEnum;
  rolesOpened = false;
  statusColorClass = this.defaultStatusColorClass;

  constructor(
    private employeeService: EmployeeService,
    private drawerPanelService: DrawerPanelSetupService,
    @Self() private unsubscribeService: UnsubscribeService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const employee = changes.employee?.currentValue;
    if (employee) {
      this.statusColorClass = this.statusColorClasses.get(employee.status) || this.defaultStatusColorClass;
    }
  }

  toggleRoles(rolesOpened: boolean) {
    this.rolesOpened = rolesOpened;
  }

  openEmployeePanel(event: Event, employeeId: string) {
    event.stopPropagation();
    // TODO: обсудить получение полной модели пользователя с бэкенда
    this.employeeService
      .retrieveEmployeeById(employeeId)
      .pipe(
        switchMap((employee) =>
          this.drawerPanelService.createComponent(UserProfileComponent, { userInfo: employee })
        ),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  onErrorImg() {
     this.employee.avatar = null;
  }
}
