import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommitteeEventStatusEnum } from '@common/enums/index';

@Component({
  selector: 'com-committee-event-status',
  templateUrl: './committee-event-status.component.html'
})
export class CommitteeEventStatusComponent implements OnChanges, OnInit {
  @Input() committeeEventStatus: CommitteeEventStatusEnum =
    CommitteeEventStatusEnum.PLANNED;
  @Input() reason: string;
  @Input() padding: string = '1.5';
  @Input() customTooltip = false;
  public isGreen: boolean = false;
  public isLightGreen: boolean = false;
  public isRed: boolean = false;
  public isYellow: boolean = false;

  ngOnInit(): void {
    this.isGreen =
      this.committeeEventStatus === CommitteeEventStatusEnum.FINISHED;
    this.isRed =
      this.committeeEventStatus === CommitteeEventStatusEnum.SUSPENDED ||
      this.committeeEventStatus === CommitteeEventStatusEnum.CANCEL;
    this.isYellow =
      this.committeeEventStatus === CommitteeEventStatusEnum.PAUSE;
  }

  ngOnChanges(): void {
    this.isGreen =
      this.committeeEventStatus === CommitteeEventStatusEnum.FINISHED;
    this.isLightGreen =
      this.committeeEventStatus === CommitteeEventStatusEnum.IN_PROGRESS;
    this.isRed =
      this.committeeEventStatus === CommitteeEventStatusEnum.SUSPENDED ||
      this.committeeEventStatus === CommitteeEventStatusEnum.CANCEL;
    this.isYellow =
      this.committeeEventStatus === CommitteeEventStatusEnum.PAUSE;
  }
}
