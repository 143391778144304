import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DATE_TIME_FORMAT, TRANSFER_REASONS } from '@common/constants';
import { MatDialogRef } from '@angular/material/dialog';
import moment from 'moment/moment';
import { tz } from 'moment';

@Component({
  selector: 'com-transfer-event-dialog',
  templateUrl: './transfer-event-dialog.component.html'
})
export class TransferEventDialogComponent {
  public formGroup = new FormGroup({
    newDate: new FormControl('', [Validators.required]),
    reason: new FormControl('', [Validators.required])
  });
  public currentDate = moment().format(DATE_TIME_FORMAT);

  protected readonly TRANSFER_REASONS = TRANSFER_REASONS;

  constructor(public matDialogRef: MatDialogRef<TransferEventDialogComponent>) {}

  public confirm(): void {
    if (this.formGroup.valid) {
      this.matDialogRef.close({
        ...this.formGroup.value,
        newDate: moment(this.formGroup.value.newDate).tz(tz.guess()).format()
      });
    }
  }
}
