import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'com-snackbar',
  templateUrl: './snackbar.component.html'
})
export class SnackbarComponent implements OnInit {
  icon: string = 'heroicons_outline:check-circle';
  iconClass: string = 'text-green-500';
  title: string = 'Решения сохранены!';
  message: string = 'Решения по выбранному вопросу повестки сохранены.';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    if (data?.icon) {
      this.icon = data?.icon;
    }
    if (data?.iconClass) {
      this.iconClass = data?.iconClass;
    }
    if (data) {
      this.title = data.title;
      this.message = data.message;
    }
  }

  ngOnInit(): void {}
}
