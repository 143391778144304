import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PlanningIntersectionComponent } from '@common/dialogs/planning-dialog/modules/planning-intersection/planning-intersection.component';
import { TabButtonsModule } from '@common/dialogs/planning-dialog/ui/tab-buttons/tab-buttons.module';
import { IntersectionTableComponent } from '@common/dialogs/planning-dialog/modules/planning-intersection/components/intersection-table/intersection-table.component';
import { BusyTimelineModule } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/busy-timeline.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@common/dialogs/planning-dialog/ui/button/button.module';
import { AllIntersectionResolvePipe } from '@common/dialogs/planning-dialog/modules/planning-intersection/pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FreeSlotsModule } from '@common/dialogs/planning-dialog/modules/planning-intersection/components/free-slots/free-slots.module';

@NgModule({
  declarations: [PlanningIntersectionComponent, IntersectionTableComponent, AllIntersectionResolvePipe],
  exports: [PlanningIntersectionComponent],
  imports: [
    CommonModule,
    TabButtonsModule,
    BusyTimelineModule,
    MatDialogModule,
    ButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatButtonModule,
    FreeSlotsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PlanningIntersectionModule {}
