import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { E_DISCIPLINE_VIOLATION } from '@common/enums/violations.enum';

@Component({
  selector: 'com-discipline-violations',
  templateUrl: './discipline-violations.component.html'
})
export class DisciplineViolationsComponent implements OnInit {
  public formGroup = new FormGroup({
    usingGadget: new FormControl(false),
    leavingMeeting: new FormControl(false),
    eatingMeeting: new FormControl(false),
    connectionFromNonWorkspace: new FormControl(false),
    connectionWithoutVideo: new FormControl(false),
    absenceInTheMeeting: new FormControl(false)
  });

  protected readonly E_DISCIPLINE_VIOLATION = E_DISCIPLINE_VIOLATION;

  constructor(
    private readonly _dialogRef: MatDialogRef<DisciplineViolationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[]
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.formGroup.setValue({
        usingGadget: this.data.includes(E_DISCIPLINE_VIOLATION.USING_GADGET_DURING_MEETING),
        leavingMeeting: this.data.includes(E_DISCIPLINE_VIOLATION.LEAVING_ROOM_DURING_MEETING),
        eatingMeeting: this.data.includes(E_DISCIPLINE_VIOLATION.EATING_IN_THE_MEETING),
        connectionFromNonWorkspace: this.data.includes(E_DISCIPLINE_VIOLATION.CONNECTION_FROM_NON_WORKPLACE),
        connectionWithoutVideo: this.data.includes(E_DISCIPLINE_VIOLATION.REMOTE_CONNECTION_WITHOUT_VIDEO),
        absenceInTheMeeting: this.data.includes(E_DISCIPLINE_VIOLATION.ABSENCE_IN_THE_MEETING)
      });
    }
  }

  public onSubmit(): void {
    const { value } = this.formGroup;
    this._dialogRef.close([
      ...(value.usingGadget ? [E_DISCIPLINE_VIOLATION.USING_GADGET_DURING_MEETING] : []),
      ...(value.leavingMeeting ? [E_DISCIPLINE_VIOLATION.LEAVING_ROOM_DURING_MEETING] : []),
      ...(value.eatingMeeting ? [E_DISCIPLINE_VIOLATION.EATING_IN_THE_MEETING] : []),
      ...(value.connectionFromNonWorkspace ? [E_DISCIPLINE_VIOLATION.CONNECTION_FROM_NON_WORKPLACE] : []),
      ...(value.connectionWithoutVideo ? [E_DISCIPLINE_VIOLATION.REMOTE_CONNECTION_WITHOUT_VIDEO] : []),
      ...(value.absenceInTheMeeting ? [E_DISCIPLINE_VIOLATION.ABSENCE_IN_THE_MEETING] : [])
    ]);
  }

  public onClose(): void {
    this._dialogRef.close();
  }
}
