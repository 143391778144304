import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private readonly navigation: ReplaySubject<Navigation> =
    new ReplaySubject<Navigation>(1);

  constructor(private readonly httpClient: HttpClient) {
    this.loadNavigation();
  }

  get navigation$(): Observable<Navigation> {
    return this.navigation.asObservable();
  }

  loadNavigation(): void {
    this.httpClient
      .get<Navigation>('api/common/navigation')
      .pipe(
        tap((navigation) => {
          this.navigation.next(navigation);
        })
      )
      .subscribe();
  }
}
