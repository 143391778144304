import { ResolutionResponsibleTypeEnum } from '@common/enums';
import { IOption } from '@common/types';

export const RESOLUTION_RESPONSIBLE_TYPES: IOption[] = [
  {
    id: ResolutionResponsibleTypeEnum.SPEAKERS,
    name: 'Ответственные - докладчики'
  },
  {
    id: ResolutionResponsibleTypeEnum.ONE,
    name: 'Один ответственный'
  },
  {
    id: ResolutionResponsibleTypeEnum.MANY,
    name: 'Разные ответственные'
  }
];
