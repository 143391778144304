import { Pipe, PipeTransform } from '@angular/core';
import { IEmployee } from '@common/types';

@Pipe({
  name: 'speakers'
})
export class SpeakersPipe implements PipeTransform {
  transform(speakers: IEmployee[]): string {
    if (speakers.length) {
      return speakers.map(({ fullName }) => fullName).join(', ');
    }

    return '-';
  }
}
