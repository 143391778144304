import { Component, OnInit, Self } from '@angular/core';
import { CalendarEventService, UnsubscribeService } from '@common/services';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'event-redirect',
  templateUrl: './event-redirect.component.html',
  styleUrls: ['./event-redirect.component.scss'],
  providers: [UnsubscribeService]
})
export class EventRedirectComponent implements OnInit {
  constructor(
    private readonly _route: ActivatedRoute,
    private readonly router: Router,
    private readonly _calendarEventService: CalendarEventService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  ngOnInit(): void {
    this._route.paramMap
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((params) => {
        const committeeId = params.get('committeeId');
        const startDate = params.get('startDate');
        this._calendarEventService
          .getEventIdByRedirectFromCalendar(committeeId, startDate)
          .pipe(takeUntil(this._unsubscribeService))
          .subscribe((eventId) => {
            this.router.navigateByUrl(`calendar/${eventId}`);
          });
      });
  }
}
