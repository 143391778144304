import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinString'
})
export class JoinStringPipe implements PipeTransform {
  transform(array: string[], start?: number | undefined, end?: number | undefined): any {
    let result = array;
    if (start) {
      if (end) {
        result = array.slice(start, end);
      } else {
        result = array.slice(start, result.length);
      }
    }
    return result.join(', ');
  }
}
