export enum ButtonActionsEnum {
  SAVE = 'save',
  EDIT = 'edit',
  DELETE = 'delete'
}

export enum ChangeActionsEnum {
  VOTE = 'vote',
  PUT_VOTE = 'put-to-vote',
  SUBMIT = 'submit',
  START = 'start',
  COMPLETE = 'complete',
  RESUME = 'resume',
  CLOSE_DRAWER = 'close-drawer'
}