import { Injectable } from '@angular/core';
import { ModelsEnum, RoleTypesEnum } from '@common/enums';
import { INewOption, IRole } from '@common/types';
import { Observable, Subject } from 'rxjs';

type RoleShortInfo = {
  id: string;
  type: RoleTypesEnum;
  accesses: number[];
};

type MemberRoleMap = Map<string, RoleShortInfo[]>;
type MemberRoleMapFlow = [ideal: MemberRoleMap, compromise: MemberRoleMap, divisional: MemberRoleMap];

@Injectable({
  providedIn: 'root'
})
export class CommitteeMembersRoleService {
  private idealMembersRoleMap = new Map<string, RoleShortInfo[]>();
  private compromiseMembersRoleMap = new Map<string, RoleShortInfo[]>();
  private divisionalMembersRoleMap = new Map<string, RoleShortInfo[]>();
  private allSelectedMembersOption: INewOption[] = [];

  private roleChange$ = new Subject<MemberRoleMapFlow>();

  public addRole(user: INewOption, role: IRole, model: ModelsEnum): void {
    const membersRoleMap = this.getMembersRoleMap(model);
    membersRoleMap.set(user.id, [...(membersRoleMap.get(user.id) || []), this._roleShortInfoMapper(role)]);
    this.allSelectedMembersOption.push(user);
    this.changeRoleMember();
  }

  public removeRole(userId: string, roleId: string, model: ModelsEnum): void {
    const membersRoleMap = this.getMembersRoleMap(model);
    membersRoleMap.set(
      userId,
      (membersRoleMap.get(userId) || []).filter(({ id }) => id !== roleId)
    );

    //TODO переделать в компромисной модели дубли из дивизионных
    if (model !== ModelsEnum.IDEAL && model !== ModelsEnum.COMPROMISE) {
      const compromiseMembersRoleMap = this.getMembersRoleMap(ModelsEnum.COMPROMISE);
      compromiseMembersRoleMap.set(
        userId,
        (compromiseMembersRoleMap.get(userId) || []).filter(({ id }) => id !== roleId)
      );
    }
    this.changeRoleMember();
  }

  public getAllSelectedMemberOption(): INewOption[] {
    return this.allSelectedMembersOption;
  }

  public isRoleLimitReached(userId: string, role: RoleShortInfo, model: ModelsEnum): boolean {
    let userRoles = [...(this.getMembersRoleMap(model).get(userId) || [])];

    const isRoleDuplicate = userRoles.some((r) => r.id === role.id);
    if (isRoleDuplicate) return true;

    userRoles = [...(this.getMembersRoleMap(model).get(userId) || []), role];

    let mandatoryCount = 0;
    let optionalCount = 0;
    let divisionalCount = 0;

    userRoles.map(({ type }) => {
      switch (type) {
        case RoleTypesEnum.DIVISIONAL:
          divisionalCount++;
          return;
        case RoleTypesEnum.MANDATORY:
          mandatoryCount++;
          return;
        default:
          optionalCount++;
      }
    });

    return (
      mandatoryCount > 1 || optionalCount > 1 || mandatoryCount + optionalCount >= 2 || divisionalCount > 1000
    );
  }

  private _roleShortInfoMapper({ id, type, accesses }: IRole): RoleShortInfo {
    return {
      id,
      type,
      accesses
    };
  }

  private getMembersRoleMap(model: ModelsEnum): Map<string, RoleShortInfo[]> {
    switch (model) {
      case ModelsEnum.COMPROMISE:
        return this.compromiseMembersRoleMap;
      case ModelsEnum.IDEAL:
        return this.idealMembersRoleMap;
      default:
        return this.divisionalMembersRoleMap;
    }
  }

  private changeRoleMember() {
    this.roleChange$.next([
      this.idealMembersRoleMap,
      this.compromiseMembersRoleMap,
      this.divisionalMembersRoleMap
    ]);
  }

  public clear(): void {
    this.idealMembersRoleMap.clear();
    this.compromiseMembersRoleMap.clear();
    this.divisionalMembersRoleMap.clear();
  }

  public getMemberRoleChanged(): Observable<MemberRoleMapFlow> {
    return this.roleChange$.asObservable();
  }
}
