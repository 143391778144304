import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'com-event-navigation',
  templateUrl: 'event-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventNavigationComponent {
  @Input() committeeTitle: string;
  @Input() set setTimeRange(dateRange: string) {
    this.timeRange = dateRange.split(' ').slice(1).join(' ');
  }
  @Input() eventDate: string;
  @Output() changeSelectedIndex = new EventEmitter<number>();

  public timeRange: string;
}
