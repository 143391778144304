import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { filter, takeUntil, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  ICalendarEvent,
  IChangeEventStatusParams,
  ICommitteeMeetingRoom,
  IEmployeeCurrentUserInfo,
  IMember
} from '@common/types';
import { CommitteeEventStatusEnum, CommitteeMeetingRoomType, RoleAccessesEnum } from '@common/enums';
import { EmployeeService, UnsubscribeService } from '@common/services';
import { UUID_EMPTY } from '@common/constants';
import { hasAccess } from '@common/utils/util';
import { AgreementEventComponent } from '@common/dialogs';
import { EventPlanningService } from '@common/dialogs/event-planning/event-planning.service';

@Component({
  selector: 'com-event-actions',
  templateUrl: './event-actions.component.html',
  providers: [UnsubscribeService]
})
export class EventActionsComponent implements OnChanges {
  @Input() event: ICalendarEvent;
  @Input() meetingRooms: ICommitteeMeetingRoom[] = [];
  @Input() members: IMember[] = [];
  @Input() isMobile = false;

  @Output() transferDrawerOpen = new EventEmitter<boolean>(false);
  @Output() unplannedEvent = new EventEmitter<ICalendarEvent>();
  @Output() changeStatus = new EventEmitter<IChangeEventStatusParams>();
  @Output() transferEvent = new EventEmitter<void>();
  @Output() cancelEvent = new EventEmitter<void>();

  public isEventControl = false;
  public changeMeetingRoomEvent = false;
  public canTransferEvent = false;
  public canCancelEvent = false;
  public canMakeAction = false;
  public canCreateUnplannedEvent = false;
  public isPause = false;
  public meetingRoomUnconfirmed: { [meetingRoomId: string]: boolean } = {};

  constructor(
    private _employeeService: EmployeeService,
    private _cdr: ChangeDetectorRef,
    private _matDialog: MatDialog,
    private _eventPlanningService: EventPlanningService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const meetingRooms: ICalendarEvent = changes.meetingRooms?.currentValue;
    if (meetingRooms) {
      this._updateMeetingRoomUnconfirmed();
    }
    const members: IMember[] = changes.members?.currentValue;
    if (members) {
      this._employeeService.employeeCurrentUserInfo$
        .pipe(takeUntil(this._unsubscribeService))
        .subscribe((employeeCurrentUserInfo) => {
          this._setAccess(employeeCurrentUserInfo, members);
        });
    }
  }

  public openAgreementDialog(): void {
    const dialogRef = this._matDialog.open(AgreementEventComponent, {
      panelClass: ['committees-app', 'agreement-dialog'],
      data: this.event
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((data: IChangeEventStatusParams) => {
        if (data) {
          this.changeStatus.emit(data);
          this.event.committeeEventStatus = data.committeeEventStatus;
          this._employeeService.employeeCurrentUserInfo$
            .pipe(takeUntil(this._unsubscribeService))
            .subscribe((employeeCurrentUserInfo) => {
              this._setAccess(employeeCurrentUserInfo, this.members);
            });
        }
      });
  }

  public openUnplannedEventDialog(): void {
    const { cancel, committeeId } = this.event;
    this._eventPlanningService
      .openUnplanned(committeeId, cancel)
      .pipe(
        filter(Boolean),
        tap((event) => this.unplannedEvent.next(event)),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }
  private _setAccess(employeeCurrentUserInfo: IEmployeeCurrentUserInfo, members: IMember[]): void {
    this.isPause = [CommitteeEventStatusEnum.PAUSE, CommitteeEventStatusEnum.WAIT_RESOLUTION].includes(
      this.event.committeeEventStatus
    );

    this.canCreateUnplannedEvent =
      [CommitteeEventStatusEnum.SUSPENDED, CommitteeEventStatusEnum.CANCEL].includes(
        this.event.committeeEventStatus
      ) &&
      hasAccess(
        members.map((member) => ({
          ...member.committeeMember,
          memberAccesses: member.memberAccesses
        })),
        employeeCurrentUserInfo.id,
        RoleAccessesEnum.CAN_CREATE_UNPLANNED_EVENT
      );

    this.canTransferEvent =
      [CommitteeEventStatusEnum.PLANNED].includes(this.event.committeeEventStatus) &&
      (hasAccess(
        members.map((member) => ({
          ...member.committeeMember,
          memberAccesses: member.memberAccesses
        })),
        employeeCurrentUserInfo.id,
        RoleAccessesEnum.CANCEL_OR_TRANSFER_EVENT
      ) ||
        this._employeeService.currentUserIsAdmin());

    this.canCancelEvent =
      [CommitteeEventStatusEnum.PLANNED].includes(this.event.committeeEventStatus) &&
      (hasAccess(
        members.map((member) => ({
          ...member.committeeMember,
          memberAccesses: member.memberAccesses
        })),
        employeeCurrentUserInfo.id,
        RoleAccessesEnum.CANCEL_OR_TRANSFER_EVENT
      ) ||
        this._employeeService.currentUserIsAdmin());

    this.canMakeAction =
      [
        CommitteeEventStatusEnum.IN_PROGRESS,
        CommitteeEventStatusEnum.PAUSE,
        CommitteeEventStatusEnum.WAIT_RESOLUTION
      ].includes(this.event.committeeEventStatus) &&
      (hasAccess(
        members.map((member) => ({
          ...member.committeeMember,
          memberAccesses: member.memberAccesses
        })),
        employeeCurrentUserInfo.id,
        RoleAccessesEnum.CAN_TRANSFER_AND_SUSPEND_EVENT
      ) ||
        hasAccess(
          members.map((member) => ({
            ...member.committeeMember,
            memberAccesses: member.memberAccesses
          })),
          employeeCurrentUserInfo.id,
          RoleAccessesEnum.CAN_MANAGE_STATUS_OF_FAILED_EVENT
        ));

    this.changeMeetingRoomEvent =
      [CommitteeEventStatusEnum.PLANNED].includes(this.event.committeeEventStatus) &&
      hasAccess(
        members.map((member) => ({
          ...member.committeeMember,
          memberAccesses: member.memberAccesses
        })),
        employeeCurrentUserInfo.id,
        RoleAccessesEnum.CHANGE_MEETING_ROOM_EVENT
      );

    this.isEventControl =
      ![CommitteeEventStatusEnum.FINISHED].includes(this.event.committeeEventStatus) &&
      (this.canCancelEvent ||
        this.canMakeAction ||
        this.canTransferEvent ||
        this.canCreateUnplannedEvent ||
        this.changeMeetingRoomEvent);
  }

  private _updateMeetingRoomUnconfirmed(): void {
    this.event.committeeMeetingRooms.forEach((eventMeetingRoom) => {
      const id = String(eventMeetingRoom.id || eventMeetingRoom);
      this.meetingRoomUnconfirmed[id] =
        [CommitteeMeetingRoomType.EVENT, undefined].includes(
          this.meetingRooms.find((meetingRoom) => meetingRoom.id === id)?.type
        ) && id !== UUID_EMPTY;
    });
    this._cdr.markForCheck();
  }
}
