import { AppRolesEnum } from '@common/enums';
import { IOption } from './common';
import { IEmployee } from './employee';
import { IPage, IResponseWrapper } from './page.model';

export interface IUserRequest extends Partial<IPage> {
  name?: string;
  appRoleId?: string;
  divisionId?: string;
  positionId?: string;
}

export interface IUserWrapper extends IResponseWrapper {
  data: IUserByAdmin[];
}

export interface IUserData extends IPage {
  data: IUserByAdmin[];
}

export interface IUser {
  name: string;
  title: string;
  email: string;
  mobile: string;
  department: string;
  sub: string;
}

export interface IEmployeeCurrentUserInfo {
  id: string;
  name: string;
  lastName: string;
  patronymic: string;
  divisionId: string;
  divisionName: string;
  appRole: IAppRole;
  fullName: string;
  shortName: string;
  hasAccessToSystemStat: boolean;
}

export interface IAppRole {
  id: string;
  name: string;
  keyCloakName: AppRolesEnum;
}

export interface IUserByAdmin {
  id?: string;
  appRole: IOption;
  appRoleName?: string;
  avatar: string;
  coverImage?: string;
  division: IOption;
  divisionName?: string;
  email: string;
  fullName: string;
  shortName: string;
  isActive: boolean;
  isMailNotificationOn: boolean;
  lastName: string;
  name: string;
  patronymic: string;
  position: IOption;
  positionName?: string;
  subDivisionName?: string;
  employee?: IEmployee;
  status?: boolean;
  hasAccessToSystemStat: boolean;
}
