import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { IEmployeeOption } from '@common/types';
import { selectOptionValidator } from '@common/utils/validators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'com-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormAutocompleteComponent implements OnChanges {
  @Output() panelOpen = new EventEmitter<void>();

  @Input() clearable: boolean = false;
  @Input() showTooltip: boolean = false;
  @Input() control: FormControl;
  @Input() options: IEmployeeOption[] = [];
  @Input() placeholder = 'Выберите значение';
  @Input() controlName: string;
  @Input() form:  UntypedFormGroup;

  public arrowIconSubject = new BehaviorSubject('heroicons_outline:chevron-down');

  ngOnChanges(changes: SimpleChanges): void {
    if (this.control) {
      this._onOptionsChanges(changes);
      if (this.options.map((option) => option.id).includes(this.control.value)) {
        this.control.setValue(this.control.value, {
          emitEvent: false,
          onlySelf: true
        });
      }
    }
  }

  public displayFn(value: string | boolean): string | undefined {
    return this.options.find((option) => option.id === value)?.name || undefined;
  }

  public clear(evt): void {
    evt.stopPropagation();
    this.control.reset();
  }

  public openPanel(): void {
    this.arrowIconSubject.next('heroicons_outline:chevron-up');
    this.panelOpen.emit();
  }

  public closePanel(): void {
    this.arrowIconSubject.next('heroicons_outline:chevron-down');
  }

  private _onOptionsChanges(changes: SimpleChanges): void {
    const options = changes.options?.currentValue;
    if (options) {
      this.control?.setValidators([
        ...(this.control.hasValidator(Validators.required) ? [Validators.required] : []),
        selectOptionValidator(options)
      ]);
    }
  }
}
