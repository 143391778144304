import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil, tap } from 'rxjs';
import { IDaysOffFormGroup, DaysOffFormValue } from '@common/types';
import { UnsubscribeService } from '@common/services';

@Component({
  selector: 'com-days-off',
  templateUrl: './days-off.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class DaysOffComponent implements OnInit, OnChanges {
  @Output() valueChange = new EventEmitter<DaysOffFormValue>();

  @Input() formValue: DaysOffFormValue;

  public formGroup: FormGroup<IDaysOffFormGroup>;
  public defaultControl = new FormControl(true);
  public weekendControl = new FormControl(true);
  public showShiftControls = true;

  constructor(
    private formBuilder: FormBuilder,
    @Self() private unsubscribeService: UnsubscribeService
  ) {
    this.createForm();
  }

  public ngOnInit(): void {
    this.formSub();
    this.defaultChangeSub();
    this.weekendChangeSub();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('formValue' in changes && this.formValue) {
      this.defaultControl.setValue(!Object.values(this.formValue).includes(true), { emitEvent: false });
      this.weekendControl.setValue(this.formValue.excludeSundays, { emitEvent: false });
      this.formGroup.patchValue(this.formValue);
      this.showShiftControls = 'shiftHolidays' in this.formValue;
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      excludeSaturdays: [true],
      excludeSundays: { value: true, disabled: true },
      excludeHolidays: [true],
      shiftHolidays: [true],
      shiftForward: [true]
    });
  }

  private formSub() {
    this.formGroup.valueChanges
      .pipe(
        tap(() => this.valueChange.emit(this.formGroup.getRawValue())),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  private defaultChangeSub() {
    this.formGroup
      .get('shiftHolidays')
      .valueChanges.pipe(
        tap((value) => {
          if (value) {
            this.formGroup.get('shiftForward').enable();
          } else {
            this.formGroup.get('shiftForward').disable();
          }
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();

    this.defaultControl.valueChanges
      .pipe(
        tap((value) => {
          this.weekendControl.setValue(!value);
          Object.values(this.formGroup.controls).forEach((control) => {
            control.setValue(!value);
          });
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  private weekendChangeSub() {
    const { excludeSaturdays, excludeSundays } = this.formGroup.controls;
    this.weekendControl.valueChanges
      .pipe(
        tap((value) => {
          if (value) {
            excludeSundays.setValue(true);
          } else {
            excludeSaturdays.setValue(false);
            excludeSundays.setValue(false);
          }
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }
}
