import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StatusPointComponent } from "./status-point.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    imports: [CommonModule, MatTooltipModule],
    exports: [StatusPointComponent],
    declarations: [StatusPointComponent]
})
export class ComStatusPointModule {}