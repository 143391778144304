import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AbstractRightPanel } from '@common/shared/components/right-panel/classess/abstract-right-panel';
import { CommitteeService } from '@common/services/committee.service';
import { ICommittee } from '@common/types/committee';

@Injectable({
  providedIn: 'root'
})
export class CommitteesRightPanelService extends AbstractRightPanel<ICommittee> {
  protected paramKey = 'committeeId';
  protected dataQuery = (committeeId: string) =>
    this._committeeService.retrieveCommittee(committeeId);

  public get committee(): Observable<ICommittee> {
    return this.currentData;
  }

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    private readonly _committeeService: CommitteeService
  ) {
    super(activatedRoute, router);
  }
}
