<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <header class="pb-4 text-center text-2xl border-b-1-gray-500 font-bold">
    Ограничивать доступ участников:
  </header>
  <div class="min-w-[400px]">
    <div *ngIf="data.downloadAccess" class="flex flex-col">
      <com-form-label
        text="Скачивание материалов"
        type="group"
      ></com-form-label>
      <mat-form-field>
        <mat-select
          [formControl]="formGroup.controls.downloadBlacklist"
          panelClass="min-w-[calc(100%+32px)] ml-6"
          placeholder="Выберите участников"
          multiple
        >
          <mat-option *ngFor="let member of members" [value]="member.id">
            {{ member.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="data.viewAccess" class="flex flex-col">
      <com-form-label text="Просмотр материалов" type="group"></com-form-label>
      <mat-form-field>
        <mat-select
          [formControl]="formGroup.controls.viewBlacklist"
          panelClass="min-w-[calc(100%+32px)] ml-6"
          placeholder="Выберите участников"
          multiple
        >
          <mat-option *ngFor="let member of members" [value]="member.id">
            {{ member.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <footer class="flex w-full justify-end gap-2 mt-6">
    <button mat-flat-button type="button" color="warn" (click)="onClose()">
      <span>Отмена</span>
    </button>
    <button mat-flat-button color="primary" type="submit">
      <span>Сохранить</span>
    </button>
  </footer>
</form>
