<mat-form-field class="w-full" [formGroup]="form" (click)="onInputClick()" (mousedown)="onInputClick()">
  <input
    matInput
    type="text"
    [placeholder]="placeholder"
    [matAutocomplete]="auto"
    [formControlName]="controlName"
    class="text-ellipsis"
  />
  <div class="min-w-6 invisible"></div>
  <mat-icon
    *ngIf="clearable && form.get(controlName).value && !form.get(controlName).disabled"
    (click)="onClear($event)"
    matSuffix
    matTooltip="Удалить"
    class="absolute -left-4 icon-size-5 cursor-pointer ml-1 opacity-60"
    svgIcon="heroicons_outline:x"
  ></mat-icon>
  <mat-autocomplete
    #auto="matAutocomplete"
    (closed)="onClose()"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="onSelect($event.option.value)"
    class="com-perfect-scrollbar"
  >
    <mat-option
      *ngIf="options.length === 0; else optionList"
      class="opacity-60 text-center text-sm pointer-events-none"
    >
      нет совпадений
    </mat-option>

    <ng-template #optionList>
      <mat-option
        *ngFor="let option of options"
        [value]="option"
        class="leading-5 h-auto py-2.5 whitespace-normal"
        [disabled]="option.isActive !== undefined && !option.isActive"
      >
        <div class="flex flex-col leading-normal py-1">
          <span
            class="truncate"
            [ngClass]="{ 'opacity-80': option.isActive !== undefined && !option.isActive }"
          >
            {{ option.name }}</span
          >
          <span
            *ngIf="option.isActive !== undefined && !option.isActive"
            class="text-red-500 font-semibold text-[12px]"
          >
            Заблокирован
          </span>
          <span *ngIf="option.position && option.isActive" class="truncate text-sm text-gray-500">
            {{ option.position.name }}</span
          >
          <span *ngIf="option.division && option.isActive" class="truncate text-sm text-gray-500">
            {{ option.division.name }}</span
          >
        </div>
      </mat-option>
    </ng-template>
  </mat-autocomplete>
</mat-form-field>
