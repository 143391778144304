import { Pipe, PipeTransform } from '@angular/core';
import { IEmployee } from '@common/types';
import { RoleAccessesEnum } from '@common/enums';

@Pipe({ name: 'MemberCheckAccess' })
export class MemberCheckAccessPipe implements PipeTransform {
  transform(employee: IEmployee, access: RoleAccessesEnum): boolean {
    const accesses = [...(employee.roles || []), ...(employee.role ? [employee.role] : [])].reduce(
      (acc, elem) => {
        acc = [...acc, ...elem.accesses];
        return acc;
      },
      []
    );

    return accesses.some((a) => a === access);
  }
}
