import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'dateRu' })
export class DateRuPipe implements PipeTransform {
  transform(value: string): string {
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    } as const;
    return new Date(value).toLocaleDateString('ru-RU', options);
  }
}
