import { EventInterval } from '@common/dialogs/planning-dialog/types';
import { addTimeToDate } from '@common/dialogs/planning-dialog/utils';
import { isOverlap, setTimeZone, toISO } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { isAfter, isBefore } from 'date-fns';

export const restrictByPlanningWindow = (
  events: EventInterval[],
  forecastStart: string,
  forecastEnd: string
): EventInterval[] => {
  const result: EventInterval[] = [];
  events.map((event) => {
    const start = addTimeToDate(forecastStart, event.timeStart);
    const end = addTimeToDate(forecastEnd, event.timeEnd);
    const planningStart = setTimeZone(forecastStart);
    const planningEnd = setTimeZone(forecastEnd);
    let timeStart = event.timeStart;
    let timeEnd = event.timeEnd;

    if (!isOverlap(start, end, planningStart, planningEnd, true)) {
      return;
    }

    if (isBefore(start, planningStart)) {
      timeStart = toISO(addTimeToDate(event.timeStart, forecastStart));
    }
    if (isAfter(end, planningEnd)) {
      timeEnd = toISO(addTimeToDate(event.timeEnd, forecastEnd));
    }

    result.push({
      ...event,
      timeStart,
      timeEnd
    });
  });

  return result;
};

export const findMaxAndMinTime = (events: EventInterval[]): [min: string, max: string] => {
  let max = null;
  let min = null;

  events.map((event) => {
    if (!min || setTimeZone(event.timeStart).getTime() < setTimeZone(min).getTime()) {
      min = event.timeStart;
    }
    if (!max || setTimeZone(event.timeEnd).getTime() > setTimeZone(min).getTime()) {
      max = event.timeEnd;
    }
  });
  return [max, min];
};
