import { NgModule } from '@angular/core';
import { TabButtonsComponent } from '@common/dialogs/planning-dialog/ui/tab-buttons/tab-buttons.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TabButtonsComponent],
  exports: [TabButtonsComponent],
  imports: [CommonModule]
})
export class TabButtonsModule {}
