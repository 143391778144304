import { EventEmitter, Injectable, Output, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { IDrawerConfig } from '@common/types';

interface IDrawerPanel {
  component: Type<any>;
  input: any;
  config: IDrawerConfig;
}

@Injectable({
  providedIn: 'root'
})
export class DrawerPanelSetupService {
  // outputInstance$ = new ReplaySubject<any>(1);

  @Output() instance: EventEmitter<any> = new EventEmitter<any>()
  
  // public config$: Subject<IDrawerConfig> = new Subject<IDrawerConfig>();

  @Output() component$: EventEmitter<IDrawerPanel> = new EventEmitter<IDrawerPanel>()

  // public component$: Subject<{component: Type<any>, input: any}> = new Subject<{component: Type<any>, input: any}>();
  // public outputInstance$: Subject<any> = new Subject<any>();
  // outputInstance$: EventEmitter<any> = new EventEmitter<any>()
  // public component$: Subject<any> = new Subject<any>();

  public createComponent(component: Type<any>, input: any, config?: IDrawerConfig): Observable<any> {
    this.component$.next({ component, input, config });

    return this.instance;
  }
}