<mat-form-field *ngIf="control" class="w-full">
  <mat-chip-list #multipleChipList class="grow mr-0" [formControl]="control">
    <div class="ml-2 pt-2" *ngIf="selectedOptions.length">
      <mat-chip
        class="text-sm h-5 min-h-5"
        *ngFor="let option of selectedOptions"
        (removed)="onSelectedOptionRemove(option)"
      >
        <span>{{option.name}}</span>
        <button matChipRemove>
          <mat-icon class="icon-size-4 mt-0.5 ml-0.5" svgIcon="heroicons_outline:x"></mat-icon>
        </button>
      </mat-chip>
    </div>
    <input
      #multipleInput
      type="text"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [matChipInputFor]="multipleChipList"
      [formControl]="inputControl"
      id="mat-chip-list-input"
    />
  </mat-chip-list>
  <div class="flex items-center h-full cursor-pointer" matSuffix (click)="multipleInput.focus()">
    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:chevron-down"></mat-icon>
  </div>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="onSelect($event.option.value, multipleInput)"
    class="com-perfect-scrollbar"
  >
    <mat-option
      *ngIf="options.length === 0; else optionList"
      class="opacity-60 text-center text-sm pointer-events-none"
    >
      нет совпадений
    </mat-option>

    <ng-template #optionList>
      <mat-option
        *ngFor="let option of options"
        [value]="option"
        class="leading-5 h-auto py-2.5 whitespace-normal"
      >
        <div class="flex flex-col leading-normal py-1">
          <span class="truncate"> {{ option.name }}</span>
          <span *ngIf="option.position" class="truncate text-sm text-gray-500">
            {{ option.position.name }}</span
          >
        </div>
      </mat-option>
    </ng-template>
  </mat-autocomplete>
</mat-form-field>
