import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss']
})
export class RightPanelComponent {
  @Input()
  public isOpen: boolean;

  @Output()
  public close = new EventEmitter();

  public onClose(): void {
    this.close.emit();
  }
}
