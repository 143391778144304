import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class TypicalJobInstructionService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _fileService: FileService
  ) {}

  public downloadMemberJobInstruction(filename: string = 'file'): void {
    this._http
      .get('api/TypicalJobInstruction/member', {
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        catchError((err) => {
          throw new Error(`error in source. Details: ${err}`);
        })
      )
      .subscribe((res) => {
        this._fileService.saveFile(res.body, filename);
      });
  }

  public downloadChairmanJobInstruction(filename: string = 'file'): void {
    this._http
      .get('api/TypicalJobInstruction/chairman', {
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        catchError((err) => {
          throw new Error(`Can't download. Details: ${err}`);
        })
      )
      .subscribe((res) => {
        this._fileService.saveFile(res.body, filename);
      });
  }
}
