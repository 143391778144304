import { IOption } from '../types';
import { WeekCodeEnum } from '@common/enums/rrule.enum';

/* eslint-disable @typescript-eslint/naming-convention */
export const TIMEZONE_TRANSLATIONS = {
  'Asia/Anadyr': 'Анадырь',
  'Asia/Barnaul': 'Барнаул',
  'Asia/Chita': 'Чита',
  'Asia/Irkutsk': 'Иркутск',
  'Asia/Kamchatka': 'Камчатка',
  'Asia/Khandyga': 'Хандыга',
  'Asia/Krasnoyarsk': 'Красноярск',
  'Asia/Magadan': 'Магадан',
  'Asia/Novokuznetsk': 'Новокузнецк',
  'Asia/Novosibirsk': 'Новосибирск',
  'Asia/Omsk': 'Омск',
  'Asia/Sakhalin': 'Сахалин',
  'Asia/Srednekolymsk': 'Среднеколымск',
  'Asia/Tomsk': 'Томск',
  'Asia/Ust-Nera': 'Усть-Нера',
  'Asia/Vladivostok': 'Владивосток',
  'Asia/Yakutsk': 'Якутск',
  'Asia/Yekaterinburg': 'Екатеринбург',
  'Asia/Yuzhno-Sakhalinsk': 'Южно-Сахалинск',
  'Europe/Astrakhan': 'Астрахань',
  'Europe/Kaliningrad': 'Калининград',
  'Europe/Kirov': 'Киров',
  'Europe/Moscow': 'Москва',
  'Europe/Samara': 'Самара',
  'Europe/Saratov': 'Саратов',
  'Europe/Simferopol': 'Симферополь',
  'Europe/Ulyanovsk': 'Ульяновск',
  'Europe/Volgograd': 'Волгоград'
};

export const WEEK_DAYS: IOption[] = [
  { id: WeekCodeEnum.MO, name: 'Пн' },
  { id: WeekCodeEnum.TU, name: 'Вт' },
  { id: WeekCodeEnum.WE, name: 'Ср' },
  { id: WeekCodeEnum.TH, name: 'Чт' },
  { id: WeekCodeEnum.FR, name: 'Пт' },
  { id: WeekCodeEnum.SA, name: 'Сб' },
  { id: WeekCodeEnum.SU, name: 'Вс' }
];

export const WEEK_CODES: string[] = [
  WeekCodeEnum.MO,
  WeekCodeEnum.TU,
  WeekCodeEnum.WE,
  WeekCodeEnum.TH,
  WeekCodeEnum.FR,
  WeekCodeEnum.SA,
  WeekCodeEnum.SU
];
