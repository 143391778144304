import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { StatusColors } from '@common/enums';
import { ICalendarEvent } from '@common/types';

@Directive({
  selector: '[statusColor]'
})
export class StatusColorDirective implements OnChanges {
  @Input() event: ICalendarEvent;
  constructor(private readonly el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('event' in changes) {
      const different = new Date(this.event.eventTime).getTime() - Date.now();
      if (different > 0) this.el.nativeElement.classList.add(StatusColors.Red);
      const milliseconds = this.event.duration * 60000;
      const untilDate = milliseconds + new Date(this.event.eventTime).getTime();
      if (untilDate - Date.now() > 0)
        this.el.nativeElement.classList.add(StatusColors.Gray);
      this.el.nativeElement.classList.add(StatusColors.Green);
    }
  }
}
