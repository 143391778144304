import { Component, OnInit, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { AuthService, UnsubscribeService } from '@common/services';
import { IUser } from '@common/types/user';

@Component({
  selector: 'com-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [UnsubscribeService]
})
export class UserComponent implements OnInit {
  public user: IUser;

  constructor(
    private readonly _authService: AuthService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    this._authService.user$
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((user) => {
        this.user = user;
      });
  }

  public logout(): void {
    this._authService.logout$()
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe();
  }
}
