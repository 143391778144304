import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventInterval } from '@common/dialogs/planning-dialog/types';

@Component({
  selector: 'com-intersection-item',
  templateUrl: 'intersection-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntersectionItemComponent {
  @Input() event: EventInterval;
}
