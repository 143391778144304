import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'com-employee-badge',
    templateUrl: 'employee-badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeBadgeComponent {
    @Input() fullName: string;
    @Input() isBusy: boolean;
    @Input() isSelected: boolean;
    @Input() timezone = '';

    constructor(private matSnackBar: MatSnackBar) {}

    get title(): string {
        const fullName = this.fullName.split(' ');
        return fullName[0][0] + fullName[1][0];
    }
    public onBadgeClick(): void {
        this.matSnackBar.open(this.isBusy ? `${this.timezone} ${this.fullName} занят(а)` : this.fullName, null, {
            duration: 2000,
            panelClass: ['flex', 'justify-center']
        });
    }
}
