import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProblemManualComponent } from '@common/dialogs/problem-manual/problem-manual.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ProblemManualComponent],
  imports: [CommonModule, MatDialogModule, MatIconModule]
})
export class ProblemManualModule {}
