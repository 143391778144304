<div class="relative flex flex-col w-full h-full max-h-128">
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button mat-dialog-close="">
      <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </div>
  <div class="flex flex-col gap-4 grow py-4">
    <div class="text-2xl font-semibold tracking-tight">Перенести событие</div>
    <p>
      При переносе события из режима видеозвонка текущий комитет перейдёт в
      статус "Запланирован", все участники будут отключены от видеоконференции и
      должны будут подключиться к событию заново с учетом времени переноса
    </p>
    <form class="flex flex-col gap-4" [formGroup]="formGroup">
      <div>
        <com-form-label
          text="Дата проведения"
          type="group"
          [required]="true"
        ></com-form-label>
        <com-form-input
          [control]="formGroup.controls.newDate"
          [min]="currentDate"
          type="datetime-local"
        ></com-form-input>
        <com-form-label
          text="Причина переноса"
          type="group"
          [required]="true"
        ></com-form-label>
        <com-form-basic-select
          class="h-12"
          [options]="TRANSFER_REASONS"
          [control]="formGroup.controls.reason"
        ></com-form-basic-select>
      </div>
      <div class="flex items-center justify-center sm:justify-end gap-4">
        <button mat-stroked-button mat-dialog-close="">Отмена</button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="formGroup.invalid"
          (click)="confirm()"
        >
          Подтвердить
        </button>
      </div>
    </form>
  </div>
</div>
