import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isNil } from 'lodash-es';

@Component({
  selector: 'com-right-panel-confirmation',
  templateUrl: './right-panel-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelConfirmationComponent {
  @Input()
  public confirmation?: boolean;

  public isNil = isNil;
}
