<com-form-block [showDivider]="false">
  <com-form-item>
    <com-form-toggle
      [control]="isFixedTerm"
      beforeLabel="С привязкой к времени начала комитета"
      afterLabel="Фиксированный срок"
      beforeLabelClass="w-69"
    ></com-form-toggle>
  </com-form-item>
  <com-form-item>
    <com-form-label
      text="Сроки загрузки"
      type="block"
      [required]="true"
    ></com-form-label>
    <ng-container [ngSwitch]="isFixedTerm.value">
      <com-form-group *ngSwitchCase="false">
        <com-form-group-item>
          <com-form-label text="за" type="group"></com-form-label>
          <ng-container [ngSwitch]="uploadBeforeType.value">
            <com-form-input
              *ngSwitchCase="UploadBeforeTypeEnum.DAYS"
              [control]="formGroup.controls.beforeDays"
              type="number"
            ></com-form-input>
            <com-form-input
              *ngSwitchCase="UploadBeforeTypeEnum.MINUTES"
              [control]="formGroup.controls.beforeMinute"
              type="number"
            ></com-form-input>
          </ng-container>
        </com-form-group-item>
        <com-form-group-item>
          <com-form-basic-select
            [control]="uploadBeforeType"
            [options]="UPLOAD_BEFORE_TYPE_OPTIONS"
          ></com-form-basic-select>
          <com-form-label text="до начала" type="group"></com-form-label>
        </com-form-group-item>
      </com-form-group>
      <com-form-group *ngSwitchDefault>
        <com-form-group-item>
          <com-form-label text="за" type="group"></com-form-label>
          <com-form-input
            [control]="formGroup.controls.beforeDays"
            type="number"
          ></com-form-input>
          <com-form-label text="дней до начала," type="group"></com-form-label>
        </com-form-group-item>
        <com-form-group-item>
          <com-form-label text="до" type="group"></com-form-label>
          <com-form-input
            [control]="formGroup.controls.beforeDaysTime"
            type="time"
          ></com-form-input>
        </com-form-group-item>
      </com-form-group>
    </ng-container>
  </com-form-item>
</com-form-block>
