<div *ngIf="weekDayMap" class="flex flex-col gap-1">
  <div *ngFor="let weekDay of DayInWeek" class="flex gap-1">
    <com-planning-diagram-cell
      *ngFor="let cell of weekDayMap[weekDay]"
      [type]="cell.type"
      [isSameYear]="cell.isSameYear"
      [isToday]="cell.isToday"
      [intersectionOrder]="cell.intersectionOrder"
      [showOrder]="cell.showOrder"
      [date]="cell.date"
      [cellSize]="cellSize"
      (click)="cellClick.emit(cell)"
    >
    </com-planning-diagram-cell>
  </div>
</div>
