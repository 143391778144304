import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { IEmployeeCurrentUserInfo } from '@common/types';
import { BehaviorSubject, debounceTime, fromEvent, Subject, takeUntil, tap } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ITimelineEvent,
  StatusOfEvent,
  Timeline,
  TotalTimeline
} from '@common/dialogs/intersection-dialog/types/timeline.types';
import { cloneDeep } from 'lodash-es';
import { BUSY } from '@common/dialogs/intersection-dialog/const/timeline.const';
import { isDayOfDatesEqual, setTimeZone } from '../../helpers/date.helpers';
import moment from 'moment';
import { BUTTON_SPINNER_DIAMETER } from '@common/constants';
import { ITimelineEntity } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';

@Component({
  selector: 'com-intersection-timeline',
  templateUrl: 'intersection-timeline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntersectionTimelineComponent implements OnInit, OnChanges, OnDestroy {
  @Input() forecastEventStart: string;
  @Input() forecastEventEnd: string;
  @Input() timelineEntities: ITimelineEntity[] = [];
  @Input() currentUser: IEmployeeCurrentUserInfo;
  @Input() isLoading: boolean;
  @Input() showCheckBoxes = false;
  @Input() disableAdaptive = false;
  @Output() selectMembers = new EventEmitter<string[]>();

  private eventTimeHours: number[] = [];
  private colorOfEventTime = 'bg-[#f1ee76]/30';
  private colorOfBusyTime = 'bg-[#e15757]/30';

  public timelineStart = 8;
  public timelineEnd = 20;
  public timelineSize = 12;
  private step = 0.5;

  public windowWidth$ = new BehaviorSubject<number>(0);
  public scale$ = new BehaviorSubject<Timeline[]>([]);
  public eventTimeline$ = new BehaviorSubject<TotalTimeline[]>([]);
  public timelineGrid$ = new BehaviorSubject<TotalTimeline[]>([]);
  public unsubscribe$ = new Subject<void>();

  public ButtonSpinnerDiameter = BUTTON_SPINNER_DIAMETER;

  constructor(private matSnackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.initTimelineSize();
    this.initTimelineStartAndEnd();
    this.initEventTime();
    this.initScale();
    this.initTimeline(this.timelineEntities);
    !this.disableAdaptive && this.initSizeSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('forecastEventStart' in changes || 'forecastEventEnd' in changes || 'rooms' in changes) {
      this.initTimelineSize();
      this.initTimelineStartAndEnd();
      this.initEventTime();
      this.initScale();
      this.initTimeline(this.timelineEntities);
    }
    if ('timelineEntities' in changes) {
      this.initTimeline(this.timelineEntities);
    }
  }

  private initEventTime(): void {
    const [startHour, endHour] = this.getStartHourAndEndHour(this.forecastEventStart, this.forecastEventEnd);

    if (startHour !== null && endHour !== null) {
      this.eventTimeHours = [startHour, endHour];
    }
  }

  private initScale(): void {
    const scale = [];
    for (let i = this.timelineStart; i < this.timelineEnd; i = i + this.step) {
      let valueName = null;
      if (Number.isInteger(i)) {
        valueName = String(i);
        valueName = valueName.length < 2 ? '0' + valueName : valueName;
      }

      scale.push({
        value: i,
        valueName,
        status: StatusOfEvent.empty,
        color: null
      });
    }
    this.scale$.next(scale);
  }

  private initSizeSubscription(): void {
    this.windowWidth$.next(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        tap(() => this.checkTimelineSize()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private initTimelineSize(): void {
    const { innerWidth } = window;
    this.windowWidth$.next(innerWidth);
    switch (true) {
      case innerWidth <= 375:
        this.timelineSize = 6;
        break;
      case innerWidth <= 415:
        this.timelineSize = 7;
        break;
      case innerWidth <= 460:
        this.timelineSize = 8;
        break;
      case innerWidth <= 520:
        this.timelineSize = 9;
        break;
      case innerWidth <= 560:
        this.timelineSize = 10;
        break;
      case innerWidth <= 620:
        this.timelineSize = 11;
        break;
      default:
        this.timelineSize = 12;
        break;
    }
  }

  private checkTimelineSize(): void {
    const lastTimelineSize = this.timelineSize;
    this.initTimelineSize();
    if (lastTimelineSize !== this.timelineSize) {
      this.initTimelineStartAndEnd();
      this.initEventTime();
      this.initScale();
      this.initTimeline(this.timelineEntities);
    }
  }

  private createTimelineEvents(member: ITimelineEntity): ITimelineEvent[] {
    let events: ITimelineEvent[] = [];

    for (let event of member.events) {
      let [startHour, endHour] = this.getStartHourAndEndHour(event.startTime, event.endTime);

      if (startHour !== null && endHour !== null) {
        if (event.isAllDay) {
          events = [
            {
              ...event,
              eventStart: 0,
              eventEnd: 24
            }
          ];
          break;
        }
        events.push({
          ...event,
          eventStart: this.checkGraphStep(startHour),
          eventEnd: this.checkGraphStep(endHour)
        });
      }
    }

    return [...events, this.getForecastEvent()];
  }

  private checkBorders(event: ITimelineEvent): ITimelineEvent {
    if (event.eventStart < this.timelineStart) {
      event.eventStart = this.timelineStart;
    }

    if (event.eventEnd > this.timelineEnd) {
      event.eventEnd = this.timelineEnd;
    }

    return event;
  }

  private createTimeline(events: ITimelineEvent[]): Timeline[] {
    const timelineEvents: Timeline[] = [];
    events.forEach((event) => {
      const lastTimeline = timelineEvents[timelineEvents.length - 1];
      if (!lastTimeline) {
        timelineEvents.push(this.getEvent(event));
        return;
      }

      if (lastTimeline?.value[0] > event.eventStart && lastTimeline?.value[1] < event.eventEnd) {
        return;
      }

      if (event.eventStart > lastTimeline?.value[0] && event.eventEnd < lastTimeline?.value[1]) {
        timelineEvents.slice(-1);
      }

      timelineEvents.push(this.getEvent(event));
    });
    return timelineEvents;
  }

  private initTimeline(statuses: ITimelineEntity[]): void {
    this.initGrid(statuses);
    this.initEventOnTimeline(statuses);
  }

  private initEventOnTimeline(statuses: ITimelineEntity[]): void {
    this.eventTimeline$.next(
      statuses.map((member) => {
        return {
          ...member,
          timeline: this.mergeCells(
            this.createTimeline(
              this.createTimelineEvents(member)
                .map((event) => this.checkBorders(event))
                .filter((event) => event.eventEnd > event.eventStart)
                .sort((a, b) => a.eventStart - b.eventStart)
            )
          )
        };
      })
    );
  }

  private initGrid(statuses: ITimelineEntity[]): void {
    this.timelineGrid$.next(
      cloneDeep(statuses).map((member) => {
        member.events = [];
        return {
          ...member,
          timeline: this.initEmptyTimeline()
        };
      })
    );
  }

  private mergeCells(timelineEvents: Timeline[]): Timeline[] {
    const timeMap = new Map<number, Date>();
    const timelineMap = this.createTimelineMap();
    const eventTime = timelineEvents.find((c) => c.status === StatusOfEvent.eventTime);

    if (timelineEvents.some((t) => t.event?.isAllDay)) {
      return [
        {
          value: [this.timelineStart, this.timelineEnd],
          valueName: BUSY,
          status: StatusOfEvent.userEvent,
          color: this.colorOfBusyTime,
          startTime: null,
          endTime: null,
          isAllDay: true
        },
        eventTime
      ];
    }

    timelineEvents.map((timelineEvent) => {
      if (timelineEvent.status === StatusOfEvent.userEvent) {
        const [start, end] = timelineEvent.value;
        timeMap.set(start, setTimeZone(timelineEvent.event.startTime));
        timeMap.set(end, setTimeZone(timelineEvent.event.endTime));

        for (let i = start; i <= end; i += 0.02) {
          i = parseFloat(i.toFixed(2));
          timelineMap.set(i, true);
        }
      }
    });

    return this.calculateMergedTimeline(timelineMap, eventTime, timeMap);
  }

  private checkGraphStep(value: number): number {
    const temp = value.toString();
    const lastDigit = parseInt(temp[temp.length - 1]);
    if (lastDigit % 2 !== 0 && temp.split('.')[1]?.length > 1) {
      return parseFloat((value - 0.01).toFixed(2));
    }
    return value;
  }

  private createTimelineMap(): Map<number, boolean> {
    const timelineMap = new Map<number, boolean>();
    for (let i = 0; i <= 24; i = i += 0.02) {
      timelineMap.set(parseFloat(i.toFixed(2)), false);
    }
    return timelineMap;
  }

  private calculateMergedTimeline(
    timelineMap: Map<number, boolean>,
    eventTime: Timeline,
    timeMap: Map<number, Date>
  ): Timeline[] {
    const result: Timeline[] = [eventTime];
    let startTimeStep = null;
    let endTimeStep = null;

    for (let [key, value] of timelineMap) {
      key = parseFloat(key.toFixed(2));

      if (!startTimeStep) {
        startTimeStep = key;
      }

      if (value) {
        endTimeStep = key;
      } else {
        if (startTimeStep && endTimeStep) {
          if (startTimeStep === 0.02) startTimeStep = 0;
          result.push({
            value: [startTimeStep, endTimeStep],
            valueName: BUSY,
            status: StatusOfEvent.userEvent,
            color: this.colorOfBusyTime,
            startTime: timeMap.get(startTimeStep),
            endTime: timeMap.get(endTimeStep),
            isAllDay: false
          });
        }
        startTimeStep = null;
        endTimeStep = null;
      }
    }
    return result;
  }

  public changeMemberActive(): void {
    this.selectMembers.emit(this.timelineEntities.filter((m) => m.id && m.selected).map((m) => m.id));
  }

  public changeIntervalAdd(): void {
    this.timelineStart = this.timelineStart + 1;
    this.timelineEnd = this.timelineEnd + 1;
    this.initScale();
    this.initTimeline(this.timelineEntities);
  }

  public changeIntervalSubtract(): void {
    this.timelineStart = this.timelineStart - 1;
    this.timelineEnd = this.timelineEnd - 1;
    this.initScale();
    this.initTimeline(this.timelineEntities);
  }

  public onEventClick(cell: Timeline): void {
    this.matSnackBar.open(this.generateInfoAboutEvent(cell), null, {
      duration: 2000,
      panelClass: ['flex', 'justify-center']
    });
  }

  private generateInfoAboutEvent(event: Timeline): string {
    if (event.isAllDay) {
      return 'Занят весь день';
    }
    return `${BUSY} ${moment(event.startTime).format('HH:mm')} - ${moment(event.endTime).format('HH:mm')}`;
  }

  private getStartHourAndEndHour(
    startTime: string | Date,
    endTime: string | Date
  ): [startHour: number | null, endHour: number | null] {
    let startHour = Number(moment(setTimeZone(startTime)).format('HH'));
    let endHour = Number(moment(setTimeZone(endTime)).format('HH'));
    const startMinutes = Number(moment(setTimeZone(startTime)).minutes());
    const endMinutes = Number(moment(setTimeZone(endTime)).minutes());

    startHour += +(startMinutes / 60).toFixed(2);
    endHour += +(endMinutes / 60).toFixed(2);

    if (startHour === endHour) {
      startHour = null;
      endHour = null;
    }
    if (startHour > endHour) {
      if (isDayOfDatesEqual(this.forecastEventStart, startTime)) {
        endHour = 24;
      } else {
        startHour = 0;
      }
    }
    return [startHour, endHour];
  }

  private initTimelineStartAndEnd(): void {
    const startHour = Number(moment(this.forecastEventStart).format('HH'));
    const endHour = Number(moment(this.forecastEventEnd).format('HH'));
    const sizeCorrection = 11 - this.timelineSize;

    this.timelineStart = startHour - 4;
    this.timelineEnd = endHour + 8;

    if (this.timelineStart + this.timelineEnd > 12) {
      this.timelineStart = startHour - 1;
      this.timelineEnd = startHour + 10 - sizeCorrection;
    }

    if (this.timelineStart <= 0) {
      this.timelineStart = 0;
      this.timelineEnd = 12 - sizeCorrection;
    }

    if (this.timelineEnd > 24) {
      this.timelineEnd = 24;
      this.timelineStart = 13 + sizeCorrection;
    }
  }

  private initEmptyTimeline(): Timeline[] {
    const beforeTimelines: Timeline[] = [];
    let beforeTime = this.timelineStart;
    while (beforeTime < this.timelineEnd) {
      const startHour = beforeTime;
      beforeTime = beforeTime + 1 < this.timelineEnd ? beforeTime + 1 : this.timelineEnd;

      beforeTimelines.push({
        value: [startHour, beforeTime],
        status: StatusOfEvent.empty,
        color: null,
        startTime: null,
        endTime: null,
        isAllDay: false
      });
    }

    return beforeTimelines;
  }

  private getEvent(event: ITimelineEvent): Timeline {
    if (event.eventTemplateId) {
      return {
        value: [event.eventStart, event.eventEnd],
        valueName: 'Занят',
        status: StatusOfEvent.userEvent,
        color: this.colorOfBusyTime,
        startTime: null,
        endTime: null,
        isAllDay: event.isAllDay,
        event
      };
    }

    return {
      value: [event.eventStart, event.eventEnd],
      status: StatusOfEvent.eventTime,
      color: this.colorOfEventTime,
      startTime: null,
      endTime: null,
      isAllDay: null
    };
  }

  private getForecastEvent(): ITimelineEvent {
    return {
      startDate: null,
      endDate: null,
      startTime: null,
      isAllDay: false,
      reason: null,
      endTime: null,
      confirmed: null,
      factType: null,
      eventTemplateTypeId: null,
      eventTemplateId: null,
      eventStart: this.eventTimeHours[0],
      eventEnd: this.eventTimeHours[1]
    };
  }
}
