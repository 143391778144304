import { CalendarMockApi } from './calendar/api';
import { NavigationMockApi } from './navigation/api';
import { NotificationsMockApi } from './notifications/api';
import { SearchMockApi } from './search/api';

export const mockApiServices = [
  NavigationMockApi,
  NotificationsMockApi,
  SearchMockApi,
  CalendarMockApi
];
