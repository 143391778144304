import { NgModule } from '@angular/core';
import { CommitteeEventStatusComponent } from './committee-event-status.component';
import { ComStatusPointModule } from '../status-point/status-point.module';
import { SharedPipesModule } from '@common/pipes';

@NgModule({
  imports: [ComStatusPointModule, SharedPipesModule],
  exports: [CommitteeEventStatusComponent],
  declarations: [CommitteeEventStatusComponent]
})
export class CommitteeEventStatusModule {}
