<form (ngSubmit)="onSubmit()">
  <header class="pb-4 text-center text-2xl border-b-1-gray-500 font-bold">Нарушения дисциплины:</header>
  <div class="flex flex-col gap-y-1 min-w-[400px]">
    <com-form-checkbox
      [control]="formGroup.controls.usingGadget"
      [label]="E_DISCIPLINE_VIOLATION.USING_GADGET_DURING_MEETING"
    ></com-form-checkbox>
    <com-form-checkbox
      [control]="formGroup.controls.leavingMeeting"
      [label]="E_DISCIPLINE_VIOLATION.LEAVING_ROOM_DURING_MEETING"
    ></com-form-checkbox>
    <com-form-checkbox
      [control]="formGroup.controls.eatingMeeting"
      [label]="E_DISCIPLINE_VIOLATION.EATING_IN_THE_MEETING"
    ></com-form-checkbox>
    <com-form-checkbox
      [control]="formGroup.controls.connectionFromNonWorkspace"
      [label]="E_DISCIPLINE_VIOLATION.CONNECTION_FROM_NON_WORKPLACE"
    ></com-form-checkbox>
    <com-form-checkbox
      [control]="formGroup.controls.connectionWithoutVideo"
      [label]="E_DISCIPLINE_VIOLATION.REMOTE_CONNECTION_WITHOUT_VIDEO"
    ></com-form-checkbox>
    <com-form-checkbox
      [control]="formGroup.controls.absenceInTheMeeting"
      [label]="E_DISCIPLINE_VIOLATION.ABSENCE_IN_THE_MEETING"
    ></com-form-checkbox>
  </div>
  <footer class="flex w-full justify-end gap-2 mt-6">
    <button mat-flat-button type="button" color="warn" (click)="onClose()">
      <span>Отмена</span>
    </button>
    <button mat-flat-button color="primary" type="submit">
      <span>Сохранить</span>
    </button>
  </footer>
</form>
