import { ChangeDetectionStrategy, Component, ElementRef, Input, Self, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileService, UnsubscribeService } from '@common/services';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, catchError, filter, of, switchMap, tap } from 'rxjs';
import { UploadedFileDto } from '@common/types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@common/modules/shared';

@Component({
  selector: 'com-form-file-upload',
  templateUrl: './form-file-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class FormFileUploadComponent {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() control = new FormControl<UploadedFileDto>(null);
  @Input() acceptFile = '';
  @Input() disabled = false;

  public multiple = true;
  public inputControl = new FormControl<string>(null);

  public fileName$ = new BehaviorSubject<string | null>(null);
  public isDocumentLoading$ = new BehaviorSubject(false);

  constructor(
    private fileService: FileService,
    private _snackBar: MatSnackBar,
    @Self() private unsubscribeService: UnsubscribeService
  ) {
    this.fileChangeSub();
  }

  private fileChangeSub(): void {
    this.inputControl.valueChanges
      .pipe(
        filter(Boolean),
        tap(() => {
          if (this.input.nativeElement.files[0].size === 0) {
            this._snackBar.openFromComponent(SnackbarComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 10000,
              data: {
                icon: 'heroicons_outline:exclamation',
                iconClass: 'text-orange-500',
                title: 'Файл пустой!',
                message: 'Проверьте содержимое загружаемого файла'
              },
              panelClass: 'committees-app'
            });
          }
        }),
        filter(() => this.input.nativeElement.files[0].size > 0),
        tap(() => this.isDocumentLoading$.next(true)),
        switchMap(() => this.fileService.createFile(this.input.nativeElement.files[0])),
        tap((uploadedFile) => this.seFile(uploadedFile)),
        catchError(() => {
          this.isDocumentLoading$.next(false);
          return of(null);
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  private seFile(uploadedFile: UploadedFileDto): void {
    const fileName = this.input.nativeElement.files[0].name;
    this.control.setValue({
      file: this.input.nativeElement.files[0],
      fileId: uploadedFile.fileId,
      fileName
    });
    this.isDocumentLoading$.next(false);
    this.fileName$.next(fileName);
  }

  public onDeleteFile(): void {
    this.inputControl.setValue(null);
    this.fileName$.next(null);
    this.input.nativeElement.value = '';
    this.control.setValue(null);
  }

  onFileDownload(): void {
    this.fileService.downloadFile(this.control.value.fileId, this.control.value.fileName);
  }
}
