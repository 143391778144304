import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';
import { CalendarEventService } from '@common/services';
import { FuseConfirmationService } from '@common/fuse/services/confirmation';
import { FuseDialogActionsEnum } from '@common/enums';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  constructor(
    protected readonly router: Router,
    private readonly _matDialog: FuseConfirmationService,
    private readonly _calendarEventService: CalendarEventService
  ) {}

  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const eventId = route.params.eventId;

    return this._matDialog
      .open({
        title: 'Отключение от видеоконференции',
        message: 'Вы действительно собираетесь покинуть видеоконференцию?',
        actions: {
          confirm: {
            show: true,
            label: 'Да',
            color: 'warn'
          },
          cancel: {
            show: true,
            label: 'Нет'
          }
        }
      })
      .afterClosed()
      .pipe(
        switchMap((res) => {
          if (res === FuseDialogActionsEnum.CONFIRMED) {
            return this._calendarEventService
              .checkOutEvent(eventId)
              .pipe(map(() => true));
          }
          return of(false);
        })
      );
  }
}
