import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[disableButtons]'
})
export class DisableButtonsDirective implements OnChanges {
  @Input() buttonId: string;
  @Input() isDisable: boolean;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('isDisable' in changes && this.buttonId) {
      const buttons = this.elementRef.nativeElement.querySelectorAll(`#${this.buttonId}`);
      buttons.forEach((button: HTMLButtonElement) => {
        button.disabled = this.isDisable;
      });
    }
  }
}
