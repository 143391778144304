import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContentChange, QuillEditorComponent, SelectionChange } from 'ngx-quill';
import { FormControl } from '@angular/forms';
import Quill, { RangeStatic } from 'quill';
import { EditableBlot } from '@common/classes';
import { EditorBlotsEnum } from '@common/enums';
import { EDITABLE_BLOT_NAME } from '@common/constants';

@Component({
  selector: 'com-form-editor',
  templateUrl: './form-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormEditorComponent implements OnInit, AfterViewInit {
  @Input() control: FormControl<string>;
  @Input() placeholder = 'Введите значение';
  @Input() hideTools: boolean = false;
  @Input() set readonly(value: boolean) {
    this._readonly = value;
    this._updateEditable();
  }

  @ViewChild(QuillEditorComponent) private readonly _editor: QuillEditorComponent;

  public range: RangeStatic;
  private _readonly: boolean = false;

  protected readonly EditorBlotsEnum = EditorBlotsEnum;
  protected readonly EDITABLE_BLOT_NAME = EDITABLE_BLOT_NAME;

  ngOnInit(): void {
    Quill.register(EditableBlot);
  }

  ngAfterViewInit(): void {
    this._updateEditable();
  }

  public onEditorChanged(event: SelectionChange | ContentChange): void {
    this.range = event.editor.getSelection();
  }

  public onEditorButtonClick(blot: EditorBlotsEnum): void {
    this._editor.quillEditor.insertEmbed(this.range?.index || 0, EDITABLE_BLOT_NAME, blot, 'user');
    this._editor.quillEditor.setSelection({
      index: (this.range?.index || 0) + 1,
      length: this.range?.length || 0
    });
  }

  private _updateEditable() {
    if (this._editor?.quillEditor) {
      if (this._readonly) {
        this._editor.quillEditor.disable();
      } else {
        this._editor.quillEditor.enable();
      }
    }
  }
}
