<div class="flex justify-between items-center w-full">
  <div class="flex gap-2 items-center cursor-pointer">
    <span class="text-foreground text-[20px] font-[700]"> 2024 год </span>
  </div>
  <com-tab-buttons
    [options]="tabs"
    (selectTab)="tabChange.emit($event)"
    [currentTab]="currentTab"
  ></com-tab-buttons>
</div>
