import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  Self,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import moment, { isMoment, Moment } from 'moment/moment';
import { filter, takeUntil } from 'rxjs';
import { UnsubscribeService } from '@common/services';

@Component({
  selector: 'com-form-datepicker',
  templateUrl: './form-datepicker.component.html',
  styleUrls: ['form-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [UnsubscribeService]
})
export class FormDatepickerComponent implements AfterViewInit {
  @Input() startControl: FormControl<moment.Moment | string>;
  @Input() endControl: FormControl<moment.Moment | string>;
  @Input() placeholder = 'Введите значение';

  @ViewChild('picker') picker: MatDateRangePicker<any>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Self() private readonly unsubscribeService: UnsubscribeService
  ) {}

  myFilter = (d: Moment | null): boolean => {
    const day = (d || moment()).day();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  ngAfterViewInit(): void {
    this._setSubscriptions();
    this.cdr.detectChanges();
  }

  private _setSubscriptions(): void {
    this.startControl.valueChanges
      .pipe(
        filter((value) => isMoment(value)),
        takeUntil(this.unsubscribeService)
      )
      .subscribe((value) => {
        this.startControl.setValue((value as moment.Moment).toISOString(true));
      });
    this.endControl?.valueChanges
      .pipe(
        filter((value) => !!value && isMoment(value)),
        takeUntil(this.unsubscribeService)
      )
      .subscribe((value) => {
        this.endControl.setValue((value as moment.Moment).toISOString(true));
      });
  }
}
