import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { RightPanelBlockComponent } from './components/right-panel-block/right-panel-block.component';
import { RightPanelConfirmationComponent } from './components/right-panel-confirmation/right-panel-confirmation.component';
import { RightPanelComponent } from './components/right-panel/right-panel.component';
import { RightPanelMembersComponent } from './components/right-panel-members/right-panel-members.component';
import { EmployeeModule } from '../employee/employee.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RightPanelHeaderComponent } from './components/right-panel-header/right-panel-header.component';
import { RightPanelMembersBlockComponent } from './components/right-panel-members-block/right-panel-members-block.component';

@NgModule({
  declarations: [
    RightPanelComponent,
    RightPanelBlockComponent,
    RightPanelConfirmationComponent,
    RightPanelMembersComponent,
    RightPanelHeaderComponent,
    RightPanelMembersBlockComponent
  ],
  imports: [CommonModule, MaterialModule, EmployeeModule, ReactiveFormsModule],
  exports: [
    RightPanelComponent,
    RightPanelBlockComponent,
    RightPanelConfirmationComponent,
    RightPanelMembersComponent,
    RightPanelHeaderComponent
  ]
})
export class RightPanelModule {}
