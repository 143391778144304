import { MemberMaterialStatusEnum, MemberPresenceStatusEnum } from '@common/enums';

export type LoggingActions =
  | CommitteeEventActions
  | CommitteeActions
  | CommitteeMemberActions
  | ResolutionActions
  | ProtocolEventActions
  | CallActions
  | DefaultActions
  | RoleActions
  | UserActions
  | PreResolutionActions
  | Centrifugo;

export type LoggingData = Partial<ILoggingData>;

interface ILoggingData {
  employeeId: string;
  eventId: string;
  materialName: string;
  resolutionId: string;
  eventResolutionId: string;
  userRating: string;
  id: string;
  roleId: string;
  roleName: string;
  committeeId: string;
  delegateFromMember: string;
  delegateTo: string;
  agendaId: string;
  materialId: string;
  status: MemberPresenceStatusEnum | MemberMaterialStatusEnum;
  delegateFrom: string;
  page: string;
  channel: string;
  message: string;
  code: number;
  blocked: boolean;
  data: any;
}

export enum Centrifugo {
  centrifugoErrorSubscribe = 'centrifugo_error_subscribe',
  centrifugoConnect = 'centrifugo_connect',
  centrifugoDisconnect = 'centrifugo_disconnect',
  centrifugoSubscribe = 'centrifugo_subscribe',
  centrifugoUnsubscribe = 'centrifugo_unsubscribe',
  centrifugoNewMessage = 'centrifugo_new_message',
  centrifugoIamGo = 'centrifugo_i_am_go'
}

export enum CommitteeActions {
  toCompromiseModelView = 'to_compromise_model_view',
  toAgendaItemsView = 'to_agenda_items_view',
  create = 'committee_action_button_create',
  checkIntersection = 'committee_check_intersection_button',
  openCreateCommitteeForm = 'open_create_committee_form_button',
  update = 'committee_action_button_update',
  openUpdateCommitteeFormFromCommitteeTable = 'open_update_committee_form_from_committee_table',
  openUpdateCommitteeFormFromCommitteeView = 'open_update_committee_form_from_committee_view',
  removeCommittee = 'remove_committee_button',
  restoreCommittee = 'restore_committee_button',
  openCommitteeView = 'open_committee_view',
  changeCommitteeFilter = 'change_committee_filter',
  cleanCommitteeFilter = 'clean_committee_filter',
  changeFieldIdealCommitteeForm = 'change_field_ideal_committee_form',
  changeFieldCompromiseCommitteeForm = 'change_field_compromise_committee_form',
  addRoleIdealCommitteeForm = 'add_role_ideal_committee_form',
  addRoleCompromiseCommitteeForm = 'add_role_compromise_committee_form',
  removeMemberIdealCommitteeForm = 'remove_member_ideal_committee_form',
  removeMemberCompromiseCommitteeForm = 'remove_member_compromise_committee_form',
  addMemberIdealCommitteeForm = 'add_member_ideal_committee_form',
  addMemberCompromiseCommitteeForm = 'add_member_compromise_committee_form',
  addTestQuestionCommitteeForm = 'add_test_questions_committee_form',
  removeTestQuestionCommitteeForm = 'remove_test_questions_committee_form',
  addAgendaItemCommitteeForm = 'add_agenda_item_committee_form',
  removeAgendaItemCommitteeForm = 'remove_agenda_item_committee_form',
  changeOrderAgendaItemCommitteeForm = 'change_order_agenda_item_committee_form',
  addSpeakerAgendaItemsCommitteeForm = 'add_speaker_agenda_items_committee_form',
  removeSpeakerAgendaItemsCommitteeForm = 'remove_speaker_agenda_items_committee_form',
  addResolutionsAgendaItemsCommitteeForm = 'add_resolutions_agenda_items_committee_form',
  removeResolutionsAgendaItemsCommitteeForm = 'remove_resolutions_agenda_items_committee_form',
  openProtocolAndRegulatoryRegulatorsFromTable = 'open_protocol_and_regulatory_regulators_from_table',
  openProtocolAndRegulatoryRegulatorsFromCommitteeEventView = 'open_protocol_and_regulatory_regulators_from_committee_event_view'
}

export enum CommitteeEventActions {
  openUnplannedDialogButton = 'open_unplanned_dialog_button',
  openViewFromCalendar = 'open_view_from_calendar',
  createUnplanned = 'create_unplanned_button',
  transferEvent = 'transfer_event_button',
  openDrawerTransferEvent = 'open_drawer_transfer_event',
  cancelEvent = 'cancel_event_button',
  openDrawerCancelCommitteeEvent = 'open_drawer_cancel_committee_event',
  suspendedEvent = 'suspended_event_button',
  suspendedAndCreateUnplanned = 'suspended_and_create_unplanned_button',
  finishedEvent = 'finished_event_button',
  restoreEvent = 'restore_event_button',
  openEventViewDrawer = 'open_event_view_drawer',
  changeMaterialStatusCommitteeEventFromEventView = 'change_material_status_committee_event_from_event_view',
  clickInfoAllowanceCommitteeEvent = 'click_info_allowance_committee_event',
  openDialogChangeMeetingRoomCommitteeEvent = 'open_dialog_change_meeting_room_committee_event',
  changeMeetingRoomCommitteeEvent = 'change_meeting_room_committee_event',
  openMaterialSidebar = 'open_material_sidebar_from_event_view',
  fixQuorumAutomatic = 'fix_quorum_automatic_committee_event',
  fixQuorumManual = 'fix_quorum_manual_committee_event'
}

export enum ProtocolEventActions {
  sendProtocol = 'send_protocol_button',
  resendProtocol = 'resend_protocol_button',
  downloadProtocol = 'download_protocol_button',
  downloadProtocolZip = 'download_protocol_zip_button',
  downloadProtocolUnionPDF = 'download_protocol_union_pdf'
}

export enum CallActions {
  clickOpenCallCommitteeFromCalendar = 'click_open_call_committee',
  clickOpenCallCommitteeFromEventView = 'click_open_call_committee',
  reloadPage = 'reload_page_on_call',
  startCall = 'start_call',
  startAgendaItemDescussion = 'start_agenda_item_descussion',
  endCall = 'end_call',
  suspendCall = 'suspend_call',
  pauseCall = 'pause_call',
  continueCall = 'continue_call',
  loadPageCallCommittee = 'load_page_call_committee',
  checkIn = 'check_in',
  checkOut = 'check_out',
  changeMaterialStatusCommitteeEventFromCall = 'change_material_status_committee_event_from_call',
  leaveJitsi = 'leave_jitsi',
  joinJitsi = 'join_jitsi'
}

export enum RoleActions {
  createRole = 'create_role_button',
  updateRole = 'update_role_button',
  openDialogUpdateRole = 'open_dialog_update_role_button',
  blockRole = 'block_role_button',
  removeRole = 'remove_role_button'
}

export enum UserActions {
  updateUser = 'update_user',
  openDialogUpdateUser = 'open_dialog_update_user',
  blockUser = 'block_user'
}

export enum CommitteeMemberActions {
  delegateMember = 'delegate_member',
  openDialogDeleteDelegateMember = 'open_dialog_delete_delegate_member',
  deleteDelegateMember = 'delete_delegate_member',
  uploadMaterial = 'upload_material',
  deleteMaterial = 'delete_material',
  downloadMaterial = 'download_material',
  watchMaterial = 'watch_material',
  lookedAllMaterialZip = 'looked_all_material_zip',
  lookedAllMaterialPdf = 'looked_all_material_pdf',
  changePresentStatus = 'change_present_status',
  callAddViolationToMaterial = 'call_add_violation_to_material',
  eventAddViolationToMaterial = 'event_add_violation_to_material'
}

export enum ResolutionActions {
  addResolution = 'add_resolution',
  saveAddResolution = 'save_add_resolution',
  editResolution = 'edit_resolution',
  saveEditResolution = 'save_edit_resolution',
  openDialogDeleteResolution = 'open_dialog_delete_resolution',
  deleteResolution = 'delete_resolution',
  voteForResolution = 'vote_for_resolution',
  voteAgainstResolution = 'vote_against_resolution',
  voteAbstainedResolution = 'vote_abstained_resolution'
}

export enum PreResolutionActions {
  saveEditResolution = 'save_edit_preliminary_resolution',
  uploadMaterialPreResolution = 'upload_material_preliminary_resolution',
  saveRatingPreResolution = 'save_rating_preliminary_resolution',
  removeMaterialPreResolution = 'remove_material_preliminary_resolution',
  downloadMaterialPreResolution = 'download_material_preliminary_resolution',
  changeStatusPreResolution = 'change_status_preliminary_resolution',
  editPreResolution = 'edit_preliminary_resolution',
  deletePreResolution = 'delete_preliminary_resolution',
  addPreResolution = 'add_preliminary_resolution'
}

export enum DefaultActions {
  cancel = 'cancel_actions'
}
