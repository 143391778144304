export enum CommitteeRolesEnum {
  CHAIRMAN = 'Председатель',
  COCHAIR = 'Сопредседатель',
  CHAIRMAN_ASSISTANT = 'Помощник Председателя',
  RESPONSIBLE = 'Ответственный за загрузку материалов',
  ADMIN = 'Администратор ИС',
  DSM = 'ДСМ',
  DKO = 'ДКО',
  MANDATORY = 'Постоянные участники',
  INVITED = 'Приглашенные участники'
}
