import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'com-toggle',
    templateUrl: 'toggle.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent {
    @Input() control: AbstractControl;
    @Input() beforeLabel: string;
    @Input() afterLabel: string;
    @Input() beforeLabelClass: string;
    @Input() controlName: string;
    @Input() form: UntypedFormGroup;
}
