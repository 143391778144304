import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyName'
})
export class KeyNamePipe implements PipeTransform {
  transform(option: any, key: string): any {
    return (key && option[key]) || option;
  }
}
