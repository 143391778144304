import { Component, Input } from '@angular/core';

@Component({
  selector: 'com-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() sizePx = 28;
  @Input() fontSize = 'sm';
  @Input() isLink = false;
  @Input() isBorder = false;
  @Input() avatarUrl: string | null;
  @Input() name: string;
  @Input() isCircle = true;
  @Input() showStatus = false;
}
