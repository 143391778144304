<div class="flex flex-col gap-6 w-full overflow-hidden mb-4">
  <div class="w-full flex justify-between">
    <com-tab-buttons
      class="max-w-[168px]"
      [options]="tabs"
      [currentTab]="currentTab"
      (selectTab)="tabChange.emit($event)"
    ></com-tab-buttons>
    <div class="flex gap-4">
      <com-button variant="outline" size="sm" (buttonClick)="cancel.emit()"
        ><span class="text-[14px]">Отмена</span></com-button
      >
      <com-button
        *ngIf="draft; else saveBtn"
        [matMenuTriggerFor]="saveAsMenu"
        [disabled]="eventsLoading"
        size="sm"
        ><span class="text-[14px]">Сохранить как</span></com-button
      >
      <ng-template #saveBtn>
        <com-button
          (buttonClick)="save.emit()"
          [disabled]="(events | allIntersectionResolve) === false"
          size="sm"
          ><span class="text-[14px]">Сохранить</span></com-button
        >
      </ng-template>

      <mat-menu #saveAsMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="saveAsDraft.emit()">Черновик</button>
        <button mat-menu-item (click)="save.emit()" [disabled]="(events | allIntersectionResolve) === false">
          Комитет
        </button>
      </mat-menu>
    </div>
  </div>
  <div [ngSwitch]="currentTab">
    <com-intersection-table
      *ngSwitchCase="IntersectionTab.all"
      [loading]="eventsLoading"
      [events]="events"
      (selectDate)="tableSelect.emit($event)"
    ></com-intersection-table>
    <div
      *ngSwitchCase="IntersectionTab.one"
      class="flex flex-col gap-[40px] border border-planning-border rounded-lg p-6"
    >
      <ng-container *ngIf="timeline.length !== 0; else noIntersection">
        <div
          *ngIf="dayInfo?.planningDate"
          class="text-[24px] font-semibold overflow-hidden flex gap-5 items-center"
        >
          <span
            [ngClass]="{
              'line-through text-planning-gray': transferInfo?.transferDateInterval
            }"
          >
            {{ dayInfo.planningDate }}
          </span>
          <ng-container *ngIf="transferInfo?.transferDateInterval as transferInterval">
            <mat-icon svgIcon="common_icons:arrow-right"></mat-icon>
            <span> {{ transferInterval }}</span>
          </ng-container>
        </div>
        <com-busy-timeline
          [timelineEntities]="timeline"
          [isLoading]="dayLoading"
          [forecastEventEnd]="planningEnd"
          [forecastEventStart]="planningStart"
        ></com-busy-timeline>
        <ng-container *ngIf="dayLoading; else transferSetUp">
          <span class="opacity-50 min-h-10 text-[16px] flex items-center px-2"> Идет загрузка.. </span>
        </ng-container>
        <ng-template #transferSetUp>
          <com-free-slots
            [times]="freeSlots[selectedFreeSlotDay] || []"
            [days]="freeDays"
            [selectedDate]="transferInfo?.select?.selectedDate || freeDays[0]?.date || ''"
            [selectedTime]="transferInfo?.select?.selectedTime || ''"
            (dayChange)="selectedFreeSlotDay = $event"
            (dateChange)="selectedTransferDate = $event"
          ></com-free-slots>
        </ng-template>
        <div class="w-full flex justify-end">
          <com-button (buttonClick)="transferEvent()" [disabled]="!Boolean(selectedTransferDate)">
            <span class="text-[14px]">Перенести событие</span>
          </com-button>
        </div>
      </ng-container>
      <ng-template #noIntersection>
        <div class="flex flex-col gap-4 min-h-[300px]">
          <div class="text-[24px] font-semibold">
            {{ dayInfo?.planningDate }}
          </div>
          <div class="text-planning-gray">Пересечений в этот день не найдено</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
