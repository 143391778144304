import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  StatusOfEvent,
  Timeline
} from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/types';

@Directive({
  selector: '[cell]'
})
export class CellDirective implements OnChanges {
  @Input() cell: Timeline;
  @Input() index: number;
  @Input() timeline: Timeline[];
  @Input() timelineStart: number;
  @Input() indexOfRow: number;
  @Input() lastRowIndex: number;
  @Input() cellSize: number;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('cell' in changes) {
      if (this.timeline && this.cell) {
        this.calculateWidthOfCell();
      }
      if (this.cell) {
        const { color, status, beyondPlanningWindow } = this.cell;
        const classList = this.el.nativeElement.classList;

        if (status === StatusOfEvent.eventTime) {
          if (this.index === 0) classList.add('rounded-t-sm');
          if (this.index === this.lastRowIndex) classList.add('rounded-b-sm');
        }

        if (status === StatusOfEvent.userEvent) {
          classList.add('rounded-sm');
        }

        if (color) {
          classList.add(color);
        }

        if (beyondPlanningWindow) {
          classList.add('opacity-50');
        }
      }
    }
  }

  private calculateWidthOfCell(): void {
    const { status } = this.cell;
    const el = this.el.nativeElement;
    const width = (this.cell.value[1] - this.cell.value[0]) * this.cellSize;
    const left = (this.cell.value[0] - this.timelineStart) * this.cellSize;
    el.style.left = `${left}px`;
    el.style.width = `${width}px`;
    el.style.zIndex = this.getZIndex(status);
  }

  private getZIndex(eventType: StatusOfEvent): string {
    switch (eventType) {
      case StatusOfEvent.userEvent:
        return `${Math.ceil(1 * this.cell.value[1])}`;
      default:
        return '0';
    }
  }
}
