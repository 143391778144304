import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {catchError, combineLatest, map, Observable, of, switchMap} from 'rxjs';
import {
  AllowanceService,
  CalendarEventService, EmployeeService,
  JitsuLoggerService
} from '@common/services';
import { SnackbarComponent } from '@common/shared';
import { ProtocolStatusEnum } from '@common/enums';
import { CallActions } from '@common/constants';

@Injectable({
  providedIn: 'root'
})
export class CheckInGuard implements CanActivate {
  constructor(
    protected readonly router: Router,
    private readonly _snackBar: MatSnackBar,
    private readonly _jitsuLoggerService: JitsuLoggerService,
    private readonly _allowanceService: AllowanceService,
    private readonly _calendarEventService: CalendarEventService,
    private readonly _employeeService: EmployeeService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const eventId = route.params.eventId;
    this._jitsuLoggerService.logEvent(CallActions.checkIn, { eventId });
    return combineLatest([
      this._calendarEventService.retrieveCalendarEvent(eventId),
      this._employeeService.employeeCurrentUserInfo$
    ]).pipe(
      switchMap(([calendarEvent, currentEmployee]) => {
        if (calendarEvent.protocolStatus === ProtocolStatusEnum.FORMING) {
          return this._allowanceService
            .hasAllowanceToConnectCall(calendarEvent.id, currentEmployee.id)
            .pipe(map((hasAllowance) => ({ hasAllowance })));
        }
        this._snackBar.openFromComponent(SnackbarComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000,
          data: {
            icon: 'heroicons_outline:exclamation',
            iconClass: 'text-red-500',
            title: 'Комитет был завершен',
            message: 'Протокол уже сформирован'
          },
          panelClass: 'committees-app'
        });
        return of(false);
      }),
      switchMap((res: { hasAllowance: boolean }) => {
        if (res) {
          if (res.hasAllowance) {
            return this._calendarEventService.checkInEvent(eventId);
          }

          this._snackBar.openFromComponent(SnackbarComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 5000,
            data: {
              icon: 'heroicons_outline:exclamation',
              iconClass: 'text-red-500',
              title: 'Допуск отсутствует',
              message: 'Подключение к комитету невозможно: отсутствует допуск'
            },
            panelClass: 'committees-app'
          });
        }
        return of(false);
      }),
      catchError(() => of(false)),
      map((res) => {
        if (!res) {
          this.router.navigate([`/calendar/${eventId}`]);
        }
        return true;
      })
    );
  }
}
