<mat-form-field class="w-full">
  <ng-container [ngSwitch]="!!endControl">
    <input
      *ngSwitchCase="false"
      matInput
      [matDatepicker]="matDatepicker"
      [matDatepickerFilter]="myFilter"
      [formControl]="startControl"
      [placeholder]="placeholder"
    />
    <mat-date-range-input
      class="flex items-center justify-between"
      *ngSwitchCase="true"
      [rangePicker]="rangePicker"
    >
      <input matStartDate [formControl]="startControl" placeholder="От" />
      <input matEndDate [formControl]="endControl" placeholder="до" />
    </mat-date-range-input>
  </ng-container>
  <mat-datepicker-toggle
    matSuffix
    [for]="endControl ? rangePicker : matDatepicker"
  >
    <mat-icon
      class="icon-size-4"
      matDatepickerToggleIcon
      svgIcon="heroicons_outline:calendar"
    ></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #matDatepicker></mat-datepicker>
  <mat-date-range-picker #rangePicker></mat-date-range-picker>
</mat-form-field>
