import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CellType } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';

@Component({
  selector: 'com-planning-diagram-cell',
  templateUrl: 'planning-diagram-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningDiagramCellComponent {
  @Input() isSameYear: boolean;
  @Input() isToday: boolean;
  @Input() type: CellType;
  @Input() showOrder: boolean;
  @Input() intersectionOrder: number;
  @Input() date: Date;
  @Input() cellSize: number;

  protected CellType = CellType;
}
