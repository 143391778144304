import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ICalendarEvent, IEmployee, ITestResult } from '@common/types';
import { AllowanceService, UnsubscribeService } from '@common/services';
import { MemberAllowanceLabelEnum, MemberAllowanceStatusEnum } from '@common/enums';
import moment from 'moment';

@Component({
  selector: 'com-event-access-sidebar',
  templateUrl: './event-access-sidebar.component.html',
  providers: [UnsubscribeService]
})
export class EventAccessSidebarComponent implements OnInit {
  @Input() event: ICalendarEvent;
  @Input() committeeId: string;
  @Input() employeeId: string;
  @Input() responsibleOfTestDevelopment: IEmployee;
  @Input() plannedPeriodOfTestDevelopment: string;

  @Output() closeDrawer = new EventEmitter<void>();

  testResults: ITestResult[] = [];
  isDeadlineMissed = new Map<string, boolean>();

  constructor(
    private readonly _allowanceService: AllowanceService,
    private readonly _cdr: ChangeDetectorRef,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  ngOnInit(): void {
    this._allowanceService
      .retrieveTestResults(this.event.id, this.employeeId)
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((testResults) => {
        this.testResults = testResults;
        this.isDeadlineMissed = new Map(
          testResults.map((testResult) => [
            testResult.testId,
            moment().isAfter(testResult.deadline) &&
              [
                MemberAllowanceStatusEnum.NotAllowance,
                MemberAllowanceStatusEnum.NotEnoughPointForAllowance
              ].includes(testResult.status)
          ])
        );
        this._cdr.markForCheck();
      });
  }

  getColorStatus(testResult: ITestResult): string {
    switch (testResult.status) {
      case MemberAllowanceStatusEnum.AllowanceIsNotRequired:
        return '';
      case MemberAllowanceStatusEnum.HasAllowance:
        return 'color-limeGreen';
      case MemberAllowanceStatusEnum.NotAllowance:
        return 'text-red-500';
      case MemberAllowanceStatusEnum.NotEnoughPointForAllowance:
        return 'text-yellow-500';
      default:
        return '';
    }
  }

  getIconStatus(testResult: ITestResult): string {
    switch (testResult.status) {
      case MemberAllowanceStatusEnum.AllowanceIsNotRequired:
      case MemberAllowanceStatusEnum.HasAllowance:
      case MemberAllowanceStatusEnum.NotEnoughPointForAllowance:
        return 'heroicons_outline:check-circle';
      case MemberAllowanceStatusEnum.NotAllowance:
        return 'heroicons_outline:exclamation';
      default:
        return 'heroicons_outline:check-circle';
    }
  }

  getLabelStatus(testResult: ITestResult): string {
    switch (testResult.status) {
      case MemberAllowanceStatusEnum.AllowanceIsNotRequired:
        return MemberAllowanceLabelEnum.AllowanceIsNotRequired;
      case MemberAllowanceStatusEnum.HasAllowance:
        return MemberAllowanceLabelEnum.HasAllowance;
      case MemberAllowanceStatusEnum.NotAllowance:
        return MemberAllowanceLabelEnum.NotAllowance;
      case MemberAllowanceStatusEnum.NotEnoughPointForAllowance:
        return MemberAllowanceLabelEnum.NotEnoughPointForAllowance;
      default:
        return '';
    }
  }
}
