import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Self } from '@angular/core';
import { FormControl } from '@angular/forms';
import { finalize, takeUntil, tap } from 'rxjs';
import moment from 'moment';
import { ICalendarEvent, IOption } from '@common/types';
import { CalendarEventService, JitsuLoggerService, UnsubscribeService } from '@common/services';
import { DATE_TIME_FORMAT, TRANSFER_REASONS } from '@common/constants';
import { CommitteeEventActions } from '@common/constants/jitsu-logger.actions';

@Component({
  selector: 'com-event-cancel-sidebar',
  templateUrl: './event-cancel-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class EventCancelSidebarComponent {
  @Input() event: ICalendarEvent;

  @Output() cancelFinish = new EventEmitter<string>();
  @Output() closeDrawer = new EventEmitter<void>();

  public reasons: IOption[] = TRANSFER_REASONS;
  public currentDate = moment().format(DATE_TIME_FORMAT);
  public reason = new FormControl<string>('');

  public isLoading = false;

  constructor(
    private calendarEventService: CalendarEventService,
    private jitsuLoggerService: JitsuLoggerService,
    @Self() private unsubscribeService: UnsubscribeService
  ) {}

  public onSubmit(): void {
    const { eventId } = this.event;
    const { value: reason } = this.reason;

    this.jitsuLoggerService.logEvent(CommitteeEventActions.transferEvent, {
      eventId
    });

    this.isLoading = true;
    this.calendarEventService
      .cancelEvent(eventId, reason)
      .pipe(
        tap(() => this.cancelFinish.emit(reason)),
        finalize(() => (this.isLoading = false)),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }
}
