import { Injectable } from '@angular/core';
import { DayInfo, DayInfoDto, EventInterval, RoomId, UserId } from '@common/dialogs/planning-dialog/types';
import { BusyTimeline } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/types';
import { IntersectionService } from '@common/services';
import { IEmployeeShort } from '@common/types';
import {
  findMaxAndMinTime,
  restrictByPlanningWindow
} from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/utils';

@Injectable({
  providedIn: 'root'
})
export class BusyTimelineService {
  constructor(private intersectionService: IntersectionService) {}

  public getTimeline(
    { busyMembers, busyRooms }: DayInfo | DayInfoDto,
    employees: IEmployeeShort[],
    planningStart?: string,
    planningEnd?: string
  ): BusyTimeline[] {
    return [
      ...this.getMembersTimeline(busyMembers, employees, planningStart, planningEnd),
      ...this.getRoomsTimeline(busyRooms, planningStart, planningEnd)
    ];
  }

  private getMembersTimeline(
    busyMembers: Record<UserId, EventInterval[]>,
    employees: IEmployeeShort[],
    planningStart?: string,
    planningEnd?: string
  ): BusyTimeline[] {
    const result: BusyTimeline[] = [];
    for (const userId in busyMembers) {
      if (this.filterIntersectionEvents(busyMembers[userId], planningStart, planningEnd)) {
        const { fullName, position, isActive } = employees.find((u) => u.id === userId);
        result.push({
          id: userId,
          position,
          fullName,
          isActive,
          isRoom: false,
          events: busyMembers[userId]
        });
      }
    }
    return result;
  }

  private getRoomsTimeline(
    busyRooms: Record<RoomId, EventInterval[]>,
    planningStart?: string,
    planningEnd?: string
  ): BusyTimeline[] {
    const result: BusyTimeline[] = [];
    for (const roomId in busyRooms) {
      if (this.filterIntersectionEvents(busyRooms[roomId], planningStart, planningEnd)) {
        result.push({
          id: roomId,
          fullName: this.intersectionService.getRoomName(roomId),
          position: null,
          isRoom: true,
          isActive: true,
          events: busyRooms[roomId]
        });
      }
    }
    return result;
  }

  private filterIntersectionEvents(
    events: EventInterval[],
    forecastStart?: string,
    forecastEnd?: string
  ): boolean {
    const [max, min] = findMaxAndMinTime(restrictByPlanningWindow(events, forecastStart, forecastEnd));

    if (!forecastEnd || !forecastEnd) return true;

    return !(!max || !min);
  }
}
