import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICalendarCommitteeEvent } from '../types';

@Injectable({
  providedIn: 'root'
})
export class CommitteeEventActionService {
  constructor(private readonly _http: HttpClient) {}

  public pauseEvent(committeeId: string): Observable<void> {
    return this._http.post<void>( // change to patch
      `api/CommitteeEventAction/${committeeId}/pauseEvent`,
      {}
    );
  }

  public continueEvent(committeeId: string): Observable<void> {
    return this._http.post<void>( // change to patch
      `api/CommitteeEventAction/${committeeId}/continueEvent`,
      {}
    );
  }

  public setQuorum(committeeId: string): Observable<void> {
    return this._http.patch<void>(
      `api/CommitteeEventAction/${committeeId}/SetQuorum`,
      {}
    );
  }

  public suspendEvent(
    committeeId: string,
    reason: string,
    disconnectCall: boolean
  ): Observable<void> {
    return this._http.post<void>(
      `api/CommitteeEventAction/${committeeId}/suspendedEvent`,
      {
        suspendedReason: reason,
        disconnectCall: disconnectCall
      }
    );
  }

  public suspendAndCreateUplannedEvent(
    committeeEventId: string,
    eventTime: string
  ): Observable<ICalendarCommitteeEvent> {
    return this._http.post<ICalendarCommitteeEvent>(
      'api/CommitteeEventAction/SuspendedAndCreateUplannedEvent',
      {
        committeeEventId,
        eventTime
      }
    );
  }
}
