import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, Observable } from 'rxjs';
import { CalendarEventService } from '@common/services';
import { SnackbarComponent } from '@common/shared';
import { ProtocolStatusEnum } from '@common/enums';

@Injectable({
  providedIn: 'root'
})
export class ProtocolStatusGuard implements CanActivate {
  constructor(
    protected readonly router: Router,
    private readonly _snackBar: MatSnackBar,
    private readonly _calendarEventService: CalendarEventService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const eventId = route.params.eventId;

    return this._calendarEventService.retrieveCalendarEvent(eventId).pipe(
      map((calendarEvent) => {
        if (calendarEvent.protocolStatus === ProtocolStatusEnum.FORMING) {
          this._snackBar.openFromComponent(SnackbarComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
            data: {
              icon: 'heroicons_outline:exclamation',
              iconClass: 'text-red-500',
              title: 'Протокол не сформирован',
              message: 'Не сформирован протокол для данного события!'
            },
            panelClass: 'committees-app'
          });
          this.router.navigate([`/calendar/${eventId}`]);
        }

        // Комитет будет завершен для всех участников. Вы не сможете повторно подключиться к видеоконференции
        return true;
      })
    );
  }
}
