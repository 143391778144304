import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WeekDayCell, WeekDayMap } from '@common/dialogs/planning-dialog/modules/planning-diagram/types';
import { DAY_IN_WEEK } from '../../const';

@Component({
  selector: 'com-planning-diagram-block',
  templateUrl: 'planning-diagram-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningDiagramBlockComponent {
  @Input() date: Date = new Date();
  @Input() cellSize: number;
  @Input() weekDayMap: WeekDayMap | null = null;
  protected DayInWeek = DAY_IN_WEEK;

  @Output() cellClick = new EventEmitter<WeekDayCell>();
}
