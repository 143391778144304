import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'com-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      state(
        'collapsed',
        style({
          height: '0'
        })
      ),
      transition('expanded <=> collapsed', animate('200ms ease-in-out'))
    ])
  ]
})
export class FormInputComponent implements AfterViewInit, OnChanges {
  @Input() clearable = false;
  @Input() readonly = false;
  @Input() control: FormControl<string | number> = new FormControl<string>(null);
  @Input() type = 'text';
  @Input() placeholder = 'Введите значение';
  @Input() preIcon: string;
  @Input() postIcon: string;
  @Input() max: number | string = null;
  @Input() min: number | string = 1;
  @Input() isMultiline = false;
  @Input() hint: string | null = null;
  @Input() alwaysShowHint = false;
  @Input() step = false;
  @Input() showHint = false;

  @Output() inputFocus = new EventEmitter<void>();
  @Output() inputFocusOut = new EventEmitter<void>();

  @ViewChild('textarea', { read: CdkTextareaAutosize })
  textarea: CdkTextareaAutosize;

  ngAfterViewInit(): void {
    this.textarea?.resizeToFitContent(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('type' in changes) {
      switch (changes.type.currentValue) {
        case 'datetime-local':
          this.max = '9999-12-31T23:59';
          break;
        case 'date':
          if (!this.max) {
            this.max = '9999-12-31';
          }
          break;
      }
    }
  }

  public clear(evt): void {
    evt.stopPropagation();
    this.control.reset();
  }
}
