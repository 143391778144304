import { Pipe, PipeTransform } from '@angular/core';
import { IntersectionEvent } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';
import { EventTypeIds } from '@common/types/calendar-api';

@Pipe({ name: 'dialogValidation' })
export class DialogValidationPipe implements PipeTransform {
  transform(events: IntersectionEvent[]): boolean {
    return events.some((event) => event.eventTypeId === EventTypeIds.committee2_0);
  }
}
