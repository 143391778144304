<div class="flex justify-between items-center">
  <button
    class="h-[60px] px-3 flex items-center cursor-pointer hover:bg-gradient-to-t from-white from-10% via-[#f8fafc] via-20% to-white to-90"
    (click)="changeSelectedIndex.emit(-1)"
  >
    <mat-icon
      class="icon-size-6 opacity-80"
      svgIcon="heroicons_outline:chevron-double-left"
    ></mat-icon>
  </button>
  <div class="flex flex-col items-center">
    <div class="flex gap-1">
      <span class="text-sm font-semibold -mb-1">{{ timeRange }}</span>
    </div>
    <h3
      class="font-semibold max-w-[570px] truncate text-lg"
      [matTooltip]="committeeTitle.length > 50 ? committeeTitle : null"
    >
      {{ committeeTitle }}</h3>
    <span class="text-sm"> {{ eventDate | dateRu }} </span>
  </div>
  <button
    class="h-[60px] px-3 flex items-center cursor-pointer hover:bg-gradient-to-t from-white from-10% via-[#f8fafc] via-20% to-white to-90"
    (click)="changeSelectedIndex.emit(1)"
  >
    <mat-icon
      class="icon-size-6 opacity-80"
      svgIcon="heroicons_outline:chevron-double-right"
    ></mat-icon>
  </button>
</div>
