import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'com-form-checkbox',
  templateUrl: './form-checkbox.component.html'
})
export class FormCheckboxComponent {
  @Input() public control: FormControl;
  @Input() public label: string;
  @Input() public disabled: boolean = false;
}
