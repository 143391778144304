<div
  *ngIf="!isNil(confirmation); else empty"
  class="flex items-center space-x-1"
>
  <mat-icon
    class="icon-size-4"
    [svgIcon]="
      confirmation ? 'heroicons_solid:check-circle' : 'heroicons_solid:ban'
    "
  ></mat-icon>
  <span>{{ confirmation ? 'Да' : 'Нет' }}</span>
</div>

<ng-template #empty>
  <span>-</span>
</ng-template>
