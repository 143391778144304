<form
  class="flex flex-col gap-y-2 min-w-96 max-h-128 overflow-y-auto p-8"
  (ngSubmit)="onSubmit()"
>
  <div>
    <div class="text-2xl font-semibold tracking-tight">
      Редактирование переговорной
    </div>
    <com-form-block [showDivider]="false">
      <com-form-multiselect
        [control]="formControl"
        [options]="meetingRooms"
      ></com-form-multiselect>
    </com-form-block>
  </div>
  <div class="flex flex-col gap-y-2">
    <div class="text-2xl font-semibold tracking-tight">
      Проверка пересечений
    </div>
    <ng-container *ngIf="dataSource.length; else noIntersections">
      <p>
        Имеются пересечения по переговорным. Необходимо изменить выбранные
        переговорные для сохранения изменений.
      </p>
      <div>
        <span>Количество пересечений: </span>
        <span class="text-red-600">{{ dataSource.length }}</span>
      </div>
      <com-table
        [fixedWidth]="true"
        [dataSource]="dataSource"
        [columns]="roomsDisplayColumns"
      ></com-table>
    </ng-container>
    <ng-template #noIntersections>
      Нет пересечений по переговорным
    </ng-template>
  </div>
  <div class="flex justify-end gap-x-4 mt-4">
    <button type="button" mat-stroked-button mat-dialog-close>Отмена</button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="formControl.invalid || !!dataSource.length || isChecking"
    >
      Сохранить
    </button>
  </div>
</form>
