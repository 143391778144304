import { Injectable } from '@angular/core';
import { AnalyticsContext, AnalyticsInterface } from '@jitsu/protocols/analytics';
import { jitsuAnalytics } from '@jitsu/js';
import { LoggingActions, LoggingData} from '@common/constants/jitsu-logger.actions';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JitsuLoggerService {
  private jitsu: AnalyticsInterface;

  constructor() {
    this.initJitsuConfig();
  }

  public connect(userId: string): void {
    this.initJitsuConfig();
    this.identifyUser(userId);
  }

  public logEvent(action: LoggingActions, data?: LoggingData, context?: AnalyticsContext): void {
    this.jitsu.track({
      event: action,
      properties: data,
      context
    });
  }

  private initJitsuConfig(): void {
    this.jitsu = jitsuAnalytics({
      host: environment.jitsuLogging.hostUrl,
      writeKey: environment.jitsuLogging.apiKey
    });
  }

  private identifyUser(userId: string): void {
    this.jitsu.identify(userId);
  }
}
