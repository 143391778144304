import { IEmployee } from '@common/types/employee';
import { MemberAllowanceStatusEnum } from '@common/enums';

export interface ITestResult {
  employee: IEmployee;
  percentResult: number;
  result: boolean;
  testId: string;
  testName: string;
  testUrl: string;
  status: MemberAllowanceStatusEnum;
  deadline: string;
}
