import { NgModule } from '@angular/core';
import { IntersectionDialogComponent } from '@common/dialogs';
import { IntersectionTimelineComponent } from '@common/dialogs/intersection-dialog/components/intersection-timeline/intersection-timeline.component';
import { TimelineEventTooltipPipe } from '@common/dialogs/intersection-dialog/pipes/timeline-event-tooltip.pipe';
import { DateRuPipe } from '@common/dialogs/intersection-dialog/pipes/date-ru.pipe';
import { EntityStatusDirective } from '@common/dialogs/intersection-dialog/directives/entity-status.directive';
import { ScaleCellDirective } from '@common/dialogs/intersection-dialog/directives/scale-cell.directive';
import { TimelineCellDirective } from '@common/dialogs/intersection-dialog/directives/timeline-cell.directive';
import { EmployeeBadgeComponent } from '@common/dialogs/intersection-dialog/components/employee-badge/employee-badge.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { IntersectionListComponent } from '@common/dialogs/intersection-dialog/components/intersection-list/intersection-list.component';
import { CommonModule } from '@angular/common';
import { AvatarModule } from '@common/shared/components/avatar/avatar.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollToSelectedDirective } from '@common/dialogs/intersection-dialog/directives/scroll-to-selected.directive';
import { EventTypeTagComponent } from '@common/dialogs/intersection-dialog/components/event-type-tag/event-type-tag.component';
import { EventNavigationComponent } from '@common/dialogs/intersection-dialog/components/event-navigation/event-navigation.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TimelineFilterComponent } from '@common/dialogs/intersection-dialog/components/timeline-filter/timeline-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogValidationPipe } from '@common/dialogs/intersection-dialog/pipes/dialog-validation.pipe';
import { TimelineLegendComponent } from '@common/dialogs/intersection-dialog/components/timeline-legend/timeline-legend.component';

@NgModule({
  declarations: [
    IntersectionDialogComponent,
    IntersectionTimelineComponent,
    EmployeeBadgeComponent,
    IntersectionListComponent,
    EventTypeTagComponent,
    EventNavigationComponent,
    TimelineFilterComponent,
    TimelineLegendComponent,
    TimelineEventTooltipPipe,
    DateRuPipe,
    DialogValidationPipe,
    EntityStatusDirective,
    ScaleCellDirective,
    TimelineCellDirective,
    ScrollToSelectedDirective
  ],
  exports: [IntersectionTimelineComponent, TimelineFilterComponent, TimelineLegendComponent, DateRuPipe],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    AvatarModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class IntersectionDialogModule {}
